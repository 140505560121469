import {useCallback, useEffect, useState} from "react";
import {copyResumeOfUser, deleteResumeOfUser, editResumeName, listResumeUser} from "@/http/api";
import configHandler from "@/common/config/configHandler";
import Resume from "@/components/resume";
import * as React from "react";
import FlexStyled from "@/styled/FlexStyled"
import {useNavigate} from "react-router-dom";
import LogoComponent from "@/components/Logo";
import HeaderContainerStyled from "@/styled/HeaderContainerStyled";
import User from "@/components/user";
import {Button, ConfigProvider, message, Spin, Input} from "antd";
import ImportModal from "@/components/ImportModal";

import {
    ContainerStyled,
    ContentContainer,
    ListContentContainer,
    PreviewContentContainer,
    ContentHeaderStyled,
    TitleTextStyled,
    ButtonPositionStyled,
    EditButtonStyled,
    PreviewBoxContainer,
    ListNullWrapStyled,
    Button2Styled,
    icon3
} from "./styled"

import moment from "moment/moment";
import withConfigProvider from "@/hoc/withConfigProvider";
import PreviewFooter from "./PreviewFooter";
import Pagination_c from "../Home/Pagination_c";
import JzThemeButton from "@/components/JzThemeButton";

export default withConfigProvider(() => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [importModalVisible, setImportModalVisible] = useState(false);
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        defaultCurrent: 1,
        defaultPageSize: 6,
        current: 1,
        pageSize: 6,
        total: 0,
    })
    const fetchData = async () => {
        setLoading(true)
        try {
            const res = await listResumeUser({
                query: {
                    page: pagination.current,
                    pageSize: pagination.pageSize
                }
            });
            const list = res?.dataList?.map((item) => {
                const dataStr = item.pagesData?.[0]?.body;
                let data;
                let template;
                data = JSON.parse(item.pagesData?.[0]?.userData);
                return {
                    data,
                    resume: {
                        id: item.id, resumeId: item.id,
                    },
                    resumeData: data,
                    name: item.name,
                    updateTime: moment(item.updateTime).format("YYYY-MM-DD HH:mm"),
                    id: item.id
                }
            });
            setPagination({
                ...pagination,
                total: res.count
            })
            setList(list);
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    };
    const onClick = (item) => {
        window.open("/resume?id=" + item.id)
    }
    const onClickHome = () => {
        navigate("/", {
            state: { scroll: 1 }
        })
    }
    const onCLickImport = () => {
        setImportModalVisible(true)
    }

    const handleFooterChange = () => {
        fetchData()
    }

    useEffect(() => {
        window.loginSuccessCallback = () => {
            fetchData()
        }

        return () => {
            window.loginSuccessCallback = undefined
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [pagination.current])

    const onChangeCurrent = (current) => {
        setPagination({
            ...pagination,
            current
        })
    }

    // 因为iframe 传入时会有延时导致短暂白屏，现主动加个延时，隐藏iframe后再显示
    const [showIframe, setShowIframe] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShowIframe(true);
        }, 1000);
    }, []);

    return <>
        <ContainerStyled>
            <HeaderContainerStyled>
                <FlexStyled $alignItems="center" $justifyContent="space-between"
                            style={{height: "100%", width: '100%'}}>
                    <LogoComponent isWhite={true}/>
                    <FlexStyled $alignItems='center'>
                        <User/>
                    </FlexStyled>
                </FlexStyled>
            </HeaderContainerStyled>
            <FlexStyled $justifyContent="center">
                <ContentContainer>
                    <ContentHeaderStyled>
                        <TitleTextStyled>我的简历（{pagination?.total}）</TitleTextStyled>
                        <ButtonPositionStyled className="flex" style={{right: 19}}>
                            {/* <Button2Styled onClick={onCLickImport} className="mr-10">
                                {icon3}
                                <div className="ml-4">
                                    导入简历
                                </div>
                            </Button2Styled> */}
                            <JzThemeButton.Type3 styles={{height: 39, width: 112, padding: 0}} className='mr-10' onClick={onCLickImport}>
                                {icon3}
                                <div className="ml-4">
                                    导入简历
                                </div>
                            </JzThemeButton.Type3>
                            {/* <Button onClick={onClickHome} type="primary"
                                    style={{height: 40, width: 112, padding: 0}}>新建简历</Button> */}
                            <JzThemeButton.Type1 styles={{height: 40, width: 112, padding: 0}} onClick={onClickHome}>
                                新建简历
                            </JzThemeButton.Type1>
                        </ButtonPositionStyled>
                    </ContentHeaderStyled>
                    {loading || !showIframe && <FlexStyled $justifyContent="center" style={{width: "100%"}}>
                        <Spin/>
                    </FlexStyled>}
                    <ListContentContainer>
                        {list.map((item) => {
                            return  <PreviewBoxContainer style={{opacity: showIframe ? 1 : 0}} key={item.id}>
                                <PreviewContentContainer className="resume-preview"
                                                         onClick={() => onClick(item)}>
                                    <div style={{position: "relative", zIndex: 1}}>
                                        <iframe
                                            loading="lazy"
                                            id={item.id}
                                            width="362"
                                            height="512"
                                            style={{opacity: 0}}
                                            onLoad={(e) => {
                                                e.target.style.opacity = 1
                                            }}
                                            src={`/resume/container?id=${item.id}`}/>
                                    </div>
                                    <EditButtonStyled className="resume-edit-button">
                                        <Button type="primary" style={{width: 160, height: 40, padding: 0}}>
                                            编辑简历
                                        </Button>
                                    </EditButtonStyled>
                                </PreviewContentContainer>
                                <PreviewFooter item={item} onChange={handleFooterChange} />
                            </PreviewBoxContainer>
                        })}

                        {list.length <= 0 && <ListNullWrapStyled>
                            <img src={"https://jianzeppt-backend-shanghai.oss-cn-shanghai.aliyuncs.com/jz-ppt-img/kjmcxbvkjui21.png"}  alt="null" />
                            <p>暂无简历</p>
                        </ListNullWrapStyled>}
                    </ListContentContainer>
                    <Pagination_c
                        defaultCurrent={pagination.defaultCurrent}
                        defaultPageSize={pagination.defaultPageSize}
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        total={pagination.total}
                        onChange={onChangeCurrent}
                    />
                    <div style={{ marginBottom: 20 }} />
                </ContentContainer>
            </FlexStyled>
            <ImportModal visible={importModalVisible} onCancel={() => setImportModalVisible(false)}/>
        </ContainerStyled>

    </>
})
