import {useCallback, useEffect, useMemo, useRef} from "react";
import {useDispatch, useStore} from "@/components/resume/redux/hooks";
import versionSlice from "@/components/resume/redux/services/versionSlice";
import resumeSlice from "@/components/resume/redux/services/resumeSlice";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";

export default () => {
    const store = useStore();
    const resumeInstance = useResumeInstance();
    const instance = useRef({
        unSubscribe: undefined, open: resumeInstance.openEdit, currentState: null
    }).current;
    const dispatch = useDispatch();
    const handler = useCallback(() => {
        const list = ['resume', 'resumeData', 'pagination'];
        const changeKey = new Set()
        const canLog = list.some((key) => {
            const state = store.getState();
            if (key === 'pagination') {
                const list = [
                    'openAutoOnePage',
                    'openAutoPagination',
                    'openHintPagination',
                    'hintPaginationLineVisible'
                ];
                return list.some((i) => {
                    if (state[key][i] !== instance.currentState?.[key][i]) {
                        changeKey.add(`${key}+${i}`)
                    }
                    return state[key][i] !== instance.currentState?.[key][i]
                })
            }
            if (state[key] !== instance.currentState?.[key]) {
                changeKey.add(key);
            }
            return state[key] !== instance.currentState?.[key];
        })
        if (instance.open && canLog) {
            versionLog();
        }
        instance.currentState = store.getState();
    }, []); //指定数据发生改变时，记录
    const listenChange = useCallback(() => {
        clearListenChange()
        instance.unSubscribe = store.subscribe(handler);
    }, []);
    const clearListenChange = useCallback(() => {
        instance.unSubscribe?.();
    }, []);
    const openListen = useCallback( () => {
        instance.currentState = store.getState();
        instance.open = true;
        resumeInstance.dataMaster.open = true;
        listenChange()
    }, []);
    const closeListen = useCallback(() => {
        resumeInstance.dataMaster.open = false;
        instance.open = false;
        clearListenChange()
    }, []);
    const versionLog = useCallback(() => {
        const {resume, resumeData} = store.getState();
        resumeInstance.version.push({resume, resumeData});
        closeListen();
        if (resumeInstance.version?.currentVersion) {
            dispatch(versionSlice.actions.setVersionCurrent(resumeInstance.version?.currentVersion?.id));
        }
        openListen();
    }, []); //记录当前版本
    const versionPre = useCallback(() => {
        if (resumeInstance.version.canPre) {
            resumeInstance.version.preVersion();
            changeDataByVersion()
        }
    }, []); //上一版本
    const versionNext = useCallback(() => {
        if (resumeInstance.version.canNext) {
            resumeInstance.version.nextVersion();
            changeDataByVersion()
        }
    }, []); //下一版本
    const changeDataByVersion = useCallback(() => {
        closeListen();
        const version = resumeInstance.version.currentVersion;
        if (version) {
            dispatch(versionSlice.actions.setVersionCurrent(version.id));
            const {data} = version;
            const versionResume = data.resume;
            const versionResumeData = data.resumeData;
            const {resume, resumeData} = store.getState();
            if (resume !== versionResume) {
                dispatch(resumeSlice.actions.set(versionResume))
            }
            if (resumeData !== versionResumeData) {
                dispatch(resumeDataSlice.actions.set(versionResumeData))
            }
        }
        openListen();
    }, []);

    useMemo(() => {
        resumeInstance.openListen = openListen;
        resumeInstance.closeListen = closeListen;
        resumeInstance.versionLog = versionLog;
        resumeInstance.versionPre = versionPre;
        resumeInstance.versionNext = versionNext;
    }, []);
    useEffect(() => {
        listenChange()
        return () => {
            clearListenChange();
        }
    }, []);
    useEffect(() => {
        instance.currentState = store.getState();
    }, []);
}
