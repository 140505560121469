// import Header from '@/components/TemplateStation/Header';
import React from "react";
import {
    ArticleContainerStyled,
    ArticleTitleStyled,
    ArticleParagraphStyled,
    ArticleTitle2Styled,
} from "@/pages/Agreement/styled";
// import FlexStyled from '@/styled/FlexStyled';
import config from "@/const";
import Footer from "@/components/Footer";
import styled from "styled-components";
import Header from "@/components/Header";

const FlexStyled = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  align-self: ${(props) => props.alignSelf};
  flex: ${(props) => props.flex};
  flex-wrap: ${(props) => props.flexWrap};
  flex-wrap: ${(props) => props.flexWrap};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
`;

export default () => {
    return (
        <>
            <FlexStyled
                flexDirection="column"
                style={{ minHeight: "100vh", background: "#eceff1" }}
            >
                <Header />
                <FlexStyled
                    flexGrow={1}
                    justifyContent="center"
                    style={{ paddingTop: 40, paddingBottom: 40 }}
                >
                    <ArticleContainerStyled>
                        <ArticleTitleStyled>了了简历用户协议</ArticleTitleStyled>
                        <ArticleTitle2Styled>一、提示条款</ArticleTitle2Styled>
                        <ArticleParagraphStyled>
                            各条款标题仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身合法权益，建议仔细阅读各条款具体表述您在申请注册过程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款您应重点阅读。如您对协议有任何疑问，可向了了简历客服咨询。当您按照注册页面提示填写信息、阅速井同意本协议目完成全部注册程序后，即表示您已充分阅速。理解并接受本协议的全部内容，并与了了简历达成一致，成为了了简历网站"用户"。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。如果您未申请注册流程，或者在本协议生效前已成为了了简历的注册用户，则您通过访问和/或使用了了简历网站，即视为您表示同意接受本协议的全部内容，否则请您不要访问或使用了了简历网站。了了简历网址，由杭州天资差异科技有限公司所有及运营。
                        </ArticleParagraphStyled>
                        <ArticleTitle2Styled>二、协议范围</ArticleTitle2Styled>
                        <ArticleParagraphStyled></ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            2.1
                            签约主体本协议由您与杭州天资差异科技有限公司（了了简历网站经营者），以下简称“了了简历”共同缔结，本协议对您与杭州天资差异科技有限公司均具有合同效力。
                            本协议项下，了了简历网站的经营者可能根据了了简历的业务调整而发生变更，变更后的了了简历经营者与您共同履行本协议并向您提供服务，了了简历网站的经营者的变更不会影响您本协议项下的权益。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            2.2
                            补充协议由于互联网行业的高速发展和法律法规的更新，您与了了简历签署的本协议列明的条款并不能完整罗列并覆盖您与了了简历所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此、了了简历公示的
                            <a target="_blank" href={"/agreement/law"}>
                                法律声明
                            </a>
                            也为本协议的补充协议，是本协议的组成部分。如你使用了了简历的相关服务，视为您同意上述补充协议.
                        </ArticleParagraphStyled>
                        <ArticleTitle2Styled>三、账户注册与使用</ArticleTitle2Styled>
                        <ArticleParagraphStyled></ArticleParagraphStyled>
                        <ArticleParagraphStyled>3.1 用户资格</ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            您确认，在您开始注册程序使用了了简历服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，请您务必在您的监护人仔细阅读协议内容并同意的前提下，完成注册。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>3.2 账户说明</ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            [账户获得]
                            当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得了了简历账户(即了了简历用户ID)并成为了了简历网站用户。了了简历账户的所有权归了了简历，用户完成注册申请手续后，获得了了简历账号的使用权。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            [账户使用]
                            您有权使用您设置或确认的了了简历会员名、邮箱、手机号码(以下简称“账户名称”)
                            及您设置的密码(账户名称及密码合称"账户”）登录了了简历网站。由于您的了了简历账户关联您的个人信息及了了简历商业信息，您的了了简历账户仅限您本人使用。未经了了简历同意，您不得直接或间接授权第三方使用您了了简历账户或获取您账户项下信息，否则该授权的行为无效，因此给了了简历造成的所有损失由您赔偿，了了简历有权冻结您的账，并不予退还未使用的VIP权限。如了了简历判断您账户的使用可能危及您的账户安全及/或了了简历信息安全的，了了简历可拒绝提供相应服务或终止本协议。您若发现账号遭他人非法使用，应立即通知了了简历。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            [账户转让]由于用户账户关联用户信用信息，仅当有法律法规明文规定、司法裁定或经了了简历同意，并符合了了简历规则规定的用户账户转让流程的情况下，您可进行账户的转让(此处仅指用户账户使用权的转让)。您的账户一经转让，该账户项下权利义务一并转移。除此之外，您的账户不得以任何方式转让、赠与、借用、租用或售卖您的了了简历账号，否则了了简历有权追究您的违约责任，且由此产生的一切责任均由您承担。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            [实名认证]作为了了简历网站的经营者，为使您更好地使用了了简历的各项服务，保障您的账户安全，了了简历可要求您按了了简历要求及我国法律法规规定完成实名认证。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            [账号回收]如您的账户超过一年未登录，了了简历有权予以进行注销或冻结清理，您的账号可能会无法再登录了了简历网站，相应服务同时终止。了了简历在对此类账号进行清理前，将以包括但不限于网站公告、站内消息等方式通知您。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            [账号下载限制]了了简历赋予每个账号在满足一定条件的情况下，通过网站正式程序下载作品的资格，但作为网站管理者，有权为保护账号安全以及网站运营规则，同时给予一定的下载限制(如1秒钟下载超过5次、短时间内高频大量下载等行为进行输入验证码、暂时限制下载等限制机制，第二天即可恢复正常使用)，而不因此承担法律责任。了了简历向用户提供申诉途径，但了了简历有权根据该使用者的具体情况，自主决定是否开放下载限制。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>3.3 注册信息管理</ArticleParagraphStyled>
                        <ArticleParagraphStyled>3.3.1 真实合法</ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            在使用了了简历服务时，您应当了了简历页面的提示准确完整地提供您的信息(包括您的姓名及电子邮件地址、联系电话、联系地址等），以便了了简历在必要时与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性。
                            您所设置的账户名不得违反国家法律法规及了了简历关于账户名的相关管理规定，否则了了简历可对您的账户名进行暂停使用或注销等处理，必要时可向主管机关报告。
                            您理解并承诺，您的账户名称、头像和简介等注册信息中不得出现任何违法或不良信息，没有冒用、关联机构或社会名人，您在账户注册过程中需遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等各项底线。
                            您同意并授权，为了更好地为您提供服务以及确保您的账户安全，了了简历可以根据您提供的手机号码、身份证号码等信息。在法律法规分许和协议约定的范围内，向全国公民身份号码查询服务中心、电信运营商、金融服务机构等可靠单位发起用户身份真实性、用户征信记录、用户手机号码有效性状态等情况的查询。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>3.3.2 更新维护</ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            您应当及时更新您提供的信息，在法律法规有明确规定要求了了简历对部分用户的信息进行核实的情况下，了了简历将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整的信息。如了了简历按您最后一次提供的信息与您联系未果、您未按照了了简历的要求及时提供信息、您提供的信息存在明显不实的，您将承担因此对您自身他人及了了简历造成的全部损失与不利后果。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>3.4 账户安全规范</ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            您的账户为您自行设置并由您保管。建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开了了简历网站。账户因您主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担。
                            您的账户只限您本人使用，不得出借或分享他人使用。当您的账户遭到未经授权的使用时，您应当立即通知了了简历，否则未经授权的使用行为均视为您本人的行为，您将自行承担所有由此导致的损失及后果。
                            除了了简历存在过错外，您应对您账户项下的所有行为结果(包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等)负责。如发现任何未经授权使用您账户登录了了简历或其他可能导致您账户遭窃、遗失的情况，您应立即通知了了简历。您理解了了简历对您的任何请求采取行动均需要合理时间，了了简历存在明显过错外，了了简历对在采取行动前已经产生的后果不承担任何责任。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>3.5 账号独立</ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            3.5.1
                            同一主体拥有的QQ账号及微信账号，在进行了了简历的VIP会员登录时，将被识别为两个独立的账号；
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            3.5.2
                            同一主体拥有多个QQ账号并进行了了简历的VIP会员登录的，每一个QQ账号将被识别为一个独立的账号；
                            3.5.3
                            同一主体拥有多个微信账号并进行了了简历的VIP会员登录的，每一个微信账号将被识别为一个独立的账号；
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            3.5.4
                            每个账号之间的充值记录、VIP权限无法进行转移、迁徙、转让、赠与、售卖、租借、分享，无论该等账号是否由同一使用人拥有，请您在登录充值/参加活动时注意区分，避免造成损失。由此造成的损失由VIP会员自行承担。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            3.5.5
                            您知悉并确认，开通会员服务后，若您中途主动取消服务或终止资格或被了了简历根据《了了简历网络服条使用协议》，本协议及相关规则注转账号、终止会员资格的，您已支付的会员服务费用将不予退还。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            3.5.6
                            如您有其他与会员服务售后相关的问题，可以通过了了简历公布的联系方式联系客服进行反馈。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled></ArticleParagraphStyled>
                        <ArticleTitle2Styled>四、了了简历的服务及规范</ArticleTitle2Styled>
                        <ArticleParagraphStyled>
                            4.1 了了简历服务
                            了了简历向您提供包括但不限于以下服务:1.了了简历网站服务;2.了了简历(付费)会员服务（下称“会员服务”）;
                            对于收费的网络服务，了了简历会在用户使用之前给予用户明确的提示，只有用户根据提示确认其愿意支付相关费用，用户才能使用该等收费网络服务。如用户拒绝支付相关费用，则了了简历有权不向用户提供该等收费网络服务。3.图片、文档上传分享服务(下称"内容上传服务”），4.了了简历搜索服务(下称"搜索服务";5.了了简历下载服务(下称“下载服务”);
                            6.了了简历提供的其他技术和/或服务(下称“其他技术和服务”)。
                            (以上服务统称为“了了简历服务”)。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>4.2 服务规范</ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.2.1
                            您可通过了了简历服务在了了简历上传、发布或传输相关内容，包括但不限于文字、软件、程序、图形、图片、声音、音乐、视频、音视频、链接等信息或其他资料
                            (下称“内容”)，但您需对此内容承担相关的法律责任。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.2.2
                            除非有相反证明，了了简历将您视为您在了了简历上传、发布或传输的内容的版权拥有人。您需保证对于通过了了简历网络服务上传到了了简历网站上的作品应拥有全部的所有权，包括但不限于版权或版权合法授权及模特肖像权授权、物权授权等其他相关合法权利。同时保证上传的作品不得侵犯或违反第一方的版权、专利权、商标权、商业秘密、隐私权、名誉权、肖像权、物权、公开权或其它知识产权或专有权利
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.2.3
                            您使用了了简历服务上传、发布或传输内容即代表了您有权且同意在全世界范围内，永久性的、不可撤销的、免费的授予了了简历对该内容的存储、使用、发布、复制、发行、展览、网络传播、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示等权利；将内容的全部或部分编入其他任何形式的作品、媒体、技术中的权利;对您的上传、发布的内容进行商业开发的权利，通过有线或无线网络向计算机终端、移动通讯终端(包括但不限于便携式通讯设备如手机和智能平板电脑等)、手持数字影音播放设备、电视接收设备(模拟信号接收设备、数字信号接收设备、数字电视、IPTV、带互联网接入功能的播放设备等)等提供信息的下载、数据传输、及其相关的宣传和推广等服务的权利;再授权给其他第三方以上述方式使用的权利;以及对作品进行单独维权的权利。您同意在使用我方平台服务过程中，遵守以下法律法规：《中华人民共和国保守国家秘密法》、《中华人民共和国著作权法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《信息网络传播权保护条例》等有关计算机及互联网规定的法律、法规。在任何情况下，我方一旦合理地认为您的行为可能违反上述法律、法规，可以在任何时候，不经事先通知终止向您提供服务。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.3 服务变更、中断或终止
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.3.1
                            鉴于网络服务的特殊性，用户同意了了简历有权随时变更、中断或终止部分或全部的网络服务(包括收费网络服务)。如变更、中断或终止的网络服务属于免费网络服务，了了简历无需通知用户，也无须对任何用户或任何第三方承担任何责任；如变更、中断或终止的网络服务属于收费网络服务，了了简历应当在变更、中断或终止之前事先通知用户。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.3.2
                            用户理解，了了简历需要定期或不定期地对提供网络服务的平台(如互联网网站)或相关的设备进行检修或者维护，如因此类情况而造成收费网
                            络服务在合理时间内的中断，了了简历无需为此承担任何责任，但了了简历应尽可能事先进行通告。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.3.3
                            如发生下列任何一种情形，了了简历有权随时中断或终止向用户提供本协议项下的网络服务(包括收费网络服务，而无需对用户或任何第三方承担任何责任:1)用户提供的个人资料不真实;2)
                            用户违反本协议中规定的使用规则;3)用户在使用收费网络服务时未按规定向了了简历支付相应的服务费
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.4 其他服务规范
                            为方便您使用了了简历和了了简历关联公司的其他相关服务，您授权了了简历将您在账户注册和使用了了简历服务过程中提供、形成的信息传递给了了简历关联公司等其他相关服务提供者，或从了了简历关联公司等其他相关服务提供者获取您在注册、使用相关服务期间提供、形成的信息。您理解并知晓在使用了了简历服务时，所接触的内容和信息来源广泛，了了简历无法对该内容和信息的准确性、真实性、可用性、安全性、完整性和正当性负责。您理解并认可您可能会接触到不正确的、令人不快的、不适当的或令人厌恶的内容和信息，您不会以此追究了了简历的相关责任。了了简历不对用户在了了简历上传、发布或传输的任何内容和信息背书、推荐或表达观点，也不对任何内容和信息的错误、瑕疵及产生的损失或损害承担任何责任，您对内容和信息的任何使用需自行承担相关的风险。您同意了了简历在提供服务的过程中以各种方式投放商业性广告或其他任何类型的商业信息（包括但不限于在了了简历的任何位置上投放广告，在您上传、传播的内容中投放广告)，您同意接受了了简历通过电子邮件、站内短信、手机短信、网站公告或其他方式向您发送促销或其他相关商业信息。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.5 使用规则：用户在使用了了简历网络服务过程中，必须遵循以下原则
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.5.1遵守中国有关的法律和法规；
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.5.2 遵守所有与了了简历网络服务有关的网络协议、规定和程序；{" "}
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.5.3 不得为任何非法目的而使用网络服务系统{" "}
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.5.4
                            不得利用了了简历网络服务系统进行任何可能对互联网正常运转造成不利影响的行为;
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.5.5
                            不得利用了了简历提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料;
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.5.6
                            不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权、肖像权、隐私权、物权或其他任何合法权益;
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.5.7 不得利用了了简历网络服务系统进行任何不利于了了简历的行为;
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.5.8
                            如发现任何非法使用用户账号或账号出现安全漏洞的情况，应立即通告了了简历。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            4.5.9
                            网络用户下载了了简历的作品，应在作品的授权许可范围内使用。未经版权拥有者许可，仅限学习交流使用，请勿做他用。同时，作品下载使用者遵守著作权法及其他相关法律的规定，不得侵害本网站及权利人的合法权利。
                        </ArticleParagraphStyled>
                        <ArticleTitle2Styled>五、费用</ArticleTitle2Styled>
                        <ArticleParagraphStyled></ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            5.1
                            了了简历为向您提供的服务付出了大量的成本，了了简历有权根据了了简历相关规则向您收取一定费用作为网站维护的费用。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            5.2
                            退款。若您已使用下载服务，因提供下载服务的特殊性，一经下载文件将会储存于您的电脑，代表您已经使用该服务，除文件本身损坏导致不可用的情况外，一律不接受退款申请。
                        </ArticleParagraphStyled>
                        <ArticleTitle2Styled>六、隐私保护</ArticleTitle2Styled>
                        <ArticleParagraphStyled></ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            6.1
                            保护用户隐私是了了简历的一项基本政策，了了简历保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存在了了简历的非公开内容，但下列情况除外:
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            6.1.1 事先获得用户的明确授权;
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            6.1.2 根据有关的法律法规要求;
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            6.1.3 按照相关政府主管部门的要求;
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            6.1.4 为维护社会公众的利益;
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            6.1.5 为维护了了简历的合法权益。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            6.2
                            了了简历可能会与第三方合作向用户提供相关的网络服有权将用户的注册资料等提供给该第三方。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            6.3 在不透露单个用户隐私资料的前提下，了了简历有权对:
                        </ArticleParagraphStyled>
                        <ArticleTitle2Styled>七、免责声明</ArticleTitle2Styled>
                        <ArticleParagraphStyled>
                            7.1
                            用户知悉其使用了了简历网络服务可能存在的法律风险将由了了简历和用户依照法律规定各自承担相应的责任。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            7.2
                            了了简历依照法律规定履行基础保障义务，但对于因不可抗力或了了简历不能控制的原因造成的网络服务中断或其它缺陷，了了简历在法律规定范围内可依法主张免责，但将尽力减少因此而给用户造成的损失和影响,
                        </ArticleParagraphStyled>
                        <ArticleTitle2Styled>八、违约赔偿</ArticleTitle2Styled>
                        <ArticleParagraphStyled>
                            8.1
                            如因了了简历违反有关法律、法规或本协议项下的任何条款而给用户造成损失，了了简历同意承担由此造成的损害赔偿责任。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            8.2
                            用户同意保障和维护了了简历及其他用户的利益，如用户违反法律法规及本协议规定，给了了简历或第三人造成损害的，用户需要依法承担相应的损害赔偿责任
                        </ArticleParagraphStyled>
                        <ArticleTitle2Styled>九、通知送达</ArticleTitle2Styled>
                        <ArticleParagraphStyled>
                            9.1
                            本协议项下了了简历对于用户所有的通知均可通过网页公告、电子部件或常规的信件传送等方式进行，该等通知干发送之日视为已送达收件人
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            9.2
                            用户对于了了简历的通知应当通过了了简历对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。
                            法律管辖{" "}
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            10.1
                            本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            10.2
                            如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决;协商不成时，任何一方均可向了了简历所在地的人民法院提起诉讼
                        </ArticleParagraphStyled>
                        <ArticleTitle2Styled>十、其他规定</ArticleTitle2Styled>
                        <ArticleParagraphStyled>
                            11.1
                            本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            11.2
                            如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            11.3 本协议中的标题仅为方便而设，在解释本协议时应被忽略。{" "}
                        </ArticleParagraphStyled>
                        <ArticleParagraphStyled>
                            11.4
                            请合理使用任意下载会员账号，如恶意下载模板，将被系统暂停账号下载功能，如有疑问请联系客服。
                        </ArticleParagraphStyled>
                        <ArticleTitle2Styled>联系方式</ArticleTitle2Styled>
                        <ArticleParagraphStyled>
                            在线客服微信号: fantuuan (周一到周五: 9:00-18:30)
                        </ArticleParagraphStyled>
                    </ArticleContainerStyled>
                </FlexStyled>
                <Footer />
            </FlexStyled>
        </>
    );
};
