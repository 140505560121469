import styled from "styled-components";

export const AiDecisionWrapStyled = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 3px 10px 0px rgba(41,117,131,0.24);
    border-radius: 4px;
    border: 1px solid;
    border-image: linear-gradient(209deg, rgba(112, 181, 236, 1), rgba(125, 247, 220, 1)) 1 1;
    padding: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
`