import {createElement} from "react";
import withComponent from "@/hoc/withComponent";
import {useSelector} from "@/components/resume/redux/hooks";
import ModuleContentListItem from "@/components/resume/components/ModuleContentListItem";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import{getModuleContentStyle} from "@/common/getStyleByAutoOnePage";
import {autoOnePage1} from "@/const/config";
import {parseMargin} from "@/utils/margin";


export default withComponent((config) => {
    const moduleInstance = useModuleInstance();
    const content = useSelector((state) => state.resumeData?.[moduleInstance.for_block_name]?.data?.content);
    const onePageLevel = useSelector((state) => state.pagination.onePageLevel);
    const autoOnePageStyle = getModuleContentStyle(onePageLevel);
    const margin = parseMargin(config?.style?.margin);
    return createElement(config.tagName, {
        style: {...config.style, margin: undefined, ...margin, ...autoOnePageStyle},
        className: config.className,
        'data-type': 'module-content'
    }, content?.map?.((item, index) => {
        if (config.contentTemplateConfig?.[0]) {
            return createElement(ModuleContentListItem, {
                ...config.contentTemplateConfig?.[0],
                style: { position: 'relative'},
                index,
                key: index
            })
        }
    }));
})



