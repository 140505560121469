import './index.scss'
import Portal from '../Portal';

const closeIcon = (
  <div
    className='close-icon'
    dangerouslySetInnerHTML={{
      __html: `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>切片</title>
          <g id="H5落地页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.499584">
              <g id="支付页面" transform="translate(-342.000000, -387.000000)" fill="#5D5B5F" fill-rule="nonzero">
                  <g id="对号-选择备份" transform="translate(342.000000, 387.000000)">
                      <g id="guanbi-2" transform="translate(2.500000, 2.500000)">
                          <path d="M7.90077963,6.5 L12.7043812,11.2813617 C13.0908474,11.6702571 13.0994722,12.295408 12.7238816,12.6948121 C12.5460353,12.8852555 12.2983098,12.995115 12.0377354,12.9990994 C11.777161,13.0030837 11.5261909,12.9008495 11.3426024,12.7159326 L6.5,7.89395457 L1.65739757,12.7159326 C1.47402766,12.9010577 1.22318332,13.0035822 0.962610366,13 C0.702037413,12.9962265 0.454187954,12.8866633 0.276118356,12.6964367 C-0.0994721657,12.2970326 -0.0908473851,11.6718817 0.295618768,11.2829863 L5.09922037,6.5 L0.295618768,1.71863835 C-0.0908473851,1.32974291 -0.0994721657,0.704592027 0.276118356,0.305187909 C0.453964691,0.114744518 0.701690195,0.00488497536 0.962264621,0.000900648149 C1.22283905,-0.00308367906 1.47380909,0.099150533 1.65739757,0.284067386 L6.5,5.10604543 L11.3426024,0.284067386 C11.5259723,0.0989423143 11.7768167,-0.00358217378 12.0373896,0 C12.2979626,0.00377346564 12.545812,0.113336696 12.7238816,0.303563254 C13.0994722,0.702967372 13.0908474,1.32811825 12.7043812,1.71701369 L7.90077963,6.5 Z" id="路径"></path>
                      </g>
                  </g>
              </g>
          </g>
      </svg>`,
    }}
  />
);

const CenterModal = ({ visible, onClose, children }) => {
  if (!visible) {
    return null;
  }

  return (
    <Portal>
      <div className="center-modal-wrap">

        <div className="center-modal-content">
          <div className="center-modal-close" onClick={onClose}>
            {closeIcon}
          </div>
          <div className="center-modal-children">
            {children}
          </div>
        </div>
      </div>
    </Portal>

  )
}

export default CenterModal;
