import withComponent from "@/hoc/withComponent"
import styled from "styled-components"
import Header from "./components/Header"
import CommandList from "./components/CommandList"
import FeatureList from "./components/FeatureList"
import Footer from "./components/Footer"
import Pad from "./components/Pad"

const PromoStyled = styled.div`
    background-image: url('https://pub.jianzeppt.cn/image/%E7%AE%80%E5%8E%86%E9%A6%96%E9%A1%B5%E8%83%8C%E6%99%AF.png') ;
    background-size: contain;
    background-repeat:no-repeat;
    background-color:rgb(199, 237, 246);
`

const Promo = () => {

    return <PromoStyled>
        <Header />
        <CommandList />
        <Pad/>
        <FeatureList />
        <Footer />
    </PromoStyled>
}

export default withComponent(Promo)