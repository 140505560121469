import withComponent from "@/hoc/withComponent";
import {useCallback, useMemo, useRef, useState} from "react";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import {useDispatch} from "@/components/resume/redux/hooks";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import styled from "styled-components";
import getDefaultModuleContent from "@/common/config/getDefaultModuleContent";
import {Module} from "@/common/module/module";
import getTextByModule from "@/common/module/getTextByModule";
import {Popover, Tooltip} from "antd";
import Portfolio from "@/components/resume/moduleManage/addMenu/Portfolio";
import DragButton from "@/components/resume/moduleManage/button/DragButton";

const IconContainerStyled = styled.div`
  position: relative;
  width: 28px;
  height: 28px;
  background: #11BBA6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    opacity: 0.2;
  }
`
const addIcon = <div style={{height: 16, width: 16, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0"> 
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="技能特长、资格证书、语言能力、自我评价、兴趣爱好" transform="translate(-2612.000000, -474.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-3备份-47" transform="translate(2608.000000, 470.000000)">
                <g id="设置备份" transform="translate(4.000000, 4.000000)">
                    <g id="tianjia-11" transform="translate(1.142857, 1.142857)">
                        <polygon id="路径" points="13.7142857 5.27472527 8.43956044 5.27472527 8.43956044 0 5.27472527 -3.61615499e-15 5.27472527 5.27472527 -1.49858148e-13 5.27472527 -1.79900051e-13 8.43956044 5.27472527 8.43956044 5.27472527 13.7142857 8.43956044 13.7142857 8.43956044 8.43956044 13.7142857 8.43956044"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>

const visibleMenuConfig = [{blockName: Module.PORTFOLIO, component: Portfolio}];
const visibleMenuBlockNameList = visibleMenuConfig.map((item) => item.blockName);


export default withComponent(() => {
    const moduleInstance = useModuleInstance();
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const addModuleContent = useCallback(() => {
        switch (moduleInstance.for_block_name) {
            default: {
                const defaultModuleContent = getDefaultModuleContent(moduleInstance.for_block_name);
                if (defaultModuleContent) {
                    dispatch(resumeDataSlice.actions.addModuleContent({
                        block_name: moduleInstance.for_block_name, item: defaultModuleContent
                    }));
                }
            }
        }

    }, []);
    const showMenu = useMemo(() => visibleMenuBlockNameList.includes(moduleInstance.for_block_name), [moduleInstance.for_block_name]);
    const onClickItem = useCallback(() => {
        setVisible(false);
    }, []);
    const title = useMemo(() => getTextByModule(moduleInstance.for_block_name), [moduleInstance.for_block_name])
    if (showMenu) {
        let Menu = visibleMenuConfig.find((item) => item.blockName === moduleInstance.for_block_name).component;
        return <Popover content={<Menu onClickItem={onClickItem}/>}
                        overlayClassName="ant-popover-custom"
                        autoAdjustOverflow={false}
                        trigger="click"
                        transitionName=""
                        getPopupContainer={null}
                        open={visible}
                        onOpenChange={setVisible}
                        placement="bottom">
            <IconContainerStyled style={{marginRight: 4}}>
                {addIcon}
            </IconContainerStyled>
        </Popover>
    }
    return <Tooltip title={title ? '添加' + title : "添加"} placement={"bottom"}
                    overlayClassName="tooltipPointerEventsNone">
        <IconContainerStyled onClick={addModuleContent} style={{marginRight: 4}}>
            {addIcon}
        </IconContainerStyled>
    </Tooltip>
})
