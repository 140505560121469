import React from 'react';
import withComponent from '@/hoc/withComponent';
import styled from 'styled-components';
import { searchIcon } from '@/components/IconModal/icon';
import FlexStyled from '@/styled/FlexStyled';


export const ContainerStyled = styled.div`
  width: 760px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 22px;
  border: 1px solid #CDCDCD;
  display: flex;
  align-items: center;
`;
export const ContainerInputStyled = styled.input`
  padding-left: 4px;
  border: none;
  outline: none;
  flex-grow: 1;

  &::placeholder {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    line-height: 14px;
  }
`;

export default withComponent(React.forwardRef((props, ref) => {
  const { onChange, onEnter } = props;
  const onKeyDown = (e)=>{
    if (e.keyCode === 13) {
      onEnter?.();
    }
  }
  return <ContainerStyled>
    <FlexStyled flexShrink={0} style={{ paddingLeft: 24, paddingRight: 20 }}>{searchIcon}</FlexStyled>
    <ContainerInputStyled onKeyDown={onKeyDown} onChange={onChange} ref={ref} placeholder='在海量精美图标中搜索' />
  </ContainerStyled>;
}));
