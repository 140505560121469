import isVip2 from "@/common/isVip";
import {downPdf} from "@/http/api";
// import FileSaver from 'file-saver'
import {useDispatch, useStore} from "react-redux";
import errorSlice from "@/redux/services/errorSlice";
import { PAY_ERROR_CODE } from "../components/ErrorModal/ErrorCode";
import { Toast } from "antd-mobile";
import { isMiniProgram, isWechatBrowser } from "../utils";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { isMobileLogin } from "../utils/login";

const useDownload = (props) => {
    const store = useStore();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const showToast = useCallback((content, icon = 'loading', duration = 0) => {
        Toast.show({
            icon,
            content,
            duration,
        });
    }, []);

    const handleError = useCallback((e) => {
        showToast(e.msg || e.message || '系统繁忙，请重试', 'fail', 3000);
    }, []);

    const clearLoading = () => {
        Toast.clear()
        setLoading(false)
    }

    const down = async (options) => {
        if (loading) return 

        const defaults = {
            resumeName: '我的简历',
            resumeId: ''
        }
        const settings = Object.assign({}, defaults, props, options)

        const {user} = store.getState();
        if (!isMobileLogin()) {
            dispatch(errorSlice.actions.setCode(10004))
            return
        }
        const isVip = isVip2(user);
        if (!isVip) {
            dispatch(errorSlice.actions.setCode(PAY_ERROR_CODE))
            return
        }

        showToast("下载中")
        setLoading(true)
        const url = await downPdf({body: {resumeUserId: settings.resumeId}})
            .catch((e) => {
                handleError(e)
            })
            .finally(() => {
                clearLoading()
            })
        if (!url) {
            handleError(new Error("无法获取下载链接"))
            return;
        }
        if (isMiniProgram() || isWechatBrowser()) {
            navigate(`/mobile/resume/wx-download?url=${url}`)
            return 
        }

        try {
            window.location.href = url
        } catch (error) {
            handleError(error)
        } finally {
            clearLoading()
        }
    }

    return {
        down
    }
}

export default useDownload