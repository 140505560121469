import { saveResumePageUserData } from '@/http/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


const initialState = {
    // 请求错误状态码
    data: {},
    status: '', 
}

// const saveResumeData = createAsyncThunk(
//     'mobileEdit/saveResumeData',
//     async (resumeData, { dispatch, getState, rejectWithValue }) => {
//       try {

//         // 执行保存操作，假设 save 函数是你用来与服务端通信的函数
//         await saveResumePageUserData({ query: { pageId }, body: resumeData });
//         // 由于假设没有返回值，我们不需要在这里返回任何东西
//       } catch (error) {
//         // 如果有错误，返回一个拒绝的值
//         return rejectWithValue(error.response.data);
//       }
//     }
// );

export const editSlice = createSlice({
    name: 'mobileEdit',
    initialState,
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setResumeData: (state, {payload}) => {
            state.data.resumeData = payload
        }
    },
    // extraReducers: (builder) => {
    //     builder
    //       .addCase(saveResumeData.pending, (state) => {
    //         state.status = 'loading';
    //       })
    //       .addCase(saveResumeData.fulfilled, (state, action) => {
    //         state.status = 'succeeded';
    //         // 假设服务端返回了完整的简历数据
    //         state.data = action.payload;
    //       })
    //       .addCase(saveResumeData.rejected, (state, action) => {
    //         state.status = 'failed';
    //         state.error = action.payload;
    //       });
    // }
})

// Action creators are generated for each case reducer function
export const { setData, setResumeData } = editSlice.actions

export default editSlice.reducer