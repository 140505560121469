import styled from "styled-components";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import getNestedProperty from "@/utils/getNestedProperty";
import {Module} from "@/common/module/module";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import {closeIcon, CloseStyled} from "@/components/resume/modules/portfolio/index";
import {Popover, Upload} from "antd";
import LinkInput from "@/components/resume/modules/portfolio/LinkInput";

const LinkListContainerStyled = styled.div`
  padding: 10px;
`
const LinkItemContainerStyled = styled.div`
  line-height: 60px;
  margin-bottom: 30px;

  &:hover {
    background: #F8F8F8;
  }

  .link-title-input {
  }

  .link-title-input:empty:before {
    content: "在这里填写作品描述";
  }

  .link-title-input:hover {

  }

  .link-title-input:focus {
    background: #E6E9EB;
  }

  .link-input {
    text-decoration: underline;
  }

  .link-input:focus {
    background: rgba(17, 187, 166, 0.08);
  }

  .link-input:empty:before {
    content: "在这里输入url地址";
    //text-decoration: none !important;
    color: #11BBA6;
  }

  .link-input:empty:hover {
    //text-decoration: none !important;
  }
`

export default (props) => {
    const dispatch = useDispatch();
    const links = useSelector(state => getNestedProperty(state.resumeData, `[${Module.PORTFOLIO}].data.links`));
    const onChangeLinkTitle = (index, value) => {
        dispatch(resumeDataSlice.actions.changeValueByPath({
            path: `[${Module.PORTFOLIO}].data.links[${index}.title]`,
            value
        }))
    }
    const onChangeLink = (index, value) => {
        dispatch(resumeDataSlice.actions.changeValueByPath({
            path: `[${Module.PORTFOLIO}].data.links[${index}.link]`,
            value
        }))

    }
    const onDelete = (index) => {
        dispatch(resumeDataSlice.actions.deletePortfolioItem({
            type: 'link',
            index
        }))
    }
    return <LinkListContainerStyled>
        {
            links.map((item, index) => {
                return <Popover content={<CloseStyled onClick={() => onDelete(index)}>{closeIcon}</CloseStyled>}
                                overlayClassName="ant-popover-custom"
                                autoAdjustOverflow={false}
                                trigger="hover"
                                transitionName=""
                                getPopupContainer={null}
                                placement="rightTop"
                                overlayInnerStyle={{right: 19, top: -6, position: "relative"}}
                >
                    <LinkItemContainerStyled key={index}>
                        <LinkInput
                            className="link-title-input"
                            value={item.title}
                            borderStyle="solid"
                            color="#797979"
                            onInput={(e) => onChangeLinkTitle(index, e.currentTarget.innerText)}
                        />
                        <LinkInput
                            className="link-input" value={item.link} borderStyle="solid" color="#11BBA6"
                            onInput={(e) => onChangeLink(index, e.currentTarget.innerText)}
                        />
                    </LinkItemContainerStyled>
                </Popover>
            })
        }
    </LinkListContainerStyled>
}
