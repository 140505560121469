import styled from "styled-components"
import InitComponent from "./components/InitComponent"
import KeyboardComponent from "./components/KeyboardComponent"
import FeedBackComponent from "./components/FeedBackComponent"
import VoiceComponent from "./components/VoiceComponent"
import { useEffect } from "react"

const UserAnswerStyled = styled.div`
    margin-top: 20px;
    .user-header {
        font-size: 14px;
        color: #343542;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        .re-submit {
            width: 100px;
            height: 26px;
            background: #11BBA6;
            border-radius: 4px;
            color: #fff;
            font-size: 12px;
        }
        .re-submit:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
    
`

const svgIcon = <div className="flex-center" style={{ marginRight: 6 }} dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="可重新作答、重新生成示例" transform="translate(-732.000000, -420.000000)" fill="#FFFFFF">
            <g id="编组-3" transform="translate(164.000000, 243.000000)">
                <g id="编组-12" transform="translate(20.000000, 171.000000)">
                    <g id="编组-7备份" transform="translate(532.000000, 0.000000)">
                        <g id="文本备份-22" transform="translate(16.000000, 6.000000)">
                            <path d="M13.1088461,11.3912791 L6.45615384,11.3912791 C6.18692307,11.3912791 5.97153846,11.6112274 5.97153846,11.8861628 C5.97153846,12.1610982 6.18692307,12.3810465 6.45615384,12.3810465 L13.1626923,12.3810465 C13.4319231,12.3810465 13.6473077,12.1610982 13.6473077,11.8861628 C13.5934615,11.6662145 13.3780769,11.3912791 13.1088461,11.3912791 Z M0.834615384,12.4222868 L3.14999999,12.4222868 C3.25769231,12.4222868 3.41923077,12.3672997 3.47307692,12.2573256 L11.4423077,4.11923773 C11.6576923,3.89928941 11.6576923,3.62435401 11.4423077,3.40440569 L9.18076922,1.03996124 C8.96538461,0.820012921 8.69615383,0.820012921 8.48076922,1.03996124 L6.86538461,2.68957364 L0.511538461,9.17804911 C0.403846149,9.28802327 0.35,9.39799742 0.35,9.50797159 L0.35,11.872416 C0.35,11.9823902 0.403846149,12.1473514 0.511538461,12.2573256 C0.56538461,12.3672997 0.726923072,12.4222868 0.834615384,12.4222868 Z M8.80384615,2.08471577 L10.4192308,3.73432817 L9.50384614,4.66910852 L7.88846153,3.01949612 L8.80384615,2.08471577 Z M1.37307692,9.7279199 L7.24230768,3.73432817 L8.85769229,5.38394058 L2.98846153,11.3775323 L1.37307692,11.3775323 L1.37307692,9.7279199 L1.37307692,9.7279199 Z" id="形状"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const UserAnswer = ({ isActive, status = 'feedback', onChange, answer, answerId, questionId, feedback }) => {

    // init初始态，用户答案answer为空
    // keyboard
    const mapStatusToCom = {
        'init': <InitComponent onChange={(value) => onChange(value)} />,
        'voice': <VoiceComponent questionId={questionId} answerId={answerId} onChange={(data) => onChange('feedback', data)} />,
        'keyboard': <KeyboardComponent questionId={questionId} answerId={answerId} onChange={() => onChange('feedback')} />,
        'feedback': <FeedBackComponent feedback={feedback} answer={answer} onClick={() => onChange('keyboard')} />
    }

    useEffect(() => {
        if (!isActive) {
            onChange('init')
        }
    }, [isActive])

    const handleClickStop = (event) => {
        event.stopPropagation()
    }
    return <div onClick={handleClickStop}>
        {
            (status === 'init' && !isActive) ? <></> : <UserAnswerStyled>
                <div className="user-header">
                    📖 我的回答：

                    {isActive && status === 'feedback' && <div onClick={() => onChange('init')} className="re-submit flex-center">{svgIcon} 重新作答</div>}
                </div>
                <div className="user-content">
                    {mapStatusToCom[status]}
                </div>
            </UserAnswerStyled>
        }
    </div>
}

export default UserAnswer