import withComponent from "@/hoc/withComponent";
import {
    BackIconStyled,
    ContainerStyled,
    DateContentContainerStyled, DateItemBoxStyled, DateItemStyled,
    LeftIconStyled,
    RightIconStyled,
    TitleStyled
} from "@/components/resume/components/dateStyled";
import {useCallback, useMemo, useRef, useState} from "react";
import useForceUpdate from "@/hooks/useForceUpdate";
import moment from "moment";


const leftIcon = <div style={{width: 12, height: 12, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="基本信息状态" transform="translate(-931.000000, -215.000000)" fill="#999999" fill-rule="nonzero">
            <g id="编组-19" transform="translate(911.000000, 192.000000)">
                <g id="更多-绿备份-3" transform="translate(20.000000, 23.000000)">
                    <path d="M8.46442045,11 C8.32727476,11 8.19010987,10.9477436 8.0854698,10.8428844 L3.1569745,6.37984753 C2.94767517,6.17047551 2.94767517,5.83044319 3.1569745,5.62070561 L8.08546981,1.15730318 C8.29476915,0.947565605 8.63372617,0.947565605 8.8430255,1.15730318 C9.05232483,1.3666752 9.05232483,1.70670752 8.8430255,1.9164451 L4.29348083,6.00008418 L8.84302549,10.0833769 C9.05232482,10.2931145 9.05232482,10.6327813 8.84302549,10.8425188 C8.73873103,10.9477436 8.60156614,11 8.46442045,11 Z" id="-"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const rightIcon = <div style={{width: 12, height: 12, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="基本信息状态" transform="translate(-1179.000000, -215.000000)" fill="#999999" fill-rule="nonzero">
            <g id="编组-19" transform="translate(911.000000, 192.000000)">
                <g id="更多-绿备份" transform="translate(274.000000, 29.000000) scale(-1, 1) translate(-274.000000, -29.000000) translate(268.000000, 23.000000)">
                    <path d="M8.46442045,11 C8.32727476,11 8.19010987,10.9477436 8.0854698,10.8428844 L3.1569745,6.37984753 C2.94767517,6.17047551 2.94767517,5.83044319 3.1569745,5.62070561 L8.08546981,1.15730318 C8.29476915,0.947565605 8.63372617,0.947565605 8.8430255,1.15730318 C9.05232483,1.3666752 9.05232483,1.70670752 8.8430255,1.9164451 L4.29348083,6.00008418 L8.84302549,10.0833769 C9.05232482,10.2931145 9.05232482,10.6327813 8.84302549,10.8425188 C8.73873103,10.9477436 8.60156614,11 8.46442045,11 Z" id="-"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const backIcon = <div style={{width: 24, height: 24, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="基本信息状态" transform="translate(-1244.000000, -209.000000)" fill="#CBCBCB" fill-rule="nonzero">
            <g id="编组-18" transform="translate(1224.000000, 192.000000)">
                <g id="更多-绿备份" transform="translate(20.000000, 17.000000)">
                    <g id="新建备份" transform="translate(5.000000, 7.500000)">
                        <polygon id="路径" points="14 5.16889972 13.999507 3.82210375 2.5729712 3.82620743 5.39784971 0.918908491 4.37280218 0 0 4.5 4.37280218 9 5.39784971 8.08109151 2.57214958 5.1730034"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>


const endYear = Number(new Date().getFullYear().toString().replace(/.$/, '0'));
const startYear = endYear - 59;
const baseLength = 10;
const yearRangeList = Array.from({length: (endYear - startYear) / baseLength + 1}).map((item, index) => {
    return Array.from({length: baseLength}).map((ite, inde) => startYear + index * baseLength + inde)
});
const monthRangeList = [
    {key: 1, value: '一月'},
    {key: 2, value: '二月'},
    {key: 3, value: '三月'},
    {key: 4, value: '四月'},
    {key: 5, value: '五月'},
    {key: 6, value: '六月'},
    {key: 7, value: '七月'},
    {key: 8, value: '八月'},
    {key: 9, value: '九月'},
    {key: 10, value: '十月'},
    {key: 11, value: '十一月'},
    {key: 12, value: '十二月'}
];

const getYearIndexByYear = (year) => {
    return yearRangeList.findIndex((range) => {
        return range.findIndex((yea) => {
            return year === yea
        }) !== -1
    })
}


export default withComponent((props) => {
    const {year, month, data, onChange, openMonth, openDay} = props;
    const baseYearIndex = getYearIndexByYear(year);
    const that = useRef({
        currentYear: year || undefined,
        currentMonth: month || undefined,
        currentType: "year",
        yearIndex: baseYearIndex === -1 ? yearRangeList.length - 3 : baseYearIndex
    }).current;
    const [, update] = useForceUpdate();
    const setYearIndex = useCallback((index) => {
        that.yearIndex = index;
        update()
    }, [])
    const setCurrentType = useCallback((type) => {
        that.currentType = type;
        update()
    }, [])
    const setCurrentYear = useCallback((year) => {
        that.currentYear = year;
        update()
    }, [])
    const setCurrentMonth = useCallback((month) => {
        that.currentMonth = month;
        update()
    }, []);
    const onClickLeft = useCallback(() => {
        if (that.currentType === "year") {
            setYearIndex(that.yearIndex - 1);
        }
    }, [])
    const onClickRight = useCallback(() => {
        if (that.currentType === "year") {
            setYearIndex(that.yearIndex + 1);
        }
    }, [])
    const onClickBack = useCallback(() => {
        if (that.currentType === "month") {
            setCurrentType("year")
        }
    }, [])

    const onClickYear = useCallback((year) => {
        setCurrentYear(year);
        if (openMonth || openDay) {
            setCurrentType('month');
        } else {
            onChange?.(that.currentYear)
        }
    }, [openMonth, openDay]);

    const onClickMonth = useCallback((month) => {
        setCurrentMonth(month);
        if (openDay) {

        } else {
            onChange?.(that.currentYear, that.currentMonth)
        }
    }, []);
    const title = useMemo(() => {
        if (that.currentType === "year") {
            return `${yearRangeList[that.yearIndex][0]} - ${yearRangeList[that.yearIndex][yearRangeList[that.yearIndex].length - 1]}`
        } else if (that.currentType === "month") {
            return that.currentYear;
        }
    }, [that.currentType, that.yearIndex, that.currentYear]);
    const content = useMemo(() => {
        if (that.currentType === "month") {
            return monthRangeList.map((month) => <DateItemBoxStyled>
                <DateItemStyled $isActive={month.key === that.currentMonth}
                                onClick={() => onClickMonth(month.key)}>{month.value}</DateItemStyled>
            </DateItemBoxStyled>)
        } else {
            return yearRangeList[that.yearIndex].map((year) => <DateItemBoxStyled>
                <DateItemStyled $isActive={year === that.currentYear}
                                onClick={() => onClickYear(year)}>{year}</DateItemStyled>
            </DateItemBoxStyled>)
        }
    }, [that.currentType, that.yearIndex, that.currentYear, that.currentMonth]);


    const leftButtonVisible = useMemo(() => {
        if (that.currentType === "month") {
            return false
        } else if (that.currentType === "year") {
            if (that.yearIndex > 0) {
                return true
            }
        }
    }, [that.yearIndex, that.currentType])
    const rightButtonVisible = useMemo(() => {
        if (that.currentType === "month") {
            return false
        } else {
            return that.yearIndex < yearRangeList.length - 1;
        }
    }, [that.currentType, that.yearIndex])
    const backButtonVisible = useMemo(() => {
        if ((openMonth || openDay) && that.currentType === "month") {
            return true
        }
    }, [that.currentType, openMonth, openDay])
    return <ContainerStyled>
        <TitleStyled>{title}</TitleStyled>
        {
            leftButtonVisible && <LeftIconStyled onClick={onClickLeft}>{leftIcon}</LeftIconStyled>
        }
        {
            rightButtonVisible && <RightIconStyled onClick={onClickRight}>{rightIcon}</RightIconStyled>
        }
        {
            backButtonVisible && <BackIconStyled onClick={onClickBack}>
                {backIcon}
            </BackIconStyled>
        }
        <DateContentContainerStyled>
            {content}
        </DateContentContainerStyled>
    </ContainerStyled>
})
