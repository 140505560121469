import styled from "styled-components"

export const icon1 = <div style={{ height: 14, width: 18 }} dangerouslySetInnerHTML={{
    __html: `<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="改简历、练面试、接offer-移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="进入面试页面（第2题）" transform="translate(-127.000000, -113.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-7" transform="translate(119.000000, 107.000000)">
                <g id="文本备份-22" transform="translate(8.000000, 6.000000)">
                    <path d="M1.75,11.1416667 L1.75,12.3083333 L12.25,12.3083333 L12.25,11.1416667 L1.75,11.1416667 Z M3.17916666,5.71666666 L5.74583334,5.71666666 L5.74583334,1.69166667 L8.10833334,1.69166667 L8.10833334,5.71666666 L10.675,5.71666666 L6.94166667,10.0333333 L3.17916666,5.71666666 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
  }} />;

export const icon2 = <div style={{ height: 14, width: 18 }} dangerouslySetInnerHTML={{
    __html: `<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="改简历、练面试、接offer-移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="进入面试页面（第2题）" transform="translate(-293.000000, -119.000000)" fill="#777777" fill-rule="nonzero">
            <g id="编组" transform="translate(293.000000, 119.000000)">
                <g id="zhongxinshengcheng" transform="translate(1.750000, 1.750000)">
                    <path d="M10.4841604,4.12502686 L10.4841604,1.50021547 L9.62807968,2.3562962 C8.65713402,0.885354644 7.0124994,0.000207161718 5.24999989,0 C2.35063927,0 0,2.34988501 0,5.24999989 C0,8.15011477 2.35063927,10.5 5.24999989,10.5 C7.38744128,10.5006065 9.31189456,9.20540753 10.1160834,7.2250196 C10.2034762,6.99633757 10.0919845,6.73975017 9.86518096,6.64759199 C9.63837741,6.55543381 9.37951356,6.66153283 9.28263037,6.8863586 C8.61589515,8.52728506 7.02120723,9.60045202 5.24999989,9.60017221 C2.84769407,9.60017221 0.899827575,7.65268285 0.899827575,5.24999989 C0.899827575,2.84769407 2.84769407,0.899827575 5.24999989,0.899827575 C6.77598335,0.899691044 8.19036201,1.69948339 8.9767795,3.00721924 L7.85934902,4.12502686 L10.4841604,4.12502686 L10.4841604,4.12502686 Z" id="路径"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}} />;

export const icon3 = <div style={{ height: 22, width: 18 }} dangerouslySetInnerHTML={{
    __html: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="改简历、练面试、接offer-移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="展开更多简历状态" transform="translate(-228.000000, -668.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-3" transform="translate(12.000000, 658.000000)">
                <g id="超级简历" transform="translate(216.000000, 10.000000)">
                    <polygon id="路径" points="13.7145959 15.7695949 14.971667 17.026666 20 11.998333 14.971667 6.97 13.7145473 8.22709541 16.5969622 11.1094374 4 11.1094374 4 12.8872286 16.5969622 12.8872286"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}} />;
export const rightSvg = (
    <div
      className="flex-center"
      dangerouslySetInnerHTML={{
        __html: `
  <?xml version="1.0" encoding="UTF-8"?>
  <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>切片</title>
      <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="点击导入简历" transform="translate(-321.000000, -227.000000)" fill="#999999" fill-rule="nonzero">
              <g id="返回" transform="translate(321.000000, 227.000000)">
                  <path d="M8.99008332,1.62606369 C9.23686819,1.37590766 9.6371048,1.3651263 9.89699915,1.60163368 C10.1568935,1.83814105 10.1837891,2.23761848 9.95794314,2.50682923 L9.91996218,2.54873926 L5.46964055,7.03409505 L9.91603312,11.445126 C10.1571558,11.6843435 10.175354,12.0682104 9.95794314,12.3291658 L9.91996218,12.3714032 C9.68065744,12.612428 9.29679472,12.6304838 9.03592242,12.4129858 L8.99368497,12.3750049 L4.08235298,7.50296354 C3.84132822,7.2636588 3.82327245,6.87979608 4.04077036,6.61892378 L4.07875134,6.57668633 L8.99008332,1.62606369 L8.99008332,1.62606369 Z" id="路径" transform="translate(7.000000, 6.997989) scale(-1, 1) translate(-7.000000, -6.997989) "></path>
              </g>
          </g>
      </g>
  </svg>
  `,
      }}
    />
  );

export const QuestionCard = styled.div`
    width: 355px;
    margin: 7px auto 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px #C6D4D2;
    border-radius: 8px;
    min-height: 82vh;
`

export const QuestionHeader = styled.div`
    width: 100%;
    height: 58px;
    background: url('https://oss.jianzeppt.com/jz-ppt-img/ksjdhfkjshkfdr.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 8px 10px 8px;
    box-sizing: border-box;
    .item1{
        font-size: 14px;
        color: #333333;
    }
    
    .item2{
        display: flex;
        align-items: center;
        background: linear-gradient( 313deg, #37EDBA 0%, #44D7D0 58%, #55BBEC 100%);
        border-radius: 4px;
        color: #FFFFFF;
        font-size: 12px;
        padding: 7px 9px 7px 8px;
        line-height: 1;
        margin-right: auto;
        margin-left: 16px;
    }
    
    .item3{
        display: flex;
        align-items: center;
    }
`

export const QuestionCardHeader = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    padding-top: 16px;

    .btn-pre,
    .btn-next{
        width: 84px;
        height: 28px;
        border-radius: 23px;
        text-align: center;
        line-height: 28px;
        font-size: 12px;
    }

    .btn-pre{
        border: 1px solid #DEDEDE;
        color: #333333;
    }
    .btn-next{
        background: #11BBA6;
        border: 1px solid #11BBA6;
        color: #FFFFFF;
    }
`

export const QuestionListItemStyled = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 21px 16px 24px;
    page-break-inside: avoid;
    .question {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #343542;
        margin-top: 14px;
        margin-bottom: 14px;
    }
    .question:first-child {
        margin-top: 0;
    }
    .strategy {
        background: #F9FAFA;
        border-radius: 8px;
        padding: 14px 12px 10px 12px;
        .tip {
            font-weight: 500;
            color: #69A099;
            margin-bottom: 4px;
        }
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 300;
    }
    `

export const processInterviewData = (data, questionList) => {
    const list =  data.map(item => {
        const { question, answer, reference } = item
        const currStatus = questionList.find(i => i.id === question.id)
        return {
            id: question?.id,
            question: question?.question,
            strategy: question?.questionReference,
            answer: answer?.answer,
            answerId: answer?.id,
            feedback: answer?.feedback,
            systemAnswer: reference?.reference,
            systemAnswerId: reference?.id,
            status: currStatus?.status || 'init',
            systemStatus: 'init',
        }
    })

    return updateInterviewStatus(list)
}

export const updateInterviewStatus = (list) => {
    return list.map(item => {
        const newItem = { ...item }
        if (item.answer) {
            newItem.status = 'feedback'
        }
        if (item.systemAnswer) {
            newItem.systemStatus = 'feedback'
        }
        return newItem
    })
}

export function downloadPdf(pdfUrl, fileName) {
    // 使用fetch API来请求PDF文件
    fetch(pdfUrl)
      .then(response => {
        if (response.ok) return response.blob();
        throw new Error('Network response was not ok.');
      })
      .then(blob => {
        // 创建一个Blob URL
        const blobUrl = window.URL.createObjectURL(blob);
  
        // 创建一个a标签
        const link = document.createElement('a');
        link.style.display = 'none';
  
        // 设置a标签的href属性为Blob URL
        link.href = blobUrl;
  
        // 设置下载文件的文件名
        link.download = fileName;
  
        // 将a标签添加到文档中
        document.body.appendChild(link);
  
        // 触发a标签的点击事件以开始下载
        link.click();
  
        // 移除a标签并释放Blob URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  }

export const processDesc = (desc = '') => {
    if (!desc) return ''
    // 使用换行符分割文本
    const lines = desc.split('\n');
    // 第一行是职位名称
    const firstLine = lines[0];
    // 剩余的数据，从第二行开始
    const remainingData = lines.slice(1).join('\n');

    return {
        jobTitle: firstLine,
        content: remainingData
    }
}