import {createSlice} from '@reduxjs/toolkit';
import initState from '../initState';
import setNestedProperty from "@/utils/setNestedProperty";
import getModuleVisibleResumeDataPathByModule from "@/common/config/getModuleVisibleResumeDataPathByModule";
import getModuleVisible from "@/common/config/getModuleVisible";
import getDefaultModuleContent from "@/common/config/getDefaultModuleContent";
import {Module} from "@/common/module/module";
import getModuleDataListBySort from "@/common/resume/getModuleDataListBySort";
import {v4} from "uuid";
import {CUSTOM_MODULE_SLOT_ENUM} from "@/common/slot/slot";
import getMainModuleDataList from "@/common/config/getMainModuleDataList";


export default createSlice({
    name: 'resumeData', initialState: initState.resumeData, reducers: {
        set(state, {payload}) {
            return payload
        }, clear() {
            return null
        }, changeGender(state, {payload}) {
            state["基本信息"].data["性别"] = payload
        }, changeName(state, {payload}) {
            state["基本信息"].data["姓名"] = payload
        }, changeAvatar(state, {payload}) {
            state[Module.AVATAR].data.src = payload
        }, changeValueByPath(state, {payload}) {
            const {path, value} = payload;
            setNestedProperty(state, path, value);
        }, switchModuleVisible(state, {payload}) {
            const {id} = payload;
            const path = getModuleVisibleResumeDataPathByModule(id);
            const visible = getModuleVisible(state, id);
            if (path) {
                setNestedProperty(state, path, !visible);
            }
        }, addModuleContent(state, {payload}) {
            const {block_name, item} = payload;
            const content = state[block_name]?.data?.content;
            if (!content) {
                state[block_name].data = [];
            }
            content.push(item)
        }, addPortfolioItem(state, {payload}) {
            const {type, item} = payload;
            if (type === "link") {
                const content = state[Module.PORTFOLIO]?.data?.links;
                if (!content) {
                    state[Module.PORTFOLIO].data.links = []
                }
                content.push(item);
            } else if (type === "image") {
                const content = state[Module.PORTFOLIO]?.data?.images;
                if (!content) {
                    state[Module.PORTFOLIO].data.images = []
                }
                content.push(item);
            }
        }, deletePortfolioItem(state, {payload}) {
            const {type, index} = payload;
            if (type === "link") {
                const content = state[Module.PORTFOLIO]?.data?.links;
                content.splice(index, 1);
            } else if (type === "image") {
                const content = state[Module.PORTFOLIO]?.data?.images;
                content.splice(index, 1);
            }
        }, moveModuleContent(state, {payload}) {
            const {block_name, currentIndex, moveIndex} = payload;
            const content = state[block_name]?.data?.content;
            if (content) {
                const current = content[currentIndex];
                const target = content[moveIndex];
                content[moveIndex] = current;
                content[currentIndex] = target;
            }
        }, deleteModuleContent(state, {payload}) {
            const {block_name, index} = payload;
            const content = state[block_name]?.data?.content;
            if (content) {
                content.splice(index, 1)
            }
        }, moveModuleBySort(state, {payload}) {
            const {block_name, currentIndex, moveIndex} = payload;
            const content = state[block_name]?.data?.content;
            if (content) {
                const current = content[currentIndex];
                const target = content[moveIndex];
                content[moveIndex] = current;
                content[currentIndex] = target;
            }
        }, preMoveModule(state, {payload}) {
            const {id, template} = payload;
            let isMain = true;
            const currentItem = state[id]
            const {leftModuleConfig, mainModuleConfig} = template;
            if (leftModuleConfig.includes(currentItem.type)) {
                isMain = false;
            }
            const list = getModuleDataListBySort(state).filter((item) => item.visible).filter((item) => isMain ? mainModuleConfig.includes(item.type) : leftModuleConfig.includes(item.type))
            const index = list.findIndex((item) => item.id === id);
            const current = list[index];
            const target = list[index - 1];
            if (!current || !target) return;
            const currentSort = current.sort
            const targetSort = target?.sort
            if (target) {
                target.sort = currentSort;
                current.sort = targetSort;
            }
        }, nextMoveModule(state, {payload}) {
            const {id, template} = payload;
            const currentItem = state[id]
            let isMain = true;
            const {leftModuleConfig, mainModuleConfig} = template;
            if (leftModuleConfig.includes(currentItem.type)) {
                isMain = false;
            }
            const list = getModuleDataListBySort(state).filter((item) => item.visible).filter((item) => isMain ? mainModuleConfig.includes(item.type) : leftModuleConfig.includes(item.type))
            console.log('list', list, leftModuleConfig, mainModuleConfig)
            const index = list.findIndex((item) => item.id === id);
            const current = list[index];
            if (!current) return
            const currentSort = current.sort
            const target = list[index + 1];
            if (!target) return;
            const targetSort = target?.sort
            console.log('currentSort', currentSort, targetSort)
            if (target) {
                target.sort = currentSort;
                current.sort = targetSort;
            }
        }, addCustomModule(state, {payload}) {
            const id = v4();
            if (payload && payload.type === 'score') {
                state['APPLICATION_INFO'] = {
                    "data": {
                        title_text: "报考信息", 
                        school: '北京大学',
                        major: '产业经济学',
                        table: [{ subject: '初试成绩', score: 450 }, { subject: '英语', score: 75 }, { subject: '思想政治', score: 75 }, { subject: '业务课1', score: 100 }, { subject: '业务课2', score: 100 }, { subject: '业务课3', score: 100 }],
                        [CUSTOM_MODULE_SLOT_ENUM.DETAILS]: "这里是自定义描述。",
                    }, visible: true, id: Module.APPLICATION_INFO, type: Module.APPLICATION_INFO
                }
            } else {
                state[id] = {
                    "data": {
                        title_text: "自定义标题", [CUSTOM_MODULE_SLOT_ENUM.DETAILS]: "这里是自定义描述。",
                    }, visible: true, id, type: Module.CUSTOM_MODULE
                }
            }
        },
    }
});
