import withComponent from '@/hoc/withComponent';
import styled from 'styled-components';

const BoxStyled = styled.div`
  width: 160px;
  border-right: 1px solid #E7E7E7;
  padding-top: 20px;
`;
const ItemStyled = styled.div`
  box-sizing: border-box;
  width: 160px;
  height: 38px;
  line-height: 38px;
  padding: 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;

  ${(props) => props.isActive ? 'background:#ECEFF1;' : ''}
  :hover {
    background: #ECEFF1;
  }
`;

export default withComponent((props) => {
    const { list, setType, type } = props;
    return <BoxStyled>
      {
        list.map((item) => {
          return <ItemStyled isActive={type === item.id} key={item.id} onClick={() => setType(item.id)}>
            {item.text}
          </ItemStyled>;
        })
      }
    </BoxStyled>;
  },
);
