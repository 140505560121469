import withComponent from "@/hoc/withComponent";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import {Upload, message} from "antd";
import {useEffect, useMemo, useRef, useState} from "react";
import useForceUpdate from "@/hooks/useForceUpdate";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import {Module} from "@/common/module/module";
import styled from "styled-components"
import {uploadFile} from "@/http/api";

const ContainerStyled = styled.div`
  position: relative;
  cursor: pointer;
`
const Container2Styled = styled.div`
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  pointer-events: none !important;
  z-index: 9;
`
const Container2ImgStyled = styled.img`
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  pointer-events: none !important;
  z-index: 9;
`
const MarkContainerStyled = styled.div`
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  background: rgba(0, 0, 0, 0.4) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  pointer-events: none !important;
  z-index: 9;
`
const MarkContainerTextStyled = styled.div`
  font-size: 14px !important;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400 !important;
  color: #FFFFFF !important;
  text-align: center !important;
  border: none !important;
  width: auto !important;
  pointer-events: none !important;
  z-index: 9;
`
const PStyled = styled.div`
  position: absolute;
  width: fit-content !important;
`

export default withComponent((props) => {
    const {tagName, style, className} = props;
    const ref = useRef();
    const ref2 = useRef();
    const that = useRef({localUrl: undefined}).current;
    const value = useSelector((state) => state.resumeData[Module.AVATAR]?.data?.src);
    const originTemplateId = useSelector((state) => state.resume.originTemplateId);
    const styleType = useSelector((state) => state.resumeData[Module.AVATAR]?.data?.styleType);
    const dispatch = useDispatch();
    const [, update] = useForceUpdate();
    const [hover, setHover] = useState(false);
    const onUpload = async (res) => {
        // 校验文件大小和MIME类型
        if (!res.file?.type?.includes("image")) {
            message.info('请上传图片格式的文件！');
            return false;
        }
        
        const file = res.file;
        const formData = new FormData()
        formData.append('file', file)
        const fileURL = await uploadFile({body: formData});
        that.localUrl = fileURL;
        dispatch(resumeDataSlice.actions.changeAvatar(fileURL));
        update()
    };
    const isCircle = styleType === "circle";
    const is34 = styleType === "3/4";
    const is11 = styleType === "1/1";
    useEffect(() => {
        update()
    }, []);
    const width = ref.current?.offsetWidth || 100;
    return <>
        <ContainerStyled ref={ref} style={style} className={className}
                         onMouseEnter={() => setHover(true)}
                         onMouseLeave={() => setHover(false)}>
            {
                originTemplateId === '1050' ? <Container2ImgStyled ref={ref2} style={{backgroundImage: `url(${value})`}} /> : <Container2Styled ref={ref2} style={{backgroundImage: `url(${value})`}}/>
            }
            {
                hover && <MarkContainerStyled>
                    <MarkContainerTextStyled>上传</MarkContainerTextStyled>
                </MarkContainerStyled>
            }
            <div style={{width: "auto", position: "relative"}}>
                {
                    !!width && <>
                        <Upload accept="image/*" showUploadList={false} customRequest={onUpload}>
                            <div style={{height: ref.current?.offsetHeight, width: ref.current?.offsetWidth}}></div>
                        </Upload>
                    </>
                }
            </div>
            <PStyled/>
        </ContainerStyled>
    </>
})
