import styled from 'styled-components'
import SelectJd from './SelectJd'
import useDispatch from '@/components/resume/redux/hooks/useDispatch'
import interviewSlice from '@/components/resume/redux/services/interviewSlice'
import { useMemo, useState } from 'react'
import { Modal, Tooltip, message } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Dialog from '@/components/Dialog'


const { confirm } = Modal;

const TabWrapStyled = styled.div`
    display: flex;
    background: #eceff1;
    position: relative;
    align-self: flex-start;
    border-top-left-radius: 4px;
    .add {
        /* position: absolute; */
        z-index: 1;
        bottom: 4px;
        left: 0;
        /* transform: translateY(100%); */
        background-color: #F7F7F7;
        height: 36px;
        width: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
    }

    .more-svg {
        position: absolute;
        z-index: 1000;
        right: 30px;
        bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background: #DAE1E5;
        border-radius: 6px;
        /* svg {
            width: 14px;
            height: 14px;
        } */
        &:hover {
            background: rgba(0,0,0,0.05);
            cursor: pointer;
        }
    }
    .add-svg {
        position: absolute;
        right: 0px;
        bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background: #DAE1E5;
        border-radius: 50%;
        svg {
            width: 13px;
            height: 13px;
        }
        &:hover {
            background: rgba(0,0,0,0.05);
            cursor: pointer;
        }
    }
`

const TabItemStyled = styled.div`
    position: relative;
    display: flex;
    ${props => props.$active && `background-color: #FFFFFF; font-weight: bold;`}
    color: #6E727C;
    width: 100px;
    height: 32px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    padding-left: 8px;
    padding-right: 4px;
    ${props => !props.$active && `padding-left: 3px;`}
    .target-title {
        font-size: 12px;
        ${props => !props.$active && `padding-left: 16px;`}
    }
    .target-title:hover {
        ${props => !props.$active && `background: #DAE1E5;
        border-radius: 8px;
        padding: 5px 16px;`}
        
        /* padding-left: 3px; */
    }
    &:hover {
        /* padding: 11px; */
        /* padding-left: 0; */
    }
    &::after {
        ${props => !props.$isDivider && `
            content: "";
            position: absolute;
            top: 10px;
            right: 2px;
            width: 1px;
            height: 13px;
            background-color: #C7D0D6;
        ` }

    }
`

const addSvg = <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="未展开样式" transform="translate(-82.000000, -112.000000)" fill="#6E727C">
            <g id="模块管理备份-5" transform="translate(82.000000, 112.000000)">
                <rect id="矩形" x="5.35714286" y="0.857142857" width="1.28571429" height="10.2857143" rx="0.642857143"></rect>
                <rect id="矩形" x="0.857142857" y="5.35714286" width="10.2857143" height="1.28571429" rx="0.642857143"></rect>
            </g>
        </g>
    </g>
</svg>
`}} />

export const deleteSvg = <div
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    dangerouslySetInnerHTML={{
        __html: `
        <?xml version="1.0" encoding="UTF-8"?>
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>切片</title>
            <g id="职位描述2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="职位描述-2-展开更多职位描述" transform="translate(-952.000000, -74.000000)" fill="#C0C0C0" fill-rule="nonzero">
                    <g id="模块管理备份-5" transform="translate(952.000000, 74.000000)">
                        <path d="M10.8294302,4.25831405 L7.99466667,7.10933333 L5.13002217,4.28039107 C4.8812872,4.04114377 4.48670734,4.04881876 4.24807167,4.29692202 L4.16910045,4.39735233 C4.01175933,4.64506676 4.04277038,4.97681626 4.25954513,5.19091184 L7.10666667,8.00533333 L4.28585011,10.8447869 C4.0711453,11.0608991 4.04325291,11.3928735 4.20681446,11.6430559 L4.28864747,11.744693 L4.38665268,11.8242224 C4.62902598,11.9831317 4.95761431,11.9554564 5.16984682,11.7418501 L8.00266667,8.888 L10.8700184,11.7196161 C11.1188696,11.9587768 11.5134386,11.9509381 11.7519667,11.7027406 L11.8309289,11.6022411 C11.988241,11.3543724 11.957148,11.0225193 11.740284,10.8083533 L8.89066667,7.99333333 L11.7139694,5.15536615 C11.9287606,4.93918455 11.9566059,4.60709068 11.7930223,4.35701184 L11.7111819,4.25543358 C11.4668975,4.01273999 11.0721238,4.01402962 10.8294302,4.25831405 Z" id="形状结合"></path>
                    </g>
                </g>
            </g>
        </svg>
        `
    }}
/>


export const moreSvg = <div
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', 
}}
    dangerouslySetInnerHTML={{
        __html: `
        <?xml version="1.0" encoding="UTF-8"?>
        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>切片</title>
            <g id="职位描述2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="职位描述-2-展开更多职位描述" transform="translate(-1189.000000, -75.000000)" fill="#6D787F" fill-rule="nonzero">
                    <g id="模块管理备份-6" transform="translate(1196.000000, 82.000000) rotate(-90.000000) translate(-1196.000000, -82.000000) translate(1189.000000, 75.000000)">
                        <g id="编组" transform="translate(2.576000, 2.310000)">
                            <path d="M6.02882279,2.54065172 L2.00889144,6.36301145 C1.7815833,6.60192479 1.7815833,6.97837734 2.00396901,7.21196342 L6.03383342,11.043941 C6.13848593,11.1543166 6.28613964,11.21721 6.43977925,11.21721 C6.5936694,11.21721 6.74142003,11.1541232 6.85077977,11.0382712 L6.90164573,10.9770801 C7.07119746,10.7430333 7.05421715,10.4091598 6.85045348,10.19476 L3.23716922,6.78971002 L6.84499444,3.38994376 C7.07275514,3.15060199 7.07275514,2.77414944 6.85045349,2.54065172 C6.62433431,2.30272945 6.25494197,2.30272945 6.02882279,2.54065172 Z" id="-" transform="translate(4.427500, 6.789710) scale(-1, 1) rotate(-90.000000) translate(-4.427500, -6.789710) "></path>
                            <path d="M6.02882279,-1.65934828 L2.00889144,2.16301145 C1.7815833,2.40192479 1.7815833,2.77837734 2.00396901,3.01196342 L6.03383342,6.84394098 C6.13848593,6.95431658 6.28613964,7.01721002 6.43977925,7.01721002 C6.5936694,7.01721002 6.74142003,6.95412317 6.85077977,6.83827121 L6.90164573,6.77708008 C7.07119746,6.54303331 7.05421715,6.20915981 6.85045348,5.99475996 L3.23716922,2.58971002 L6.84499444,-0.810056237 C7.07275514,-1.04939801 7.07275514,-1.42585056 6.85045349,-1.65934828 C6.62433431,-1.89727055 6.25494197,-1.89727055 6.02882279,-1.65934828 Z" id="-备份" transform="translate(4.427500, 2.589710) scale(-1, 1) rotate(-90.000000) translate(-4.427500, -2.589710) "></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        `
    }}
/>
const add2Svg = <div
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}
    dangerouslySetInnerHTML={{
        __html: `
        <?xml version="1.0" encoding="UTF-8"?>
<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="职位描述2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="职位描述-2-展开更多职位描述" transform="translate(-1216.000000, -76.000000)" fill="#6D787F">
            <g id="模块管理备份-5" transform="translate(1216.000000, 76.000000)">
                <rect id="矩形" x="5.35714286" y="0.857142857" width="1.28571429" height="10.2857143" rx="0.642857143"></rect>
                <rect id="矩形" x="0.857142857" y="5.35714286" width="10.2857143" height="1.28571429" rx="0.642857143"></rect>
            </g>
        </g>
    </g>
</svg>
        `
    }}
/>

const TabList = ({ value, options, onAdd, onDelete }) => {
    const newOptions = useMemo(() => {
        const selectOptionIndex = options.findIndex(item => item.id === value)
        if (selectOptionIndex > 2) {
            return [
                {
                    ...options[selectOptionIndex],
                    targetTitle: `目标岗位 ${selectOptionIndex+1}`
                }, 
                ...options.slice(0, 2).map((item, index) => ({
                    ...item,
                    targetTitle: `目标岗位 ${index === 0 ? '' : index+1}`
                }))
            ]
        } else {
            return options.slice(0, 3).map((item, index) => ({
                ...item,
                targetTitle: `目标岗位 ${index === 0 ? '' : index+1}`
            }))
        }
    }, [options, value])
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null)
    const dispatch = useDispatch()

    const handleClickAdd = () => {
        if (options.length >= 5) {
            message.error('最多可添加5个目标岗位，您可以调整或修改您的目标岗位内容')
            return 
        }
        onAdd && onAdd()
    }

    const handleChange = (value) => {
        dispatch(interviewSlice.actions.setJdId(value))
    }

    const handleDelete = (id) => {
        setOpen(true)
        setDeleteId(id)
    }

    const handleOk = () => {
        handleCancel()
        onDelete && onDelete(deleteId)
    }

    const handleCancel = () => {
        setOpen(false)
    }

    return <TabWrapStyled>
        {
            newOptions.map((item, index) => {
                const selectValue = value || newOptions[0].id
                const isActive = item.id === selectValue
                const preActive = (newOptions.findIndex(item => item.id === selectValue) - 1) === index
                return <TabItemStyled 
                    onClick={() => handleChange(item.id)} 
                    $active={isActive}
                    $isDivider={isActive || preActive} 
                    key={index}
                >
                    <div className='target-title'>{item.targetTitle}</div>

                    {(isActive) && <Tooltip title="删除目标岗位" placement="bottom">
                        <div style={{ cursor: 'pointer' }} onClick={() => handleDelete(item.id)}>{deleteSvg}</div>
                    </Tooltip>}
                </TabItemStyled>
            })
        }
        {
            options.length > 2 && 
                <div className='more-svg'>
                    {/* {moreSvg} */}
                    <SelectJd 
                        value={value}
                        onChange={handleChange}
                        options={options}
                        onDelete={handleDelete}
                    />
                </div>
        }
        <Tooltip title="添加目标岗位" placement='bottom'>
            <div onClick={handleClickAdd} className='add-svg'>
                {add2Svg}
            </div>
        </Tooltip>

        <Dialog 
            width={400} 
            open={open} 
            titleText='删除目标岗位提示' 
            descText='删除后，将无法重新找回'
            onCancel={handleCancel}
            onOk={handleOk}
        />
        
        {/* <div onClick={handleClickAdd} className="add">{addSvg}</div> */}
    </TabWrapStyled>
}

export default TabList