import withComponent from '@/hoc/withComponent';
import styled from 'styled-components';
import FlexStyled from '@/styled/FlexStyled';
import * as React from 'react';
import {useState, useMemo} from 'react';
import { vip_life, vipTypeEnum } from '@/const/pay';



const SiderStyled = styled.div`
  width: 218px;
  height: 488px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 10px;
  position: relative;
  overflow: hidden;
`;


const ItemStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4C535C;
  line-height: 12px;
`;

const TitleStyled = styled.div`
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4C535C;
  line-height: 16px;
`;
const CircleStyled = styled.div`
  width: 4px;
  height: 4px;
  background: #4C535C;
  border-radius: 50%;
  overflow: hidden;
`;
const ServiceTextStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #B2B2B2;
  line-height: 12px;
  display: flex;
  align-items: center;
`;
const ButtonStyled = styled.div`
  width: 170px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #E4E6E8;
  line-height: 32px;
  text-align: center;
  cursor: pointer;

  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #66696E;
`;


const icon1 = <div style={{height: 20, width: 20}} dangerouslySetInnerHTML={{
    __html: `<svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='VIP会员' transform='translate(-188.000000, -160.000000)' fill='#02BBA4' fill-rule='nonzero'>
            <g transform='translate(188.000000, 160.000000)' id='形状'>
                <path d='M18.1873082,8.2657398 L15.6187525,3.37540089 C15.497679,3.14491312 15.2550088,3 14.9900947,3 L5.02478573,3 C4.76114309,3 4.51941623,3.14353813 4.39777064,3.37233655 L1.7997601,8.2583779 C1.66014833,8.52093866 1.70687306,8.84130449 1.91597518,9.05520091 L9.4739091,16.7864675 C9.60713235,16.9227378 9.79147382,17 9.98423157,17 L9.98470912,17 C10.1773085,17 10.3615653,16.9231106 10.4948597,16.7871215 L18.06882,9.06013376 C18.2775643,8.84719734 18.3251533,8.52814093 18.1873082,8.2657398 L18.1873082,8.2657398 Z M14.167917,9.04400858 L10.4989857,12.7870681 C10.3656913,12.9230572 10.1814345,12.9999466 9.9888351,12.9999466 L9.98835755,12.9999466 C9.7955956,12.9998194 9.61125119,12.9226781 9.47803508,12.7863954 L5.81689729,9.04128057 C5.54712321,8.76532139 5.55713253,8.32769935 5.83924634,8.0638107 C6.12136016,7.79990337 6.56874235,7.80971301 6.83851643,8.08567219 L9.98954186,11.3089703 L13.1476349,8.08707356 C13.417772,7.8114694 13.8651924,7.80227637 14.1469241,8.06652003 C14.4286559,8.3307637 14.4380731,8.76840442 14.167917,9.04400858 L14.167917,9.04400858 Z'></path>
            </g>
        </g>
    </g>
</svg>`,
}}/>;
const icon2 = <div style={{height: 178, width: 178}} dangerouslySetInnerHTML={{
    __html: `<svg width='178px' height='178px' viewBox='0 0 178 178' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.047314'>
        <g id='VIP会员' transform='translate(-114.000000, -374.000000)' fill='#02BBA4' fill-rule='nonzero'>
            <g transform='translate(158.000000, 122.000000)' id='形状'>
                <g transform='translate(-44.000000, 252.000000)'>
                    <path d='M161.867043,73.5650842 L139.006897,30.041068 C137.929343,27.9897267 135.769578,26.7 133.411843,26.7 L44.720593,26.7 C42.3741735,26.7 40.2228044,27.9774893 39.1401587,30.0137953 L16.0178649,73.4995633 C14.7753201,75.836354 15.1911702,78.68761 17.0521791,80.5912881 L84.317791,149.399561 C85.5034779,150.612366 87.144117,151.3 88.859661,151.3 L88.8639111,151.3 C90.5780458,151.3 92.217931,150.615684 93.4042511,149.405381 L160.812498,80.6351904 C162.670322,78.7400563 163.093865,75.9004543 161.867043,73.5650842 L161.867043,73.5650842 Z M126.094461,80.4916764 L93.4409723,113.804906 C92.2546522,115.015209 90.614767,115.699525 88.9006323,115.699525 L88.8963822,115.699525 C87.1808008,115.698393 85.5401356,115.011835 84.3545122,113.798919 L51.7703859,80.467397 C49.3693966,78.0113604 49.4584795,74.1165242 51.9692924,71.7679153 C54.4801054,69.41914 58.4618069,69.5064458 60.8627962,71.9624825 L88.9069226,100.649836 L117.013951,71.9749547 C119.41817,69.5220777 123.400212,69.4402597 125.907625,71.7920283 C128.415038,74.1437969 128.49885,78.0387993 126.094461,80.4916764 L126.094461,80.4916764 Z'></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}}/>;
const icon3 = <div style={{height: 20, width: 20}} dangerouslySetInnerHTML={{
    __html: `<svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='SVIP会员' transform='translate(-188.000000, -160.000000)' fill='#F4BC6A' fill-rule='nonzero'>
            <g id='VIP会员' transform='translate(188.000000, 160.000000)'>
                <g id='f-vip' transform='translate(1.720000, 3.000000)'>
                    <path d='M16.4673082,5.2657398 L13.8987525,0.375400894 C13.777679,0.144913116 13.5350088,0 13.2700947,0 L3.30478573,0 C3.04114309,0 2.79941623,0.143538126 2.67777064,0.372336549 L0.0797601027,5.2583779 C-0.0598516709,5.52093866 -0.0131269418,5.84130449 0.195975177,6.05520091 L7.7539091,13.7864675 C7.88713235,13.9227378 8.07147382,14 8.26423157,14 L8.26470912,14 C8.45730851,14 8.64156528,13.9231106 8.77485968,13.7871215 L16.34882,6.06013376 C16.5575643,5.84719734 16.6051533,5.52814093 16.4673082,5.2657398 L16.4673082,5.2657398 Z M12.447917,6.04400858 L8.77898565,9.78706809 C8.64569126,9.92305722 8.46143449,9.99994661 8.2688351,9.99994661 L8.26835755,9.99994661 C8.0755956,9.99981943 7.89125119,9.92267808 7.75803508,9.78639543 L4.09689729,6.04128057 C3.82712321,5.76532139 3.83713253,5.32769935 4.11924634,5.0638107 C4.40136016,4.79990337 4.84874235,4.80971301 5.11851643,5.08567219 L8.26954186,8.30897028 L11.4276349,5.08707356 C11.697772,4.8114694 12.1451924,4.80227637 12.4269241,5.06652003 C12.7086559,5.3307637 12.7180731,5.76840442 12.447917,6.04400858 L12.447917,6.04400858 Z' id='形状'></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}}/>;
const icon4 = <div style={{height: 179, width: 179}} dangerouslySetInnerHTML={{
    __html: `<svg width='179px' height='179px' viewBox='0 0 179 179' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.0914562137'>
        <g id='SVIP会员' transform='translate(-114.000000, -374.000000)' fill='#F4BC6A' fill-rule='nonzero'>
            <g id='VIP会员' transform='translate(158.000000, 122.000000)'>
                <g transform='translate(-44.000000, 252.000000)' id='f-vip'>
                    <g transform='translate(15.394000, 26.850000)'>
                        <path d='M147.382408,47.1283712 L124.393835,3.359838 C123.310227,1.29697238 121.138329,0 118.767347,0 L29.5778323,0 C27.2182306,0 25.0547752,1.28466623 23.9660472,3.33241212 L0.713852919,47.0624822 C-0.535672455,49.412401 -0.117486129,52.2796752 1.75397784,54.1940482 L69.3974864,123.388884 C70.5898345,124.608503 72.2396907,125.3 73.9648726,125.3 L73.9691466,125.3 C75.6929112,125.3 77.3420092,124.61184 78.5349941,123.394737 L146.321939,54.2381971 C148.190201,52.3324162 148.616122,49.4768613 147.382408,47.1283712 L147.382408,47.1283712 Z M111.408857,54.0938768 L78.5719216,87.5942594 C77.3789367,88.8113621 75.7298387,89.4995222 74.0060741,89.4995222 L74.0018001,89.4995222 C72.2765806,89.4983839 70.6266982,88.8079688 69.4344139,87.5882391 L36.6672307,54.0694611 C34.2527527,51.5996264 34.3423361,47.6829092 36.8672548,45.3211058 C39.3921734,42.9591352 43.396244,43.0469315 45.810722,45.5167661 L74.0123996,74.365284 L102.277333,45.5293084 C104.695059,43.0626512 108.699472,42.9803735 111.220971,45.3453543 C113.74247,47.7103351 113.826754,51.6272195 111.408857,54.0938768 L111.408857,54.0938768 Z' id='形状'></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}}/>;
const icon5 = <div style={{height: 18.5, width: 12}} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="登陆、付费" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="简历VIP会员" transform="translate(-186.000000, -244.000000)" fill="#FF5152" fill-rule="nonzero">
            <g id="字体下拉备份-49" transform="translate(186.000000, 244.000000)">
                <g id="编组-11" transform="translate(1.000000, 1.000000)">
                    <polygon id="路径" points="0.0382409178 9.11362284 9.0925136 0 9.96175908 0.886377156 0.906750993 10"></polygon>
                    <polygon id="路径" points="9.13957935 9.96058894 0 0.933967876 0.86042065 0.0394110648 10 9.06528852"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}}/>;
const icon6 = <div style={{height: 18.5, width: 12}} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="登陆、付费" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="简历VIP会员" transform="translate(-186.000000, -218.000000)" fill="#11BBA6">
            <g id="字体下拉备份-38" transform="translate(186.000000, 218.000000)">
                <polygon id="路径" points="5.31004147 10.5 0 5.976451 0.882242051 5.04947669 5.13283901 8.67031399 10.9541284 1.5 12 2.25999049"></polygon>
            </g>
        </g>
    </g>
</svg>`,
}}/>;
const icon7 = <div style={{height: 18.5, width: 12}} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="登陆、付费" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="简历SVIP会员" transform="translate(-186.000000, -218.000000)" fill="#CB9444">
            <g id="字体下拉备份-38" transform="translate(186.000000, 218.000000)">
                <polygon id="路径" points="5.31004147 10.5 0 5.976451 0.882242051 5.04947669 5.13283901 8.67031399 10.9541284 1.5 12 2.25999049"></polygon>
            </g>
        </g>
    </g>
</svg>`,
}}/>;


const highlightRender = (text, color) => {
    return `<span style='color: ${color}'>${text}</span>`
}

export const getResumeNumberByVipType = (vipType) => {
    return vipType === vip_life ? 15 : 5
}

export default withComponent((props) => {
    const {vipType, selectVipType, errorCode, zIndex} = props;
    const [visible2, setVisible2] = useState(false);
    const text = vipType === 'vip' ? 'VIP会员特权' : 'SVIP会员特权';
    const isVip = vipType === 'vip' || vipType === vipTypeEnum.upgradeVip

    const config = useMemo(() => {
        if (!isVip) {
            return [{
                txt: '智能在线编辑',
                type: 2,
            }, {
                txt: `${highlightRender('AI模拟面试', '#CB9444')}`,
                type: 2,
            }, {
                txt: `简历创建份数 ${highlightRender('15份', '#CB9444')}`,
                type: 2,
            }, {
                txt: `在线简历下载 ${highlightRender('30套/月', '#CB9444')}`,
                type: 2,
            }, {
                txt: '会员专属模板 1000+',
                type: 2,
            }, {
                txt: '一键更换模板',
                type: 2,
            }, {
                txt: '调整简历配色、字体',
                type: 2,
            }, {
                txt: `AI消耗次数 ${highlightRender('300次/月', '#CB9444')}`,
                type: 2,
            }, {
                txt: `${highlightRender('优先客服支持', '#CB9444')}`,
                type: 2,
            }]
        }
        return [{
            txt: '智能在线编辑',
            type: 1,
        }, {
            txt: 'AI模拟面试',
            type: 0,
        }, {
            txt: `简历创建份数 ${highlightRender('5份', '#11BBA6')}`,
            type: 1,
        }, {
            txt: `在线简历下载 ${highlightRender('15套/月', '#11BBA6')}`,
            type: 1,
        }, {
            txt: '会员专属模板 1000+',
            type: 1,
        }, {
            txt: '一键更换模板',
            type: 1,
        }, {
            txt: '调整简历配色、字体',
            type: 1,
        }, {
            txt: `AI消耗次数 ${highlightRender('150次/月', '#11BBA6')}`,
            type: 1,
        }, {
            txt: '优先客服支持',
            type: 0,
        }]
    }, [isVip])


    const titleIcon = vipType === 'vip'
        ? icon1 : icon3;
    const bgIcon = vipType === 'vip'
        ? icon2 : icon4;
    return <SiderStyled>
        <FlexStyled $alignItems='center' style={{marginLeft: 32, paddingTop: 30}}>
            <div style={{marginRight: 7}}>{titleIcon}</div>
            <TitleStyled>{text}</TitleStyled>
        </FlexStyled>
        <div style={{marginTop: 40, marginLeft: 30}}>
            {
                config.map((item, index) => {
                    console.log(item, 'item');
                    return <FlexStyled key={index} $alignItems='center' style={{marginTop: 14, columnGap: 14}}>
                        {item.type === 0 ? icon5 : item.type === 1 ? icon6 : icon7}
                        <ItemStyled dangerouslySetInnerHTML={{ __html: item.txt }} />
                    </FlexStyled>;
                })
            }
        </div>
{/*        <FlexStyled $justifyContent='center' $alignItems='center'
                    style={{position: 'absolute', left: 0, right: 0, bottom: 24}}>
            <ButtonStyled onClick={() => setVisible2(true)}>
                VIP/SVIP权益对比
            </ButtonStyled>
        </FlexStyled>*/}
        <div style={{
            position: 'absolute',
            bottom: 84 + 27,
            left: -45,
        }}>
            {bgIcon}
        </div>
        {/*<MemberRightsModal visible={visible2} onCancel={() => setVisible2(false)} zIndex={zIndex}/>*/}
    </SiderStyled>;
});
