import JzButton from '@/components/JzButton'
import useQueryParam from '@/hooks/useQueryParam'
import { Switch, Input, Form } from 'antd-mobile'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useModuleTitle from '../../hooks/useModuleTitle'
import useResumeData from '../../hooks/useResumeData'

const ModuleTitleEditStyled = styled.div`
    padding: 20px;
    .resume-wrap-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 15px;
        padding-bottom: 24px;
        background-color: #FFFFFF;
    }

    .module-title-item {
        padding-bottom: 17px;
        border-bottom: 1px solid #E8E9EB;
    }
    .module-title-item-last {
        padding-top: 20px;
        padding-bottom: 0;
        border: none;
    }

    label {
        display: block;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #888888;
    }
`

const ModuleTitleEdit = () => {
    useResumeData()
    const moduleName = useQueryParam('name')
    const customId = useQueryParam('customId')
    const { titleValue, titleVisible, onChange, save } = useModuleTitle({ moduleName, customId })
    const navigate = useNavigate()

    const handleBack = () => {
        navigate(-1)
    }

    const handleSave = () => {
        save()
    }

    const handleInputChange = (value) => {
        onChange(value, titleVisible)
    }

    const handleSwitchChange = (value) => {
        onChange(titleValue, !value)
    }


    return (
        <ModuleTitleEditStyled>
            <div className='module-title-item'>
                <label className='pb-12'>模块标题</label>
                <Input style={{ 
                    '--font-size': 15,
                }} value={titleValue} onChange={handleInputChange} placeholder='请输入' />
            </div>
            <div className='module-title-item module-title-item-last flex-center justify-between'>
                <label>隐藏标题</label>
                <Switch style={{
                    '--checked-color': '#11BBA6',
                    '--height': '16px',
                    '--width': '30px',
                    '--adm-color-background': '#DEE0E6',
                    '--border-width': '1px'
                }} checked={!titleVisible} onChange={handleSwitchChange} />
            </div>

            <div className="resume-wrap-footer flex">
                <JzButton onClick={() => handleBack()} className="flex-1" style={{ marginRight: 8, backgroundColor: '#F3F4F5' }} >取消</JzButton>
                <JzButton onClick={() => handleSave()} type="submit" className="flex-2" $type="primary">保存</JzButton>
            </div>
        </ModuleTitleEditStyled>
    )
}

export default ModuleTitleEdit