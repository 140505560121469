

// value: [Date, Date]
import { DatePicker } from "antd-mobile"
import moment from "moment"
import { useState } from "react"
import styled from "styled-components"

const RangeDatePickerStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .divider {
        width: 10px;
        height: 2px;
        background: #DEDEDE;
    }
    .gray {
        color: #CCCCCC;
    }
`

const minDate = moment("1961/1/1").toDate()
const maxDate = new Date()

// onChange: [Date, Date]
const RangeDatePicker = ({ value = [], onChange }) => {
    const [startVisible, setStartVisible] = useState(false)
    const [endVisible, setEndVisible] = useState(false)

    return <RangeDatePickerStyled>
        <div onClick={() => setStartVisible(true)}>
            <DatePicker
                min={minDate}
                max={maxDate}
                visible={startVisible} 
                className="edit-custom-picker" 
                title={"开始时间"}
                value={value?.[0]}
                onCancel={() => setStartVisible(false)}
                onClose={() => setStartVisible(false)}
                onConfirm={(v) => onChange([v, value[1]])}
            >
                {value => value ? moment(value).format('YYYY年MM月DD日') : <span className="gray">开始时间</span>}
            </DatePicker>
        </div>
        <div className="divider"/>
        <div onClick={() => setEndVisible(true)}>
            <DatePicker
                min={minDate}
                max={maxDate}
                visible={endVisible} 
                className="edit-custom-picker" 
                title={"结束时间"}
                value={value?.[1]}
                onCancel={() => setEndVisible(false)}
                onClose={() => setEndVisible(false)}
                onConfirm={(v) => onChange([value[0], v])}
            >
                {value => value ? moment(value).format('YYYY年MM月DD日') : <span className="gray">结束时间</span>}
            </DatePicker>
        </div>

    </RangeDatePickerStyled>
}

export default RangeDatePicker