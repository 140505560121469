import styled from "styled-components"


const SystemFeedBackComponentStyled = styled.div`
    .system-feedback {
        background: #F6FCFB;
        border-radius: 8px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #5B5C63;
        padding: 14px;

        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word; 
    }
`

const SystemFeedBackComponent = ({ answer = '“在之前的一个项目中，我曾在一家软件公司担任 UI 设计师。我们收到了大量关于用户界面的复杂性以及浏览不同功能的困难的客户反馈。我的职责是分析这些反馈并进行改进以增强整体用户体验。首先，我进行了广泛的用户研究，包括访谈和调查，以深入了解用户的痛点。这帮助我确定了需要改进的具体领域。', feedback = '1、 提供了无关的答案\n2、没有解决数据收集系统的优化问题\n3、缺乏具体的改进策略或步骤' }) => {
    return <SystemFeedBackComponentStyled>
        <div className="system-feedback">
            {answer}
        </div>
    </SystemFeedBackComponentStyled>
}

export default SystemFeedBackComponent