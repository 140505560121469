import React from 'react';
import ReactDOM from 'react-dom/client';
import "./initialize"
import App from './App.js';
import reportWebVitals from './reportWebVitals';
import store from "@/redux/store";
import local_store from "store";
import userSlice from "@/redux/services/userSlice";
import { fetchUserThunk } from "@/components/resume/redux/thunk/user";
import { update_keyword } from './http/api';
import qs from 'qs';
import { decode } from 'web-iconv';
import { getParam, isMiniProgram } from './pages/Mobile/utils';
import { updateWxToken } from './pages/Mobile/utils/login';
import loginHandler from './common/user/loginHandler';


const { dispatch } = store;
const user = local_store.get('user');
if (user) {
    dispatch(userSlice.actions.set(user));
    dispatch(fetchUserThunk());
}

const updateKeyword = async () => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const hasPush = !!localStorage.getItem('hasPushKeyword');
    if (hasPush) return;
    let { keyword: keyword$1, source, bd_vid } = query;
    if (keyword$1 || source || bd_vid) {
        let keyword;
        let logIdUrl = window.location.href;
        if (keyword$1) {
            keyword = await decode(keyword$1, 'gb2312');
        }
        update_keyword({
            body: { keyword, source, logIdUrl }
        }).catch(() => void 0);
        localStorage.setItem('hasPushKeyword', 'true');
    }
};

updateKeyword()

if (isMiniProgram()) {
    const user = decodeURIComponent(getParam('user'))
    console.log('查看一下', window.location.href)
    if (user) {
        try {
            updateWxToken(JSON.parse(user)).then(user => {
                console.log('刷了一下token', user)
            })
        } catch (error) {
            console.log('error', error)
        }
    } else {
        console.log('user为空', user)
    }
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
