import withComponent from '@/hoc/withComponent';
import styled from 'styled-components';
import Pointer from '@/styled/Pointer';
import LogoComponent from "@/components/Logo"

import React, { useState } from 'react';
import FlexStyled from '@/styled/FlexStyled';
import User from "@/components/user";


const Styled = styled.div`
  flex-shrink: 0;
  height: 60px;
  background: #3E4248;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  padding: 0 40px;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
`;
const LineStyled = styled.div`
  width: 118px;
  height: 38px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 19px;

  :hover {
    cursor: pointer;
    opacity: 0.9;
  }

  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 38px;
  text-align: center;
`;

export default withComponent(() => {

  return <Styled>
    <div>
      <LogoComponent isWhite isDark={false} isO={false} />
    </div>
    <FlexStyled $alignItems='center'>

      <User/>

    </FlexStyled>
  </Styled>;
});
