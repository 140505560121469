import { Toast } from "antd-mobile"


export const showToast = (content, icon = 'loading', duration = 0) => {
    Toast.show({
        icon,
        content,
        duration,
    })
}

export const clearToast = () => {
    Toast.clear()
}