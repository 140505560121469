import FlexStyled from '@/styled/FlexStyled';
import React from 'react';
import withComponent from '@/hoc/withComponent';
import styled from 'styled-components';
import { svip_life, svip_y, vip_life, vip_y, vipTypeEnum } from '@/const/pay';
const isHalfPrice= ()=>false

const CardRenderStyled = styled.div`
  position: relative;
  /* width: 147px; */
  height: 156px;
  background: #FFFFFF;
  border-radius: 6px;
  padding-top: 18px;
  background: ${props => props.isActive ? props.isSVip ? '#FFFBF4' : 'rgba(1,152,133,0.04)' : 'white'};
  border: 2px solid ${props => props.isActive ? props.isSVip ? '#EDB769' : '#00BCA6' : 'transparent'};
  cursor: pointer;
`;
const CardTitleStyled = styled.div`
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 22px;
  text-align: center;
`;
const PriceStyled = styled.div`
  position: relative;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1D2129;
  line-height: 28px;
  letter-spacing: 1px;
`;
const PrefixPriceStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1D2129;
  line-height: 14px;
  letter-spacing: 1px;
`;

const CardHiteStyled = styled.div`
  height: 24px;
  padding: 0 12px;
  white-space: nowrap;
  background: linear-gradient(225deg, #FF641C 0%, #EF333F 100%);
  border-radius: 10px 0px 10px 0px;
  position: absolute;
  top: -12px;
  left: -2px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 12px;

`;

const LineStyled = styled.div`
  height: 1px;
  background: #F2F3F4;
  margin: 0 19px;
  background: ${props => props.isActive ? props.isSVip ? '#FFEED6' : '#D8F3EF' : '#F2F3F4'};
`;
const DesStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4C535C;
  line-height: 12px;
  text-align: center;
`;
const OPriceStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #A4A7AF;
  line-height: 12px;
  text-align: center;
  text-decoration: line-through;
`;
const ReduceTextStyled = styled.div`
  position: absolute;
  bottom: -4px;
  right: -2px;
  transform: translateX(100%) scale(0.8);
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #EF333F;
  line-height: 17px;
  letter-spacing: 1px;
  white-space: nowrap;
`;
const Reduce2TextStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 17px;
  color: #1D2129;
  margin-right: 6px;
  text-decoration: line-through;
  text-decoration-color: #EF333F;
`;
const PriceTextStyled = styled.div`
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #EF333F;
  line-height: 20px;
  letter-spacing: 1px;
`;
const Price2TextStyled = styled.div`
  position: relative;
  top: 3px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #EF333F;
  line-height: 20px;
  letter-spacing: 1px;
`;
const SpanStyled = styled.div`
  position: absolute;
  right: 10px;
  top: -14px;
  font-size: 12px;
  color: #EF333F;
`

export default withComponent((props) => {
  const { upgradeSVipInfo, index, price, title, footerText, isActive, onClick, style, originPrice, hitText, vipType, reduceText, baseMoney, id, isUpgrade, price1,presentReport } = props;
  let isDiscount = originPrice !== price;
  const isSVip = vipType === 'svip';
  let isH = isHalfPrice() && (id === 20 || id === 21 || id === 28) && !isUpgrade;
  const present = ()=>{
    presentReport && presentReport()
  }
  let e;
  if (isH) {
    e = <FlexStyled $alignItems='center' $justifyContent='center' style={{ marginTop: 14, marginBottom: 14 }}>
      <Reduce2TextStyled>¥{baseMoney}</Reduce2TextStyled>
      <FlexStyled $alignItems='flex-end'>
        <Price2TextStyled>
          ¥
        </Price2TextStyled>
        <PriceTextStyled>
          {price}
        </PriceTextStyled>
      </FlexStyled>
    </FlexStyled>;
  } else {
    e = <FlexStyled $alignItems='center' $justifyContent='center' style={{ marginTop: 14, marginBottom: 14, position: 'relative', left: (reduceText !== undefined && vipType === vipTypeEnum.upgradeSVip) ? -14 : undefined }}>
      {upgradeSVipInfo && upgradeSVipInfo.remainingSum && <SpanStyled>-¥{upgradeSVipInfo.remainingSum}</SpanStyled>}
      <FlexStyled $alignItems='flex-end'>
        <PrefixPriceStyled>
          ¥
        </PrefixPriceStyled>
        <PriceStyled>
          {reduceText !== undefined && vipType === vipTypeEnum.upgradeSVip && <ReduceTextStyled>(已抵扣¥{reduceText})</ReduceTextStyled>}
          {vipType === vipTypeEnum.upgradeSVip ? baseMoney : Number(price1?.toFixed( ((id === svip_life || id === svip_y || id === vip_life || id === vip_y) ? 2 : 1) ))}
        </PriceStyled>
        {vipType !== vipTypeEnum.upgradeSVip && <div style={{ fontSize: 14, position: 'relative', top: 2 }}>
          {/* /月 */}
        </div>}
      </FlexStyled>
    </FlexStyled>;
  }
  return (
    !!price ? <div style={{ position: 'relative', flex: 1, marginLeft: index !== 0 ? 12 : 0 }}>
      <div onClick={onClick}>
        {hitText && <CardHiteStyled>{hitText}</CardHiteStyled>}
        <CardRenderStyled isSVip={isSVip || isUpgrade} isActive={isActive} style={style}>
          <CardTitleStyled>{title}</CardTitleStyled>
          {e}
          <LineStyled isActive={isActive} isSVip={isSVip || isUpgrade} style={{ marginTop: 8, marginBottom: 15 }} />
          {/*<DesStyled>{footerText}</DesStyled>*/}
          {/* {isUpgrade && that.upgradeSVipInfo && <Deduction upgradeSVipInfo={that.upgradeSVipInfo} style={{ marginTop: 10 }} />} */}
          {
            id === 30 ? <OPriceStyled style={{ marginTop: 8 }}>临时VIP</OPriceStyled> :
            <OPriceStyled style={{ marginTop: 8, visibility: isDiscount ? undefined : 'hidden' }}>原价 ￥ {originPrice}</OPriceStyled>
          }
          
        </CardRenderStyled>
      </div>
    </div> : <></>
  );
});
