import styled from "styled-components";

export const MenuContainerStyled = styled.div`
  width: 128px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 0.5);
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  padding: 8px 0;
`
export const MenuItemStyled = styled.div`
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2C2C2C;
  line-height: 40px;
  cursor: pointer;
  text-align: center;


  &:hover {
    background: #F4F4F4;
  }
`
