import withBaseMenu from "@/components/resume/moduleManage/withBaseMenu";
import {IconContainerStyled} from "@/components/resume/moduleManage";
import withComponent from "@/hoc/withComponent";
import {Tooltip} from "antd";

const icon = <div style={{height: 16, width: 16, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="基本信息状态" transform="translate(-680.000000, -84.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-3备份" transform="translate(676.000000, 80.000000)">
                <g id="设置备份" transform="translate(4.000000, 4.000000)">
                    <path d="M8.00376747,10.9272838 C9.62620327,10.9272838 10.9414473,9.61203971 10.9414473,7.98960391 C10.9414473,6.36716812 9.62620327,5.05192403 8.00376747,5.05192403 C6.38133167,5.05192403 5.06608759,6.36716812 5.06608759,7.98960391 C5.07114328,9.60994096 6.38343043,10.9222281 8.00376747,10.9272838 L8.00376747,10.9272838 Z M1.1429463,7.98960391 C1.14229547,7.5043351 1.18855663,7.02013496 1.28117715,6.54378654 C2.11341752,6.45214744 2.84880734,5.96074209 3.25190911,5.22689556 C3.68125668,4.45863505 3.71887812,3.53177961 3.35320841,2.73124902 C4.08173874,2.02375532 4.95974948,1.4887667 5.92252718,1.16571429 C6.38534198,1.88413238 7.18141022,2.31825129 8.03599907,2.31825129 C8.89058792,2.31825129 9.68665616,1.88413238 10.149471,1.16571429 C11.1105198,1.49567756 11.9853501,2.03693942 12.7095807,2.74966707 C12.355779,3.54793217 12.4001692,4.46646726 12.8292981,5.22689556 C13.2221995,5.92835577 13.9208059,6.40545283 14.7171488,6.51615946 C14.920886,7.55815163 14.9020564,8.63144251 14.6618946,9.66564698 C13.8777629,9.79717466 13.1981588,10.2830916 12.820089,10.982538 C12.4334786,11.6749251 12.3630402,12.5000608 12.6266994,13.2479588 C11.8812375,13.9701277 10.9802754,14.511965 9.99291748,14.8319116 C9.51827294,14.2231876 8.7894828,13.8672986 8.01758101,13.8672986 C7.24567922,13.8672986 6.51688908,14.2231876 6.04224454,14.8319116 C5.05803367,14.5233286 4.15723317,13.994187 3.40846258,13.2847949 C3.69469507,12.5232095 3.63068076,11.6741775 3.23349105,10.9641199 C2.84360934,10.2413775 2.13173854,9.74854384 1.31801326,9.6380199 C1.19892764,9.09670624 1.14024508,8.5438548 1.1429463,7.98960391 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>

export default withComponent(withBaseMenu((props) => {
    const {onClick,} = props;
    return <Tooltip title={"设置"} placement={"bottom"}
                    overlayClassName="tooltipPointerEventsNone">
        <IconContainerStyled onClick={onClick}>
            {icon}
        </IconContainerStyled>
    </Tooltip>

}))
