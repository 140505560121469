import { useLayoutEffect, useState } from "react"

const useWidth = () => {
    const [width, setWidth] = useState(document.documentElement.clientWidth)
    useLayoutEffect(() => {
        const updateWidth = () => {
            const w = document.documentElement.clientWidth
            setWidth(w)
        }
  
        updateWidth()
        window.addEventListener('resize', updateWidth, false);
        return () => {
            window.removeEventListener('resize', updateWidth, false);
        }
    }, [])
    return width
}

export default useWidth