import {createSlice} from '@reduxjs/toolkit';
import initState from '../initState';
import {fetchUserThunk, fetchAnonymousUserThunk} from "../thunk/user"


export default createSlice({
    name: 'user',
    initialState: initState.user,
    reducers: {
        set(state, {payload}) {
            return {...state, ...payload};
        },
        clear() {
            return null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserThunk.fulfilled, (state, action) => {
            return action.payload;
        });
        builder.addCase(fetchAnonymousUserThunk.fulfilled, (state, action) => {
            return action.payload;
        });
    },

});
