import withComponent from "@/hoc/withComponent";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import TabItemImport from "./TabItemImport";
import TabItemOne from "./TabItemOne";
import { getJzImageUrl } from "@/utils/image";

const TabsStyled = styled.div`
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  margin-bottom: 53px;
  .t-list {
    margin: 0 auto;
    margin-bottom: 16px;
  }
`;
const TemplateButtonStyled = styled.div`
    position: absolute;
    top: 8px;
    right: 0;
    width: 110px;
    height: 38px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: PingFangSC, PingFang SC;
    font-weight: bold;
    font-size: 14px;
    color: #555555;
    ${(props) => props.$isB
        && `
    background: #3D3F4B;
    color: #ECECEC;
  `}
    line-height: 14px;
    text-align: right;
    font-style: normal;
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }
    :active {
        opacity: 0.6;
    }
`
const HeaderStyled = styled.div`
  position: relative;
  height: 54px;
`;
const TabItemStyled = styled.div`
  text-align: center;
  font-size: 15px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: ${(props) => (props.$active ? "#FFFFFF" : "#15252B")};
  ${(props) => props.$isB && "color: #FFFFFF"};
  cursor: pointer;
  white-space: nowrap;
`;

// const TabItemStyled = styled.div`
//     border-radius: 21px 0px 0px 21px;
//     width: 159px;
//     height: 42px;
//     color: #FFF;
//     font-size: 15px;

//     ${props => props.$active && `
//         background: #FFFFFF;
//         color: #01B6A1;
//     `}
//     ${props => props.$isImport && `
//         border-radius: 0 21px 21px 0px;
//     `}
// `

const tabConfig = [
  {
    key: "one",
    text: "一键生成",
  },
  {
    key: "import",
    text: "导入简历",
  },
];

const Tabs = () => {
  const [tabKey, setTabKey] = useState("one");
  const navigate = useNavigate();
    const type = 'w'
  const handleClickAgreement = () => {
    navigate("/mobile/agreement");
  };

  const handleClick = (value)=>{
    setTabKey(value)
  }

  const onClickGoTemplate = () =>{
    navigate('/template')
  }

  return (
    <TabsStyled>
      <div className="t-list flex-center" style={{display:'inline'}}>
        {/* {tabConfig.map((item) => {
          const isActive = tabKey === item.key;
          return (
            <TabItemStyled
              key={item.key}
              $active={isActive}
              $isImport={item.key === "import"}
              className="flex-center t-item"
              onClick={() => setTabKey(item.key)}
            >
              {item.text}
            </TabItemStyled>
          );
        })} */}

        <HeaderStyled>
          {tabKey === "one" && (
            <>
              {type === "w" ? (
                <img
                  src="https://oss.jianzeppt.com/user/test/4556/817065b8-afb6-4b74-afc1-91d1207fa69f.png"
                  style={{
                    width: 254,
                    height: 54,
                    top: 0,
                    left: 160,
                    position: "absolute",
                    pointerEvents: "none",
                  }}
                />
              ) : (
                <img
                  src="https://oss.jianzeppt.com/user/test/4556/817065b8-afb6-4b74-afc1-91d1207fa69f.png"
                  style={{
                    width: 254,
                    height: 54,
                    top: 0,
                    left: 160,
                    position: "absolute",
                    pointerEvents: "none",
                  }}
                />
              )}

              <img
                src={getJzImageUrl("jkcxvnjdsf.png")}
                style={{
                  width: 204,
                  height: 64,
                  top: -3,
                  left: -5,
                  position: "absolute",
                  pointerEvents: "none",
                }}
              />
            </>
          )}

          {tabKey === "import" && (
            <>
              {type === "w" ? (
                <img
                  src="https://oss.jianzeppt.com/user/test/4556/eae2fcdc-8dd8-402a-9301-1f5d4e25ed66.png"
                  style={{
                    width: 200,
                    height: 66,
                    top: 0,
                    left: 0,
                    position: "absolute",
                    pointerEvents: "none",
                  }}
                />
              ) : (
                <img
                  src="https://oss.jianzeppt.com/user/test/4556/eae2fcdc-8dd8-402a-9301-1f5d4e25ed66.png"
                  style={{
                    width: 200,
                    height: 66,
                    top: 0,
                    left: 0,
                    position: "absolute",
                    pointerEvents: "none",
                  }}
                />
              )}
              <img
                src="https://oss.jianzeppt.com/user/test/4556/5c3432ca-0976-48e2-8401-6f0f963ef1db.png"
                style={{
                  width: 265,
                  height: 64,
                  top: -3,
                  left: 162,
                  position: "absolute",
                  pointerEvents: "none",
                }}
              />
            </>
          )}

          <TabItemStyled
            key="one"
            $key="one"
            $active={tabKey === "one"}
            onClick={() => handleClick("one")}
            style={{
              position: "absolute",
              top: 19,
              left: 17,
              width: 136,
              height: 21,
            }}
          >
            一键生成
          </TabItemStyled>
          <TabItemStyled
            key="import"
            $key="import"
            $active={tabKey === "import"}
            onClick={() => handleClick("import")}
            style={{
              position: "absolute",
              top: 19,
              left: 209,
              width: 188,
              height: 21,
            }}
          >
            导入内容（PDF、Word）
          </TabItemStyled>
            <TemplateButtonStyled onClick={onClickGoTemplate}>
              浏览模板站
            </TemplateButtonStyled>
        </HeaderStyled>
      </div>
      <TabItemImport
        style={{ display: tabKey === "import" ? "block" : "none" }}
      />
      <TabItemOne style={{ display: tabKey === "one" ? "block" : "none" }} />
    </TabsStyled>
  );
};

export default withComponent(Tabs);
