import { Modal, Toast } from "antd-mobile";
import React from "react";
import styled from "styled-components";
import ThemeButton from "@/styled/ThemeButton";
import { useCallback, useEffect, useRef } from "react";
import useForceUpdate from "@/hooks/useForceUpdate";
import withComponent from "@/hoc/withComponent";
import FlexStyled from "@/styled/FlexStyled";
import { bindPhonePc, getUserInfo, sendCode } from "@/http/api";
import store from "store";
import { useDispatch, useSelector, useStore } from "react-redux";
import { getEnterpriseMobile } from "@/http/api";

const Text1Styled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
`;
const Text2Styled = styled.div`
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #2c2c2c;
  font-weight: bold;
  margin: 12px 0;
`;

const InputStyled1 = styled.input`
  width: 320px;
  height: 44px;
  padding: 0 12px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #e4e6e8;

  :focus {
    box-shadow: 0px 0px 4px 0px rgba(0, 188, 166, 0.5);
    border: 1px solid #00bca6;
  }
`;
const InputStyled2 = styled.input`
  width: 206px;
  height: 44px;
  padding: 0 12px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #e4e6e8;

  :focus {
    box-shadow: 0px 0px 4px 0px rgba(0, 188, 166, 0.5);
    border: 1px solid #00bca6;
  }
`;

const ButtonStyled = styled.div`
  width: 108px;
  height: 44px;
  background: #f7f8f9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  :hover {
    opacity: 0.9;
  }
`;

const Button2Styled = styled(ThemeButton)`
  width: 120px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: white;
  ${(props) => (props.isDisable ? "opacity:0.3 !important;" : "")}
  ${(props) => (props.isDisable ? "cursor:not-allowed !important;" : "")}
`;

const Content = (props) => {
  const user = useSelector((state) => state.user);
  const ref = useRef();
  const ref2 = useRef();
  const dispatch = useDispatch();
  const that = useRef({
    binding: false,
    codeSending: false,
    countDowning: false,
    codeSendTimer: undefined,
    sendTelephone: undefined,
    countDown: 60000,
  }).current;
  const [, update] = useForceUpdate();
  const countDown = useCallback(() => {
    that.countDowning = true;
    that.countDown = 60000;
    update();
    that.codeSendTimer = setInterval(() => {
      that.countDown -= 1000;
      if (that.countDown <= 0) {
        that.countDowning = false;
        clearInterval(that.codeSendTimer);
      }
      update();
    }, 1000);
  }, []);
  function isValidChinesePhoneNumber(phoneNumber) {
    const regExp = /^1[3-9]\d{9}$/;
    return regExp.test(phoneNumber);
  }
  const onClickSend = useCallback(async () => {
    if (that.codeSending) return;
    that.codeSending = true;
    update();
    const value = ref.current.value;
    if (!value || !isValidChinesePhoneNumber(value)) {
      // if (!value) {
      Toast.show({ content: "手机号格式不正确" });
    that.codeSending = false;
    return;
    }

    that.sendTelephone = value;
    try {
      await sendCode({ phone: value });
      Toast.show({ content: "验证码已发送，请查看手机消息！" });

      countDown();
    } catch (e) {
    } finally {
      that.codeSending = false;
      update();
    }
  }, []);
  const onChange = useCallback(() => {
    update();
  }, []);
  const onBind = useCallback(async () => {
    if (that.binding) {
      return;
    }
    const value = ref.current.value;
    if (!value || !isValidChinesePhoneNumber(value)) {
      // if (!value) {
      Toast.show({ content: "手机号格式不正确" });

      return;
    }
    that.binding = true;
    // const hide = message.loading("", 0);
    try {
      const data = await getEnterpriseMobile({
        query: { code: ref2.current.value + "", phone: ref.current.value + "" },
      });
      if (data) {
        props.onSuccess && props.onSuccess({ phone: ref?.current?.value });
      }
    } catch (e) {
      Toast.show({ content: "验证码错误" });

    } finally {
      that.binding = false;
      // hide();
    }
  }, []);
  const canBind = !!ref2.current?.value;
  useEffect(() => {
    return () => {
      clearTimeout(that.codeSendTimer);
    };
  }, []);

  return (
    <Modal
      width="400px"
      height="405px"
      visible={props.open}
      footer={null}
      showCloseButton
      closeOnMaskClick
      destroyOnClose
      onClose={props.onCancel}
      content={
        <div>
          {
            <>
              <FlexStyled $justifyContent="center">
                <img
                  src="https://pub.jianzeppt.cn/image/dengyue.png"
                  style={{ width: "130px", height: "92px" }}
                ></img>
              </FlexStyled>

              <FlexStyled $justifyContent="center" alignItems="center">
                <Text2Styled>恭喜你！</Text2Styled>
              </FlexStyled>
              <FlexStyled $justifyContent="center" alignItems="center">
                <Text1Styled>获得免费职业测评机会</Text1Styled>
              </FlexStyled>
              <InputStyled1
                ref={ref}
                type={"phone"}
                placeholder="请输入手机号"
                style={{ marginTop: 30, width: "calc(100% - 24px)" }}
              />
              <FlexStyled alignItems="center" style={{ marginTop: 12 }}>
                <InputStyled2
                  onChange={onChange}
                  ref={ref2}
                  placeholder="短信验证码"
                  style={{ marginRight: 6 }}
                />
                <ButtonStyled
                  onClick={that.countDowning ? undefined : onClickSend}
                >
                  {that.countDowning
                    ? that.countDown / 1000 + "s"
                    : "发送验证码"}
                </ButtonStyled>
              </FlexStyled>
              <FlexStyled $justifyContent="center">
                <Button2Styled
                  isDisable={!canBind}
                  onClick={canBind ? onBind : undefined}
                  style={{ marginTop: 64, marginBottom: "28px", width: "100%" }}
                >
                  立即领取
                </Button2Styled>
              </FlexStyled>
            </>
          }
        </div>
      }
    ></Modal>
  );
};

export default Content;
