import React from 'react';
import FlexStyled from '@/styled/FlexStyled';
import Pointer from '@/styled/Pointer';
import {BtnStyleEl} from "@/components/ErrorModal";


export default (props) => {
  return <FlexStyled $flexDirection='column' $alignItems='flex-start' $justifyContent='flex-start' style={{padding: '20px', width: '100%'}}>
    <div style={{ fontSize: 18, fontWeight: 'bold' }}>页数超出限制</div>
    <FlexStyled $justifyContent='center' style={{ marginTop: 15, fontSize: 14, color: '#999999' }}>单个简历页数达到使用限制，请升级</FlexStyled>
    <FlexStyled $justifyContent='flex-end' style={{ marginTop: 20, fontSize: 16, color: '#007AFF', width: '100%' }}>
      {/*<Pointer onClick={props.onCancel}>升级会员</Pointer>*/}
      {/*<BtnStyleEl onClick={props.onCancel}>升级</BtnStyleEl>*/}
    </FlexStyled>
  </FlexStyled>;
};
