import {createSlice} from '@reduxjs/toolkit';
import initState from '../initState';


export default createSlice({
    name: 'template',
    initialState: initState.template,
    reducers: {
        set(state, {payload}) {
            return {...state, ...payload};
        },
        clear() {
            return null
        },
    }
});
