import './initialize';
import Router from "./router/index"
import {Provider} from "react-redux";
import store from "@/redux/store";
import { checkWebpFeature } from "./utils/checkWebpFeature"
// import ErrorModal from "@/components/ErrorModal";


checkWebpFeature()
function App(props) {
    return <Provider store={store}>
        {/* <ErrorModal/> */}
      <Router {...props}/>
    </Provider>
}

export default App;
