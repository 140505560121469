import {createElement, useEffect} from "react";
import withComponent from "@/hoc/withComponent";
import { useSelector} from "@/components/resume/redux/hooks";
import getNestedProperty from "@/utils/getNestedProperty";
import {Module} from "@/common/module/module";
import getResumePath from "@/common/resume/getResumePath";
import getPlaceholderBySlot from "@/common/config/getPlaceholder";
import {BASE_Slot} from "@/common/slot/slot";
import {BASE_FIELD_ENUM} from "@/common/field/field";


export default withComponent((props) => {
    const {tagName, style, className, children = []} = props;

    const path = getResumePath(Module.BASE, undefined, BASE_Slot.GENDER);
    const value = useSelector((state) => getNestedProperty(state.resumeData, path));
    const placeholder = getPlaceholderBySlot(Module.BASE, BASE_FIELD_ENUM.GENDER, BASE_Slot.GENDER);
    return createElement(tagName, {style, className}, value||placeholder);
})
