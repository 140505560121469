import {
  fetchResumeSearch,
  getDataFromTemplate,
  reachUserCrateResumeLimit,
  getSearchTagsJson
} from "@/http/api";
import { InfiniteScroll, Popup, Toast, Skeleton, Input } from "antd-mobile";
import { useEffect, useReducer, useState } from "react";
import config from "@/pages/Home/config";
import { ReactComponent as SearchIcon } from "@/pages/Mobile/svg/search-gray.svg";
import { Ellipsis } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import getQuery from "@/utils/getQuery";
import strictStringify from "@/common/strictStringify";
import {
  ResumeListStyled,
  TagStyled,
  PopupContentStyled,
  ResumeButton,
  rightSvg,
} from "./ResumeListStyled";
import styled from "styled-components";
import { useImmerReducer } from "use-immer";
import { isEmpty } from "lodash";
import RatioImg from "@/pages/Mobile/components/RatioImg";
import isLogin from "@/common/isLogin";
import errorSlice from "@/redux/services/errorSlice";
import store from "@/redux/store";
import { useDispatch } from "react-redux";
import { compressImage } from "@/utils/image";
import { clearFilterWithoutSearchWord,setConfigParams} from '@/pages/Mobile/pages/TemplateList/indexSlice.js';
import { getFilterConfigByType } from '@/pages/Home/config';
import DropdownMenu from "./DropDownMenu";

// const MobileUseList = config.find(item => item.title === '行业').children

const limit = 6;

const SearchInputStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7f7f7;
  border-radius: 20px;
  overflow: hidden;
  width: 172px;
  input {
    font-size: 11px;
    box-sizing: border-box;
    width: 100%;
    background: transparent;
    padding: 8px 0px 8px 16px;
    border: none;
    outline: none;
    color: #000;
  }
  input::placeholder {
    color: #b6b6b6;
  }
  .svg {
    /* position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%); */
    width: 24px;
    height: 24px;
    margin-left: 10px;
    margin-right: -10px;
  }
`;

const initialState = {
  dataList: [],
  filter: {
    use: "全部", // 用途
    searchWord: "", // 关键词
    position: "全部",
    profession: "全部",
    style: "全部",
  },
  hasMore: true, //
  current: 1,
  loading: false,
};

const filterConfig = {
    // '行业': getFilterConfigByType('行业'),
    '语言': getFilterConfigByType('语言'),
    '用途': getFilterConfigByType('用途'),
    '风格': getFilterConfigByType('风格')
}

const listReducer = (draft, action) => {
  switch (action.type) {
    case "init": {
      draft.current = 1;
      draft.dataList = [];
      draft.hasMore = true;
      break;
    }
    case "loading": {
      draft.loading = true;
      break;
    }
    case "changed": {
      draft.current += 1;
      draft.dataList.push(...action.data);
      if (action.data.length < limit) {
        draft.hasMore = false;
      } else {
        draft.hasMore = true;
      }
      draft.loading = false;
      break;
    }
    case "updateFilter": {
      draft.filter = {
        ...draft.filter,
        ...action.filter,
      };
      draft.current = 1;
      draft.dataList = [];
      draft.hasMore = true;
      break;
    }
    default:
      break;
  }
};

const ResumeList = ({ home, filter = {}, style }) => {
  const navigate = useNavigate();
  const dispatchCommon = useDispatch();
  const [state, dispatch] = useImmerReducer(listReducer, {
    ...initialState,
    filter: {
      ...initialState.filter,
      ...filter,
    },
  });
  const { position } = state.filter;
  if (filter) {
  }
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectTemplate, setSelectTemplate] = useState({});
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (!isEmpty(filter)) {
      dispatch({
        type: "updateFilter",
        filter,
      });
    }
  }, [filter]);

  const fetchList = async () => {
    const res = await fetchResumeSearch({
      query: {
        page: state.current,
        limit: 6,
        ...state.filter,
      },
    });
    const { dataList } = res;
    dispatch({
      type: "changed",
      data: dataList || [],
    });
  };

  const handleClickTemplate = (template) => {
    setVisible(true);
    setLoading(true);
    setImgKey((n) => n + 1);
    setSelectTemplate(template);
  };
  const throwErrorCustomWithErrorCodeModal = async (errorCode) => {
    dispatch(errorSlice.actions.setCode(errorCode))
  };
  const handleSelectTemplate = async () => {
   
    if (btnLoading) return;
    setBtnLoading(true);
    getDataFromTemplate({ query: { resumeId: selectTemplate.id } })
      .then((res) => {
        if (res) {
          const query = {
            absolute_laout: getQuery().absolute_laout,
            id: res.id,
          };
          navigate("/mobile/resume" + strictStringify(query), {
            state: { isTemplate: true },
          });
        } else {
          Toast.show("网络繁忙，请重试");
        }
      }).catch((err)=>{
        console.log(err)
        if(err.code===10201){
          if(!isLogin()){
            throwErrorCustomWithErrorCodeModal(1009);
            return
          }
          throwErrorCustomWithErrorCodeModal(10201);
          return
        }
        if(err.code === 1009){
          throwErrorCustomWithErrorCodeModal(1009);
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const handleClickAll = () => {
    navigate("/mobile/template");
  };

  const [loading, setLoading] = useState(false);

  const loadImg = () => {
    setLoading(false);
  };
  const [imgKey, setImgKey] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const handleSearch = () => {
    dispatchCommon(
        setConfigParams({ type: "searchWord", value: searchValue })
    );
    navigate("/mobile/template?fromHome=true", { replace: true });
  };

  const onDropdownOk = (type, value) => {
    dispatchCommon(
      setConfigParams({ type: "searchWord", value: searchValue })
    );
    navigate("/mobile/template?fromHome=true", { replace: true });
  };
  
  return (
    <ResumeListStyled style={style}>
      {home && (
        <div className="header flex justify-between items-center" style={{marginBottom:'0px'}}>
          <h2>简历模板</h2>

          <SearchInputStyled>
          <SearchIcon className="svg" />
          <Input
                className="search-input"
                placeholder="在2000万+模板中搜索…"
                clearable
                onChange={(e) => setSearchValue(e)}
                value={searchValue}
                onEnterPress={handleSearch}
            ></Input>
          </SearchInputStyled>
        </div>
      )}
        {home && <DropdownMenu onOk={onDropdownOk} />}
      <div className="resume-wrap">
        {/* {
                home && <div className="resume-use flex-center justify-start">
                    {
                        MobileUseList.map(item => {
                            return <TagStyled
                                onClick={() => dispatch({
                                    type: 'updateFilter',
                                    filter: {
                                        position: item
                                    }
                                })}
                                $active={item === position}
                                key={item}
                                className="flex-center"
                            >{item}</TagStyled>
                        })
                    }
                </div>
            } */}
        <div className="resume-list">
          {state.dataList.map((item) => {
            return (
              <div
                key={item.id}
                className="resume-item"
                onClick={() => handleClickTemplate(item)}
              >
                <RatioImg
                  className="resume-item-img"
                  src={item.cover}
                  ratio={[794, 1103]}
                />
                <Ellipsis
                  className="resume-item-title"
                  direction="end"
                  content={item.name}
                />
              </div>
            );
          })}
        </div>
        <InfiniteScroll loadMore={fetchList} hasMore={state.hasMore} />
      </div>

      <Popup
        style={{ zIndex: 102 }}
        visible={visible}
        onMaskClick={() => setVisible(false)}
        onClose={() => {
          setVisible(false);
        }}
        showCloseButton
        destroyOnClose
        bodyStyle={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          minHeight: "40vh",
        }}
      >
        <PopupContentStyled style={{ padding: 20 }}>
          <Ellipsis
            className="resume-title"
            direction="end"
            content={selectTemplate.name}
          />
          {loading && <Skeleton animated style={{ height: "60vh" }}></Skeleton>}
          <img
            key={imgKey}
            style={{ width: "100%" }}
            onLoad={loadImg}
            src={compressImage(selectTemplate.cover)}
          />

          <ResumeButton
            $loading={btnLoading}
            onClick={handleSelectTemplate}
            className="resume-btn"
          >
            使用此模板
          </ResumeButton>
        </PopupContentStyled>
      </Popup>
      <Popup>

      </Popup>
    </ResumeListStyled>
  );
};

export default ResumeList;
