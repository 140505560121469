import { useEffect, useState } from "react"
import CenterModal from "../CenterModal"
import './index.scss'
import { isPhoneNumber, isCode, isWechat, isWechatBrowser, isMiniProgram, wxAppLogin, wxLogin, getParam } from "../../../utils"
import { mobileCheckCode, mobilePhoneLogin, mobileSendCode } from "@/http/mobileApi"
import { Toast } from "antd-mobile"
import setUserByLocal from "@/common/user/setUserByLocal"
import { useDispatch, useSelector } from "react-redux"
import { updateWxToken } from "@/pages/Mobile/utils/login"
import { login_anonymous } from "@/http/api"
import LocalStore from 'store'
import loginHandler from "@/common/user/loginHandler"

const LoginModal = ({ visible, onClose, onSuccess, onCancel }) => {
    const [phone, setPhone] = useState('')
    const [code, setCode] = useState('')
    const [count, setCount] = useState(-1)
    const [validateError, setValidateError] = useState('进入了了简历')
    const user = useSelector(state => state.user)
    useEffect(() => {
        if (isWechatBrowser() && getParam('code')) {
            Toast.show({
                icon: 'loading',
                content: '登录中…',
            })
            wxLogin().finally(() => {
                Toast.clear()
            })
        }

    }, [])

    // 微信浏览器环境内尝试微信登录
    useEffect(() => {
        if (visible && isWechatBrowser() && !getParam('code')) {
            Toast.show({
                icon: 'loading',
                content: '登录中…',
            })
            updateWxToken().then((user) => {
                wxLogin().finally(() => {
                    Toast.clear()
                })
            }).catch(async err => {
                onCancel && onCancel()
                const user = await login_anonymous()
                loginHandler(user)
                wxLogin().finally(() => {
                    // window.location.reload()
                    Toast.clear()
                })
            })
        }
        if (visible && isMiniProgram()) {
            wxAppLogin()
        }
    }, [visible])

    useEffect(() => {
        if (validateError === '进入了了简历') return
        setTimeout(() => {
            setValidateError('进入了了简历')
        }, 1500)

    }, [validateError])

    const handleClick = () => {
        if (!isPhoneNumber(phone)) {
            setValidateError('请输入正确的手机号')
            return
        }
        // 避免重复点击
        if (count !== -1) return

        const data = {
            phone
        }
        mobileSendCode(data).then(res => {
            setCount(60)
            let timerID = setInterval(() => {
                setCount((count) => {
                    if (count === 1) {
                        clearInterval(timerID)
                        return -1
                    }
                    return count - 1
                })
            }, 1000)
        }).catch(err => {
            setCount(-1)
            Toast.show({
                content: err?.msg || '验证码发送失败',
                position: 'top',
            })
        })


    }

    const validate = () => {
        if (!isPhoneNumber(phone)) {
            setValidateError('请输入正确的手机号')
            return false
        }
        if (!isCode(code)) {
            setValidateError('请输入正确的验证码')
            return false
        }
        return true
    }

    const handleLogin = () => {
        if (isWechatBrowser()) {
            wxLogin()
        } else if (isMiniProgram()) {
            wxAppLogin()
        } else {
            if (!validate()) {
                return
            }
            const data = {
                phone,
                code
            }
            // 检查
            mobilePhoneLogin(data).then(res => {
                loginHandler(res)
                Toast.show({
                    content: '登录成功',
                    position: 'top',
                })
                onSuccess && onSuccess()
            }).catch(err => {
                Toast.show({
                    content: err?.msg || '登录失败',
                    position: 'top',
                })
            })

        }

    }

    const handleClose = () => {
        onClose && onClose()
        onCancel && onCancel()
    }

    // 微信环境内不显示手机号登录弹窗，在微信环境内这个组件只负责处理逻辑
    return (
        !isWechat() &&
        <CenterModal visible={visible} onClose={handleClose} id='loginModal'>
            <div className="login-modal">
                <div className="icon">
                    <img src={"https://oss.jianzeppt.com/jz-ppt-img/mlxojoijoiq.png"} />
                </div>
                <h3 className="h3">
                    <img src="https://oss.jianzeppt.com/jz-ppt-img/dnvcnjkvhdif.png" />
                </h3>
                <div className="form">
                    <div className="input-item">
                        <input value={phone} onChange={e => setPhone(e.target.value)} type="text" maxLength={11} placeholder="请输入手机号" />
                    </div>
                    <div className="input-item">
                        <input value={code} onChange={e => setCode(e.target.value)} type="text" maxLength={6} placeholder="请输入验证码" />
                        <div onClick={handleClick} className="code">{count === -1 ? '获取验证码' : `${count}秒后可重新发送验证码`}</div>
                    </div>
                </div>

                <div onClick={handleLogin} className="jz-btn">{validateError}</div>
                <div className="other">
                    <p className="center">微信用户电脑端绑定手机号即可用手机登录</p>
                    <p className="center tip">3秒登录，即可下载！</p>
                </div>
            </div>
        </CenterModal>
    )
}

export default LoginModal
