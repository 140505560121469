import { Modal } from "antd";
import React from "react";
import styled from "styled-components";
import ThemeButton from "@/styled/ThemeButton";
import { useCallback, useEffect, useRef } from "react";
import useForceUpdate from "@/hooks/useForceUpdate";
import withComponent from "@/hoc/withComponent";
import FlexStyled from "@/styled/FlexStyled";
import { bindPhonePc, getUserInfo, sendCode } from "@/http/api";
import { message } from "antd";
import store from "store";
import { useDispatch, useSelector, useStore } from "react-redux";
import axios from 'axios';
import loginHandler from "@/common/user/loginHandler";
import { getEnterpriseMobile } from "@/http/api";
const icon1 = (
  <div
    dangerouslySetInnerHTML={{
      __html: `<svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='个人中心' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='手机号绑定' transform='translate(-230.000000, -156.000000)' fill='#EC5B59' fill-rule='nonzero'>
            <g id='警示' transform='translate(230.000000, 156.000000)'>
                <path d='M8,0 C12.41875,0 16,3.58125 16,8 C16,12.41875 12.41875,16 8,16 C3.58125,16 0,12.41875 0,8 C0,3.58125 3.58125,0 8,0 Z M8.85833333,12.05625 C8.84375,11.5145833 8.35416667,11.0979167 7.82291667,11.2020833 C7.39583333,11.2854167 7.10625,11.6708333 7.14166667,12.1729167 C7.175,12.6375 7.55833333,13.0041667 8.02083333,12.9916667 C8.54375,12.98125 8.87291667,12.575 8.85833333,12.05625 Z M8,3.00625 C7.61666667,3.00625 7.31041667,3.32291667 7.32083333,3.70625 L7.32083333,3.70625 L7.48958333,9.51041667 C7.49791667,9.78541667 7.72291667,10.0041667 8,10.00625 C8.27708333,10.00625 8.50208333,9.78541667 8.51041667,9.51041667 L8.51041667,9.51041667 L8.67916667,3.70625 C8.69166667,3.32291667 8.38333333,3.00625 8,3.00625 Z' id='形状结合'></path>
            </g>
        </g>
    </g>
</svg>`,
    }}
  />
);
const icon2 = (
  <div
    dangerouslySetInnerHTML={{
      __html: `<svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='个人中心' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='绑定成功' transform='translate(-230.000000, -156.000000)' fill='#40BA61' fill-rule='nonzero'>
            <g id='警示备份' transform='translate(230.000000, 156.000000)'>
                <path d='M8,0 C12.41875,0 16,3.58125 16,8 C16,12.41875 12.41875,16 8,16 C3.58125,16 0,12.41875 0,8 C0,3.58125 3.58125,0 8,0 Z M11.4587308,4.93604622 L6.80666667,9.72533333 L4.541282,7.39192687 C4.30076781,7.14469528 3.90532373,7.13878397 3.65779074,7.37888913 C3.41015277,7.61930082 3.40433465,8.01489434 3.64475416,8.26243801 L6.35923955,11.057675 C6.47686898,11.1788652 6.63855908,11.2472268 6.80749595,11.2472268 C6.97643281,11.2472268 7.13812291,11.1788652 7.25576413,11.0576628 L12.3552298,5.80658706 C12.595647,5.55904568 12.5898289,5.16345216 12.3422113,4.92306023 C12.0946962,4.68287312 11.699239,4.68872134 11.4587308,4.93604622 Z' id='形状结合'></path>
            </g>
        </g>
    </g>
</svg>`,
    }}
  />
);

const Text1Styled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
`;
const Text2Styled = styled.div`
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #2C2C2C;
    font-weight:bold;
    margin:12px 0 ;
`;
const Text3Styled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #90969e;
  line-height: 17px;
`;

const InputStyled1 = styled.input`
  width: 320px;
  height: 44px;
  padding: 0 12px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #e4e6e8;

  :focus {
    box-shadow: 0px 0px 4px 0px rgba(0, 188, 166, 0.5);
    border: 1px solid #00bca6;
  }
`;
const InputStyled2 = styled.input`
  width: 206px;
  height: 44px;
  padding: 0 12px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #e4e6e8;

  :focus {
    box-shadow: 0px 0px 4px 0px rgba(0, 188, 166, 0.5);
    border: 1px solid #00bca6;
  }
`;

const ButtonStyled = styled.div`
  width: 108px;
  height: 44px;
  background: #f7f8f9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  :hover {
    opacity: 0.9;
  }
`;

const Button2Styled = styled(ThemeButton)`
  width: 120px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: white;
  ${(props) => (props.isDisable ? "opacity:0.3 !important;" : "")}
  ${(props) => (props.isDisable ? "cursor:not-allowed !important;" : "")}
`;

const Content = (props) => {
  const user = useSelector((state) => state.user);
  const ref = useRef();
  const ref2 = useRef();
  const dispatch = useDispatch();
  const that = useRef({
    binding: false,
    codeSending: false,
    countDowning: false,
    codeSendTimer: undefined,
    sendTelephone: undefined,
    countDown: 60000,
  }).current;
  const [, update] = useForceUpdate();
  const countDown = useCallback(() => {
    that.countDowning = true;
    that.countDown = 60000;
    update();
    that.codeSendTimer = setInterval(() => {
      that.countDown -= 1000;
      if (that.countDown <= 0) {
        that.countDowning = false;
        clearInterval(that.codeSendTimer);
      }
      update();
    }, 1000);
  }, []);
  function isValidChinesePhoneNumber(phoneNumber) {
    const regExp = /^1[3-9]\d{9}$/;
    return regExp.test(phoneNumber);
  }
  const onClickSend = useCallback(async () => {
    if (that.codeSending) return;
    const value = ref.current.value;
    that.codeSending = true;
    update();
    
    if (!value || !isValidChinesePhoneNumber(value)) {
    // if (!value) {
      message.error({content:"手机号格式不正确",
        style:{
          zIndex:99999992,
        },
      });
      that.codeSending = false;

      return;
    }

    that.sendTelephone = value;
    try {
      await sendCode({ phone: value });
      message.success({content:"验证码已发送，请查看手机消息！",
        style:{
          zIndex:1000
        }
      });
      countDown();
    } catch (e) {
      message.error({content:"验证码错误",
        style:{
          zIndex:1000
        }
      });

    } finally {
      that.codeSending = false;
      update();
    }
  }, []);
  const onChange = useCallback(() => {
    update();
  }, []);
  const onBind = useCallback(async () => {
    if (that.binding) {
      return;
    }
    const value = ref.current.value;
    if (!value || !isValidChinesePhoneNumber(value)) {
      message.error({content:"手机号格式不正确",
        style:{
          zIndex:999999999999
        }
      });
      return;
    }
    that.binding = true;
    const hide = message.loading("", 0);
    try {
      const data =  await getEnterpriseMobile({query:{code:ref2.current.value + '',phone:ref.current.value + ''}})
      if(data){
        props.onSuccess && props.onSuccess({phone:ref?.current?.value})
      }
    } catch (e) {
    } finally {
      that.binding = false;
      hide();
    }
  }, []);
  const canBind = !!ref2.current?.value;
  useEffect(() => {
    return () => {
      clearTimeout(that.codeSendTimer);
    };
  }, []);

  return (
    <Modal
        width='400px'
        height='474px'
      open={props.open}
      footer={null}
      zIndex={1000}
        onCancel={props.onCancel}
      //   modalRender={() => <Content {...props} />}
    >
      <div>
        {
          <>
            <FlexStyled $justifyContent='center'>
                <img src="https://pub.jianzeppt.cn/image/dengyue.png" style={{width:'130px',height:'92px'}}>
                </img>
            </FlexStyled>

            <FlexStyled  $justifyContent='center' alignItems="center">
              <Text2Styled>恭喜你！</Text2Styled>
            </FlexStyled>
            <FlexStyled  $justifyContent='center' alignItems="center">
              <Text1Styled>获得免费职业测评机会</Text1Styled>
            </FlexStyled>
            <InputStyled1
              ref={ref}
              type={"phone"}
              placeholder="请输入手机号"
              style={{ marginTop: 30 }}
            />
            <FlexStyled alignItems="center" style={{ marginTop: 12 }}>
              <InputStyled2
                onChange={onChange}
                ref={ref2}
                placeholder="短信验证码"
                style={{ marginRight: 6 }}
              />
              <ButtonStyled
                onClick={that.countDowning ? undefined : onClickSend}
              >
                {that.countDowning ? that.countDown / 1000 + "s" : "发送验证码"}
              </ButtonStyled>
            </FlexStyled>
            <FlexStyled
                $justifyContent='center'
            >
                <Button2Styled
              isDisable={!canBind}
              onClick={canBind ? onBind : undefined}
              style={{ marginTop: 64,marginBottom:'28px' }}
            >
              立即领取
            </Button2Styled>
            </FlexStyled>
            
          </>
        }
      </div>
      
    </Modal>
  );
};

export default Content;
