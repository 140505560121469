import withComponent from "@/hoc/withComponent";
import styled from "styled-components";
import {useCallback, useMemo, useRef, useState} from "react";
import {Scrollbars} from 'react-custom-scrollbars';

const MenuContainerStyled = styled.div`
  width: 204px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 8px 0;
  border: 1px solid #E0E0E0;
`
const Menu3ContainerStyled = styled.div`
  position: relative;
`
const Menu2ContainerStyled = styled.div`
  position: absolute;
  top: 0;
  transform: translateX(100%);
`
const MenuItemBoxStyled = styled.div`
  height: 40px;
  padding-left: 24px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const MenuItemTextStyled = styled.div`

  font-size: 14px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
`
const MenuItemIconStyled = styled.div`
`


const rightIcon = <div style={{height: 12, width: 12, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="基本信息状态" transform="translate(-3047.000000, -198.000000)" fill="#666666" fill-rule="nonzero">
            <g id="编组-3备份" transform="translate(2872.000000, 176.000000)">
                <g id="更多-绿备份" transform="translate(175.000000, 22.000000)">
                    <path d="M8.46442045,11 C8.32727476,11 8.19010987,10.9477436 8.0854698,10.8428844 L3.1569745,6.37984753 C2.94767517,6.17047551 2.94767517,5.83044319 3.1569745,5.62070561 L8.08546981,1.15730318 C8.29476915,0.947565605 8.63372617,0.947565605 8.8430255,1.15730318 C9.05232483,1.3666752 9.05232483,1.70670752 8.8430255,1.9164451 L4.29348083,6.00008418 L8.84302549,10.0833769 C9.05232482,10.2931145 9.05232482,10.6327813 8.84302549,10.8425188 C8.73873103,10.9477436 8.60156614,11 8.46442045,11 Z" id="-" transform="translate(6.000000, 6.000000) scale(-1, 1) translate(-6.000000, -6.000000) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>


const SelectComponent = (props) => {
    const {list, onClickItem, index,width} = props;
    const [hoverTarget, setHoverTarget] = useState()
    const onItemMouseEnter = (item) => {
        setHoverTarget(item);
    }
    const onItemMouseLeave = () => {
    }
    const Container = index === 0 ? Menu3ContainerStyled : Menu2ContainerStyled;
    return <Container>
        <MenuContainerStyled style={{width}}>
            <Scrollbars autoHeight autoHide autoHeightMax={300}>
                {list.map((item, index) => {
                    const isActive = hoverTarget === item;
                    const isString = typeof item === "string";
                    const key = isString ? index : item.id;
                    const onClick = isString ? () => onClickItem?.(item) : !!item.children ? undefined : () => onClickItem?.(item);
                    const label = isString ? item : item.name;
                    const rightIconVisible = (isActive && item?.children);
                    return <MenuItemBoxStyled key={key}
                                              onMouseEnter={() => onItemMouseEnter(item)}
                                              onMouseLeave={onItemMouseLeave}
                                              onClick={onClick}
                                              style={{
                                                  background: isActive ? '#F4F4F4' : undefined,
                                                  color: isActive ? '#11BBA6' : undefined,
                                                  cursor: !!onClick ? "pointer" : undefined
                                              }}
                    >
                        <MenuItemTextStyled>{label}</MenuItemTextStyled>
                        <MenuItemIconStyled
                            style={{visibility: rightIconVisible ? undefined : 'hidden'}}>
                            {rightIcon}
                        </MenuItemIconStyled>
                    </MenuItemBoxStyled>
                })}
            </Scrollbars>
            {hoverTarget && hoverTarget?.children &&
                <SelectComponent key={hoverTarget?.id || hoverTarget} list={hoverTarget?.children}
                                 width={width}
                                 onClickItem={onClickItem}/>}
        </MenuContainerStyled>
    </Container>
}

export default withComponent((props) => {
    const {onClickItem, list = [],width} = props;
    return <SelectComponent index={0} list={list} onClickItem={onClickItem} width={width}/>
})
