import withComponent from '@/hoc/withComponent';
import FlexStyled from '@/styled/FlexStyled';
import QRCode from 'qrcode.react';
import { Spin } from 'antd';
import React, { useEffect, useRef } from 'react';
import { payLogoImage, weChatLogoImage } from '@/const/pay';
import styled from 'styled-components';
import Pointer from '@/styled/Pointer';
import ThemeButton from '@/styled/ThemeButton';
import moment from 'moment';
import useForceUpdate from '@/hooks/useForceUpdate';

const icon2 = <div dangerouslySetInnerHTML={{
  __html: `<svg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='单个购买' transform='translate(-452.000000, -70.000000)' fill='#D4D6E7' fill-rule='nonzero'>
            <g id='提示' transform='translate(452.000000, 70.000000)'>
                <path d='M8.0767065,11.6363636 L8.10834286,11.638 C8.2747065,11.6562727 8.39197922,11.8152727 8.34916104,11.9775455 L8.33252468,12.0359091 C8.15743377,12.5941818 7.62452468,13 6.99452468,13 L6.92961559,12.9986364 C6.3067065,12.9708182 5.78988831,12.5461818 5.63961559,11.9775455 L5.63361559,11.9472727 C5.61016104,11.7858182 5.74025195,11.6363636 5.91234286,11.6363636 L8.0767065,11.6363636 L8.0767065,11.6363636 Z M6.99452468,1 C7.22045935,1 7.40361559,1.18315624 7.40361559,1.40909091 L7.40361559,1.83836364 C9.50034286,2.04918182 11.0854338,3.89309091 11.0854338,6.02472727 L11.0854338,8.90909091 L11.0870701,8.95 C11.1084705,9.23453457 11.3455501,9.45449308 11.6308883,9.45454545 L11.728252,9.45454545 L11.7746156,9.45590909 C12.1127974,9.47718182 12.4117065,9.72672727 12.4455247,10.066 L12.4485247,10.1096364 C12.455793,10.2949088 12.3872933,10.4751511 12.2588094,10.6088319 C12.1303255,10.7425128 11.9529396,10.8181818 11.7675247,10.8181818 L2.26079741,10.8181818 L2.21443377,10.8168182 C1.87625195,10.7955455 1.57734286,10.546 1.54352468,10.2067273 L1.54052468,10.1630909 C1.53325456,9.97777121 1.601791,9.79748637 1.73033444,9.66379707 C1.85887789,9.53010777 2.03633516,9.45455128 2.22179741,9.45454545 L2.35816104,9.45454545 L2.39907013,9.45318182 C2.68370932,9.4317736 2.90370592,9.19453403 2.90361559,8.90909091 L2.90361559,5.90909091 C2.90361559,3.78781818 4.51816104,2.04372727 6.58543377,1.83836364 L6.58543377,1.40909091 C6.58543377,1.18315624 6.76859001,1 6.99452468,1 Z' id='形状'></path>
            </g>
        </g>
    </g>
</svg>`,
}} />;
const icon3 = <div style={{ width: 20, height: 20, position: 'relative' }} dangerouslySetInnerHTML={{
  __html: `<svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='position: absolute;top: 0'>
    <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='单个购买' transform='translate(-513.000000, -209.000000)' fill-rule='nonzero'>
            <g id='word备份-8' transform='translate(513.000000, 209.000000)'>
                <g id='ppt-3' transform='translate(1.428571, 0.857143)'>
                    <path d='M2.82653061,0 L12.3877551,0 L16.9387755,4.60204082 L16.9387755,17.7755102 C16.9387755,18.0572857 16.7103469,18.2857143 16.4285714,18.2857143 L2.82653061,18.2857143 C2.5447551,18.2857143 2.31632653,18.0572857 2.31632653,17.7755102 L2.31632653,0.510204082 C2.31632653,0.228428571 2.5447551,0 2.82653061,0 Z' id='路径' stroke='#B7B7BD' fill='#FFFFFF'></path>
                    <path d='M12.3877551,0 L12.3877551,4.09183673 C12.3877551,4.37361224 12.6161837,4.60204082 12.8979592,4.60204082 L16.9387755,4.60204082 L12.3877551,0 Z' id='路径' stroke='#B7B7BD' stroke-linejoin='round'></path>
                    <path d='M0.326530612,2.63265306 L6.18367347,2.63265306 C6.40136054,2.63265306 6.51020408,2.7414966 6.51020408,2.95918367 L6.51020408,8.81632653 C6.51020408,9.03401361 6.40136054,9.14285714 6.18367347,9.14285714 L0.326530612,9.14285714 C0.108843537,9.14285714 0,9.03401361 0,8.81632653 L0,2.95918367 C0,2.7414966 0.108843537,2.63265306 0.326530612,2.63265306 Z' id='路径' fill='#FF7A64'></path>
                    <path d='M4.04081633,10.3469388 L15.3877551,10.3469388 C15.5238095,10.3469388 15.5918367,10.414966 15.5918367,10.5510204 L15.5918367,10.5510204 C15.5918367,10.6870748 15.5238095,10.755102 15.3877551,10.755102 L4.04081633,10.755102 C3.9047619,10.755102 3.83673469,10.6870748 3.83673469,10.5510204 L3.83673469,10.5510204 C3.83673469,10.414966 3.9047619,10.3469388 4.04081633,10.3469388 Z' id='路径' fill='#D8D8D8'></path>
                    <path d='M4.04081633,13.122449 L15.3877551,13.122449 C15.5238095,13.122449 15.5918367,13.1904762 15.5918367,13.3265306 L15.5918367,13.3265306 C15.5918367,13.462585 15.5238095,13.5306122 15.3877551,13.5306122 L4.04081633,13.5306122 C3.9047619,13.5306122 3.83673469,13.462585 3.83673469,13.3265306 L3.83673469,13.3265306 C3.83673469,13.1904762 3.9047619,13.122449 4.04081633,13.122449 Z' id='路径' fill='#D8D8D8'></path>
                    <path d='M4.04081633,11.7346939 L15.3877551,11.7346939 C15.5238095,11.7346939 15.5918367,11.8027211 15.5918367,11.9387755 L15.5918367,11.9387755 C15.5918367,12.0748299 15.5238095,12.1428571 15.3877551,12.1428571 L4.04081633,12.1428571 C3.9047619,12.1428571 3.83673469,12.0748299 3.83673469,11.9387755 L3.83673469,11.9387755 C3.83673469,11.8027211 3.9047619,11.7346939 4.04081633,11.7346939 Z' id='路径' fill='#D8D8D8'></path>
                    <path d='M4.04081633,14.4897959 L15.3877551,14.4897959 C15.5238095,14.4897959 15.5918367,14.5578231 15.5918367,14.6938776 L15.5918367,14.6938776 C15.5918367,14.829932 15.5238095,14.8979592 15.3877551,14.8979592 L4.04081633,14.8979592 C3.9047619,14.8979592 3.83673469,14.829932 3.83673469,14.6938776 L3.83673469,14.6938776 C3.83673469,14.5578231 3.9047619,14.4897959 4.04081633,14.4897959 Z' id='路径' fill='#D8D8D8'></path>
                    <path d='M4.04081633,15.877551 L15.3877551,15.877551 C15.5238095,15.877551 15.5918367,15.9455782 15.5918367,16.0816327 L15.5918367,16.0816327 C15.5918367,16.2176871 15.5238095,16.2857143 15.3877551,16.2857143 L4.04081633,16.2857143 C3.9047619,16.2857143 3.83673469,16.2176871 3.83673469,16.0816327 L3.83673469,16.0816327 C3.83673469,15.9455782 3.9047619,15.877551 4.04081633,15.877551 Z' id='路径' fill='#D8D8D8'></path>
                    <path d='M5.04081633,5.06234694 C5.04081633,5.40318367 4.87726531,5.577 4.49061224,5.62942857 L1.26530612,5.62942857 C1.15259184,5.62942857 1.06122449,5.72079592 1.06122449,5.8335102 L1.06122449,7.90816327 C1.06122449,8.02087755 1.15259184,8.1122449 1.26530612,8.1122449 C1.37802041,8.1122449 1.46938776,8.02087755 1.46938776,7.90816327 L1.46938776,6.03759184 L4.50395918,6.03759184 C4.51258368,6.03759323 4.5211996,6.03704792 4.5297551,6.03595918 C5.12204082,5.9605102 5.44897959,5.62026531 5.44897959,5.06234694 C5.44897959,4.51479592 5.13677551,4.14455102 4.55761224,3.98677551 C4.54012621,3.98200918 4.52208318,3.97959184 4.50395918,3.97959184 L1.26530612,3.97959184 C1.15259184,3.97959184 1.06122449,4.07095918 1.06122449,4.18367347 C1.06122449,4.29638776 1.15259184,4.3877551 1.26530612,4.3877551 L4.47585714,4.3877551 C4.86665306,4.50108163 5.04081633,4.71493878 5.04081633,5.06234694 Z' id='路径' fill='#FFFFFF'></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}} />;
const icon4 = <div style={{ width: 51, height: 51, position: 'relative' }} dangerouslySetInnerHTML={{
  __html: `<svg width='51px' height='51px' viewBox='0 0 51 51' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
  <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
    <g id='购买成功-立即下载' transform='translate(-577.000000, -99.000000)' fill='#FECF91' fill-rule='nonzero'>
      <g id='zhifuchenggong' transform='translate(577.000000, 99.000000)'>
        <path d='M25.5,51 C11.41686,51 0,39.58314 0,25.5 C0,11.41686 11.41686,0 25.5,0 C39.58314,0 51,11.41686 51,25.5 C51,39.58314 39.58314,51 25.5,51 Z M14.14077,24.10923 L12.05487,26.89077 L23.18205,37.32282 L40.10436,18.08205 L38.48205,16.22718 L23.18205,31.99128 L14.14077,24.10923 L14.14077,24.10923 Z' id='形状'></path>
      </g>
    </g>
  </g>
</svg>
`,
}} />;
const icon5 = <div style={{ width: 80, height: 80, position: 'relative' }} dangerouslySetInnerHTML={{
  __html: `<svg width='80px' height='80px' viewBox='0 0 80 80' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
  <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
    <g id='购买成功-立即下载' transform='translate(-565.000000, -275.000000)' fill-rule='nonzero'>
      <g id='word备份-7' transform='translate(565.000000, 275.000000)'>
        <g id='ppt-3' transform='translate(5.714286, 3.428571)'>
          <path d='M11.3061224,0 L49.5510204,0 L67.755102,18.4081633 L67.755102,71.1020408 C67.755102,72.2291429 66.8413878,73.1428571 65.7142857,73.1428571 L11.3061224,73.1428571 C10.1790204,73.1428571 9.26530612,72.2291429 9.26530612,71.1020408 L9.26530612,2.04081633 C9.26530612,0.913714286 10.1790204,0 11.3061224,0 Z' id='路径' stroke='#B7B7BD' fill='#FFFFFF'></path>
          <path d='M49.5510204,0 L49.5510204,16.3673469 C49.5510204,17.494449 50.4647347,18.4081633 51.5918367,18.4081633 L67.755102,18.4081633 L49.5510204,0 Z' id='路径' stroke='#B7B7BD' stroke-linejoin='round'></path>
          <path d='M1.30612245,10.5306122 L24.7346939,10.5306122 C25.6054422,10.5306122 26.0408163,10.9659864 26.0408163,11.8367347 L26.0408163,35.2653061 C26.0408163,36.1360544 25.6054422,36.5714286 24.7346939,36.5714286 L1.30612245,36.5714286 C0.43537415,36.5714286 0,36.1360544 0,35.2653061 L0,11.8367347 C0,10.9659864 0.43537415,10.5306122 1.30612245,10.5306122 Z' id='路径' fill='#FF7A64'></path>
          <path d='M16.1632653,41.3877551 L61.5510204,41.3877551 C62.0952381,41.3877551 62.3673469,41.6598639 62.3673469,42.2040816 L62.3673469,42.2040816 C62.3673469,42.7482993 62.0952381,43.0204082 61.5510204,43.0204082 L16.1632653,43.0204082 C15.6190476,43.0204082 15.3469388,42.7482993 15.3469388,42.2040816 L15.3469388,42.2040816 C15.3469388,41.6598639 15.6190476,41.3877551 16.1632653,41.3877551 Z' id='路径' fill='#D8D8D8'></path>
          <path d='M16.1632653,52.4897959 L61.5510204,52.4897959 C62.0952381,52.4897959 62.3673469,52.7619048 62.3673469,53.3061224 L62.3673469,53.3061224 C62.3673469,53.8503401 62.0952381,54.122449 61.5510204,54.122449 L16.1632653,54.122449 C15.6190476,54.122449 15.3469388,53.8503401 15.3469388,53.3061224 L15.3469388,53.3061224 C15.3469388,52.7619048 15.6190476,52.4897959 16.1632653,52.4897959 Z' id='路径' fill='#D8D8D8'></path>
          <path d='M16.1632653,46.9387755 L61.5510204,46.9387755 C62.0952381,46.9387755 62.3673469,47.2108844 62.3673469,47.755102 L62.3673469,47.755102 C62.3673469,48.2993197 62.0952381,48.5714286 61.5510204,48.5714286 L16.1632653,48.5714286 C15.6190476,48.5714286 15.3469388,48.2993197 15.3469388,47.755102 L15.3469388,47.755102 C15.3469388,47.2108844 15.6190476,46.9387755 16.1632653,46.9387755 Z' id='路径' fill='#D8D8D8'></path>
          <path d='M16.1632653,57.9591837 L61.5510204,57.9591837 C62.0952381,57.9591837 62.3673469,58.2312925 62.3673469,58.7755102 L62.3673469,58.7755102 C62.3673469,59.3197279 62.0952381,59.5918367 61.5510204,59.5918367 L16.1632653,59.5918367 C15.6190476,59.5918367 15.3469388,59.3197279 15.3469388,58.7755102 L15.3469388,58.7755102 C15.3469388,58.2312925 15.6190476,57.9591837 16.1632653,57.9591837 Z' id='路径' fill='#D8D8D8'></path>
          <path d='M16.1632653,63.5102041 L61.5510204,63.5102041 C62.0952381,63.5102041 62.3673469,63.7823129 62.3673469,64.3265306 L62.3673469,64.3265306 C62.3673469,64.8707483 62.0952381,65.1428571 61.5510204,65.1428571 L16.1632653,65.1428571 C15.6190476,65.1428571 15.3469388,64.8707483 15.3469388,64.3265306 L15.3469388,64.3265306 C15.3469388,63.7823129 15.6190476,63.5102041 16.1632653,63.5102041 Z' id='路径' fill='#D8D8D8'></path>
          <path d='M20.1632653,20.2493878 C20.1632653,21.6127347 19.5090612,22.308 17.962449,22.5177143 L5.06122449,22.5177143 C4.61036735,22.5177143 4.24489796,22.8831837 4.24489796,23.3340408 L4.24489796,31.6326531 C4.24489796,32.0835102 4.61036735,32.4489796 5.06122449,32.4489796 C5.51208163,32.4489796 5.87755102,32.0835102 5.87755102,31.6326531 L5.87755102,24.1503673 L18.0158367,24.1503673 C18.0503347,24.1503729 18.0847984,24.1481917 18.1190204,24.1438367 C20.4881633,23.8420408 21.7959184,22.4810612 21.7959184,20.2493878 C21.7959184,18.0591837 20.547102,16.5782041 18.230449,15.947102 C18.1605049,15.9280367 18.0883327,15.9183673 18.0158367,15.9183673 L5.06122449,15.9183673 C4.61036735,15.9183673 4.24489796,16.2838367 4.24489796,16.7346939 C4.24489796,17.185551 4.61036735,17.5510204 5.06122449,17.5510204 L17.9034286,17.5510204 C19.4666122,18.0043265 20.1632653,18.8597551 20.1632653,20.2493878 Z' id='路径' fill='#FFFFFF'></path>
        </g>
      </g>
    </g>
  </g>
</svg>
`,
}} />;
const closeIcon = (
  <div
    style={{ height: 24, width: 24 }}
    dangerouslySetInnerHTML={{
      __html: `<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>

    <g id='详情页优化' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='立即编辑--弹窗' transform='translate(-1170.000000, -370.000000)'>
            <g id='编组-11' transform='translate(760.000000, 370.000000)'>
                <g id='关闭' transform='translate(410.000000, 0.000000)'>
                    <circle id='椭圆形' fill='#5C5C5C' cx='12' cy='12' r='12'></circle>
                    <g id='closel' transform='translate(7.000000, 7.000000)' fill='#FFFFFF' fill-rule='nonzero'>
                        <path d='M9.7663551,8.65168539 L6.11645342,5.00039471 L9.76740755,1.34910402 C10.0768283,1.03965476 10.0778807,0.540746783 9.76846001,0.231297529 C9.46009173,-0.0770991764 8.95912483,-0.0770991764 8.651809,0.231297529 L4.99980241,3.88258822 L1.34779583,0.231297529 C1.04048,-0.0770991764 0.540565552,-0.0770991764 0.231144821,0.231297529 C-0.0761710068,0.539694235 -0.0761710068,1.04070731 0.233249724,1.35015657 L3.88315141,5.00039471 L0.232197273,8.65063284 C-0.0772234582,8.95902955 -0.0772234582,9.45899008 0.231144821,9.76843933 C0.385855187,9.92421651 0.590030771,10 0.78999655,10 C0.989962328,10 1.19413791,9.92421651 1.34779583,9.76949188 L4.99980241,6.11820119 L8.651809,9.76949188 C8.80651937,9.92316396 9.00859005,10 9.20960828,10 C9.41167896,10 9.61374964,9.92421651 9.76740755,9.76949188 C10.0757758,9.45899008 10.0768283,8.9600821 9.7663551,8.65168539 Z' id='路径'></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`,
    }}
  />
);


const ExportSinglePagePPTStyled = styled.div`
  pointer-events: auto;
  width: 450px;
  height: 482px;
  background: #FFFFFF;
  border-radius: 10px;
`;
const TitleStyled = styled.div`
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #353A40;
  line-height: 26px;
  text-align: center;
`;
const HeaderStyled = styled.div`
  width: 450px;
  height: 74px;
  background: linear-gradient(180deg, #36384D 0%, #666984 100%);
  border-radius: 10px 10px 0px 0px;
`;
const HeaderStyled2 = styled.div`
  width: 348px;
  height: 24px;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #D4D6E7;
  line-height: 12px;

`;
const TextStyled2 = styled.div`
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FECF91;
  line-height: 50px;
`;
const TextStyled3 = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 26px;
`;
const TextStyled4 = styled.span`
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #EF333F;
  line-height: 33px;
`;
const TextStyled5 = styled.span`
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  line-height: 18px;
`;
const TextStyled6 = styled.span`
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  line-height: 18px;
`;
const TextStyled7 = styled.span`
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #EF333F;
  line-height: 18px;
`;
const TextStyled8 = styled.div`
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9E9E9E;
  line-height: 70px;
  text-align: center;
`;

const PaySuccessStyled = styled.div`
  width: 450px;
  height: 482px;
  background: #FFFFFF;
  border-radius: 10px;
  pointer-events: auto;
`;
const PaySuccessHStyled = styled.div`
  width: 450px;
  height: 160px;
  background: linear-gradient(180deg, #36384D 0%, #666984 100%);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TextStyled9 = styled.div`
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FECF91;
  line-height: 33px;
`;
const TextStyled10 = styled.div`
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  line-height: 22px;
  text-align: center;
`;
const TextStyled11 = styled(ThemeButton)`
  width: 156px;
  height: 39px;
  border-radius: 4px;

  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 39px;
  text-align: center;
`;
const TextStyled12 = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #90969E;
  line-height: 17px;
  text-align: center;
`;


export const SinglePaySuccess = withComponent((props) => {
  const { onCancel, name } = props;
  const onCLickDown = () => {
    window.open('/pptx/personalCenter?type=down');
  };
  return <PaySuccessStyled>
    <Pointer onClick={onCancel} style={{ position: 'absolute', top: 0, right: -40 }}>{closeIcon}</Pointer>
    <PaySuccessHStyled>
      <div>{icon4}</div>
      <TextStyled9 style={{ marginTop: 20 }}>支付成功</TextStyled9>
    </PaySuccessHStyled>
    <div>
      <FlexStyled $justifyContent='center' style={{ marginTop: 50 }}>
        <div>{icon5}</div>
      </FlexStyled>
      <TextStyled10 style={{ marginTop: 20 }}>{name}</TextStyled10>
      <TextStyled12 style={{ marginTop: 10 }}>已触发自动下载</TextStyled12>
      <FlexStyled $justifyContent='center'>
        <TextStyled11 onClick={onCLickDown} style={{ marginTop: 20 }}>查看历史记录</TextStyled11>
      </FlexStyled>
    </div>
  </PaySuccessStyled>;
});


export default withComponent((props) => {
  const { orderConfigData, QRObj, tabIndex, onCancel, name } = props;
  const that = useRef({ duration: moment.duration(10* 60, 'seconds') }).current;
  const [, update] = useForceUpdate();
  let hours = that.duration?.get('hours');
  let minutes = that.duration?.get('minutes');
  let seconds = that.duration?.get('seconds');
  if (minutes?.toString().length === 1) {
    minutes = '0' + minutes;
  }
  if (seconds?.toString().length === 1) {
    seconds = '0' + seconds;
  }
  useEffect(() => {
    that.timer = setInterval(() => {
      if (that.duration.asSeconds() > 1) {
        that.duration = that.duration.subtract(1, 'seconds');
        update();
      } else {
        clearInterval(that.timer);
      }
    }, 1000);
    return () => {
      clearInterval(that.timer);
    };
  }, []);
  return <ExportSinglePagePPTStyled>
    <Pointer onClick={onCancel} style={{ position: 'absolute', top: 0, right: -40 }}>{closeIcon}</Pointer>
    <HeaderStyled>
      <FlexStyled $alignItems='center' $justifyContent='center'>
        <HeaderStyled2>
          <div style={{ marginRight: 4 }}>{icon2}</div>
          <div>温馨提示：本次购买仅支持下载当前资料，请按需购买</div>
        </HeaderStyled2>
      </FlexStyled>
      <FlexStyled $justifyContent='center'>
        <TextStyled2>单个购买</TextStyled2>
      </FlexStyled>
    </HeaderStyled>
    <div>
      <FlexStyled $alignItems='center' $justifyContent='center' style={{ marginTop: 23 }}>
        <TextStyled3>
          扫码支付 <TextStyled4>{orderConfigData[tabIndex]?.price}</TextStyled4> 元，即可下载
        </TextStyled3>
      </FlexStyled>
      <FlexStyled $alignItems='center' $justifyContent='center' style={{ marginTop: 20 }}>
        <div style={{ marginRight: 4 }}>{icon3}</div>
        <TextStyled5>{name}</TextStyled5>
      </FlexStyled>
      <FlexStyled $alignItems='center' $justifyContent='center' style={{ marginTop: 34 }}>
        <TextStyled6 style={{ marginRight: 5 }}>剩余支付时间：</TextStyled6>
        <TextStyled7>{hours}:{minutes}:{seconds}</TextStyled7>
      </FlexStyled>
      <FlexStyled $alignItems='center' $justifyContent='center' style={{ marginTop: 14 }}>
        <FlexStyled>
          <div style={{ marginRight: 30 }}>
            <FlexStyled
              $alignItems='center'
              $justifyContent='center'
              style={{
                padding: 5,
                width: 150,
                height: 150,
                outline: '1px solid #2BA144',
                borderRadius: 4,
              }}
            >
              <div style={{ width: 136, height: 136 }}>
                {QRObj.wechatUrl ? (
                  <QRCode
                    value={QRObj.wechatUrl}
                    fgColor='#000000'
                    style={{
                      width: '100%',
                      height: '100%',
                      background: '#ffffff',
                    }}
                  />
                ) : (
                  <FlexStyled
                    $alignItems='center'
                    $justifyContent='center'
                    style={{ height: '100%', width: '100%' }}
                  >
                    <Spin />
                  </FlexStyled>
                )}
              </div>
            </FlexStyled>
            <FlexStyled $justifyContent='center'>
              <img
                src={payLogoImage}
                width={82}
                height={20}
                style={{ marginTop: 10 }}
              />
            </FlexStyled>
          </div>
          <div>
            <FlexStyled
              $alignItems='center'
              $justifyContent='center'
              style={{
                padding: 5,
                width: 150,
                height: 150,
                outline: '1px solid #1577FF',
                borderRadius: 4,
              }}
            >
              <div style={{ width: 136, height: 136 }}>
                {QRObj.payUrl ? (
                  <QRCode
                    value={QRObj.payUrl}
                    fgColor='#000000'

                    style={{
                      width: '100%',
                      height: '100%',
                      background: '#ffffff',
                    }}
                  />
                ) : (
                  <FlexStyled
                    $alignItems='center'
                    $justifyContent='center'
                    style={{ height: '100%', width: '100%' }}
                  >
                    <Spin />
                  </FlexStyled>
                )}
              </div>
            </FlexStyled>
            <FlexStyled $alignItems='center' $justifyContent='center'>
              <img
                src={weChatLogoImage}
                width={96}
                height={20}
                style={{ marginTop: 10 }}
              />
            </FlexStyled>
          </div>
        </FlexStyled>
      </FlexStyled>
      <TextStyled8>请选择使用 微信/支付宝 扫码支付</TextStyled8>
    </div>
  </ExportSinglePagePPTStyled>;
});
