import {Module} from "@/common/module/module";
import {
    CAMPUS_ACTIVITIES_FIELD_ENUM, CUSTOM_MODULE_FIELD_ENUM,
    EDUCATIONAL_BACKGROUND_FIELD_ENUM,
    FiledType,
    INTEREST_FIELD_ENUM,
    INTERNSHIP_EXPERIENCE_FIELD_ENUM,
    JOB_INTEREST_FIELD_ENUM, LANGUAGE_PROFICIENCY_FIELD_ENUM,
    PROJECT_EXPERIENCE_FIELD_ENUM,
    SELF_EVALUATION_FIELD_ENUM,
    SKILLS_FIELD_ENUM,
    WORK_EXPERIENCE_FIELD_ENUM
} from "@/common/field/field";
import getTextByField from "@/common/field/getTextByField";

import {
    CUSTOM_MODULE_SLOT_ENUM,
    EDUCATIONAL_BACKGROUND_SLOT_ENUM,
    HONORS_AND_AWARDS_SLOT_ENUM
} from "@/common/slot/slot";

const getBasePlaceholder = (fileName, slotName) => {
    switch (fileName) {
        case '姓名': {
            return fileName
        }
        case '性别': {
            return fileName
        }
        case '头像': {
            return fileName
        }
        case '生日': {
            return fileName
        }
        case '手机号': {
            return "联系电话"
        }
        case '微信号': {
            return fileName
        }
        case '邮箱': {
            return fileName
        }
        case '现居地': {
            return fileName
        }
        case '婚姻状况': {
            return fileName
        }
        case '工作年限': {
            return fileName
        }
        case '身高体重': {
            return fileName
        }
        case '民族': {
            return fileName
        }
        case '籍贯': {
            return fileName
        }
        case '政治面貌': {
            return fileName
        }
        case '求职意向': {
            return fileName
        }
        case '生日年份': {
            return fileName
        }
        case '生日月份': {
            return fileName
        }
        case '生日日期': {
            return fileName
        }
        case '学历': {
            return "最高学历"
        }
        case 'height': {
            return "身高"
        }
        case 'weight': {
            return "体重"
        }
    }
}

const basePlaceholder = "占位符"
export default (blockName, fileName, slotName) => {
    let text = basePlaceholder
    switch (blockName) {
        case Module.BASE: {
            text = getBasePlaceholder(fileName, slotName)
            break
        }
        case Module.EDUCATIONAL_BACKGROUND: {
            switch (slotName) {
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.DATE: {
                    return "设置时间"
                }
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.SCHOOL: {
                    return "填写学校名称"
                }
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.MAJOR: {
                    return "填写专业名称"
                }
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.EDUCATION: {
                    return "请选择学历"
                }
                case EDUCATIONAL_BACKGROUND_SLOT_ENUM.DETAILS: {
                    return "尽量简洁，突出重点，成绩优异的话建议写上GPA及排名等信息，如: GPA:3.72/4 (专业前10%)GRE: 324（5000字以内）"
                }
            }
            break
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            switch (fileName) {
                case INTERNSHIP_EXPERIENCE_FIELD_ENUM.DATE: {
                    return "设置时间"
                }
                case INTERNSHIP_EXPERIENCE_FIELD_ENUM.ACTIVITY_NAME: {
                    return "填写公司名称"
                }
                case INTERNSHIP_EXPERIENCE_FIELD_ENUM.ROLE: {
                    return "填写职位"
                }
                case INTERNSHIP_EXPERIENCE_FIELD_ENUM.DETAILS: {
                    return "名称此项为非必选项，根据实际情况选择，实习经验的时间采取倒叙形式，最近经历写在前面，实习经验的描述与目标岗位的招聘要求尽量匹配，用词精准，实习成果尽量以数据来呈现，突出个人成果以及 做出的贡献，描述尽量具体简洁。（5000字以内）"
                }
            }
            break
        }
        case Module.WORK_EXPERIENCE: {
            switch (fileName) {
                case WORK_EXPERIENCE_FIELD_ENUM.DATE: {
                    return "设置时间"
                }
                case WORK_EXPERIENCE_FIELD_ENUM.COMPANY: {
                    return "填写公司名称"
                }
                case WORK_EXPERIENCE_FIELD_ENUM.POSITION: {
                    return "岗位名称"
                }
                case WORK_EXPERIENCE_FIELD_ENUM.DETAILS: {
                    return "详细描述你的职责范围、工作任务及取得的成绩，工作经验的时间采取倒叙形式，最近经历写在前面，描述尽量具体简洁，工作经验的描述与目标岗位的招聘要求尽量匹配，用词精准。（5000字以内）"
                }
            }
            break
        }
        case Module.PROJECT_EXPERIENCE: {
            switch (fileName) {
                case PROJECT_EXPERIENCE_FIELD_ENUM.DATE: {
                    return "设置时间"
                }
                case PROJECT_EXPERIENCE_FIELD_ENUM.NAME: {
                    return "填写项目名称"
                }
                case PROJECT_EXPERIENCE_FIELD_ENUM.ROLE: {
                    return "填写角色名称"
                }
                case PROJECT_EXPERIENCE_FIELD_ENUM.DETAILS: {
                    return "描述你参加的项目负责的工作内容，内容清晰，突出重点，如项目描述、项目职责、项目业绩。（5000字以内）"
                }

            }
            break
        }
        case Module.CAMPUS_ACTIVITIES: {
            switch (fileName) {
                case CAMPUS_ACTIVITIES_FIELD_ENUM.DATE: {
                    return "设置时间"
                }
                case CAMPUS_ACTIVITIES_FIELD_ENUM.ACTIVITY_NAME: {
                    return "填写活动名称"
                }
                case CAMPUS_ACTIVITIES_FIELD_ENUM.ROLE: {
                    return "填写活动职责"
                }
                case CAMPUS_ACTIVITIES_FIELD_ENUM.DETAILS: {
                    return "此项非必选项，根据实际情况选择，结合目标岗位，筛选和岗位相关的校园经历，有条理的描述活动内容，运用客观事实和具体数据，体现个人综合素质和专业能力，语言尽量准确简洁。（5000字以内）\n"
                }
            }
            break
        }
        case Module.SKILLS: {
            switch (fileName) {
                case SKILLS_FIELD_ENUM.DETAILS: {
                    return "按照“技能领域：编程语言（掌握程度）”的格式罗列专业技能，如：Web 开发：Python（熟练）（5000字以内）"
                }
            }
            break
        }
        case Module.HONORS_AND_AWARDS: {
            switch (fileName) {
                case HONORS_AND_AWARDS_SLOT_ENUM.DETAILS: {
                    return "介绍荣誉奖项"
                }
            }
            break
        }
        case Module.SELF_EVALUATION: {
            switch (fileName) {
                case SELF_EVALUATION_FIELD_ENUM.DETAILS: {
                    return "简短分条地总结与申请岗位相关的经验、技能以及能力（5000字以内）"
                }
            }
            break
        }
        case Module.INTEREST: {
            switch (fileName) {
                case INTEREST_FIELD_ENUM.DETAILS: {
                    return "输入兴趣爱好（5000字以内）"
                }
            }
            break
        }
        case Module.LANGUAGE_PROFICIENCY: {
            switch (fileName) {
                case LANGUAGE_PROFICIENCY_FIELD_ENUM.DETAILS: {
                    return "添加语言能力证书（5000字以内）"
                }
            }
            break
        }
        case Module.CUSTOM_MODULE: {
            switch (fileName) {
                case CUSTOM_MODULE_FIELD_ENUM.DETAILS: {
                    return "在这里添加自定义描述（5000字以内）"
                }
            }
            break
        }
    }
    if (text === basePlaceholder) {
        const t = getTextByField(blockName, fileName);
        if (t) {
            text = t;
        }
    }
    return text
}
