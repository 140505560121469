import { isIOSMiniProgram, wxAppPay } from '.'
import mobile from '../config'

// 微信支付必须是这两个域名，并且非IOS小程序
export const isSupportWxPay = () => {
    const url = new URL(window.location.href)
    const hostList = [
        'zxznppt.com',
        'jzmbti.com',
        'jztest.jzmbti.com',
        'ppt.nhw09.cn',
        'ppt.weixiu777.com',
    ]
    const isUrl = hostList.includes(url.host)
    // return isUrl && !isIOSMiniProgram()
    return !isIOSMiniProgram()
}

export const wxJsApiPay = (options) => {
    const { onSuccess, data } = options
    const { nonceStr, prepay_id, sign, timeStamp, signType } = data
    function onBridgeReady() {
        const payload = {
            appId: mobile.appId,     //公众号ID，由商户传入
            timeStamp: timeStamp || String(parseInt(Date.now() / 1000)),     //时间戳，自1970年以来的秒数
            nonceStr: nonceStr,      //随机串
            package: prepay_id,
            signType: signType || "HMAC-SHA256",     //微信签名方式
            paySign: sign //微信签名
        }
        WeixinJSBridge.invoke('getBrandWCPayRequest', payload,
            function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    onSuccess && onSuccess()
                }
            });
    }
    if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
    } else {
        onBridgeReady();
    }
}

export const wxSdkPay = (options) => {
    const { onSuccess, data } = options
    const { nonceStr, prepay_id, sign, timeStamp, signType } = data

    // 微信小程序支付跳转到微信小程序中间页进行
    wxAppPay({ nonceStr, prepay_id, sign, timeStamp, signType })

    // console.log('测试', {
    //     timestamp: timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
    //     nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
    //     package: prepay_id, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
    //     signType: signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
    //     paySign: sign, // 支付签名
    // })
    // wx.chooseWXPay({
    //     timestamp: timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
    //     nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
    //     package: prepay_id, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
    //     signType: signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
    //     paySign: sign, // 支付签名
    //     success: function (res) {
    //       // 支付成功后的回调函数
    //       console.log('res', res)
    //     }
    // });
}

