import loginHandler from '@/common/user/loginHandler';
import { wxUserToken } from '@/http/mobileApi';
import store from 'store';

export const isMobileLogin = () => {
    const user = store.get('user')
    return !!user?.isWXLogin || !! user?.mobile
}

export const isPhoneLogin = () => {
    const user = store.get('user')
    return !! user?.mobile
}


export const updateWxToken = async (userData) => {
    return new Promise((resolve, reject) => {
        if (userData) {
            loginHandler(userData)
        }

        wxUserToken().then(user => {
            loginHandler(user)
            resolve(user)
        }).catch(err => {
            reject(err)
        })
    })
}