class WebSocketClient {
  constructor(url) {
    this.url = url;
    this.ws = null;
    this.isConnected = false;
  }

  connect() {
    return new Promise((resolve, reject) => {
      // 创建一个新的 WebSocket 对象
      this.ws = new WebSocket(this.url);

      // 连接成功建立的回调
      this.ws.onopen = () => {
        this.isConnected = true;
        console.log("WebSocket connection established.");
        resolve();
      };

      // 连接发生错误的回调
      this.ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        reject(error);
      };

      // 接收服务器消息的回调
      this.ws.onmessage = (event) => {
        console.log('event', event.data)
        this.onMessage(event.data);
      };

      // 连接关闭的回调
      this.ws.onclose = (event) => {
        this.isConnected = false;
        this.onClose(event);
        console.log("WebSocket connection closed:", event);
      };
    });
  }

  send(data) {
    if (this.isConnected && this.ws) {
      this.ws.send(data);
    } else {
      console.error("WebSocket is not connected.");
    }
  }

  // 可以被覆盖的方法，以便在子类中处理消息
  onMessage(data) {
    console.log("Received data from WebSocket:", data);
  }

  // 可以被覆盖的方法，以便在子类中处理连接关闭
  onClose(event) {
    // 处理关闭事件
  }

  close() {
    if (this.ws) {
      this.ws.close();
    }
  }
}


const wsClient = new WebSocketClient(`wss://api-test-resume.jianzeppt.cn/websocket/ai/stt`);

export default wsClient