
import { Dialog, Modal } from 'antd-mobile';

import JzImage from '@/components/JzImage';
import '@/pages/Mobile/pages/My/index.scss'



const CommonModal = ({ visible, onConfirm, onCancel,content,sureText = '升级' }) => {

    return (
        <Modal
            showCloseButton
                bodyClassName = 'user-quit-modal'
                visible={visible}
                 header={(
                    <div className="mdl-title">
                     
                    {/* <JzImage src='delete-icon.png' style={{ width: 20, height: 20, marginRight: 6 }} /> */}

                    <div className="title">提示</div>
                    </div>
                    )}
            content= {(<>
                <div className="mdl-text" style={{ padding:'0 43px'}}> {content} </div>
            </>)}
            onClose={onCancel}
            maskStyle={{
                height: 'calc(100vh - 80px)'
            }}
            actions={
                [
                {
                    text: sureText,
                    key: 'upgrade',
                    onClick: onConfirm
                }
                ]
            }
        />
    )
}


export default CommonModal