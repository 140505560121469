import { CSSProperties } from "react"
import styled from "styled-components"

interface JzButtonStyledProps {
    '$type'?: string
}
const JzButtonStyled = styled.button<JzButtonStyledProps>`
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  

  color: #888888;
  background: #EAEAEF;
  ${props => props.$type === 'primary' && `color: #FFFFFF;background: #11BBA6;`}
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`

interface JzButtonProps {
    style: CSSProperties,
    children: any
}

const JzButton = (props: JzButtonProps) => {
    const { style, ...otherProps } = props

    return <JzButtonStyled style={props.style} {...otherProps}>
        {props.children}
    </JzButtonStyled>
}

export default JzButton