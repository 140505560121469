import {createSlice} from '@reduxjs/toolkit';
import initState from '../initState';

const clearAutoPagination = (state) => {
    state.openAutoPagination = false;
    state.offsets = null;
}
const clearOnePage = (state) => {
    state.openAutoOnePage = false;
    state.onePageLevel = null;
}
export default createSlice({
    name: 'pagination',
    initialState: initState.pagination,
    reducers: {
        set(state, {payload}) {
            return payload
        },
        switchHintPagination(state) {
            state.openHintPagination = !state.openHintPagination;
            if (state.openHintPagination) {
                clearAutoPagination(state);
            }
        },
        switchAutoPagination(state) {
            state.openAutoPagination = !state.openAutoPagination;
            if (state.openAutoPagination) {
                state.openHintPagination = false;
                clearOnePage(state);
            } else {
                state.offsets = null;
            }
        },
        switchAutoOnePage(state) {
            if (state.openAutoOnePage) {
                clearOnePage(state);
            } else {
                state.openAutoOnePage = true;
            }
            clearAutoPagination(state);
        },
        switchHintPaginationLineVisible(state) {
            state.hintPaginationLineVisible = !state.hintPaginationLineVisible;
        },
        setOnePageLevel(state, {payload}) {
            state.onePageLevel = payload;
        },
        setOffsets(state, {payload}) {
            state.offsets = payload;
        },
        setPageCount(state, {payload}) {
            state.pageCount = payload;
        },
        setHidePageIndex(state, {payload}) {
            state.hidePageIndex = payload
        }
    }
});
