import React, {createElement, memo} from 'react';
import withErrorBoundary from './withErrorBoundary';
import AntDesignTheme from "@/const/AntDesignTheme";
import {ConfigProvider} from "antd";

export default (Component) => memo((props) => {
    return <ConfigProvider theme={AntDesignTheme} autoInsertSpaceInButton={false}>
        {createElement(Component, props)}
    </ConfigProvider>
});


