import { Popover } from "antd"
import styled from "styled-components"

const svg = <div className='flex-center ml-4' dangerouslySetInnerHTML={{ __html: `
    <?xml version="1.0" encoding="UTF-8"?>
    <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="新增报考信息表格模块" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="表格编辑交互" transform="translate(-275.000000, -493.000000)" fill="#11BBA6">
                <g id="编组备份-2" transform="translate(213.000000, 485.000000)">
                    <g id="编组-8" transform="translate(45.000000, 8.000000)">
                        <g id="字体下拉备份-28" transform="translate(17.000000, 0.000000)">
                            <path d="M2.93250224,4 C2.57558545,4 2.34735479,4.48211202 2.62076555,4.75859292 L5.69283126,7.86632446 C5.86902763,8.04455851 6.13107528,8.04455851 6.30725651,7.86632446 L9.37942814,4.75859292 C9.64598472,4.48904547 9.43816531,4 9.07220039,4 L2.93250224,4 L2.93250224,4 Z" id="路径"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
`}} />

const EditThStyled = styled.div`
    border-top: 1px solid #11BBA6;
    border-left: 1px solid #11BBA6;
    height: 30px;
    color: #11BBA6;
    ${props => props.$focus && `background: #DFF8F5;`}
`

const ContentStyled = styled.div`
    padding: 8px 0;
    .c-item {
        width: 107px;
        height: 35px;
    }
    .c-item:hover {
        background: #ECEFF1;
        cursor: pointer;
    }
`

const Content = ({ index, onDeleteColumn, onAddColumn }) => {

    return <ContentStyled>
        <div onClick={() => onAddColumn('left', index)} className="c-item flex-center">插入左列</div>        
        <div onClick={() => onAddColumn('right', index)} className="c-item flex-center">插入右列</div>        
        <div onClick={() => onDeleteColumn(index)} className="c-item flex-center">删除列</div>
    </ContentStyled>
}

const EditableHeader = ({ table, focusIndex, onChangeFocusIndex, onAddColumn, onDeleteColumn }) => {
    const handleAdd = (...props) => {
        if (side === 'left') {}
    }

    const handleDelete = () => {

    }


    return <div className='edit-header flex'>
        {table.getRowModel().rows.map((row, index) => {
            return <div className='flex-1'>
                <Popover content={<Content index={index} onAddColumn={onAddColumn} onDeleteColumn={onDeleteColumn} />} trigger="click" overlayInnerStyle={{ padding: 0 }}>
                    <EditThStyled $focus={focusIndex === index} className='edit-header-th flex-center' onClick={() => onChangeFocusIndex(index)}>
                        {index + 1}
                        {svg}
                    </EditThStyled>
                </Popover>
            </div>
        })}
    </div>
}


export default EditableHeader