import styled from "styled-components"
import { Button, Tabs, Input, message } from 'antd';
import { useEffect, useState } from "react";
import http from "@/http/http";
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

const ImportCaseStyled = styled.div`
    padding: 20px;
    
`

const ContainerStyled = styled.div`

    .header{
        display: flex;
        column-gap: 20px;
        margin: 20px 0;
        padding: 0 20px;
        .change{
            display: flex;
            column-gap: 10px;
            margin-left: auto;
        }
    }

    .content{
    display: flex;
    height: 400vh;
    padding: 0 100px;
    column-gap: 50px;
    .left,.right{
        flex: 1;
        height: 100%;

        .iframe{
            width: 100%;
            height: 100%;
            overflow: scroll;
        }
    }
    }
`

const ImportCase = () => {

    const [page, setPage] = useState(1)
    const [data, setData] = useState(null)
    const [key, setKey] = useState("pdf")
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        http.get(`resume/develop/listImportCase?page=${page}&limit=1&type=${key}`).then(res => {
            if (res.dataList.length) {
                setData(res.dataList[0])
            } else {
                message.error('没有数据')
            }
        })
    }, [page, key])

    const getNewData = () => {
        http.get(`resume/develop/getImportCase?caseId=${inputValue}`).then(res => {
            if (res) {
                setKey(res?.type)
                setData(res)
                setPage(1)
            } else {
                message.error('没有数据')
            }
        })
    }

    const pdfContent = (
        <ContainerStyled>
            <div className="header">
                <div className="item">
                    id： {data?.id}
                </div>
                <div className="item">
                    简历名称：{data?.fileName}
                </div>
                <div className="change">
                    <Button onClick={() => setPage(i => i + 1)}>下一个</Button>
                    <Input onChange={(e) => setInputValue(e.target.value)} onPressEnter={getNewData} />
                </div>
            </div>
            <div className="content">
                <div className="left">
                    <iframe className="iframe" src={data?.fileUrl + '#toolbar=0'}></iframe>
                </div>
                <div className="right">
                    <iframe
                        scrolling="yes"
                        style={{ overflow: 'auto' }}
                        width="362"
                        height="512"
                        className="iframe"
                        loading="lazy"
                        id={data?.id}
                        src={`/resume/container?id=${data?.resumeUserId}`} />
                </div>
            </div>
        </ContainerStyled>
    )

    const wordContent = (
        <ContainerStyled>
            <div className="header">
                <div className="item">
                    id： {data?.id}
                </div>
                <div className="item">
                    简历名称：{data?.fileName}
                </div>
                <div className="change">
                    <Button onClick={() => setPage(i => i + 1)}>下一个</Button>
                    <Input onChange={(e) => setInputValue(e.target.value)} onPressEnter={getNewData} />
                </div>
            </div>
            <div className="content">
                <div className="left">
                    <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        documents={[
                            { uri: data?.fileUrl },
                          ]}
                        style={{height: '100%'}}
                        config={{
                            header: {
                                disableHeader: false,
                                disableFileName: false,
                                retainURLParams: false
                            },
                        }}
                    />
                </div>
                <div className="right">
                    <iframe
                        style={{ overflow: 'auto' }}
                        width="362"
                        height="512"
                        className="iframe"
                        loading="lazy"
                        id={data?.id}
                        src={`/resume/container?id=${data?.resumeUserId}`} />
                </div>
            </div>
        </ContainerStyled >
    )

    const tabItems = [
        {
            key: 'pdf',
            label: 'pdf',
            children: pdfContent
        },
        {
            key: 'word',
            label: 'word',
            children: wordContent,
        },
    ];

    const onChangeTabs = (key) => {
        setKey(key);
    }

    return (
        <ImportCaseStyled>
            <Tabs defaultActiveKey="PDF" items={tabItems} onChange={onChangeTabs} />
        </ImportCaseStyled>
    )
}



export default ImportCase