import {createSlice} from '@reduxjs/toolkit';
import initState from '../initState';


export default createSlice({
    name: 'version',
    initialState: initState.version,
    reducers: {
        set(state, {payload}) {
            return payload
        },
        setVersionCurrent(state, {payload}) {
            state.current = payload;
        },

    }
});
