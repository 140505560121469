import withComponent from "@/hoc/withComponent";
import styled from "styled-components";
import {useCallback, useState} from "react";


const ContainerStyled = styled.div`
  //width: 313px;
  height: 84px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 0.5);
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InputBoxStyled = styled.div`
  margin: 0 20px;
  width: fit-content;
  height: 42px;
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
  align-items: center;

  ${props => props.$isActive ? 'border: 1px solid #11BBA6;' : 'border: 1px solid #E0E0E0;'}
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
`
const InputStyled = styled.input`
  width: 56px;
  outline: none;
  border: none;
  margin-right: 4px;
  font-size: 14px;

  ::placeholder {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
`

const LineStyled = styled.div`
  width: 12px;
  height: 2px;
  background: #D8D8D8;
  margin: 0 10px;
`

const PostfixTextStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
`

export default withComponent((props) => {
    const {openHeight = true, openWeight = true} = props;
    const [heightFocus, setHeightFocus] = useState(false);
    const [weightFocus, setWeightFocus] = useState(false);
    const onChangeHeight = useCallback((e) => {
        props.onChangeHeight?.(e.target.value)
    }, [])
    const onChangeWeight = useCallback((e) => {
        props.onChangeWeight?.(e.target.value)
    }, [])
    return <ContainerStyled>
        {
            openHeight && <InputBoxStyled $isActive={heightFocus}>
                <InputStyled type={"number"}
                             onChange={onChangeHeight}
                             placeholder='输入身高' onFocus={() => setHeightFocus(true)}
                             onBlur={() => setHeightFocus(false)}/>
                <PostfixTextStyled>cm</PostfixTextStyled>
            </InputBoxStyled>
        }
        {
            openWeight && openHeight && <LineStyled/>
        }
        {
            openWeight && <InputBoxStyled $isActive={weightFocus}>
                <InputStyled type={"number"} placeholder='输入体重'
                             onChange={onChangeWeight}
                             onFocus={() => setWeightFocus(true)}
                             onBlur={() => setWeightFocus(false)}/>
                <PostfixTextStyled>kg</PostfixTextStyled>
            </InputBoxStyled>
        }
    </ContainerStyled>
})
