import {createElement} from "react";
import withComponent from "@/hoc/withComponent";
import {useSelector} from "@/components/resume/redux/hooks";
import getNestedProperty from "@/utils/getNestedProperty";
import useModuleContentInstance from "@/components/resume/hooks/useModuleContentInstance";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import getResumePath from "@/common/resume/getResumePath"
import getPlaceholderBySlot from "@/common/config/getPlaceholder";

export default withComponent((props) => {
    const {tagName, style, className, slot_value} = props;
    const fieldInstance = useFieldInstance();
    const placeholder = getPlaceholderBySlot(fieldInstance?.for_block_name, fieldInstance?.for_field_name,slot_value);
    const moduleContentInstance = useModuleContentInstance();
    const path = getResumePath(fieldInstance?.for_block_name, fieldInstance?.for_field_name, slot_value, moduleContentInstance?.index);
    const getValueByState = (state, path) => {
        const resumeData = state.resumeData;
        return getNestedProperty(resumeData, path)
    }
    const value = useSelector((state) => getValueByState(state, path));
    const placeholderVisible = !value;
/*    let text = value;
    if(slot_value==="身高"){
        text += "cm"
    }
    if(slot_value==="体重"){
        text += "kg"
    }*/

    return createElement(tagName, {
        style: {...style, color: placeholderVisible ? 'gray' : undefined},
        className,
        'data-type': slot_value
    }, value || placeholder)
})

