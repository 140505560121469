import { ReactComponent as HistorySvg } from '../../svg/history.svg'
import { ReactComponent as ServiceSvg } from '../../svg/service.svg'
import { ReactComponent as MySvg } from '../../svg/my.svg'
import { ReactComponent as RightNavSvg } from '../../svg/right_nav.svg'
import { ReactComponent as DownloadSvg } from '../../svg/download.svg'
import { ReactComponent as ResumeSvg } from '../../svg/resume.svg'
import {ReactComponent as GetReportSvg} from '../../svg/getReport.svg'
import './index.scss'
import Modal from '../../components/Modal/StandardModal/Modal'
import { useEffect, useMemo, useState } from 'react'
import { isMiniProgram, isPowerPoint, isWechat, isWpsPowerPoint } from '../../utils'
import store from 'store'
import { Dialog,Toast } from 'antd-mobile'
import isVip, { isDVIP, isSVIP } from '@/common/isVip'
import moment from 'moment'
import CenterModal from '../../components/Modal/CenterModal'
import PayModal from '../../components/Modal/PayModal'
import isLogin from '@/common/isLogin'
import LoginModal from '../../components/Modal/LoginModal'
import { svipIcon, vipIcon } from '@/components/user/UserMenu'
import MobileLayout from '../../layout'
import { useNavigate,useLocation } from 'react-router-dom'
import { svip_life, svipLastId } from '../../components/Modal/PayModal/config'
import { updateWxToken } from '../../utils/login'
import GetReportModal from 'src/pages/Mobile/components/Modal/PayModal/GetReportModal.js'
import GetReportSuccessModal from 'src/pages/Mobile/components/Modal/PayModal/GetReportSuccessModal.js'
import { useSelector } from 'react-redux'
import { vip_life } from '@/const/pay'
import styled from 'styled-components'
// import { isPluginEnv } from '../../components/Modal/PayModal/usePluginPay'

const Text2Styled = styled.div`
    position:absolute;
    z-index:2;
    width: 64px;
    height: 24px;
    background: rgba(251,107,90,0.12);
    border-radius: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FB5E55;
    line-height:24px;
    text-align:center;
    right:30px;
`

const initList = [
    {
        type: 'download', //0
        title: '我的简历',
        icon: <ResumeSvg />,
        url: '/mobile/my_list',
        style: {
            marginBottom: 12
        }
    },
    {
        type: 'download', //0
        title: '下载记录',
        icon: <DownloadSvg />,
        url: '/mobile/history',
        style: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }
    },
    {
        type: 'history',
        title: '生成记录',
        icon: <HistorySvg />,
        url: '/mobile/history?type=gpt',
        style: {
            // marginBottom: 12,
            // borderTopLeftRadius: 0,
            // borderTopRightRadius: 0,
        }
    },
    {
        type: 'getReport',
        title: '职业性格测评机会',
        icon: <GetReportSvg />,
        url: '/mobile/history?type=gpt',
        style: {
            marginBottom: 12,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        }
    },
    {
        type: 'service', //
        title: '联系客服',
        icon: <ServiceSvg />,
        style: {
            marginBottom: 12,
            // borderTopLeftRadius: 0,
            // borderTopRightRadius: 0,
        }
    },
]


// Define the additional items and their conditions
const additionalItems = [
    {
        item: {
            type: 4,
            title: '退出登录',
            icon: <MySvg />,
        },
        
        condition: () => false,
    },
];

// normal | vip | svip
const getCustomVipType = () => {
    const user = store.get('user')
    const vip = isVip();
    const upgradeButtonVisible = vip && user.vipType !== svipLastId && user.vipType !== svip_life;
    if (!vip) {
        return 'normal'
    } else {
        if (upgradeButtonVisible) {
            return 'vip'
        } else {
            return 'svip'
        }
    }
}

const isUpgrade = () => {
    const user = store.get('user')
    const vip = isVip();
    return vip && user.vipType !== vip_life
}

const My = ({ title }) => {
    // usePageTitle(title)
    const [actionList, setActionList] = useState(initList)
    // const history = useHistory()
    const navigate = useNavigate()
    const {state} = useLocation()
    const [visible, setVisible] = useState(false)
    const [benefitsVisible, setBenefitsVisible] = useState(false)
    const [payVisible, setPayVisible] = useState(false)
    const [loginVisible, setLoginVisible] = useState(false)
    const [pageKey, setPageKey] = useState(0)
    const stateUser = useSelector(state => state.user)
    useEffect(() => {
        additionalItems.forEach(({ item, condition }) => {
            if (condition() && !actionList.find((i) => i.type === item.type)) {
                setActionList((list) => [...list, item]);
            }
        });
        if(state?.openLogin){
            setLoginVisible(true)
            Toast.show('请先登录')
        }
    }, [])

    useEffect(() => {
        if (isWechat()) {
            updateWxToken()
        }
    }, [])

    const showQuitBtn = store.get('user') && store.get('user').mobile;
    // '' 没资格 1 未领取 2 已领取
    const showGetReportState = store.get('user') && store.get('user').enterpriseMbtiMobileState;
    // const showGetReportState =2
    const getReportPhone = (store.get('user') && store.get('user').enterpriseMbtiMobile );

    const [getModalOpen, setGetModalOpen] = useState(false);
    const [usePhone, setUsePhone] = useState(getReportPhone);
    console.log(usePhone)
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const onGetReportSuccess = (v) => {
        setUsePhone(v?.phone);
        setGetModalOpen(false);
        setSuccessModalOpen(true);
    };

    const handleNavigate = async (type, url) => {
        if (type === 'history' || type === 'download') {
            if (isLogin()) {
                navigate(url)
            } else {
                setLoginVisible(true)
                // console.log('用户未登录，mobileLogin会自动触发登录')
            }
        } else if (type === 'service') {
            setVisible(true)
        } else if (type === 4) {
            const result = await Dialog.confirm({
                title: '退出登录',
                content: '您当前确定要退出吗？',
                bodyStyle: {},
                bodyClassName: 'user-quit-dialog',
            });
            if (result) {
                store.remove('user');
                window.location.reload();
            }
        }else if(type==='getReport'){
            if(showGetReportState==1){

                setGetModalOpen(true)
            }else if(showGetReportState==2){
                
                setSuccessModalOpen(true)
                // setGetModalOpen(true)

            }
        }
    }

    const handlePaySuccess = () => {
        setPageKey(k => k + 1)
        setPayVisible(false)

        window.location.reload();
        
        // setTimeout(()=>{
        //     setGetModalOpen(true)
        // },1000) 
    }

    // 其实这里不应该这么做，因为用户有可能取消授权，取消后再调用setLoginVisible(true)无法触发组件内的重登陆
    const handleLogin = () => {
        setLoginVisible(true)
        setPageKey(k => k + 1)
    }

    const handleLoginSuccess = () => {
        setLoginVisible(true)
        // 登录成功后页面重刷新
        if (isWpsPowerPoint()) {
            setPageKey(k => k + 1)
        } else {
            window.location.reload()
        }
    }

    const user = store.get('user')
    const vipType = 'normal'
    const mapTypeToVip = {
        'normal': {
            label: '开通会员',
            onClick: () => {
                setPayVisible(true)
            }
        },
        'vip': {
            label: '升级会员',
            onClick: () => {
                setPayVisible(true)
            }
        },
        'svip': {
            label: '升级会员',
            onClick: () => {
                setPayVisible(true)
            }
        },
    }

    let border = ''
    if (isDVIP()) {
        border = '1px solid #11BBA6'
    } else if (isSVIP()) {
        border = '1px solid #F4BC6A'
    }

    const btnVip = mapTypeToVip[vipType]
    const login = isLogin()
    return <MobileLayout>
        <div key={pageKey} className="my-page">
            <div className='user-info'>
                <div className='user-icon'>
                    <img
                        style={{
                            border,
                            borderRadius: '50%'
                        }}
                        src={login ? user.icon : 'https://oss.jianzeppt.com/jz-ppt-img/unlogin_user.png'} />
                    <div className='vip-icon'>
                        {isDVIP() && vipIcon}
                        {isSVIP() && svipIcon}
                    </div>
                    {
                        login ?
                            <div className='username'>
                                <div className='name'>
                                    {user.userName}
                                </div>
                                <div className='time'>{isVip() && moment(user?.editableVipTime).format('YYYY.MM.DD到期')}</div>
                            </div>
                            : <div onClick={handleLogin} className='un-login'>点击登录/注册</div>
                    }
                </div>
                {
                    login && user.vipType !== 22 &&  ( 
                        <div className={`user-vip ${vipType}`} onClick={btnVip.onClick}>
                            {btnVip.label}
                        </div>
                    )
                }
            </div>
            <div className="my-page-list">
                {
                    actionList.map((item, index) => {
                        const { title, icon, type, url } = item

                        if(!showGetReportState && type==='getReport'){
                            return null
                        }
                        return (
                            <div onClick={() => handleNavigate(type, url)} key={index} className='my-page-list-item' style={item.style}>
                                <div className="left">
                                    <div className="icon">{icon}</div>
                                    <div className="title">{title}</div>
                                </div>
                                <div className="right">
                                    <div className="nav">
                                        {(showGetReportState===1 && type ==='getReport') && <Text2Styled>待领取</Text2Styled>}
                                        {type!=='getReport' &&<RightNavSvg />}
                                        {type==='getReport' && showGetReportState===2 &&<RightNavSvg />}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {showQuitBtn && (
                        <div
                            className="my-page-list-item"
                            style={{
                                color: '#999999',
                                textAlign: 'center',
                                marginTop: '12px',
                                justifyContent: 'center',
                                height: '22px',
                                fontFamily: 'PingFangSC, PingFang SC',
                                fontWeight: 500,
                                fontSize: '15px',
                            }}
                            onClick={() => {
                                handleNavigate(4);
                            }}
                        >
                            {' '}
                            退出登录{' '}
                        </div>
                    )}
            </div>
            <Modal className="my-page-modal" visible={visible} onClose={() => setVisible(false)}>
                <div className="my-page-modal-content">
                    <img style={{ width: '100%' }} src="https://jianzeppt-backend-shanghai.oss-cn-shanghai.aliyuncs.com/jz-ppt-img/51691548814_.pic.jpg" />
                </div>
            </Modal>
          

            <CenterModal className="my-page-modal" visible={benefitsVisible} onClose={() => setBenefitsVisible(false)}>
                <div className="my-page-modal-content">
                    <img style={{ width: '100%' }} src="https://oss.jianzeppt.com/jz-ppt-img/mkcnvkjnd.png" />
                </div>
            </CenterModal>
            <PayModal visible={payVisible}  onClose={() => setPayVisible(false)} onSuccess={handlePaySuccess} />
            <LoginModal visible={loginVisible} onSuccess={handleLoginSuccess} onClose={() => setLoginVisible(false)} />
                    
        </div>
        <GetReportModal
                open={getModalOpen}
                onSuccess={onGetReportSuccess}
                onCancel={() =>{ 
                    // window.location.reload();
                    setGetModalOpen(false)}}
            ></GetReportModal>
            <GetReportSuccessModal
                open={successModalOpen}
                phone={usePhone}
                onCancel={() =>{ 
                    if(showGetReportState===1){
                        window.location.reload();
                    }
                    setSuccessModalOpen(false)}}
            ></GetReportSuccessModal>
        {/* {modal} */}
    </MobileLayout>
}

export default My
