import styled from 'styled-components';
import withComponent from '@/hoc/withComponent';
import {Tooltip} from "antd";
import useVersionCanPre from "@/pages/ResumeDetail/hooks/useVersionCanPre";
import {useCallback} from "react";
import useResumeDetailInstance from "@/pages/ResumeDetail/hooks/useResumeDetailInstance";

const RevocationStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: ${(props) => (props['data-disabled'] ? 'not-allowed' : 'pointer')};


  path {
    fill: ${(props) => (props['data-disabled'] ? (props.isDark ? '#999999' : '#555555') : (props.isDark ? '#6E727C' : 'white'))};
  }

`;

const icon = <div style={{width: 24, height: 24}} dangerouslySetInnerHTML={{
    __html: `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>撤销</title></title>
        <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="图标选中样式" transform="translate(-82.000000, -200.000000)" fill="#999999" fill-rule="nonzero">
                <g id="文本备份-17" transform="translate(82.000000, 200.000000)">
                    <path d="M8.98652981,12.1875261 C8.98631497,12.2885435 8.92322234,12.3805384 8.82415179,12.4242884 C8.72508124,12.4680383 8.60750836,12.4558259 8.52156094,12.3928581 L4.67864567,9.56734998 C4.56571108,9.48407788 4.5,9.35819943 4.5,9.22513005 C4.5,9.09206067 4.56571108,8.96618221 4.67864567,8.88291012 L8.52156094,6.05740199 C8.60750836,5.99443417 8.72508124,5.98222181 8.82415179,6.02597173 C8.92322234,6.06972165 8.98631497,6.16171655 8.98652981,6.26273395 L8.98652981,8.34764305 L14.2377184,8.34764305 C17.1010455,8.34209422 19.4413828,10.4457773 19.4989191,13.0768328 C19.5564554,15.7078883 17.3099782,17.8962969 14.4487207,17.9964901 L14.2377184,18 L6.59962592,18 C6.3359758,18 6.12224514,17.8035678 6.12224514,17.5612565 L6.12224514,16.6837695 C6.12224514,16.4414582 6.3359758,16.245026 6.59962592,16.245026 L14.2377184,16.245026 C16.0494308,16.2474295 17.5332398,14.9225465 17.5829493,13.258092 C17.6326588,11.5936374 16.2301606,10.1959426 14.4210326,10.1070045 L14.2377184,10.102617 L8.98652981,10.102617 L8.98652981,12.1875261 Z" id="路径"></path>
                </g>
            </g>
        </g>
    </svg>`,
}}/>;


export default withComponent((props) => {
    const {style, isDark} = props;
    const canPre = useVersionCanPre();
    const resumeDetailInstance = useResumeDetailInstance()
    const onClick = useCallback(() => {
        resumeDetailInstance.resumeRef.current?.versionPre?.()
    }, [])
    return <RevocationContainer isDark={isDark} onClick={canPre ? onClick : undefined} disabled={!canPre} style={style}/>;
});

export const RevocationContainer = (props) => {
    const {disabled, onClick, style, isDark} = props;
    return <Tooltip placement='bottom'
                    title={'撤销'}
                    mouseEnterDelay={1}
                    mouseLeaveDelay={0}
                    arrowPointAtCenter>
        <RevocationStyled data-disabled={disabled} isDark={isDark} onClick={onClick} style={style}>
            {icon}
        </RevocationStyled>
    </Tooltip>
};
