
import styled from "styled-components"
import UserResumeItem from "./UserResumeItem"
import { useEffect, useState } from "react"
import { listResumeUser } from "@/http/api"
import moment from "moment"
import { useNavigate } from "react-router-dom"

const rightSvg = <div className="flex-center" dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="点击导入简历" transform="translate(-321.000000, -227.000000)" fill="#999999" fill-rule="nonzero">
            <g id="返回" transform="translate(321.000000, 227.000000)">
                <path d="M8.99008332,1.62606369 C9.23686819,1.37590766 9.6371048,1.3651263 9.89699915,1.60163368 C10.1568935,1.83814105 10.1837891,2.23761848 9.95794314,2.50682923 L9.91996218,2.54873926 L5.46964055,7.03409505 L9.91603312,11.445126 C10.1571558,11.6843435 10.175354,12.0682104 9.95794314,12.3291658 L9.91996218,12.3714032 C9.68065744,12.612428 9.29679472,12.6304838 9.03592242,12.4129858 L8.99368497,12.3750049 L4.08235298,7.50296354 C3.84132822,7.2636588 3.82327245,6.87979608 4.04077036,6.61892378 L4.07875134,6.57668633 L8.99008332,1.62606369 L8.99008332,1.62606369 Z" id="路径" transform="translate(7.000000, 6.997989) scale(-1, 1) translate(-7.000000, -6.997989) "></path>
            </g>
        </g>
    </g>
</svg>
`}} />

const MyResumeStyled = styled.div`
    padding: 16px;
    margin-bottom: 9px;
    background-color: #fff;
    border-radius: 16px 16px 0px 0px;
    position: relative;
    margin-top: -12px;
    .header {
        margin-bottom: 22px;
        padding-top: 6px;
        h2 {
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #2C2C2C;
            margin: 0;
            position: relative;
            padding-left: 8px;
        }

        h2::after {
            content: "";
            position: absolute;
            background: #11BBA6;
            width: 2px;
            border-radius: 2px;
            /* height: 100%; */
            left: 0;
            top: 0;
            bottom: 0;
        }
        span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999; 
        }
    }

    .resume-content {
        
    }
`

const MyResume = ({ onChange }) => {
    const [resumeData, setResumeData] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        listResumeUser({
            query: {
                page: 1,
                pageSize: 1
            }
        }).then(data => {
            
            if (data && data.dataList && data.dataList[0]) {
                const item = data.dataList[0]
                setResumeData({
                    ...item,
                    updateTime: moment(item.updateTime).format("YYYY-MM-DD HH:mm"),
                })
                onChange && onChange(true)
            }
        })
    }

    const handleMutation = () => {
        fetchData()
    }

    const handleMore = () => {
        navigate('/mobile/my_list')
    }

    return resumeData && <MyResumeStyled>
        <div className="header flex justify-between items-center">
            <h2>我的简历</h2>
            <span className="flex-center" onClick={handleMore}>
                <div className="pr-5">更多</div>
                {rightSvg}
            </span>
        </div>
        <UserResumeItem 
            id={resumeData.id} 
            title={resumeData.name} 
            time={resumeData.updateTime}
            onMutation={handleMutation}
            imgSrc={"https://oss.jianzeppt.com/resume/cover/8943.png"} 
        />
    </MyResumeStyled>
}

export default MyResume