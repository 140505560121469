export const searchIcon = <div style={{ height: 18, width: 18 }} dangerouslySetInnerHTML={{
  __html: `<svg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='图标替换' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='图标弹窗' transform='translate(-687.000000, -226.000000)' fill='#444444' fill-rule='nonzero'>
            <g id='编组' transform='translate(465.000000, 198.000000)'>
                <g id='编组-11' transform='translate(198.000000, 15.000000)'>
                    <g id='搜索' transform='translate(24.000000, 13.000000)'>
                        <path d='M12.598957,3.7562412 C10.1570308,1.3135322 6.19465995,1.3135322 3.75273375,3.7562412 C1.31080755,6.1989502 1.31080755,10.1625912 3.75273375,12.6053002 C6.19465995,15.0480092 10.1570308,15.0480092 12.598957,12.6053002 C15.0408832,10.1625912 15.0408832,6.1989502 12.598957,3.7562412 Z M2.39815582,13.9603124 C-0.799385275,10.770964 -0.799385275,5.59057739 2.39815582,2.39201127 C5.59569692,-0.797337089 10.7652086,-0.797337089 13.9627497,2.39201127 C17.1602908,5.59057739 17.1602908,10.7617463 13.9627497,13.9603124 C10.7652086,17.1588785 5.58648211,17.1588785 2.39815582,13.9603124 Z M17.6763205,17.6750736 C17.2432242,18.1083088 16.5336833,18.1083088 16.100587,17.6750736 L14.5156387,16.098835 C14.0825423,15.6655998 14.0825423,14.9558315 14.5156387,14.5225963 C14.948735,14.0893612 15.6582758,14.0893612 16.0913722,14.5225963 L17.6671057,16.098835 C18.1094168,16.5320702 18.1094168,17.2418384 17.6763205,17.6750736 L17.6763205,17.6750736 Z' id='形状'></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}} />;
