import React from 'react'
import classNames from 'classnames';
import './Modal.scss'

import Portal from '../Portal';

const closeIcon = (
  <div
    className='close-icon'
    dangerouslySetInnerHTML={{
      __html: `<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>

    <g id='详情页优化' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='立即编辑--弹窗' transform='translate(-1170.000000, -370.000000)'>
            <g id='编组-11' transform='translate(760.000000, 370.000000)'>
                <g id='关闭' transform='translate(410.000000, 0.000000)'>
                    <circle id='椭圆形' fill='#5C5C5C' cx='12' cy='12' r='12'></circle>
                    <g id='closel' transform='translate(7.000000, 7.000000)' fill='#FFFFFF' fill-rule='nonzero'>
                        <path d='M9.7663551,8.65168539 L6.11645342,5.00039471 L9.76740755,1.34910402 C10.0768283,1.03965476 10.0778807,0.540746783 9.76846001,0.231297529 C9.46009173,-0.0770991764 8.95912483,-0.0770991764 8.651809,0.231297529 L4.99980241,3.88258822 L1.34779583,0.231297529 C1.04048,-0.0770991764 0.540565552,-0.0770991764 0.231144821,0.231297529 C-0.0761710068,0.539694235 -0.0761710068,1.04070731 0.233249724,1.35015657 L3.88315141,5.00039471 L0.232197273,8.65063284 C-0.0772234582,8.95902955 -0.0772234582,9.45899008 0.231144821,9.76843933 C0.385855187,9.92421651 0.590030771,10 0.78999655,10 C0.989962328,10 1.19413791,9.92421651 1.34779583,9.76949188 L4.99980241,6.11820119 L8.651809,9.76949188 C8.80651937,9.92316396 9.00859005,10 9.20960828,10 C9.41167896,10 9.61374964,9.92421651 9.76740755,9.76949188 C10.0757758,9.45899008 10.0768283,8.9600821 9.7663551,8.65168539 Z' id='路径'></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`,
    }}
  />
);



const Modal = React.memo(({ visible, onClose, children, className }) => {
  const cls = classNames({
    [className]: className,
    'modal-wrap': true,
    'active': !visible
  })

  return (
    <Portal>
      <div className={cls}>
        {/* <div className="modal-overlay" onClick={onClose} /> */}
        <div className="modal-close" onClick={onClose}>
          {closeIcon}
        </div>
        <div className="modal-content">
          <div className="content">
            {children}
          </div>
        </div>
      </div>
    </Portal>

  )
})

export default Modal;
