import withComponent from "@/hoc/withComponent";
import {useCallback, useMemo, useState} from "react";
import {useDispatch} from "@/components/resume/redux/hooks";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import styled from "styled-components";
import {Module} from "@/common/module/module";
import {Popover, Tooltip} from "antd";
import Portfolio from "@/components/resume/moduleManage/layoutMenu/Portfolio";

const IconContainerStyled = styled.div`
  width: 28px;
  height: 28px;
  background: #11BBA6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
    &:hover{
        opacity: 0.8;
    }
`
const icon = <div style={{height: 16, width: 16,position:"relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="作品展示、自定义展示" transform="translate(-1141.000000, -911.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-2备份-2" transform="translate(1081.000000, 907.000000)">
                <g id="编组-3备份-38" transform="translate(56.000000, 0.000000)">
                    <g id="设置备份" transform="translate(4.000000, 4.000000)">
                        <g id="mobanguanli-2" transform="translate(1.714286, 1.714286)">
                            <path d="M12.2222222,0 L0.349206344,0 C0.139682534,0 0,0.139682534 0,0.349206344 L0,3.84126985 C0,4.05079366 0.139682534,4.19047619 0.349206344,4.19047619 L12.2222222,4.19047619 C12.431746,4.19047619 12.5714286,4.05079366 12.5714286,3.84126985 L12.5714286,0.349206344 C12.5714286,0.139682534 12.431746,0 12.2222222,0 Z M12.152381,4.81904762 L5.16825396,4.81904762 C4.95873015,4.81904762 4.81904762,5.01104761 4.81904762,5.29904761 L4.81904762,12.0190476 C4.81904762,12.3070476 4.95873015,12.4990476 5.16825396,12.4990476 L12.152381,12.4990476 C12.3619048,12.4990476 12.5015873,12.3070476 12.5015873,12.0190476 L12.5015873,5.29904761 C12.5015873,5.0590476 12.3269841,4.81904762 12.152381,4.81904762 Z" id="形状"></path>
                            <path d="M3.84126985,4.88888889 L0.349206344,4.88888889 C0.139682534,4.88888889 0,5.02857143 0,5.23809524 L0,12.2222222 C0,12.431746 0.139682534,12.5714286 0.349206344,12.5714286 L3.84126985,12.5714286 C4.05079366,12.5714286 4.19047619,12.431746 4.19047619,12.2222222 L4.19047619,5.23809524 C4.19047619,5.02857143 4.05079366,4.88888889 3.84126985,4.88888889 Z" id="路径"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>

const visibleMenuConfig = [{blockName: Module.PORTFOLIO, component: Portfolio}];
const visibleMenuBlockNameList = visibleMenuConfig.map((item) => item.blockName);


export default withComponent(() => {
    const moduleInstance = useModuleInstance();
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const onClick = useCallback(() => {

    }, []);
    const showMenu = useMemo(() => visibleMenuBlockNameList.includes(moduleInstance.for_block_name), [moduleInstance.for_block_name]);
    const onClickItem = useCallback(() => {
        setVisible(false);
    }, [])
    if (showMenu) {
        let Menu = visibleMenuConfig.find((item) => item.blockName === moduleInstance.for_block_name).component;
        return <Popover content={<Menu onClickItem={onClickItem}/>}
                        overlayClassName="ant-popover-custom"
                        autoAdjustOverflow={false}
                        trigger="click"
                        transitionName=""
                        getPopupContainer={null}
                        open={visible}
                        onOpenChange={setVisible}
                        placement="bottom">
            <Tooltip title={"版式更换"} placement={"bottom"}
                     overlayClassName="tooltipPointerEventsNone">
                <IconContainerStyled style={{marginRight: 4}}>
                    {icon}
                </IconContainerStyled>
            </Tooltip>

        </Popover>
    }

    return <IconContainerStyled onClick={onClick} style={{marginRight: 4}}>
        {icon}
    </IconContainerStyled>
})
