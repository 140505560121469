import { Spin } from "antd"
import styled from "styled-components"
// import { Button } from "antd"

const ButtonBaseStyled = styled.div`
    background: #11BBA6;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    width: 184px;
    height: 42px;
    column-gap: 6px;
    cursor: pointer;

    .ant-spin-dot-item{
        background: #FFFFFF;
    }


    &:hover{
        background: #0FA795;
    }

    &:active{
        background: #0D9584;
    }

    background: ${(props) => props.loading ? 'rgba(17,187,166, 0.8)!important' : undefined};

    background: ${(props) => props.disable ? '#D9D9D9!important' : undefined};
`

const Type1 = styled(ButtonBaseStyled)`
`;

const Type2 = styled(ButtonBaseStyled)`
    background: linear-gradient( 90deg, #47B5EA 0%, #13D39D 100%);
    &:hover{
        background: linear-gradient( 90deg, #5ba0cc 0%, #67ba90 100%);
    }
    &:active{
        background: linear-gradient( 90deg, #518eb6 0%, #4da681 100%);
    }
`;

const Type3 = styled(ButtonBaseStyled)`
    background: #ffffff;
    color: #11BBA6;
    border: 1px solid #11BBA6;
    &:hover{
        border-color: #0FA795;
        background: #ffffff;
        color: #0FA795;
    }
    &:active{
        border-color: #0D9584;
        background: #ffffff;
        color: #0D9584;
    }
`;

const Type4 = styled(ButtonBaseStyled)`
    background: #F4F4F6;
    color: #333333;
    &:hover{
        background: #e7e7e9;
        color: #333333;
    }
    &:active{
        background: #dbdbdc;
        color: #333333;
    }
`;


const ButtonComponent = (props) => {

    const { styles,
            onClick = () => {},
            status,
            Component,
            className,
            children } = props

    const handleBtnClick = () => {
        if(status === 'loading' || status === 'disable'){
            return
        }
        onClick()
    }

    return(
        <Component 
        style={styles} 
        className={className}
        loading={status === 'loading'} 
        disable={status === 'disable'} 
        onClick={handleBtnClick}>
            <Spin spinning={status === 'loading'} style={{marginRight: 6}} size="small" />
            {status === 'loading' ? '加载中...' : children}
        </Component>
    )
}

const JzThemeButton = {
    Type1: (props) => <ButtonComponent Component={Type1} {...props} />,
    Type2: (props) => <ButtonComponent Component={Type2} {...props} />,
    Type3: (props) => <ButtonComponent Component={Type3} {...props} />,
    Type4: (props) => <ButtonComponent Component={Type4} {...props} />,
}

export default JzThemeButton