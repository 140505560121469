import {combineReducers} from 'redux';
import {createReducer} from '@reduxjs/toolkit';
import initState from './initState';
import {STATE_RESET} from './actions';


import resumeSlice from "@/components/resume/redux/services/resumeSlice";
import templateSlice from "@/components/resume/redux/services/templateSlice";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import paginationSlice from "@/components/resume/redux/services/paginationSlice";
import versionSlice from "@/components/resume/redux/services/versionSlice";
import focusSlice from "@/components/resume/redux/services/focusSlice";
import hoverSlice from "@/components/resume/redux/services/hoverSlice";
import aiDecisionSlice from "@/components/resume/redux/services/aiDecisionSlice";
import loadingSlice from "@/components/resume/redux/services/loadingSlice";
import themeSlice from "@/components/resume/redux/services/themeSlice";
import interviewSlice from './services/interviewSlice';


const combineReducer = combineReducers({
    resume: resumeSlice.reducer,
    resumeData: resumeDataSlice.reducer,
    template: templateSlice.reducer,
    pagination: paginationSlice.reducer,
    version: versionSlice.reducer,
    focus: focusSlice.reducer,
    hover: hoverSlice.reducer,
    aiDecision: aiDecisionSlice.reducer,
    loading: loadingSlice.reducer,
    theme: themeSlice.reducer,
    interview: interviewSlice.reducer
});

const reducer = createReducer(initState, (builder) => {
    builder
        .addCase(STATE_RESET, (state, action) => {
            return initState;
        })
        .addDefaultCase(combineReducer);
});

export default reducer;
