import { BASE_FIELD_ENUM } from "@/common/field/field"
import { useEffect } from "react"
import { validateEmail, validatePhoneNumber } from "@/utils/validate"
import './useSelectedFieldCss.css'
import { Module } from "@/common/module/module"

const useInputValidate = ({ ref, slot_value }) => {

    const isBaseModule = (e) => {
        const fp = (e) => {
            if (e.dataset.type === Module.BASE) return true
            if (e.tagName === 'BODY' || e.tagName === 'HTML') return false
            return fp(e.parentElement)
        }
        return fp(e)
    }
    const getFieldBlock = (e) => {
        const fp = (e) => {
            if (e.tagName === 'LI') return e
            if (e.tagName === 'BODY' || e.tagName === 'HTML') return false
            return fp(e.parentElement)
        }
        return fp(e)
    }

    // useEffect(() => {
    //     if (!document.querySelector('#info_error_modal')) {
    //         const el = document.createElement('div')
    //         el.id = 'info_error_modal'
    //         document.body.appendChild(el)
    //     }
    // }, [])

    useEffect(() => {
        const focus = (e) => {
            if (!isBaseModule(e.target)) return 
            const block = getFieldBlock(e.target)
            if (block) {
                block.classList.add('result-field-input-focus')
            }
        }

        const blur = (e) => {
            if (!isBaseModule(e.target)) return 
            const block = getFieldBlock(e.target)
            if (block) {
                block.classList.remove('result-field-input-focus')
            }
        }

        const change = (e) => {
            if (!isBaseModule(e.target)) return 
            const contentEditable = e.target.getAttribute('contenteditable')
            if (contentEditable) {
                const value = e.target.textContent
                const el = getFieldBlock(e.target)
                if (slot_value === BASE_FIELD_ENUM.PHONE_NUMBER) {
                    if (validatePhoneNumber(value)) {
                        el.classList.remove('result-field-input-error')
                    } else {
                        el.classList.add('result-field-input-error')
                    }
                } else if (slot_value === BASE_FIELD_ENUM.MAIL) {
                    if (validateEmail(value)) {
                        el.classList.remove('result-field-input-error')
                    } else {
                        el.classList.add('result-field-input-error')
                    }
                }
            }
        }

        ref.current.addEventListener('focus', focus)
        ref.current.addEventListener('blur', blur)
        ref.current.addEventListener('input', change)
        return () => {
            ref.current?.removeEventListener('focus', focus)
            ref.current?.removeEventListener('blur', blur)
            ref.current?.removeEventListener('input', change)
        }
    }, [])
}

export default useInputValidate
