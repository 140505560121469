import styled from "styled-components";
import React, {useMemo} from 'react';
import {Button, Divider, Drawer, Switch} from 'antd';
import {getModuleList, Module} from "@/common/module/module";
import getTextByModule from "@/common/module/getTextByModule";
import {useSelector, useStore} from "@/components/resume/redux/hooks";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import getModuleVisible from "@/common/config/getModuleVisible";
import withComponent from "@/hoc/withComponent";
import {getIconByModule} from "@/common/resume/getIconByModule";
import getTitleTextByModule from "@/common/module/getTitleTextByModule";


const ContainerPositionStyled = styled.div`
  width: 280px;
  height: 100%;
  background: #FFFFFF;
`
const ContainerStyled = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
  height: 100%;
  box-sizing: border-box;
`

const CellStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  height: 50px;
  padding: 0 20px;


  &:hover {
    background: #F7F7F7;
  }

`
const CellIconStyled = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`
const CellTitleStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #323232;
  line-height: 20px;
`
const CellSwitchPositionStyled = styled.div`
  position: absolute;
  top: 17px;
  right: 20px;
`
const CellRequirePositionStyled = styled.div`
  position: absolute;
  top: 14px;
  right: 18px;
`

const TitleStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  padding-bottom: 5px;
  padding-left: 16px;
`
const RequireStyled = styled.div`
  width: 34px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #11BBA6;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #11BBA6;
  line-height: 22px;
  text-align: center;
`
const AddCustomModuleButtonPosition = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const AddCustomModuleButtonStyled = styled.div`
  width: 240px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #00BCA6;
  cursor: pointer;
  opacity: 0.9;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #11BBA6;
  line-height: 38px;
  text-align: center;
`


const requireElement = <RequireStyled>
    必填
</RequireStyled>;


export default withComponent((props) => {
    const {onClose, visible} = props;
    const store = useStore();
    const resumeData = useSelector(state => state.resumeData);
    const template = useSelector(state => state.template);
    const moduleConfig = useMemo(() => getModuleList().filter((module) => {
        if (module === Module.APPLICATION_INFO) return true
        return template?.moduleConfig?.[module] && module !== Module.CUSTOM_MODULE
    }), [template?.moduleConfig]);
    const customModuleConfig = !resumeData ? [] : Object.keys(resumeData).filter((key) => resumeData[key].type === Module.CUSTOM_MODULE);
    const config = useMemo(() => {
        try {
            const config1 = moduleConfig.map((id, index) => ({
                id: id,
                blockName: id,
                index,
                title: getTitleTextByModule(resumeData, id) || getTextByModule(id),
                require: id === Module.BASE,
                visible: getModuleVisible(resumeData, id)
            }));
            const config2 = customModuleConfig.map((id, index) => {
                return {
                    id: id,
                    index,
                    blockName: Module.CUSTOM_MODULE,
                    // title: "自定义模块",
                    title: getTitleTextByModule(resumeData, id) || "自定义模块",
                    visible: getModuleVisible(resumeData, id)
                }
            });
            return [...config1, ...config2]
        } catch (error) {
            console.log('error', error)
        }

        return []

    }, [moduleConfig, customModuleConfig])
    const visibleConfig = useMemo(() => config.filter((a) => a.visible), [resumeData]);
    const unVisibleConfig = useMemo(() => config.filter((a) => !a.visible), [resumeData]);
    const onChange = (item) => {
        store.dispatch(resumeDataSlice.actions.switchModuleVisible({
            id: item.id
        }));
    };
    const onClickAddCustom = () => {
        store.dispatch(resumeDataSlice.actions.addCustomModule());
    }
    const onClickAddCustom2 = () => {
        store.dispatch(resumeDataSlice.actions.addCustomModule({ type: 'score' }));
    }
    const cellRender = (item) => {
        return <CellStyled key={item.id} data-id={item.id}>
            <CellIconStyled src={getIconByModule(item.blockName)} width={20} height={20}></CellIconStyled>
            <CellTitleStyled>{item.title}</CellTitleStyled>
            {item.require ? <CellRequirePositionStyled>{requireElement}</CellRequirePositionStyled> :
                <CellSwitchPositionStyled>
                    <Switch size="small" checked={item.visible} onChange={() => onChange(item)}/>
                </CellSwitchPositionStyled>}
        </CellStyled>
    }

    return <>
        <Drawer className="ant-custom-drawer" width="auto" placement="left" onClose={onClose} open={visible}>
            <ContainerPositionStyled>
                <ContainerStyled>
                    {visibleConfig.length > 0 && <TitleStyled>已有模块</TitleStyled>}
                    {visibleConfig.map((item) => {
                        return cellRender(item)
                    })}
                    <div style={{padding: '0 16px'}}><Divider style={{marginTop: 6, marginBottom: 20}}/></div>
                    {unVisibleConfig.length > 0 && <TitleStyled>待添加模块</TitleStyled>}
                    {unVisibleConfig.map((item) => {
                        return cellRender(item)
                    })}

                    <AddCustomModuleButtonPosition>
                        <AddCustomModuleButtonStyled onClick={onClickAddCustom}>
                            添加自定义模块
                        </AddCustomModuleButtonStyled>
                    </AddCustomModuleButtonPosition>
                </ContainerStyled>
            </ContainerPositionStyled>
        </Drawer>
    </>
})
