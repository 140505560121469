export function parseMargin(margin) {
    // Split the margin property into parts

    let marginTop = 0;
    let marginRight = 0;
    let marginBottom = 0;
    let marginLeft = 0;
    if (!margin) {
        return {
            marginTop,
            marginRight,
            marginBottom,
            marginLeft
        };
    }
    const parts = margin.split(" ").map(part => part.trim());
    if (parts.length === 1) {
        // If there is only one part, it applies to all sides
        marginTop = marginRight = marginBottom = marginLeft = parts[0];
    } else if (parts.length === 2) {
        // If there are two parts, the first applies to top and bottom, the second to left and right
        marginTop = marginBottom = parts[0];
        marginRight = marginLeft = parts[1];
    } else if (parts.length === 3) {
        // If there are three parts, the first applies to top, the second to left and right, the third to bottom
        marginTop = parts[0];
        marginRight = marginLeft = parts[1];
        marginBottom = parts[2];
    } else if (parts.length === 4) {
        // If there are four parts, they apply to top, right, bottom, and left respectively
        marginTop = parts[0];
        marginRight = parts[1];
        marginBottom = parts[2];
        marginLeft = parts[3];
    }

    return {
        marginTop,
        marginRight,
        marginBottom,
        marginLeft
    };
}
