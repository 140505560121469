import withComponent from "@/hoc/withComponent"
import { forwardRef, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { ImportContent } from "@/components/ImportModal"
import ImportVideoModal from "@/components/ImportVideoModal";

const TabItemImportStyled = styled.div`
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    background: #FFFFFF;
    box-shadow: 0px 0 12px 0px rgba(0,0,0,0.05);
    border-radius: 8px;
    padding: 18px 0;
    padding-bottom: 17px;
    .video-tip {
        font-size: 10px;
        color: #B4B4B4;
        text-decoration-line: underline;
    }
    input {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        opacity: 0;
        left: 0;
        top: 0;
    }
`


const TabItemImport = ({ style }) => {
    const [visible, setVisible] = useState(false)

    return <TabItemImportStyled style={style}>
        <ImportContent isHome onClickVideo={() => setVisible(true)} style={{ marginBottom: 12 }} contentStyle={{ height: 80 }} />
        <ImportVideoModal isHome visible={visible} onCancel={() => setVisible(false)} />
    </TabItemImportStyled>
}

export default withComponent(TabItemImport)