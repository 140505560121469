import {Pagination} from "antd";
import withComponent from "@/hoc/withComponent";
import styled from "styled-components"

const ContainerStyled = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: center;

  .ant-pagination-prev {
    button {
      border: none !important;
      background-color: transparent;
      color: #000000;
    }
  }

  .ant-pagination-next {
    button {
      border: none !important;
      background-color: transparent;
      color: #000000;
    }
  }

  .ant-pagination-item {
    border: none !important;
    background-color: transparent;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;

    a {
      height: 100%;
      color: #000000 !important;
    }
  }

  .ant-pagination-item-ellipsis {
    color: #000000;
  }

  .ant-pagination-item-link-icon {
    color: #000000 !important;
  }

  .ant-pagination-item-active {
    border: none !important;
    background-color: #000000;
    border-radius: 50%;
    overflow: hidden;

    a {
      height: 100%;
      color: #ffffff !important;
    }
  }
`
export default withComponent((props) => {
    const {current, defaultCurrent, defaultPageSize, pageSize, total, onChange} = props;
    return <ContainerStyled>
        <Pagination responsive
                    showSizeChanger={false}
                    hideOnSinglePage
                    defaultCurrent={defaultCurrent}
                    current={current}
                    defaultPageSize={defaultPageSize}
                    pageSize={pageSize}
                    total={total}
                    onChange={onChange}
        />
    </ContainerStyled>
})
