import withComponent from "@/hoc/withComponent";
import useConfigRender from "@/components/resume/hooks/useConfigRender";
import {createElement, useCallback, useMemo, useRef, useState} from "react";
import {Popover} from "antd";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import getNestedProperty from "@/utils/getNestedProperty";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import withField from "@/components/resume/hoc/withField";
import StartAndEnd from "@/components/resume/components/StartAndEnd";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import useModuleContentInstance from "@/components/resume/hooks/useModuleContentInstance";
import getResumePath from "@/common/resume/getResumePath";
import {EDUCATIONAL_BACKGROUND_SLOT_ENUM, WORK_EXPERIENCE_SLOT_ENUM} from "@/common/slot/slot";
import {Module} from "@/common/module/module";
import useSelectedHightlight from "@/components/resume/hooks/useSelectedHighlight";
import focusSlice from "@/components/resume/redux/services/focusSlice";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";


export default withComponent(withField((props) => {
    const {tagName, style, className, children = [], fieldRef} = props;
    const [visible, setVisible] = useState(false);
    const fieldInstance = useFieldInstance();
    const moduleInstance = useModuleInstance();
    const moduleContentInstance = useModuleContentInstance();
    const configRender = useConfigRender();
    const cls = useSelectedHightlight({ visible, className })
    const element = createElement(tagName, {
        style,
        className: cls,
        ref:fieldRef
    }, children?.map?.((config) => configRender(config)));
    const dispatch = useDispatch();
    let start_slot_value;
    let end_slot_value;
    switch (fieldInstance.for_block_name) {
        case Module.EDUCATIONAL_BACKGROUND: {
            start_slot_value = EDUCATIONAL_BACKGROUND_SLOT_ENUM.START_DATE
            end_slot_value = EDUCATIONAL_BACKGROUND_SLOT_ENUM.END_DATE
            break
        }
        case Module.WORK_EXPERIENCE: {
            start_slot_value = WORK_EXPERIENCE_SLOT_ENUM.START_DATE
            end_slot_value = WORK_EXPERIENCE_SLOT_ENUM.END_DATE
            break
        }
        case Module.PROJECT_EXPERIENCE: {
            start_slot_value = WORK_EXPERIENCE_SLOT_ENUM.START_DATE
            end_slot_value = WORK_EXPERIENCE_SLOT_ENUM.END_DATE
            break
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            start_slot_value = WORK_EXPERIENCE_SLOT_ENUM.START_DATE
            end_slot_value = WORK_EXPERIENCE_SLOT_ENUM.END_DATE
            break
        }
        case Module.CAMPUS_ACTIVITIES: {
            start_slot_value = WORK_EXPERIENCE_SLOT_ENUM.START_DATE
            end_slot_value = WORK_EXPERIENCE_SLOT_ENUM.END_DATE
            break
        }
    }

    const onSuccess = ({start, end}) => {
        const START_DATE_path = getResumePath(fieldInstance.for_block_name, fieldInstance.for_field_name, start_slot_value, moduleContentInstance?.index);
        const END_DATE_path = getResumePath(fieldInstance.for_block_name, fieldInstance.for_field_name, end_slot_value, moduleContentInstance?.index);
        dispatch(resumeDataSlice.actions.changeValueByPath({path: START_DATE_path, value: start}))
        dispatch(resumeDataSlice.actions.changeValueByPath({path: END_DATE_path, value: end}))
        setVisible(false);
    }

    const handleOpenChange = (v) => {
        setVisible(v)

        // 处理聚焦失焦态
        // if (v) {
        //     dispatch(focusSlice.actions.setModuleId(moduleInstance.data.id));
        // } else {
        //     dispatch(focusSlice.actions.setModuleId(null));
        // }
    }

    const startPath = getResumePath(fieldInstance.for_block_name, fieldInstance.for_field_name, start_slot_value, moduleContentInstance?.index);
    const endPath = getResumePath(fieldInstance.for_block_name, fieldInstance.for_field_name, end_slot_value, moduleContentInstance?.index);
    const startDate = useSelector((state) => getNestedProperty(state.resumeData, startPath));
    const endDate = useSelector((state) => getNestedProperty(state.resumeData, endPath));
    return <Popover content={<StartAndEnd startDate={startDate} endDate={endDate} onSuccess={onSuccess}/>}
                    destroyTooltipOnHide
                    align={{
                        offset: [0, 2]
                    }}
                    overlayClassName="ant-popover-custom"
                    autoAdjustOverflow={false}
                    trigger="click"
                    transitionName=""
                    onOpenChange={handleOpenChange}
                    getPopupContainer={null}
                    placement="bottomLeft"
                    open={visible}
    >
        {element}
    </Popover>
}))
