import useSelector from "@/components/resume/redux/hooks/useSelector"
import useResumeDetailInstance from "@/pages/ResumeDetail/hooks/useResumeDetailInstance"
import { Switch, Tabs } from "antd-mobile"
import styled from "styled-components"

const ThemeTabStyled = styled.div`
    .theme-header {
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #C2C2C2;
        margin-bottom: 20px;
    }
`

const ColorListStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    row-gap: 12px;
    column-gap: 20px;
`

const ColorItemStyled = styled.div`
  width: 30px;
  height: 30px;
  background: #0A70BE;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.9;
  ${props => props.$active ? 'box-shadow: 0px 4px 10px 0px rgba(55, 182, 84, 0.5);' : ''}

`

const ThemeTab = () => {
    const theme = useSelector(state => state.theme)
    const themeIndex = useSelector(state => state.resume?.themeIndex);
    const resumeDetailInstance = useResumeDetailInstance();

    const onClickColor = (index) => {
        resumeDetailInstance.resumeRef.current?.changeThemeWithSave?.(index)
    }
    const onChange = () => {
        if (themeIndex !== undefined) {
            resumeDetailInstance.resumeRef.current?.changeThemeWithSave?.(undefined)
        }
    }

    return <ThemeTabStyled>
        <div className="theme-header flex justify-between">
            <div>颜色</div>
            <div className="flex-center">
                <span className="pr-10">初始状态</span>
                <Switch style={{
                    '--checked-color': '#11BBA6',
                    '--height': '16px',
                    '--width': '30px',
                    '--adm-color-background': '#DEE0E6',
                    '--border-width': '1px'
                }} checked={themeIndex === undefined} onChange={onChange} />
            </div>
        </div>
        <ColorListStyled>
            {theme?.colorList?.map?.((color, index) => {
                return <ColorItemStyled key={index}
                    $active={themeIndex === index}
                    onClick={() => onClickColor(index)}
                    style={{ background: color }} />
            })}
        </ColorListStyled>
    </ThemeTabStyled>
}

export default ThemeTab