import useStore from "@/components/resume/redux/hooks/useStore";
import {useCallback, useEffect, useMemo, useRef} from "react";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";
import aiDecisionSlice from "@/components/resume/redux/services/aiDecisionSlice";
import {useDispatch} from "@/components/resume/redux/hooks";
import getResumePath from "@/common/resume/getResumePath";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import {message} from "antd";

const list = ['resume', 'resumeData'];


export default () => {
    const store = useStore();
    const resumeInstance = useResumeInstance();
    const instance = useRef({
        unSubscribe: undefined, open: true, currentState: {}
    }).current;
    const dispatch = useDispatch();
    const handler = useCallback(() => {
        const {aiDecision} = store.getState();
        if (!aiDecision || aiDecision.length === 0) return;
        const flag = list.some((key) => {
            const state = store.getState();
            return state[key] !== instance.currentState?.[key];
        })
        if (instance.open && flag) {
            console.log('触发了这个位置1')
            message.success({
                content: "内容已保留！", className: "theme-message-default"
            })
            dispatch(aiDecisionSlice.actions.set([]));
        }
    }, []);

    const onClickSuccess = useCallback((currentAiDecision, disabledMsg = false) => {
        dispatch(aiDecisionSlice.actions.setStatusById({id: currentAiDecision.id, status: "success"}));
        if (!disabledMsg) {
            console.log('触发了这个位置2')
            message.success({
                content: "内容已保留！", className: "theme-message-default"
            })
        }
    }, [])
    const onClickCancel = useCallback((currentAiDecision, disabledMsg = false) => {
        instance.open = false;
        try {
            let originValue = currentAiDecision.originValue;
            let path;
            if (currentAiDecision.moduleContentIndex !== undefined && currentAiDecision.moduleContentIndex !== null) {
                path = getResumePath(currentAiDecision.blockName, undefined, undefined, currentAiDecision.moduleContentIndex);
            } else {
                path = getResumePath(currentAiDecision.blockName, undefined, currentAiDecision.slotName, undefined);
            }
            instance.open = false;
            dispatch(resumeDataSlice.actions.changeValueByPath({path: path, value: originValue}));
            dispatch(aiDecisionSlice.actions.setStatusById({id: currentAiDecision.id, status: "revocation"}));
            logState();
            instance.open = true;
            if (!disabledMsg) {
                message.success({
                    content: "内容已撤销！", className: "theme-message-dark"
                });
            }
        } catch (e) {

        }
        instance.open = true;
    }, [])

    const setAiDecision = useCallback((changePart) => {
        instance.open = false;
        try {
            dispatch(aiDecisionSlice.actions.set(changePart));
            logState();
        } catch (e) {

        }
        instance.open = true;
    }, []);
    const logState = useCallback(() => {
        const state = store.getState();
        list.forEach((key) => {
            instance.currentState[key] = state[key];
        });
    }, [])
    useMemo(() => {
        resumeInstance.aiDecision = instance;
        resumeInstance.aiDecision.setAiDecision = setAiDecision;
        resumeInstance.aiDecision.onClickCancel = onClickCancel;
        resumeInstance.aiDecision.onClickSuccess = onClickSuccess;
    }, []);
    const listenChange = useCallback(() => {
        instance.unSubscribe = store.subscribe(handler);
    }, []);
    const clearListenChange = useCallback(() => {
        instance.unSubscribe?.();
    }, []);

    useEffect(() => {
        listenChange()
        return () => {
            clearListenChange();
        }
    }, []);
}
