import withComponent from "@/hoc/withComponent"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { fetchResumeSearch, getDataFromTemplate } from "@/http/api"
import { Popup, Toast } from "antd-mobile"
import { Ellipsis } from "antd-mobile"
import RatioImg from "@/pages/Mobile/components/RatioImg"
import { PopupContentStyled, ResumeButton } from "../../Home/components/ResumeListStyled"
import getQuery from "@/utils/getQuery"
import strictStringify from "@/common/strictStringify"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { clearFilter } from "../../TemplateList/indexSlice"
import isLogin from "@/common/isLogin"
import errorSlice from "@/redux/services/errorSlice"
import store from "@/redux/store"

const CommandListStyled = styled.div`
    background: #F5F5F5;
    font-family: PingFangSC, PingFang SC;
    padding-top: 30px;
    .command-h1 {
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 14px;
        text-align: center;
    }

    .command-p {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin-bottom: 20px;
        text-align: center;
    }

    .resume-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 9px;
        padding: 0 10px;
        .resume-item {
            margin-bottom: 16px;
            position: relative;
            box-shadow: 0px 0px 2px 0px #C8C8C8;
            &-img {
                border: 1px solid #E2E2E2;
                margin-bottom: 8px;
            }
            &-title {
                padding-left: 2px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #343542;
            }
            &-mask {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 70px;
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.11) 40%, rgba(0,0,0,0.69) 100%);
            }
            &-btn {
                width: 109px;
                height: 32px;
                background: linear-gradient(144deg, #27DFD2 0%, #11BBA6 100%);
                border-radius: 16px;

                font-size: 12px;
                font-weight: 600;
                color: #FFFFFF;
            }
        }
    }

    .more-btn {
        background: #FFFFFF;
        border-radius: 16px;
        border: 1px solid #00BCA6;
        color: #11BBA6;
        width: 145px;
        height: 32px;
    }
`

const svg = <div className="flex-center pl-12" dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="移动端推广页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="推广页" transform="translate(-230.000000, -2104.000000)" fill="#11BBA6" fill-rule="nonzero">
            <g id="编组-11备份-2" transform="translate(119.000000, 2094.000000)">
                <g id="文本备份-15" transform="translate(111.000000, 10.000000)">
                    <path d="M7.7057857,1.39376887 C7.9173156,1.17934942 8.26037555,1.17010826 8.48314213,1.37282887 C8.70590872,1.57554948 8.72896206,1.91795869 8.53537983,2.14871077 L8.50282473,2.18463366 L4.68826333,6.02922433 L8.49945696,9.81010802 C8.70613357,10.0151516 8.72173196,10.3441804 8.53537983,10.5678564 L8.50282473,10.6040599 C8.29770637,10.8106526 7.96868119,10.8261289 7.74507636,10.6397021 L7.70887283,10.607147 L3.4991597,6.4311116 C3.29256705,6.22599325 3.27709067,5.89696807 3.46351745,5.67336324 L3.49607257,5.63715971 L7.7057857,1.39376887 L7.7057857,1.39376887 Z" id="路径" transform="translate(6.000000, 5.998276) scale(-1, 1) translate(-6.000000, -5.998276) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const CommandList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [dataList, setDataList] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const [selectTemplate, setSelectTemplate] = useState({})
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        fetchList()
    }, [])

    const fetchList = async () => {
        try {
            const res = await fetchResumeSearch({
                query: {
                    page: 1,
                    limit: 12,
                },
            })
            const { dataList } = res;
            setDataList(dataList)    
        } catch (error) {
            Toast.show("网络繁忙，请重试")
        }
    };

    const handleClickTemplate = (template) => {
        setVisible(true)
        setSelectTemplate(template)
    }

    const handleSelectTemplate = async () => {
        if (!isLogin()) {
            store.dispatch(errorSlice.actions.setCode(1009))
            return 
        }
        if (btnLoading) return 
        setBtnLoading(true)
        getDataFromTemplate({query: {resumeId: selectTemplate.id}}).then(res => {
            if (res) {
                const query = {
                    absolute_laout: getQuery().absolute_laout, id: res.id
                }
                navigate("/mobile/resume" + strictStringify(query), { state: { isTemplate:true } })
            } else {
                Toast.show("网络繁忙，请重试")
            }
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    const handleMore = () => {
        dispatch(clearFilter())
        navigate("/mobile/template")
        
    }

    return <CommandListStyled>
        <div className="command-h1">精选模板推荐</div>
        <div className="command-p">几十种颜色任意选，7种模块自由组合</div>

        <div className="resume-list">
            {
                dataList.map(item => {
                    return <div key={item.id} className="resume-item" onClick={() => handleClickTemplate(item)}>
                        <RatioImg className="resume-item-img" src={item.cover} ratio={[173, 245]} style={{ border: 'none', borderRadius: 0 }} />
                        {/* <Ellipsis className="resume-item-title" direction='end' content={item.name} /> */}
                        <div className="flex-center resume-item-mask">
                            <div className="flex-center resume-item-btn">在线制作</div>
                        </div>
                    </div>
                })
            }
        </div>
        
        <div onClick={handleMore} className="flex-center" style={{ paddingTop: 24, paddingBottom: 40 }}>
            <div className="more-btn flex-center">
                更多简历模板
                {svg}
            </div>
        </div>

        <Popup 
            visible={visible}
            onMaskClick={() => setVisible(false)}
            onClose={() => setVisible(false)}
            showCloseButton
            bodyStyle={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                minHeight: '40vh',
            }}
        >
            <PopupContentStyled style={{ padding: 20 }}>
                <Ellipsis className="resume-title" direction='end' content={selectTemplate.name} />
                <img style={{ width: '100%' }} src={selectTemplate.cover} /> 

                <ResumeButton $loading={btnLoading} onClick={handleSelectTemplate} className="resume-btn">使用此模板</ResumeButton>
            </PopupContentStyled>
        </Popup>
    </CommandListStyled>
}

export default withComponent(CommandList)