import withComponent from "../../hoc/withComponent";
import styled from "styled-components"
import {useMemo, useState} from "react";
import FlexStyled from "../../styled/FlexStyled"
import useQuery from "@/hooks/useQuery";
import getQuery from "@/utils/getQuery";
import strictStringify from "@/common/strictStringify";

const ContainerStyled = styled.div`
  position: relative;
  background: #FEFFFF;
  border-radius: 8px;
  cursor: pointer;
  padding-bottom: 12px;
  overflow: hidden;


  .template-container-menu-box {
    visibility: hidden;
  }

  &:hover .template-container-menu-box {
    visibility: visible;
  }


  &:hover {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    transform: scale(1.044);
  }


`
const DownButtonStyled = styled.div`
  width: 146px;
  height: 44px;
  background: #00BCA6;
  border-radius: 4px;


  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 44px;
  text-align: center;
`
const CollectStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  overflow: hidden;
`
const MenuPositionStyled = styled.div`
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ImageContainerStyled = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:hover::before {
    opacity: 0.3;
  }
`
const TitleStyled = styled.div`
  padding: 14px 12px 20px 12px;
  height: 13px;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #383835;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const InfoContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
`
const InfoTextStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
`
const icon1 = <div style={{width: 16, height: 16}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="简历首页" transform="translate(-632.000000, -1050.000000)">
            <g id="编组-10备份" transform="translate(620.000000, 688.000000)">
                <g id="下载" transform="translate(12.000000, 362.000000)">
                    <rect id="矩形" x="0" y="0" width="16" height="16"></rect>
                    <g id="24px" transform="translate(0.800000, 0.800000)" fill="#666666" fill-rule="nonzero">
                        <path d="M4.32094912,9.55847661 C4.32094912,9.96450493 3.99875768,10.2936581 3.60131439,10.2936581 L2.88167964,10.2936581 C1.4230882,10.2951778 0.193916442,9.18191963 0.0205955149,7.7023784 C-0.152725413,6.22283717 0.784592072,4.84470293 2.20234444,4.49456147 C2.48981144,2.19742922 4.24359118,0.379290788 6.48769455,0.0519512866 C8.73179792,-0.275388215 10.9135627,0.968686116 11.8188235,3.09183934 C13.5352409,3.60868522 14.6190619,5.33553864 14.3625321,7.14473361 C14.1060023,8.95392858 12.5870857,10.2956185 10.7976618,10.2936581 C10.4002186,10.2934529 10.0781882,9.96413734 10.0783869,9.55810902 C10.0785856,9.1520807 10.4009382,8.82309428 10.7983815,8.82329729 C11.8726362,8.82556343 12.7850869,8.02059339 12.9393642,6.93450816 C13.0936416,5.84842292 12.4428027,4.81169206 11.4122299,4.50191327 L10.7688765,4.30782593 L10.5004527,3.67998278 C9.85427092,2.16312906 8.29603215,1.27394126 6.6929214,1.50726673 C5.08981066,1.74059221 3.83664012,3.03896785 3.63081941,4.67982666 L3.5063226,5.68555197 L2.54201203,5.92375007 C1.83818409,6.10378232 1.37522027,6.7904242 1.46143612,7.52639943 C1.54765198,8.26237465 2.15614358,8.81801983 2.88167964,8.82329729 L3.60131439,8.82329729 C3.99875768,8.8232973 4.32094912,9.15244829 4.32094912,9.55847661 Z M9.74699512,11.0633887 C10.0279275,11.3504761 10.0279275,11.8158448 9.74699512,12.1029322 L7.70755025,14.18496 C7.42617306,14.47168 6.97064427,14.47168 6.68926708,14.18496 L4.64982221,12.1029322 C4.37710093,11.8144633 4.38100137,11.3559279 4.6585887,11.0723445 C4.93617603,10.7887611 5.38501622,10.7847764 5.66738575,11.0633887 L6.47985336,11.8934061 L6.47985336,5.88258003 C6.47985336,5.4765517 6.80204482,5.14740071 7.19948812,5.14740071 C7.59693142,5.14740071 7.91912287,5.4765517 7.91912287,5.88258003 L7.91912287,11.8912006 L8.72943158,11.0633887 C9.01044885,10.776388 9.46597786,10.776388 9.74699512,11.0633887 Z" id="形状"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`
}}/>
const icon2 = <div style={{width: 16, height: 16}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="简历首页" transform="translate(-770.000000, -1050.000000)">
            <g id="编组-10备份" transform="translate(620.000000, 688.000000)">
                <g id="编组-4备份-18" transform="translate(12.000000, 362.000000)">
                    <g id="下载备份" transform="translate(138.000000, 0.000000)">
                        <rect id="矩形备份-5" x="0" y="0" width="16" height="16"></rect>
                        <g id="shoucang-2" transform="translate(1.600000, 2.000000)" fill="#666666" fill-rule="nonzero">
                            <path d="M6.01125458,9.4275686 C6.25867146,9.31961671 6.54132854,9.31961671 6.78874542,9.4275686 L9.39098564,10.5625391 L9.0919507,7.79062189 C9.0633863,7.52716718 9.15058418,7.26424611 9.33181489,7.06737776 L11.2395942,4.99692025 L8.4522068,4.41869839 C8.18723906,4.36378727 7.9586009,4.20132549 7.82327906,3.97180571 L6.4,1.5568425 L4.97672094,3.97180571 C4.8413991,4.20132549 4.61276094,4.36378727 4.3477932,4.41869839 L1.56040582,4.99692025 L3.46818511,7.06737776 C3.64953057,7.26419431 3.7368461,7.52712143 3.70836742,7.79062189 L3.40901436,10.5625391 L6.01125458,9.4275686 Z M3.42332986,11.9190886 C3.11176422,12.0549737 2.74974956,12.0178443 2.47376849,11.8216984 C2.19778742,11.6255525 2.04981027,11.3002194 2.08562569,10.9683525 L2.42792526,7.79746844 L0.245606451,5.42918622 C0.0170902774,5.18126563 -0.0587252317,4.83285188 0.0467599101,4.51537757 C0.152245052,4.19790326 0.422977675,3.95968294 0.756828948,3.89058079 L3.9450504,3.22957797 L5.57320021,0.467308199 C5.74360438,0.178164454 6.05882747,0 6.4,0 C6.74117253,0 7.05639562,0.178164454 7.22679979,0.467308199 L8.8549496,3.22957797 L12.0431711,3.89058079 C12.3770223,3.95968294 12.6477549,4.19790326 12.7532401,4.51537757 C12.8587252,4.83285188 12.7829097,5.18126563 12.5543935,5.42918622 L10.3720747,7.79746844 L10.7143743,10.9683525 C10.7501897,11.3002194 10.6022126,11.6255525 10.3262315,11.8216984 C10.0502504,12.0178443 9.68823578,12.0549737 9.37667014,11.9190886 L6.4,10.6210459 L3.42332986,11.9190886 Z" id="形状"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`
}}/>
const collectImage = `https://oss.jianzeppt.com/user/12426/2bf3e0a1-cb39-4336-9290-18491df63ef6.png`
const collectImage2 = `https://oss.jianzeppt.com/user/12426/0eba26e8-403c-4f85-afc9-968b5f62002e.png`


export default withComponent((props) => {
    const {style, item, width} = props;
    const [hover, setHover] = useState(false);
    const isCollect = false;

    const onClickCollect = (e) => {
        e.stopPropagation()
    }
    const onClick = () => {
      if (props.onClick) {
        props.onClick()
      } else {
        const query = {
            absolute_laout: getQuery().absolute_laout, id: item.id, create_flag: "1"
        }
        window.open("/resume" + strictStringify(query))
      }
    }
    const imgHeight = width * (300 / 212);
    const src = useMemo(() => item.cover + `?x-oss-process=image/resize,,h_${Math.floor(imgHeight)},w_${Math.floor(width)}`, [item])
    return <ContainerStyled $isHover={hover}
                            onClick={onClick}
                            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
                            style={{...style, width}}>
        <ImageContainerStyled>
            <MenuPositionStyled className="template-container-menu-box">
                <DownButtonStyled style={{marginRight: 8}}>{props.text || "使用模板"}</DownButtonStyled>
                {/* <CollectStyled onClick={onClickCollect}>
                    <img width={26} height={24} src={isCollect ? collectImage2 : collectImage}/>
                </CollectStyled> */}
            </MenuPositionStyled>
            <img width={width} height={imgHeight}
                 src={src}/>
        </ImageContainerStyled>
        <TitleStyled>
            {item.name}
        </TitleStyled>
        <InfoContainerStyled>
            <FlexStyled $justifyContent="center" $alignItems="center">
                <div style={{marginRight: 6}}>{icon1}</div>
                <InfoTextStyled>{item.likes}</InfoTextStyled>
            </FlexStyled>
            
            <FlexStyled $justifyContent="center" $alignItems="center">
                <div>风格：{item.style}</div>
            </FlexStyled>
            
        </InfoContainerStyled>

    </ContainerStyled>
})
