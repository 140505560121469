import styled from "styled-components";
import BasicInfo from "./components/BasicInfo";
import JobInterest from "./components/JobInterest";
import JzButton from "@/components/JzButton";
import useResumeData from "@/pages/Mobile/hooks/useResumeData";
import { Module } from "@/common/module/module";
import EducationalBackground from "./components/EducationalBackground";
import WorkExperience from "./components/WorkExperience";
import SchoolExperience from "./components/SchoolExperience";
import ProjectExperience from "./components/ProjectExperience";
import InternshipExperience from "./components/InternshipExperience";
import { useNavigate, useLocation } from "react-router-dom";
import useQueryParam from "@/hooks/useQueryParam";
import SingleField from "./components/SingleField";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import Portfolio from "./components/Portfolio";
import usePreScroll from "../../hooks/usePreScroll";
import PcTip from "./components/PcTip";
import { useSelector, useDispatch } from "react-redux";
import MobileLayout from "../../layout";
import { listResumeUser } from "@/http/api";
import NavigationHeader from "../../components/NaviGationHeader";
const editSvg = (
  <div
    className="flex-center"
    style={{
      transform: "translate(3px,1px)",
    }}
    dangerouslySetInnerHTML={{
      __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="底部加改简历模块" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="改简历" transform="translate(-254.000000, -102.000000)" fill="#969FB2" fill-rule="nonzero">
            <g id="编组-6" transform="translate(245.000000, 99.000000)">
                <g id="编辑" transform="translate(9.000000, 3.000000)">
                    <g id="jianli" transform="translate(1.749549, 1.608771)">
                        <path d="M9.25812222,8.73992 C9.86326444,8.11205111 9.85099111,7.11431778 9.23065111,6.50144444 C8.61024889,5.88864889 7.61237556,5.88864889 6.99197333,6.50144444 C6.37166444,7.11431778 6.35939111,8.11205111 6.96451778,8.73992 C6.20150222,9.15743111 5.72565778,9.95659778 5.72222,10.8263711 C5.72222,10.9319912 5.76419278,11.0332823 5.83888667,11.1079578 C5.91352327,11.1826547 6.01480078,11.2245623 6.12039556,11.2245623 L10.10226,11.2245623 C10.207877,11.2245623 10.3091776,11.1826537 10.3838601,11.1079712 C10.4585426,11.0332887 10.5004511,10.9319881 10.5004511,10.8263711 C10.4970444,9.95659778 10.0211378,9.15743111 9.25812222,8.73992 L9.25812222,8.73992 Z M8.11130444,6.84458444 C8.55112222,6.84458444 8.90768667,7.20108667 8.90768667,7.64093556 C8.90768667,8.08073778 8.55112222,8.43730222 8.11130444,8.43730222 C7.67151778,8.43730222 7.31495333,8.08073778 7.31495333,7.64093556 C7.31495333,7.20108667 7.67151778,6.84458444 8.11130444,6.84458444 Z M6.56634222,10.4282111 C6.74821778,9.72399556 7.38398889,9.23242444 8.11130444,9.23369752 C8.83866667,9.23242444 9.47443778,9.72399556 9.65629778,10.4282111 L6.56634222,10.4282111 L6.56634222,10.4282111 Z" id="形状"></path>
                        <path d="M8.3209,0 L0.66976,0 C0.314782222,0 0.024468889,0.275208889 0,0.623326667 L0,10.1151089 C0.0246638418,10.4664004 0.316872592,10.7386845 0.669028889,10.7386845 L5.1016,10.7386845 C5.12844889,9.90509333 5.49927778,9.12904222 6.10988,8.58581111 C5.96616222,8.28403333 5.89232,7.95102 5.89653556,7.60742889 C5.90373778,7.021 6.13744444,6.47103333 6.55466,6.05882667 C6.97198444,5.64662 7.52479778,5.41963333 8.11132,5.41963333 C8.41899333,5.41963333 8.71736444,5.48219778 8.99179556,5.60108889 L8.99179556,0.670895556 C8.99179556,0.299802222 8.6912,0 8.3209,0 Z M4.30829778,6.53272667 L2.00775556,6.53272667 C1.87387139,6.53270756 1.75016652,6.46126684 1.68323556,6.34531333 C1.61625268,6.22935979 1.61625268,6.08647132 1.68323556,5.97051778 C1.75015168,5.85455147 1.87386772,5.78312 2.00775556,5.78312 L4.30829778,5.78312 C4.51534222,5.78312 4.68309333,5.95088667 4.68309333,6.15791556 C4.68309333,6.36489778 4.51532667,6.53272667 4.30829778,6.53272667 L4.30829778,6.53272667 Z M5.70403556,4.23895111 L2.00856444,4.23895111 C1.80155111,4.23895111 1.63375333,4.07116889 1.63375333,3.86415556 C1.63375333,3.65718889 1.80155111,3.48936 2.00856444,3.48936 L5.70403556,3.48936 C5.91106444,3.48936 6.07883111,3.65718889 6.07883111,3.86415556 C6.07883111,4.07116889 5.91106444,4.23895111 5.70403556,4.23895111 Z M5.70403556,2.24880444 L2.00856444,2.24880444 C1.80155111,2.24880444 1.63375333,2.08097556 1.63375333,1.87394667 C1.63375333,1.66699556 1.80155111,1.49916667 2.00856444,1.49916667 L5.70403556,1.49916667 C5.91106444,1.49916667 6.07883111,1.66699556 6.07883111,1.87394667 C6.07883111,2.08097556 5.91106444,2.24880444 5.70403556,2.24880444 Z" id="形状"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`,
    }}
  />
);

const ResumeWrapStyled = styled.div`
  padding: 10px;
  background-color: #f7f8f9;
  min-height: calc(100vh);
  padding-bottom: 72px;
  .resume-wrap-footer {
    position: fixed;
    bottom: 70px;
    left: 0;
    right: 0;
    padding: 8px 12px;
    padding-bottom: 24px;
    background-color: #ffffff;
  }
`;
const rightSvg = (
  <div
    style={{
      transform: "translate(0,2px)",
    }}
    dangerouslySetInnerHTML={{
      __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="点击导入简历" transform="translate(-321.000000, -227.000000)" fill="#999999" fill-rule="nonzero">
            <g id="返回" transform="translate(321.000000, 227.000000)">
                <path d="M8.99008332,1.62606369 C9.23686819,1.37590766 9.6371048,1.3651263 9.89699915,1.60163368 C10.1568935,1.83814105 10.1837891,2.23761848 9.95794314,2.50682923 L9.91996218,2.54873926 L5.46964055,7.03409505 L9.91603312,11.445126 C10.1571558,11.6843435 10.175354,12.0682104 9.95794314,12.3291658 L9.91996218,12.3714032 C9.68065744,12.612428 9.29679472,12.6304838 9.03592242,12.4129858 L8.99368497,12.3750049 L4.08235298,7.50296354 C3.84132822,7.2636588 3.82327245,6.87979608 4.04077036,6.61892378 L4.07875134,6.57668633 L8.99008332,1.62606369 L8.99008332,1.62606369 Z" id="路径" transform="translate(7.000000, 6.997989) scale(-1, 1) translate(-7.000000, -6.997989) "></path>
            </g>
        </g>
    </g>
</svg>
`,
    }}
  />
);
const MyStyled = styled.div`
  background-color: rgba(247, 248, 249, 1);
  max-height: calc(100vh - 80px);
  overflow: hidden;
  box-sizing: border-box;
  .my-title {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-bottom: 15px;
  }
`;

const BoxStyled = styled.div`
  position: absolute;
  left: calc(50% - 132px);
  top: calc(50% - 67px);
  width: 265px;
  height: 134px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  & > div:nth-child(1) {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #353336;
    margin: 36px 0 0;
  }
  .t-btn {
    margin: 23px 0 0;
    width: 124px;
    height: 36px;
    background: #11bba6;
    border-radius: 9px;

    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
  }
`;

const moduleList = [
  {
    moduleName: Module.BASE,
    Component: BasicInfo,
  },
  {
    moduleName: Module.JOB_INTEREST,
    Component: JobInterest,
  },
  {
    moduleName: Module.EDUCATIONAL_BACKGROUND,
    Component: EducationalBackground,
  },
  {
    moduleName: Module.WORK_EXPERIENCE,
    Component: WorkExperience,
  },
  {
    moduleName: Module.CAMPUS_ACTIVITIES, // 校园活动
    Component: SchoolExperience,
  },
  {
    moduleName: Module.PROJECT_EXPERIENCE, // 项目经历
    Component: ProjectExperience,
  },
  {
    moduleName: Module.INTERNSHIP_EXPERIENCE, // 实习经历
    Component: InternshipExperience,
  },
  {
    moduleName: Module.PORTFOLIO, // 作品展示
    Component: Portfolio,
  },
  {
    moduleName: Module.SKILLS, // 技能特长
    Component: SingleField,
  },
  {
    moduleName: Module.SELF_EVALUATION, // 自我评价
    Component: SingleField,
  },
  {
    moduleName: Module.INTEREST, // 兴趣爱好
    Component: SingleField,
  },
  {
    moduleName: Module.LANGUAGE_PROFICIENCY, // 语言能力
    Component: SingleField,
  },
  {
    moduleName: Module.HONORS_AND_AWARDS, // 荣誉证书
    Component: SingleField,
  },
];

const Resume = ({}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClickOne = () => {
    navigate("/mobile/index");
  };
  const total = useSelector((state) => state.mobile.resumeUserCount);

  const renderDom = (
    <MobileLayout>
      <MyStyled>
        <img
          style={{ width: "100%", height: "100%" }}
          src="https://pub.jianzeppt.cn/image/%E6%94%B9%E7%AE%80%E5%8E%86%E5%A4%87%E4%BB%BD.png"
        ></img>

        {!loading && (
          <BoxStyled>
            <div>暂无简历，请新建简历</div>
            <div onClick={handleClickOne} className="flex-center t-btn">
              新建简历
            </div>
          </BoxStyled>
        )}
      </MyStyled>
    </MobileLayout>
  );
  const { state } = useLocation();
  let resumeId = useQueryParam("id");
  console.log(resumeId)
  
  if(!resumeId && total === 0){
    return renderDom
  }
  const navigatorUrl = state?.navigatorUrl;
  const isTemplate = state?.isTemplate;

  const { data: resumeData, template } = useResumeData();
  const resumeName = useSelector(
    (state) => state.mobileEdit.data?.resume?.name
  );
  const [visible, setVisible] = useState(true);

  usePreScroll();
  const visibleModuleList = useMemo(() => {
    if (!template || !template.moduleConfig) return moduleList;
    const moduleConfig = template.moduleConfig;
   
    let list = [];
    if (!isEmpty(resumeData)) {
      const customModuleList = Object.keys(resumeData)
        .filter((key) => {
          return (
            resumeData[key].type === Module.CUSTOM_MODULE &&
            resumeData[key].visible !== false
          );
        })
        .map((key) => ({
          sort: resumeData[key].sort,
          customId: key,
          moduleName: Module.CUSTOM_MODULE,
          Component: SingleField,
        }));
      list = moduleList
        .filter(
          (item) =>
            moduleConfig[item.moduleName] &&
            resumeData[item.moduleName]?.visible !== false
        )
        .map((item) => ({ ...item, sort: resumeData?.[item.moduleName]?.sort }))
        .concat(customModuleList);
    } else {
      list = moduleList
        .filter((item) => moduleConfig[item.moduleName])
        .map((item) => ({
          ...item,
          sort: resumeData?.[item.moduleName]?.sort,
        }));
    }

    // 排序
    list.sort((a, b) => a.sort - b.sort);
    return list;
  }, [template, resumeData]);

  const handleClickModuleManage = () => {
    navigate(`/mobile/resume/module_manage?id=${resumeId}`);
  };

 
  const handleClickPreview = () => {
    navigate(`/mobile/resume/preview?id=${resumeId}`);
  };
  const onBack = navigatorUrl
    ? () => {
        navigate(navigatorUrl);
      }
    : null;
  if (__DEV__) {
    console.log("resumeData", resumeData, isTemplate, state);
  }

  const clickMore = () => {
    navigate("/mobile/my_list");
  };
  // 测试不想闪一下
  if (JSON.stringify(resumeData) === "{}") {
    return null;
  }
  return resumeId ? (
    <MobileLayout>
      <ResumeWrapStyled>
        <PcTip visible={visible} onClose={() => setVisible(false)} />
        {resumeData &&
          visibleModuleList.map((moduleItem, index) => {
            const { moduleName, Component, customId } = moduleItem;
            let data = resumeData[moduleName]?.data;
            if (moduleName === Module.CUSTOM_MODULE) {
              data = resumeData[customId]?.data;
            }
            const titleShow = index === 0;

            return (
              <div
                key={moduleName}
                style={{ marginBottom: 8, position: "relative" }}
              >
                <div
                  onClick={clickMore}
                  style={{
                    position: "absolute",
                    background: "#F4F4F6",
                    borderRadius: "11px",
                    zIndex: 10,
                    top: 14,
                    right: "17px",
                    display: titleShow ? "flex" : "none",
                    width: "110px",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  {editSvg}
                  <div
                    style={{
                      fontFamily: "PingFangSC, PingFang SC",
                      fontWeight: 400,
                      fontWize: "12px",
                      color: "#969FB2",
                      background: "#F4F4F6",
                      lineHeight: "20px",
                      height: "20px",
                      textAlign: "center",
                    }}
                  >
                    我的简历({total})
                  </div>
                  {rightSvg}
                </div>
                <Component
                  customId={customId}
                  moduleName={moduleName}
                  data={data}
                />
              </div>
            );
          })}

        <div className="resume-wrap-footer flex">
          <JzButton
            onClick={handleClickModuleManage}
            className="flex-1"
            style={{ marginRight: 8, backgroundColor: "#F3F4F5" }}
          >
            模块管理
          </JzButton>
          <JzButton
            onClick={handleClickPreview}
            className="flex-2"
            $type="primary"
          >
            预览简历
          </JzButton>
        </div>
      </ResumeWrapStyled>
    </MobileLayout>
  ) : (
    <MobileLayout>
      <MyStyled>
        <img
          style={{ width: "100%", height: "100%" }}
          src="https://pub.jianzeppt.cn/image/%E6%94%B9%E7%AE%80%E5%8E%86%E5%A4%87%E4%BB%BD.png"
        ></img>

        {!loading && (
          <BoxStyled>
            <div>暂无简历，请新建简历</div>
            <div onClick={handleClickOne} className="flex-center t-btn">
              新建简历
            </div>
          </BoxStyled>
        )}
      </MyStyled>
    </MobileLayout>
  );
};

export default Resume;
