import styled from "styled-components";

export default styled.div`
  cursor: pointer;
  background: #11BBA6;

  :hover {
    background: #0D9584;
    color: white;
  }

  :active {
    background: #0FA795;
    color: white;
  }
`;