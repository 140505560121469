import {useEffect, useState} from "react";
import classNames from "classnames";


const useSelectedHightlight = (props) => {
    const { visible, className } = props
    const [cls, setCls] = useState(className)

    useEffect(() => {
        const newCls = classNames({
            [className]: className,
            'resume-module-active': visible,
            'resume-module-border': true
        })
        setCls(newCls)
    }, [visible, className])

    return cls
}

export default useSelectedHightlight