import { createSlice } from '@reduxjs/toolkit';
import initState from '../initState';
import { saveResumeData } from '../thunk/edit';

export default createSlice({
    name: 'error',
    initialState: initState.error,
    reducers: {
        setCode(state, {payload}) {
            state.code = payload

        },
    },
});
