import withComponent from "@/hoc/withComponent";
import styled from "styled-components";
import {useState} from "react";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import {Module} from "@/common/module/module";
import getNestedProperty from "@/utils/getNestedProperty";


const ContainerStyled = styled.div`
  width: 282px;
  height: 325px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #E0E0E0;
  padding: 15px 0;
  box-sizing: border-box;
`
const LayoutBoxPositionStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;
const LayoutBoxStyled = styled.div`
  position: relative;
  width: 240px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${props => props.$isActive ? '#11BBA6' : 'transparent'};
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`;

const Layout1BoxStyled = styled.div`
  width: 224px;
  height: 130px;
  border: 1px solid #E9E9E9;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Layout2BoxStyled = styled.div`
  width: 113px;
  height: 68px;
  border: 1px solid #E9E9E9;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Layout3BoxStyled = styled.div`
  width: 74px;
  height: 46px;
  border: 1px solid #E9E9E9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TriangleStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 38px solid transparent;
  border-bottom: 38px solid transparent;
  border-right: 38px solid #11BBA6;
  transform: translateY(-50%);
`
const SuccessStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
const MarkStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
`
const ReplaceButtonStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 48px;
  height: 24px;
  background: #11BBA6;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;

`


const icon1 = 'https://mzgf.oss.mzgf.com/upload/2023-07/13110921_gwh0q2.png';
const successIcon = <div dangerouslySetInnerHTML={{
    __html: `<svg width="20px" height="21px" viewBox="0 0 20 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="作品展示、自定义展示" transform="translate(-1135.000000, -1149.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-4" transform="translate(895.000000, 963.000000)">
                <g id="设置备份-2" transform="translate(240.000000, 186.300000)">
                    <path d="M8.14377475,14.2348485 C7.89809638,14.2272306 7.66494455,14.1247374 7.49334188,13.9489193 L3.61025753,10.0888746 C3.24102527,9.70625634 3.24102527,9.10033217 3.61025753,8.71771393 C3.79096863,8.53596033 4.03679197,8.4337501 4.29321206,8.4337501 C4.54963215,8.4337501 4.7954555,8.53596033 4.9761666,8.71771393 L8.14377475,11.9019259 L15.0230387,6.03619208 C15.2017539,5.85319119 15.4468315,5.75 15.702741,5.75 C15.9586505,5.75 16.2037282,5.85319119 16.3824434,6.03619208 C16.5643631,6.21673822 16.6666667,6.46233718 16.6666667,6.71852321 C16.6666667,6.97470924 16.5643631,7.2203082 16.3824434,7.40085434 L8.82672929,13.9489193 C8.64593367,14.1307886 8.4003336,14.2336127 8.14377475,14.2348485 Z" id="路径"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>


export default withComponent(() => {
    const dispatch = useDispatch();
    const [hover, setHover] = useState("");
    const imgLayoutType = useSelector(state => getNestedProperty(state.resumeData, `[${Module.PORTFOLIO}].data.imgLayoutType`));
    const onClickItem = (type) => {
        dispatch(resumeDataSlice.actions.changeValueByPath({
            path: `[${Module.PORTFOLIO}].data.imgLayoutType`,
            value: type
        }))
    }
    return <ContainerStyled>
        <LayoutBoxPositionStyled>
            <LayoutBoxStyled onClick={() => onClickItem(1)} $isActive={imgLayoutType === 1 } onMouseEnter={() => setHover('1')}
                             onMouseLeave={() => setHover('')}>
                {
                    hover === "1" && <MarkStyled/>
                }
                {
                    hover === "1" && <ReplaceButtonStyled>
                        更换
                    </ReplaceButtonStyled>
                }
                {
                    imgLayoutType === 1 && <>
                        <TriangleStyled/>
                        <SuccessStyled>
                            {successIcon}
                        </SuccessStyled>
                    </>
                }
                <Layout1BoxStyled>
                    <img src={icon1} width={228} height={133}/>
                </Layout1BoxStyled>
            </LayoutBoxStyled>
        </LayoutBoxPositionStyled>
        <LayoutBoxPositionStyled>
            <LayoutBoxStyled onClick={() => onClickItem(2)} $isActive={imgLayoutType === 2 } onMouseEnter={() => setHover('2')}
                             onMouseLeave={() => setHover('')}>
                {
                    hover === "2" && <MarkStyled/>
                }
                {
                    hover === "2" && <ReplaceButtonStyled>
                        更换
                    </ReplaceButtonStyled>
                }
                {
                    imgLayoutType === 2 && <>
                        <TriangleStyled/>
                        <SuccessStyled>
                            {successIcon}
                        </SuccessStyled>
                    </>
                }
                <Layout2BoxStyled>
                    <img src={icon1} width={101} height={57}/>
                </Layout2BoxStyled>
                <Layout2BoxStyled>
                    <img src={icon1} width={101} height={57}/>
                </Layout2BoxStyled>
            </LayoutBoxStyled>
        </LayoutBoxPositionStyled>
        <LayoutBoxPositionStyled>
            <LayoutBoxStyled onClick={() => onClickItem(3)} $isActive={imgLayoutType === 3 } onMouseEnter={() => setHover('3')}
                             onMouseLeave={() => setHover('')}>
                {
                    hover === "3" && <MarkStyled/>
                }
                {
                    hover === "3" && <ReplaceButtonStyled>
                        更换
                    </ReplaceButtonStyled>
                }
                {
                    imgLayoutType === 3 && <>
                        <TriangleStyled/>
                        <SuccessStyled>
                            {successIcon}
                        </SuccessStyled>
                    </>
                }
                <Layout3BoxStyled>
                    <img src={icon1} width={62} height={35}/>
                </Layout3BoxStyled>
                <Layout3BoxStyled>
                    <img src={icon1} width={62} height={35}/>
                </Layout3BoxStyled>
                <Layout3BoxStyled>
                    <img src={icon1} width={62} height={35}/>
                </Layout3BoxStyled>
            </LayoutBoxStyled>
        </LayoutBoxPositionStyled>
    </ContainerStyled>
})
