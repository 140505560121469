import {createSlice} from '@reduxjs/toolkit';
import initState from '../initState';


export default createSlice({
    name: 'aiDecision',
    initialState: initState.aiDecision,
    reducers: {
        set(state, {payload}) {
            return payload
        },
        clear() {
            return null
        },
        setStatusById(state, {payload}) {
            const {id, status} = payload;
            const index = state.findIndex((item) => item.id === id);
            if (index !== -1) {
                state[index].status = status
            }
        },
        deleteById(state, {payload}) {
            const index = state.findIndex((item) => item.id === payload);
            if (index !== -1) {
                state.splice(index, 1, 0)
            }
        }
    }
});
