import ModuleBox from "../ModuleBox"
import useQueryParam from "@/hooks/useQueryParam";
import { useNavigate } from "react-router-dom";
import useQuerySlot from "@/pages/Mobile/hooks/useQuerySlot";
import { EDUCATIONAL_BACKGROUND_SLOT_ENUM, JOB_INTEREST_SLOT_ENUM } from "@/common/slot/slot";
import moment from "moment";
import { ContentItem, TargetItem, TargetItemDate, TargetItemDetail } from '../../styled'

const EducationalBackground = ({ data = {}, moduleName }) => {
    const resumeId = useQueryParam('id')
    const navigate = useNavigate()
    const title = useQuerySlot({
        moduleName,
        slotName: EDUCATIONAL_BACKGROUND_SLOT_ENUM.TITLE_TEXT
    })

    const contentList = data['content'] || []
    

    const handleClickContent = (index) => {
        navigate(`/mobile/edit/general?id=${resumeId}&name=${moduleName}&contentIndex=${index}`)
    }


    return <ModuleBox 
        name={moduleName} 
        title={title || "求职意向"}
        addIndex={contentList.length}
    >
        {
            contentList.map((content = {}, index) => {
                return <ContentItem className="content-item" key={index} onClick={() => handleClickContent(index)}>
                    <TargetItem>{content.school}</TargetItem>
                    <TargetItemDate>
                        <div>{content.major}</div>
                        <div>
                            {moment(content.start_date).format("YYYY.MM")}-{moment(content.end_date).format("YYYY.MM")}
                        </div>
                    </TargetItemDate>
                    <TargetItemDetail>
                        {content.details}
                    </TargetItemDetail>
                </ContentItem>
            })
        }

    </ModuleBox>
}

export default EducationalBackground
