import styled from 'styled-components';
import {useState} from "react";
import {color} from "@/const/themeConfig";
import {Button, message} from "antd";
import useForceUpdate from "@/hooks/useForceUpdate";
import {useResumeDispatch, useResumeStore, useSelector} from "@/components/resume/redux/hooks";
import getResumePath from "@/common/resume/getResumePath";
import {Module} from "@/common/module/module";
import {BASE_FIELD_ENUM, EDUCATIONAL_BACKGROUND_FIELD_ENUM, JOB_INTEREST_FIELD_ENUM} from "@/common/field/field";
import {BASE_Slot, EDUCATIONAL_BACKGROUND_SLOT_ENUM, JOB_INTEREST_SLOT_ENUM} from "@/common/slot/slot";
import getNestedProperty from "@/utils/getNestedProperty";
import resumeSlice from "@/components/resume/redux/services/resumeSlice";
import {Toast} from "antd-mobile"
import { editResumeName } from '@/http/api';

const ContainerStyled = styled.div`
  width: 446px;
  height: 330px;
  background: #FFFFFF;
  border-radius: 4px;
  position: relative;
  pointer-events: auto;
`
const TitleStyled = styled.div`
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2C2C2C;
  line-height: 25px;
  position: absolute;
  top: 24px;
  left: 32px;
`
const InputContainerStyled = styled.div`
  position: absolute;
  top: 61px;
  left: 32px;
  width: 380px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  padding: 9px 18px;
  box-sizing: border-box;
`
const InputTextStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  height: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
`
const InputStyled = styled.input`
  display: block;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  box-sizing: border-box;
  outline: none;
  border: none;
  line-height: 18px;
  padding: 0;
`
const Title2Styled = styled.div`
  position: absolute;
  top: 111px;
  left: 32px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
`
const OptionBoxPositionStyled = styled.div`
  position: absolute;
  top: 141px;
  left: 32px;
  right: 68px;
`
const OptionBoxStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const OptionStyled = styled.div`
  margin-bottom: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #949494;
  text-align: center;

  .ant-btn {
    padding: 0;
  }

  :hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &:hover .ant-btn-default {
    color: #11bba6;
    border-color: #11bba6;
  }




`
const CancelButtonStyled = styled.div`
  position: absolute;
  top: 262px;
  left: 230px;


  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`
const SuccessButtonStyled = styled.div`
  position: absolute;
  top: 262px;
  left: 330px;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`

export default (props) => {
    const {onCancel, text} = props;
    const store = useResumeStore();
    const dispatch = useResumeDispatch();
    const [selectSet, setSelectSet] = useState(new Set());
    const [isFocus, setFocus] = useState(false);
    const [isCustom, setIsCustom] = useState(true);
    const [name, setName] = useState(text)
    const [, update] = useForceUpdate()
    const list = ['姓名', '求职意向', '毕业院校', '专业', '学历', '电话号码', '工作年限', '自定义'];
    const getValueByText = (text) => {
        const {resumeData} = store.getState();
        switch (text) {
            case "姓名": {
                const path = getResumePath(Module.BASE, BASE_FIELD_ENUM.NAME, BASE_Slot.NAME);
                return getNestedProperty(resumeData, path);
            }
            case "求职意向": {
                const path = getResumePath(Module.JOB_INTEREST, JOB_INTEREST_FIELD_ENUM.ROLE, JOB_INTEREST_SLOT_ENUM.ROLE);
                return getNestedProperty(resumeData, path);
            }
            case "毕业院校": {
                const path = getResumePath(Module.EDUCATIONAL_BACKGROUND, EDUCATIONAL_BACKGROUND_FIELD_ENUM.SCHOOL, EDUCATIONAL_BACKGROUND_SLOT_ENUM.SCHOOL);
                return getNestedProperty(resumeData, path);
            }
            case "专业": {
                const path = getResumePath(Module.EDUCATIONAL_BACKGROUND, EDUCATIONAL_BACKGROUND_FIELD_ENUM.MAJOR, EDUCATIONAL_BACKGROUND_SLOT_ENUM.MAJOR);
                return getNestedProperty(resumeData, path);
            }
            case "学历": {
                const path = getResumePath(Module.EDUCATIONAL_BACKGROUND, EDUCATIONAL_BACKGROUND_FIELD_ENUM.EDUCATION, EDUCATIONAL_BACKGROUND_SLOT_ENUM.EDUCATION);
                return getNestedProperty(resumeData, path);
            }
            case "电话号码": {
                const path = getResumePath(Module.BASE, BASE_FIELD_ENUM.PHONE_NUMBER, BASE_Slot.PHONE_NUMBER);
                return getNestedProperty(resumeData, path);
            }
            case "工作年限": {
                const path = getResumePath(Module.BASE, BASE_FIELD_ENUM.YEARS_OF_WORKING, BASE_Slot.YEARS_OF_WORKING);
                return getNestedProperty(resumeData, path);
            }
        }
    }
    const getNameBySet = () => {
        let arr = [];
        [...selectSet.values()].forEach((text) => {
            arr.push(getValueByText(text))
        });
        return arr.filter((n) => !!n).join("-")
    }
    const onClickItem = (text) => {
        if (text === "自定义") {
            selectSet.clear()
            setName("");
            setIsCustom(true);
        } else {
            if (selectSet.has(text)) {
                selectSet.delete(text);
            } else {
                if (!getValueByText(text)) {
                    Toast.show({
                        content: text + "未填写",
                        position: 'top'
                    });
                    return
                } else {
                    selectSet.add(text)
                }
            }
            const name = getNameBySet();
            setName(name);
            setIsCustom(false);
        }
        update()
    }
    const onChange = (e) => {
        setName(e.target.value)
    };
    const onSave = () => {
        const { resume } = store.getState()
        dispatch(resumeSlice.actions.changeName({value: name}));
        editResumeName({
            resumePageId: resume.resumeId,
            name
        }).then(res => {
            message.success({content: "修改成功"})
        }).catch(err => {
            message.error({content: "修改失败"})
        })
        onCancel();
    }

    return <ContainerStyled>
        <TitleStyled>
            简历名称
        </TitleStyled>
        <InputContainerStyled onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}
                              style={{borderColor: isFocus ? color : undefined}}>
            {isCustom ? <InputStyled autoFocus onChange={onChange} value={name}
                                     placeholder={"请命名"}/> : <InputTextStyled>
                {name ? name : "未命名"}
            </InputTextStyled>}
        </InputContainerStyled>
        <Title2Styled>
            选择模块内容，生成专业简历名称
        </Title2Styled>
        <OptionBoxPositionStyled>
            <OptionBoxStyled>
                {list.map((text, index) => {
                    let isActive = selectSet.has(text)
                    let type;
                    let className;
                    if (text === "自定义") {
                        if (isCustom) {
                            type = "primary"
                        }
                    } else {
                        if (isActive) {
                            className = "ant-custom-button-out"
                        }
                    }
                    return <OptionStyled key={index}>
                        <Button className={className}  type={type} onClick={() => onClickItem(text)}
                                style={{width: 76, height: 30}}>
                            {text}
                        </Button>
                    </OptionStyled>
                })}
            </OptionBoxStyled>
        </OptionBoxPositionStyled>

        <CancelButtonStyled> <Button onClick={onCancel}
                                     style={{height: 36, width: 84}}>取消</Button></CancelButtonStyled>
        <SuccessButtonStyled> <Button onClick={onSave} type="primary"
                                      style={{height: 36, width: 84}}>保存</Button></SuccessButtonStyled>
    </ContainerStyled>
}
