import JzThemeButton from "@/components/JzThemeButton"
import useQueryParam from "@/hooks/useQueryParam"
import { generateReference } from "@/http/api"
import { Spin, message } from "antd"
import { useState } from "react"
import styled from "styled-components"


const SystemInitComponentStyled = styled.div`
    border-radius: 8px;
    background: #F6FCFB;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;

    .init-btn {
        width: 168px;
        height: 32px;
        background: linear-gradient(102deg, #37EDBA 0%, #50C4E3 69%, #55BBEC 100%, #55BBEC 100%);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
    .init-btn:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`

const icon1 = <div className="flex-center" style={{ marginRight: 7 }} dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="键盘回答" transform="translate(-433.000000, -871.000000)" fill="#FFFFFF">
            <g id="编组-3" transform="translate(164.000000, 243.000000)">
                <g id="编组-5" transform="translate(252.000000, 620.000000)">
                    <g id="模块管理备份-2" transform="translate(17.000000, 8.000000)">
                        <g id="magic-full" transform="translate(2.000000, 2.000000)">
                            <path d="M9.28980196,7.35343405 L6.28541153,8.99507833 L4.64376725,11.9972013 L3.00212296,8.99507833 L0,7.35343405 L3.00212296,5.71178978 L4.64376725,2.70739934 L6.28541153,5.71178978 L9.28980196,7.35343405 Z M10.5143562,1.48564377 L12,2.29910869 L10.5143562,3.11257356 L9.70089134,4.59821735 L8.88956714,3.11043286 L7.40178265,2.29910866 L8.88956714,1.48778447 L9.70089131,6.79456491e-14 L10.5143562,1.48564377 Z M1.82334154,1.01767085 L2.69479153,1.49361663 L1.82334152,1.96956239 L1.34739576,2.84101239 L0.871449989,1.9695624 L1.51656465e-12,1.49361663 L0.871449989,1.01767085 L1.34739576,0.146220865 L1.82334154,1.01767085 Z" id="形状"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}}/> 
 

const SystemInitComponent = ({ onChange, questionId, referenceId }) => {
    const [loading, setLoading] = useState(false)
    const resumeId = useQueryParam('id')

    const handleClick = () => {
        if (loading) return 
        setLoading(true)
        generateReference({
            query: {
                resumeId: parseInt(resumeId),
                questionId,
                // referenceId
            }
        }).then(res => {
            onChange && onChange('feedback')
        }).finally(() => {
            setLoading(false)
        })
    }

    // return <Spin spinning={loading}>
    //     <SystemInitComponentStyled>
    //         <div onClick={handleClick} className="init-btn">{icon1}AI 生成（基于简历）</div>
    //     </SystemInitComponentStyled>
    // </Spin>
    return <SystemInitComponentStyled>
        <JzThemeButton.Type2 styles={{
            width: 168,
            height: 32,
            columnGap: 0
        }} onClick={handleClick} status={loading && 'loading'}>
            {icon1}AI 生成（基于简历）
        </JzThemeButton.Type2>
        </SystemInitComponentStyled>
}

export default SystemInitComponent