import {getModuleList, Module} from "@/common/module/module";
import {CONTACT_INFORMATION_SLOT_ENUM} from "@/common/slot/slot";

export default (resumeData) => {

    const moduleList = getModuleList();
    delete moduleList[Module.CUSTOM_MODULE];
    if (!resumeData[Module.CONTACT_INFORMATION]) {
        resumeData[Module.CONTACT_INFORMATION] = {
            visible: false,
            id: Module.CONTACT_INFORMATION,
            data: {
                [CONTACT_INFORMATION_SLOT_ENUM.TITLE]: "联系方式"
            }
        }
    }
    Object.keys(resumeData).forEach((key) => {
        const item = resumeData[key];
        //如果是字符串就别加了
        if(typeof item === 'string') return
        item.id = key;
        if (moduleList.includes(key)) {
            item.type = key;
        }
    })
    
    return resumeData
}
