
import styled from "styled-components"
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
    arrayMove
} from 'react-sortable-hoc';
import useModuleConfig from "./useModuleConfig";
import { getIconByModule } from "@/common/resume/getIconByModule";
import { Switch } from "antd-mobile";
import { useState } from "react";
import {
    ModuleManageStyled,
    CellStyled,
    CellIconStyled,
    CellTitleStyled,
    CellSwitchPositionStyled,
    CellRequirePositionStyled,
    TitleStyled,
    RequireStyled,
    triggerSvg,
    sortSvg,
    rightSvg,
    addSvg
} from './styled'
import { Module } from "@/common/module/module";
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js"

// const DragHandle = sortableHandle(() => <span>::</span>);


const SortableContainer = sortableContainer(({children}) => {
  return <div>{children}</div>;
});


const DragHandle = sortableHandle(() => <div>{sortSvg}</div>);

const ModuleItem = sortableElement(({ item, onChange, isDisable }) => {
    return <CellStyled key={item.id} data-id={item.id}>
        <CellIconStyled src={getIconByModule(item.blockName)} width={20} height={20}></CellIconStyled>
        <CellTitleStyled>{item.title}</CellTitleStyled>
        {item.require ? <CellRequirePositionStyled>
            <RequireStyled>
                必填
            </RequireStyled>
        </CellRequirePositionStyled> :
            <CellSwitchPositionStyled>
                {
                    isDisable ?
                    <Switch style={{
                        '--checked-color': '#11BBA6',
                        '--height': '16px',
                        '--width': '30px',
                        '--adm-color-background': '#DEE0E6',
                        '--border-width': '1px'
                    }} checked={item.visible} onChange={() => onChange(item)} />
                    : <DragHandle />
                }
            </CellSwitchPositionStyled>}
    </CellStyled>
})

const NoSortModuleItem = ({ item, onChange, isDisable, isSortMode }) => {
    return <CellStyled key={item.id} data-id={item.id}>
        <CellIconStyled src={getIconByModule(item.blockName)} width={20} height={20}></CellIconStyled>
        <CellTitleStyled>{item.title}</CellTitleStyled>
        {item.require ? <CellRequirePositionStyled>
            <RequireStyled>
                必填
            </RequireStyled>
        </CellRequirePositionStyled> :
            <CellSwitchPositionStyled>
                {
                    <Switch style={{
                        '--checked-color': '#11BBA6',
                        '--height': '16px',
                        '--width': '30px',
                        '--adm-color-background': '#DEE0E6',
                        '--border-width': '1px'
                    }} checked={item.visible} onChange={() => onChange(item)} />
                }
            </CellSwitchPositionStyled>}
    </CellStyled>
}

const ModuleManage = () => {
    const [
        { visibleConfig, unVisibleConfig},
        {onChange, onSave, onAdd}
    ] = useModuleConfig()
    const [disabled, setDisabled] = useState(true)
    const sortVisibleConfig = visibleConfig.filter(item => ![Module.AVATAR, Module.BASE].includes(item.id))
    const noSortVisibleConfig = visibleConfig.filter(item => [Module.AVATAR, Module.BASE].includes(item.id))

    const handleChange = (item) => {
        const result = [...visibleConfig, ...unVisibleConfig]
        const resultObj = result.reduce((pre, cur) => {
            if (item.id === cur.id) {
                pre[cur.id] = {
                    ...cur,
                    visible: !cur.visible
                }
            } else {
                pre[cur.id] = cur
            }
            return pre
        }, {})
        onChange(resultObj)
    }

    const handleSortEnd = ({oldIndex, newIndex}) => {
        // 重定义sort
        const maxSort = Math.max(...noSortVisibleConfig.map(item => item.sort))
        const newConfig = arrayMove(sortVisibleConfig, oldIndex, newIndex).map((item, index) => ({
            ...item,
            sort: index + maxSort + 1
        }))
        const result = [...noSortVisibleConfig, ...newConfig]
        const resultObj = result.reduce((pre, cur) => {
            pre[cur.id] = cur
            return pre
        }, {})
        onChange(resultObj, false)
        // setVisibleConfig(result)
    }


    const handleClickSort = () => {
        setDisabled((d) => !d)
    }

    const handleClickRight = () => {
        setDisabled((d) => !d)
        onSave()
    }

    const handleAdd = () => {
        onAdd()
    }
    
    return <ModuleManageStyled>
        <NavigationHeader title={document.title}></NavigationHeader>
        <div className="bg-white mb-8 p-16">
            <div className="manage-title flex-center justify-between" style={{ height: 37 }}>
                <div className="gray">已有模块</div>
                {
                    disabled ? <div className="flex-center trigger" onClick={handleClickSort}>{triggerSvg}排序</div>:
                    <div onClick={handleClickRight} className="flex-center manage-btn">{rightSvg} 确认</div>
                }
                
            </div>
            {
                noSortVisibleConfig.map((item, index) => {
                    return <NoSortModuleItem key={item.id} index={index} item={item} onChange={handleChange} />
                })
            }
            <SortableContainer onSortEnd={handleSortEnd} useDragHandle>
                {sortVisibleConfig.map((item, index) => {
                    return <ModuleItem disabled={disabled} isDisable={disabled} key={item.id} index={index} item={item} onChange={handleChange} />
                })}
            </SortableContainer>
        </div>
        <div className="bg-white p-16">
            <div className="manage-title flex-center justify-between">
                <div className="gray">待添加模块</div>
            </div>
            <SortableContainer>
                {unVisibleConfig.map((item) => {
                    return <ModuleItem disabled={true} isDisable={true} key={item.id} item={item} onChange={handleChange} />
                })}
            </SortableContainer>
        </div>
        
        <div style={{ backgroundColor: '#FFFFFF' }} className="p-20">
            <div onClick={handleAdd} className="flex-center add-btn">
                {addSvg} 新增自定义模块
            </div>
        </div>

    </ModuleManageStyled>
}

export default ModuleManage