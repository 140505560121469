import {Button, Modal, Upload, message} from "antd";
import React, {createElement, useMemo, useRef, useState, useEffect} from "react";
import styled from "styled-components";
import { ImportContentVideo } from "./ImportModal";


const ContainerStyled = styled.div`
    width: 600px;
    height: 348px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    pointer-events: auto;
    .vd-h2 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #4C4C4C;
    }
    .video-tip {
        font-size: 12px;
        color: #B4B4B4;
        text-decoration-line: underline;
    }
    .vd-item {
        padding: 5px 9px;
        border-radius: 4px;
        padding-right: 43px;
        font-weight: 500;
        font-size: 12px;
        color: #3C3C3C;
    }
    .vd-item:hover {
        background: #F4F4F4;
        color: #11BBA6;
        cursor: pointer;
    }
    .vd-item-active {
        background: #F4F4F4;
        color: #11BBA6;
    }
    .im-svg {
        svg {
            width: 42px;
            height: 42px;
        }
    }
`


const Component = (props) => {
    return <ContainerStyled>
        <ImportContentVideo isHome={props.isHome} onPre={props.onCancel} onCancel={props.onCancel} />
    </ContainerStyled>
}

export default (props) => {
    return <Modal width={'auto'}
                  open={props.visible}
                  footer={false}
                  transitionName=''
                  maskTransitionName=''
                  centered
                  getContainer={props.getContainer}
                  destroyOnClose={props.destroyOnClose}
                  mask={props.mask}
                  zIndex={props.zIndex}
                  maskClosable={props.onCancel}
                  modalRender={() => createElement(Component, props)}
    />;
}
