import styled from "styled-components"
import Header from "@/pages/DownClientPage/Header";
import FlexStyled from "@/styled/FlexStyled";
import isVip2 from "@/common/isVip";
import {ConfigProvider, message} from "antd";
import {downPdf} from "@/http/api";
import fetchFile from "@/common/fetchFile";
import downFile from "@/common/downFile";
import {ResumeReduxContext} from "@/components/resume/redux/context";
import {useEffect, useMemo, useRef, useState} from "react";
import {createStore} from "@/components/resume/redux/store";
import {Provider, useStore} from "react-redux";
import {useLocation} from "react-router-dom";
import initState from "@/components/resume/redux/initState";
import {useResumeSelector, useResumeStore} from "@/components/resume/redux/hooks";
import LoginModal from "@/components/user/LoginModal";
import * as React from "react";
import PayModal from "@/components/PayModal";
import Resume from "@/components/resume";
import {Scrollbars} from 'react-custom-scrollbars';
import useForceUpdate from "@/hooks/useForceUpdate";
import Right from "@/pages/DownClientPage/Right";
import withConfigProvider from "@/hoc/withConfigProvider";
import paginationSlice from "@/components/resume/redux/services/paginationSlice";

const ContainerStyled = styled.div`
  height: 100vh;
  background: #ECEFF1;
  display: flex;
  flex-direction: column;
`
const NameContainerStyled = styled.div`
  width: 794px;
  flex-shrink: 0;
  height: 22px;
  margin-bottom: 12px;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676B75;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
`
const LeftContainerStyled = styled.div`
  width: 794px;
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
`
const RightContainerStyled = styled.div`
  width: 280px;
  height: 100%;
  margin-left: 16px;
  margin-top: 16px;
  background: #FFFFFF;

`
const CountTextStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(103, 107, 117, 0.52);
  line-height: 20px;
`

const Component = () => {
    const resumeRef = useRef();
    const [, update] = useForceUpdate()
    const [loginVisible, setLoginVisible] = useState(false);
    const [payModalVisible, setPayModalVisible] = useState(false);
    const store = useStore();
    const resumeStore = useResumeStore();
    const resume = useResumeSelector((state) => state.resume);
    const pageCount = useResumeSelector((state) => state.pagination?.pageCount);
    const down = async (type = 'pdf') => {
        const {user} = store.getState();
        if (!user.isWXLogin) {
            setLoginVisible(true)
            return
        }
        const isVip = isVip2(user);
        if (!isVip) {
            setPayModalVisible(true)
            return
        }
        const hide = message.loading("", 0);
        const {resume} = resumeStore.getState();
        const url = await downPdf({body: {resumeUserId: resume.resumeId, type}})
            .catch((e) => {
                message.error(e.msg)
            })
            .finally(() => {
                hide()
            })
        if (!url) return;
        const name = resume.name || '我的简历';
        const blob = await fetchFile(url);
        const mapTypeToSuffix = {
            'img': '.png',
            'pdf': '.pdf',
            'word': '.docx',
        }
        await downFile(blob, name + mapTypeToSuffix[type]).finally(() => {
            hide()
        })
    }
    useEffect(() => {
        update()
    }, []);
    return <ContainerStyled>
        <Header/>
        <FlexStyled $flexGrow={1} $justifyContent='center' style={{overflow: "hidden"}}>
            <FlexStyled style={{height: "100%"}}>
                <FlexStyled $justifyContent="center" style={{height: "100%"}}>
                    <LeftContainerStyled>
                        <NameContainerStyled>
                            <div>{resume?.name}</div>
                            <CountTextStyled>共{pageCount || 1}页</CountTextStyled>
                        </NameContainerStyled>
                        <FlexStyled $flexGrow={1} style={{height: 0, width: 794}}>
                            <Scrollbars>
                                <div style={{position: "relative", zIndex: 0}}>
                                    <Resume resumeRef={resumeRef}
                                            openEdit={false}
                                            width={794}
                                            store={resumeStore}/>
                                </div>
                            </Scrollbars>
                        </FlexStyled>
                    </LeftContainerStyled>
                </FlexStyled>
                <RightContainerStyled>
                    <Right down={down}/>
                </RightContainerStyled>
            </FlexStyled>
        </FlexStyled>
        <LoginModal getContainer={null} visible={loginVisible} onSuccess={() => setLoginVisible(false)}
                    onCancel={() => setLoginVisible(false)}/>
        <PayModal id={1} visible={payModalVisible}
                  onSuccess={() => setPayModalVisible(false)}
                  onCancel={() => setPayModalVisible(false)}/>
    </ContainerStyled>
}

export default withConfigProvider(() => {
    let location = useLocation();
    const state = location.state;
    const resumeStore = useMemo(() => createStore({
        devTools: __PRO__ ? false : {name: "简历下载"},
        initState: Object.assign({}, state)
    }), []);
    return <Provider context={ResumeReduxContext} store={resumeStore}>
        <Component/>
    </Provider>
})

