import moment from "moment";
import { pickerModuleList } from "./config";
import { isArray } from "lodash";
import { cityConfig, jobInterestRoleConfig } from "@/components/resume/const/getMenuConfigByField";
import { JOB_INTEREST_FIELD_ENUM } from "@/common/field/field";
import { JOB_INTEREST_SLOT_ENUM } from "@/common/slot/slot";


const findPath = (cityConfig, cityName, path = []) => {
    for (const item of cityConfig) {
      // 如果找到匹配的城市名，返回包含父级名称和城市名的数组
      if (item.name === cityName) {
        return [...path, cityName];
      }
      // 如果当前项有子项，递归搜索子项
      if (item.children) {
        const foundPath = findPath(item.children, cityName, [...path, item.name]);
        // 如果在子项中找到匹配的城市名，返回找到的路径
        if (foundPath) {
          return foundPath;
        }
      }
    }
    // 如果没有找到匹配的城市名，返回null
    return [cityName];
};

// 处理输入层
// 做两件事
// 1. 将选择器模块字段value从 'value' => ['value']
export const processBasicInfo = (basicInfoData) => {
    const pickerConfig = pickerModuleList.reduce((pre, cur) => {
        const key = cur.key
        pre[key] = [basicInfoData[key]]
        return pre
    }, {})
    const city = JOB_INTEREST_FIELD_ENUM.CITY
    const role = JOB_INTEREST_FIELD_ENUM.ROLE
    const jobStatus = JOB_INTEREST_FIELD_ENUM.JOB_STATUS
    const salary = JOB_INTEREST_FIELD_ENUM.SALARY
    const maxSalary = JOB_INTEREST_SLOT_ENUM.Max_SALARY
    const jobArrivalTime = 'jobArrivalTime'
    return {
        ...basicInfoData,
        ...pickerConfig,
        [city]: findPath(cityConfig, basicInfoData[city]),
        [role]: findPath(jobInterestRoleConfig, basicInfoData[role]),
        [jobStatus]: [basicInfoData[jobStatus], basicInfoData[jobArrivalTime]],
        [salary]: [parseInt(basicInfoData[salary]), parseInt(basicInfoData[maxSalary])]
    }
}


// 处理输出层
export const processBasicInfoExport = (values) => {
    const newValues = {
        ...values,
    }

    Object.keys(newValues).forEach(key => {
        if (isArray(newValues[key])) {
            if (key === JOB_INTEREST_FIELD_ENUM.JOB_STATUS) {
                const [jobStatus, jobArrivalTime] = newValues[key]
                newValues[key] = jobStatus
                newValues['jobArrivalTime'] = jobArrivalTime
            } else if (key === JOB_INTEREST_FIELD_ENUM.SALARY) {
                if (newValues[key][1]) {
                    newValues[JOB_INTEREST_SLOT_ENUM.Max_SALARY] = newValues[key][1]
                }
                newValues[key] = newValues[key][0]
            } else {
                newValues[key] = newValues[key][newValues[key].length - 1]
            }
        }
    })
    return newValues
}