export default (resumeData) => {
   return Object.values(resumeData).sort((a, b) => {
        if (a.sort < b.sort) {
            return -1;
        } else if (a.sort > b.sort) {
            return 1;
        } else if (!Number.isNaN(Number(a.sort))) {
            return -1
        } else if (!Number.isNaN(Number(b.sort))) {
            return 1
        }
        return 0;
    });
}
