import {createElement, useCallback, useMemo, useState} from "react";
import withComponent from "@/hoc/withComponent";
import {useDispatch} from "@/components/resume/redux/hooks";
import useConfigRender from "@/components/resume/hooks/useConfigRender";
import {Popover} from 'antd';
import {MenuContainerStyled, MenuItemStyled} from "@/components/resume/styled/fieldMenu";
import getMenuConfigByField from "@/components/resume/const/getMenuConfigByField";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import withField from "@/components/resume/hoc/withField";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import useModuleContentInstance from "@/components/resume/hooks/useModuleContentInstance";
import getResumePath from "@/common/resume/getResumePath";
import Select from "@/components/resume/components/Select";

import {Module} from "@/common/module/module";
import {JOB_INTEREST_FIELD_ENUM} from "@/common/field/field";

export default withComponent(withField((props) => {
    const {tagName, style, className, children = [], fieldRef} = props;

    const configRender = useConfigRender();
    const fieldInstance = useFieldInstance();
    const moduleContentInstance = useModuleContentInstance();

    const element = createElement(tagName, {
        style,
        className,
        ref: fieldRef
    }, children.map((config) => configRender(config)));
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const onClickMenuItem = useCallback((item) => {
        let value = item;
        let slot_value = fieldInstance.for_field_name;
        switch (fieldInstance.for_block_name) {
            case Module.JOB_INTEREST: {
                switch (fieldInstance.for_field_name) {
                    case JOB_INTEREST_FIELD_ENUM.CITY:
                    case JOB_INTEREST_FIELD_ENUM.ROLE: {
                        value = item.name;
                    }
                }
            }
        }
        dispatch(resumeDataSlice.actions.changeValueByPath({
            path: getResumePath(fieldInstance.for_block_name, fieldInstance.for_field_name, slot_value, moduleContentInstance?.index),
            value
        }));
        setVisible(false);
    }, []);
    const config = useMemo(() => getMenuConfigByField(fieldInstance.for_block_name, fieldInstance.for_field_name) || [], []);
    return <Popover content={<Select onClickItem={onClickMenuItem} list={config}/>}
                    destroyTooltipOnHide
                    overlayClassName="ant-popover-custom-field-menu"
                    autoAdjustOverflow={false}
                    trigger="click"
                    getPopupContainer={null}
                    transitionName=""
                    open={visible}
                    placement="bottom"
                    onOpenChange={setVisible}>
        {element}
    </Popover>
}))

