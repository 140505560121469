// 兼容微信内下载文件

import { useEffect, useState } from "react";
import { getParam } from "../../utils";
import ClipboardJS from 'clipboard'
import './index.scss'
// import { toast } from "react-hot-toast";
import { ReactComponent as LinkIcon} from '@/pages/Mobile/svg/link.svg'
import { Toast } from "antd-mobile";
// import { emailSendPPT } from "@/service/mobile";

const WxDownload = () => {
    const [email, setEmail] = useState('')
    const [fileUrl, setFileUrl] = useState('')
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const clipboard = new ClipboardJS('.jz-resume-btn');
        clipboard.on('success', function(e) {
            Toast.show({
                icon: 'success',
                content: '复制成功！'
            })
        })

        // 下载的url
        const url = getParam('url')
        if (url) {
            setFileUrl(url)
        }

        return () => {
            clipboard.destroy()
        }
    }, [])

    const handleChangeEmail = (e) => {

        setEmail(e.target.value)
    }

    return <div className="wx-download">
        <div className="card">
            <div className="h3">
                <LinkIcon className="svg-icon" />
                链接下载
                <span>（将链接复制到浏览器上即可下载）</span>
            </div>
            <p>下载链接有效期30分钟，请尽快下载</p>
            <div data-clipboard-text={fileUrl} className="jz-resume-btn">复制链接</div>
        </div>
        {/* <div className="card">
            <div className="h3">
                <EmailIcon className="svg-icon" />
                邮箱收取
            </div>
            <p>我们会将此资源发送至您的邮箱</p>
            <input placeholder="请输入邮箱地址" value={email} onChange={handleChangeEmail} />
            <Button loading={loading} loadingText="文件发送中" className="btn primary" onClick={handleSend}>
                发送
            </Button>
        </div> */}
    </div>
}

export default WxDownload;
