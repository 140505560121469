import styled from "styled-components";
import {Switch, Upload} from "antd"
import getDefaultModuleContent from "@/common/config/getDefaultModuleContent";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import {useDispatch} from "@/components/resume/redux/hooks";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import {uploadFile} from "@/http/api";

const ContainerStyled = styled.div`
  width: 96px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  padding: 8px 0;
`
const CellStyled = styled.div`
  width: 96px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: #F4F4F4;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2C2C2C;
  }
`
export default (props) => {
    const {onClickItem} = props;
    const moduleInstance = useModuleInstance();
    const dispatch = useDispatch();


    const onClickAddLink = () => {
        const defaultModuleContent = getDefaultModuleContent(moduleInstance.for_block_name, {type: "link"});
        dispatch(resumeDataSlice.actions.addPortfolioItem({type: "link", item: defaultModuleContent}))
        onClickItem?.();
    }
    const onUpload = async (res) => {
        const file = res.file;
        const fileURL = await uploadFile({body: {file}});
        const defaultModuleContent = getDefaultModuleContent(moduleInstance.for_block_name, {type: "image"});
        defaultModuleContent.src = fileURL
        dispatch(resumeDataSlice.actions.addPortfolioItem({type: "image", item: defaultModuleContent}))
        onClickItem?.();
    }
    return <ContainerStyled>
        <Upload showUploadList={false} customRequest={onUpload}>
            <CellStyled>图片作品</CellStyled>
        </Upload>
        <CellStyled onClick={onClickAddLink}>链接作品</CellStyled>
    </ContainerStyled>
}
