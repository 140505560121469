import React, {createElement, forwardRef, useMemo} from "react";
import {createStore} from "@/components/resume/redux/store";
import {Provider} from "react-redux";
import {ResumeReduxContext} from "@/components/resume/redux/context";

export default(Component) => {
    return forwardRef((props, ref) => {
        const store = useMemo(() => props.store || createStore(), []);
        return <Provider context={ResumeReduxContext} store={store}>
            {createElement(Component, {...props, ref})}
        </Provider>
    })
}
