import withComponent from "../../hoc/withComponent";
import styled from "styled-components"
import {useMemo, useState} from "react";
import FlexStyled from "../../styled/FlexStyled"
import useQuery from "@/hooks/useQuery";
import getQuery from "@/utils/getQuery";
import strictStringify from "@/common/strictStringify";
import { reachUserCrateResumeLimit } from "@/http/api";
import { compressAndResizeImage } from "@/utils/image";
import './index.scss'
const ContainerStyled = styled.div`
  position: relative;
  background: #FEFFFF;
  border-radius: 8px;
  cursor: pointer;
  padding-bottom: 12px;
  overflow: hidden;


  .template-container-menu-box {
    visibility: hidden;
  }

  &:hover .template-container-menu-box {
    visibility: visible;
  }


  &:hover {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    transform: scale(1.044);
  }


`
const DownButtonStyled = styled.div`
  width: 146px;
  height: 44px;
  background: #00BCA6;
  border-radius: 4px;


  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 44px;
  text-align: center;
`
const CollectStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  overflow: hidden;
`
const MenuPositionStyled = styled.div`
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ImageContainerStyled = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:hover::before {
    opacity: 0.3;
  }
`
const TitleStyled = styled.div`
  padding: 14px 12px 20px 12px;
  height: 13px;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #383835;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const InfoContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
`
const InfoTextStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px;
`
const icon1 = <div style={{width: 16, height: 16}} dangerouslySetInnerHTML={{
    __html: `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>切片</title>
        <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="简历首页" transform="translate(-632.000000, -1050.000000)">
                <g id="编组-10备份" transform="translate(620.000000, 688.000000)">
                    <g id="下载" transform="translate(12.000000, 362.000000)">
                        <rect id="矩形" x="0" y="0" width="16" height="16"></rect>
                        <g id="chakan-3" transform="translate(0.800000, 2.600000)" fill="#666666" fill-rule="nonzero">
                            <path d="M14.367543,5.15701777 C13.5303488,2.09582026 10.5709088,-0.0333216832 7.2,0.000394785425 C3.8290912,-0.0333216832 0.869651171,2.09582026 0.0324569875,5.15701777 C-0.0108189958,5.3164659 -0.0108189958,5.4835341 0.0324569875,5.64298223 C0.869651171,8.70417974 3.8290912,10.8333217 7.2,10.7996052 C10.5709088,10.8333217 13.5303488,8.70417974 14.367543,5.64298223 C14.410819,5.4835341 14.410819,5.3164659 14.367543,5.15701777 L14.367543,5.15701777 Z M7.2,9.44970391 C4.53445223,9.47582616 2.18630094,7.81136613 1.48684543,5.4 C2.1874081,2.98933567 4.53487087,1.32536368 7.2,1.35029609 C9.86554777,1.32417384 12.2136991,2.98863387 12.9131546,5.4 C12.2125919,7.81066433 9.86512913,9.47463632 7.2,9.44970391 Z" id="形状"></path>
                            <path d="M7.2,2.7 C5.70883118,2.7 4.5,3.90883118 4.5,5.4 C4.5,6.89116882 5.70883118,8.1 7.2,8.1 C8.69116882,8.1 9.9,6.89116882 9.9,5.4 C9.9,3.90883118 8.69116882,2.7 7.2,2.7 L7.2,2.7 Z M7.2,6.74999999 C6.45441559,6.74999999 5.85000001,6.14558441 5.85000001,5.4 C5.85000001,4.65441559 6.45441559,4.05000001 7.2,4.05000001 C7.94558441,4.05000001 8.54999999,4.65441559 8.54999999,5.4 C8.54999999,6.14558441 7.94558441,6.74999999 7.2,6.74999999 L7.2,6.74999999 Z" id="形状"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
`
}}/>
const icon2 = <div style={{width: 16, height: 16}} dangerouslySetInnerHTML={{
    __html: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="简历首页" transform="translate(-770.000000, -1050.000000)">
            <g id="编组-10备份" transform="translate(620.000000, 688.000000)">
                <g id="编组-4备份-18" transform="translate(12.000000, 362.000000)">
                    <g id="下载备份" transform="translate(138.000000, 0.000000)">
                        <rect id="矩形备份-5" x="0" y="0" width="16" height="16"></rect>
                        <g id="shoucang-2" transform="translate(1.600000, 2.000000)" fill="#666666" fill-rule="nonzero">
                            <path d="M6.01125458,9.4275686 C6.25867146,9.31961671 6.54132854,9.31961671 6.78874542,9.4275686 L9.39098564,10.5625391 L9.0919507,7.79062189 C9.0633863,7.52716718 9.15058418,7.26424611 9.33181489,7.06737776 L11.2395942,4.99692025 L8.4522068,4.41869839 C8.18723906,4.36378727 7.9586009,4.20132549 7.82327906,3.97180571 L6.4,1.5568425 L4.97672094,3.97180571 C4.8413991,4.20132549 4.61276094,4.36378727 4.3477932,4.41869839 L1.56040582,4.99692025 L3.46818511,7.06737776 C3.64953057,7.26419431 3.7368461,7.52712143 3.70836742,7.79062189 L3.40901436,10.5625391 L6.01125458,9.4275686 Z M3.42332986,11.9190886 C3.11176422,12.0549737 2.74974956,12.0178443 2.47376849,11.8216984 C2.19778742,11.6255525 2.04981027,11.3002194 2.08562569,10.9683525 L2.42792526,7.79746844 L0.245606451,5.42918622 C0.0170902774,5.18126563 -0.0587252317,4.83285188 0.0467599101,4.51537757 C0.152245052,4.19790326 0.422977675,3.95968294 0.756828948,3.89058079 L3.9450504,3.22957797 L5.57320021,0.467308199 C5.74360438,0.178164454 6.05882747,0 6.4,0 C6.74117253,0 7.05639562,0.178164454 7.22679979,0.467308199 L8.8549496,3.22957797 L12.0431711,3.89058079 C12.3770223,3.95968294 12.6477549,4.19790326 12.7532401,4.51537757 C12.8587252,4.83285188 12.7829097,5.18126563 12.5543935,5.42918622 L10.3720747,7.79746844 L10.7143743,10.9683525 C10.7501897,11.3002194 10.6022126,11.6255525 10.3262315,11.8216984 C10.0502504,12.0178443 9.68823578,12.0549737 9.37667014,11.9190886 L6.4,10.6210459 L3.42332986,11.9190886 Z" id="形状"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`
}}/>
const collectImage = `https://oss.jianzeppt.com/user/12426/2bf3e0a1-cb39-4336-9290-18491df63ef6.png`
const collectImage2 = `https://oss.jianzeppt.com/user/12426/0eba26e8-403c-4f85-afc9-968b5f62002e.png`


export default withComponent((props) => {
    const {style, item, width} = props;
    const [hover, setHover] = useState(false);
    const isCollect = false;

    const onClickCollect = (e) => {
        e.stopPropagation()
    }
    const onClick = () => {
      reachUserCrateResumeLimit().then((data) => {
        if (data === false) {
          // 先调一下接口，没问题才下一步
            const query = {
                absolute_laout: getQuery().absolute_laout, id: item.id, create_flag: "1"
            }
            window.open("/resume" + strictStringify(query))
        }
      })
    }
    const imgHeight = width * (300 / 212);
    const src = useMemo(() => compressAndResizeImage(item.cover), [])
    return <ContainerStyled $isHover={hover}
                            onClick={onClick}
                            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
                            style={{...style, width}}>
        <ImageContainerStyled>
            <MenuPositionStyled className="template-container-menu-box">
                <DownButtonStyled style={{marginRight: 8}}>使用模板</DownButtonStyled>
                {/* <CollectStyled onClick={onClickCollect}>
                    <img width={26} height={24} src={isCollect ? collectImage2 : collectImage}/>
                </CollectStyled> */}
            </MenuPositionStyled>
            <div class="image-skeleton" style={{
              width:width ,height:imgHeight
            }}>
              <img  width={width} height={imgHeight}
                 src={src}  />
            </div>
            {/* <img width={width} height={imgHeight}
                 src={src}/> */}
        </ImageContainerStyled>
        <TitleStyled>
            {item.name}
        </TitleStyled>
        <InfoContainerStyled>
            <FlexStyled $justifyContent="center" $alignItems="center">
                <div style={{marginRight: 6}}>{icon1}</div>
                <InfoTextStyled>{item.likes}</InfoTextStyled>
            </FlexStyled>
            {/* <FlexStyled $justifyContent="center" $alignItems="center">
                <div style={{marginRight: 6}}>{icon2}</div>
                <InfoTextStyled>23412</InfoTextStyled>
            </FlexStyled> */}
        </InfoContainerStyled>

    </ContainerStyled>
})

