import { useEffect, useState } from "react"
import { getResumeDataById, saveResumePageUserData } from "@/http/api"
// import { setData, setResumeData } from "../ResumeEdit/editSlice"
import { useDispatch, useSelector } from "react-redux"
import { BASE_FIELD_ENUM, EDUCATIONAL_BACKGROUND_FIELD_ENUM, JOB_INTEREST_FIELD_ENUM, PORTFOLIO_FIELD_ENUM, PROJECT_EXPERIENCE_FIELD_ENUM } from "@/common/field/field";
import { setData } from "../pages/ResumeEdit/editSlice";
import { Module } from "@/common/module/module";
import { BASE_Slot, EDUCATIONAL_BACKGROUND_SLOT_ENUM, JOB_INTEREST_SLOT_ENUM, PORTFOLIO_SLOT_ENUM } from "@/common/slot/slot";

const baseInfoFieldList = [
    { label: BASE_FIELD_ENUM.NAME, value: BASE_FIELD_ENUM.NAME, required: true, checked: true },
    { label: BASE_FIELD_ENUM.GENDER, value: BASE_FIELD_ENUM.GENDER, checked: true },
    { label: BASE_FIELD_ENUM.AGE, value: BASE_FIELD_ENUM.AGE, checked: true },
    { label: BASE_FIELD_ENUM.EDUCATION_BACKGROUND, value: BASE_FIELD_ENUM.EDUCATION_BACKGROUND,required: true, checked: true },
    { label: BASE_FIELD_ENUM.YEARS_OF_WORKING, value: BASE_FIELD_ENUM.YEARS_OF_WORKING, checked: true },
    { label: BASE_FIELD_ENUM.PRESENT_ADDRESS, value: BASE_FIELD_ENUM.PRESENT_ADDRESS, checked: true },
    { label: BASE_FIELD_ENUM.PHONE_NUMBER, value: BASE_FIELD_ENUM.PHONE_NUMBER, required: true, checked: true },
    { label: BASE_FIELD_ENUM.MAIL, value: BASE_FIELD_ENUM.MAIL,required: true, checked: true },
    { label: BASE_FIELD_ENUM.WECHAT_ID, value: BASE_FIELD_ENUM.WECHAT_ID, checked: true },
    { label: BASE_FIELD_ENUM.NATION, value: BASE_FIELD_ENUM.NATION, checked: true },
    { label: BASE_FIELD_ENUM.ADDRESS_AND_ORIGIN, value: BASE_FIELD_ENUM.ADDRESS_AND_ORIGIN, checked: true },
    { label: BASE_FIELD_ENUM.HEIGHT, value: BASE_FIELD_ENUM.HEIGHT, checked: true },
    { label: BASE_FIELD_ENUM.WEIGHT, value: BASE_FIELD_ENUM.WEIGHT, checked: true },
    { label: BASE_FIELD_ENUM.MARITAL_STATUS, value: BASE_FIELD_ENUM.MARITAL_STATUS, checked: true },
    { label: BASE_FIELD_ENUM.POLITICS_STATUS, value: BASE_FIELD_ENUM.POLITICS_STATUS, checked: true },
]

const jobInterestFieldList = [
    { label: "求职类型", value: JOB_INTEREST_FIELD_ENUM.JOB_TYPE,  checked: true },
    { label: "意向岗位", value: JOB_INTEREST_FIELD_ENUM.ROLE,  checked: true },
    { label: "意向城市", value: JOB_INTEREST_FIELD_ENUM.CITY,  checked: true },
    { label: "薪资要求", value: JOB_INTEREST_FIELD_ENUM.SALARY,  checked: true },
    { label: "求职状态", value: JOB_INTEREST_FIELD_ENUM.JOB_STATUS,  checked: true },
]

const educationalBackgroundFieldList = [
    { label: "学校名称", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.SCHOOL, checked: true },
    { label: "在校时间", value: EDUCATIONAL_BACKGROUND_FIELD_ENUM.DATE, checked: true },
    { label: "学历", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.EDUCATION, checked: true },
    { label: "UI设计师", value: JOB_INTEREST_FIELD_ENUM.SALARY, checked: true },
    { label: "在校经历", value: JOB_INTEREST_FIELD_ENUM.JOB_STATUS, checked: true },
]
const workExperienceFieldList = [
    { label: "学校名称", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.SCHOOL,  checked: true },
    { label: "在校时间", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.DATE,  checked: true },
    { label: "学历", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.EDUCATION,  checked: true },
    { label: "UI设计师", value: JOB_INTEREST_FIELD_ENUM.SALARY,  checked: true },
    { label: "在校经历", value: JOB_INTEREST_FIELD_ENUM.JOB_STATUS,  checked: true },
]
const projectExperienceFieldList = [
    { label: "学校名称", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.SCHOOL,  checked: true },
    { label: "在校时间", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.DATE,  checked: true },
    { label: "学历", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.EDUCATION,  checked: true },
    { label: "UI设计师", value: JOB_INTEREST_FIELD_ENUM.SALARY,  checked: true },
    { label: "在校经历", value: JOB_INTEREST_FIELD_ENUM.JOB_STATUS,  checked: true },
]
const internshipExperienceFieldList = [
    { label: "学校名称", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.SCHOOL,  checked: true },
    { label: "在校时间", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.DATE,  checked: true },
    { label: "学历", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.EDUCATION,  checked: true },
    { label: "UI设计师", value: JOB_INTEREST_FIELD_ENUM.SALARY,  checked: true },
    { label: "在校经历", value: JOB_INTEREST_FIELD_ENUM.JOB_STATUS,  checked: true },
]

const schoolExperienceFieldList = [
    { label: "学校名称", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.SCHOOL, checked: true },
    { label: "在校时间", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.DATE, checked: true },
    { label: "学历", value: EDUCATIONAL_BACKGROUND_SLOT_ENUM.EDUCATION, checked: true },
    { label: "UI设计师", value: JOB_INTEREST_FIELD_ENUM.SALARY, checked: true },
    { label: "在校经历", value: JOB_INTEREST_FIELD_ENUM.JOB_STATUS, checked: true },
]

const portfolioFieldList = [
    { label: "链接", value: PORTFOLIO_FIELD_ENUM.LINK, checked: true },
    { label: "图集", value: PORTFOLIO_FIELD_ENUM.PICTURE, checked: true },
]



const mapModuleNameToFieldList = {
    '基本信息': baseInfoFieldList,
    [Module.JOB_INTEREST]: jobInterestFieldList,
    [Module.EDUCATIONAL_BACKGROUND]: educationalBackgroundFieldList,
    [Module.WORK_EXPERIENCE]: workExperienceFieldList,
    [Module.PROJECT_EXPERIENCE]: projectExperienceFieldList,
    [Module.INTERNSHIP_EXPERIENCE]: internshipExperienceFieldList,
    [Module.CAMPUS_ACTIVITIES]: schoolExperienceFieldList,
    [Module.PORTFOLIO]: portfolioFieldList
}

// JOB_INTEREST_FIELD_ENUM
// JOB_INTEREST_SLOT_ENUM.CITY

const useResumeFieldVisible = ({ moduleName, fieldConfig }) => {
    const dispatch = useDispatch()
    const data = useSelector(state => state.mobileEdit.data)
    const [fieldList, setFieldList] = useState(fieldConfig || mapModuleNameToFieldList[moduleName] || [])

    // 这种情况其实就可以设计成受控组件。
    useEffect(() => {
        if (moduleName || fieldConfig) {
            setFieldList(fieldConfig || mapModuleNameToFieldList[moduleName])
        }
    }, [moduleName, fieldConfig])

    useEffect(() => {
        
        if (data?.resumeData?.[moduleName]) {
            const moduleData = data.resumeData[moduleName]
            const hideFieldList = []
            Object.keys(moduleData).forEach(key => {
                // 查找需要隐藏的字段
                if (key.includes('Visible') && !moduleData[key]) {
                    const [field] = key.split('Visible')
                    hideFieldList.push(field)
                }
            })

            if (hideFieldList.length > 0) {
                setFieldList(fl => {
                    return fl.map(f => {
                        if (hideFieldList.includes(f.value)) {
                            return {
                                ...f,
                                checked: false
                            }
                        }
                        return f
                    })
                })
            }
        }
    }, [data])

    const save = async (pageId, resumeData) => {
        await saveResumePageUserData({ query: { pageId }, body: resumeData });
    }

    const onChangeFieldList = (fl) => {
        setFieldList(fl)
        const fieldVisibleObj = fl.reduce((pre, cur) => {
            const key = `${cur.value}Visible`
            pre[key] = cur.checked
            return pre
        }, {})

        const moduleData = {
            ...data.resumeData[moduleName],
            ...fieldVisibleObj
        }
        const newData = {
            ...data,
            resumeData: {
                ...data.resumeData,
                [moduleName]: moduleData
            }
        }
        dispatch(setData(newData))
        
        // 保存
        const pageId = newData?.resume?.pageId
        if (pageId) {
            // save(pageId, newData.resumeData)
        }
    }

    return {
        fieldList,
        onChangeFieldList
    }
}

export default useResumeFieldVisible