import setUserByLocal from "@/common/user/setUserByLocal";
import http from "./http";
import pptHttp from "./pptHttp";
import parse from "@/common/resume/parse";
import loginHandler from "@/common/user/loginHandler";

export const gzhQRCode = (query, body) => http.get('/resume/login/wechat/qr', {query, body})
export const gzhState = (query, body) => http.get('/resume/login/wechat', {query, body})
export const getUserInfo = (query, body) => http.get('/resume/user/token', {query, body})
export const wxUserToken = (query) => http.get('/resume/user/wxUserToken', {query})


export  const mobileSendCode = (query) => {
    return http.get(`/login/sendCode`, {query});
}
  
// {code: xxx, phone: yyy}
export  const mobileCheckCode = (query) => {
    return http.get(`/login/checkCode`, {query});
}
  
// 下面两个接口为什么要这么写？
// 1. 登录完之后，后端返回的userData不完整，因此必须使用这个userData去重新刷新token获取正确的user信息
// 2. loginHandler的作用是，当登录接口成功后，我们需要将登录后的不完整的userData写入到localstorage并且写入到redux中
// 3. 第二步是必要的，因为getUserInfo这个接口的user-id, user-token基于redux中的user信息，因此要保证先将第二步写入再刷新token
export  const mobilePhoneLogin = (query) => {
    // 登录完之后
    return new Promise(async (resolve, reject) => {
        try {
            const userData = await http.get(`/login/phoneCodeLogin`, {query});
            loginHandler(userData)
            const data = await getUserInfo()
            resolve(data)
        } catch (error) {
            reject(error)
        }
    })
}

export const wxBrowserLogin = (query) => {
    // 登录完之后
    return new Promise(async (resolve, reject) => {
        try {
            const userData = await http.post(`/login/wechat/wxBrowserLogin`, {query});
            loginHandler(userData)
            const data = await getUserInfo()
            resolve(data)
        } catch (error) {
            reject(error)
        }
    })
}

// 支付相关接口

export const payWxPay = (body) => {
    return http.post('/resume/order/WXPay_H5_order', { body })
}

export const payWxJsApiPay = (body) => {
    return http.post('/resume/order/pay_weixin2_jsapi', { body })
}

export const payMiniProgramPay = (body) => {
    return http.post('/resume/order/pay_weixi_miniapp_jsapi', { body })
}


export async function sendWeiXingLog(body) {
    return http.post(`/ppt/user/sendWeiXingLog`, {body});
}

export async function getResumeTemplateById(query) {
    return http.get(`/resume/resume/getResumeById`, {query});
}

export async function uploadResumeByWord(option) {
    return http.upload(`/resume/ai/upload_resume/word`, option);
}

export async function generateResume(query) {
    return http.get(`/resume/resume/generate`, {query});
}