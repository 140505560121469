import {getResumeDataById} from "@/http/api";
import useQuery from "@/hooks/useQuery";
import styled from "styled-components"
import * as React from "react";
import {useEffect, useMemo, useRef, useState, useLayoutEffect} from "react";
import Resume from "@/components/resume/index";
import {createStore} from "@/components/resume/redux/store";
import {ResumeReduxContext} from "@/components/resume/redux/context";
import {Provider} from "react-redux";
import {ResumeDetailInstanceContext} from "@/pages/ResumeDetail/context";
import {createGlobalStyle} from 'styled-components';
import dispatchResumeData from "@/common/resume/dispatchResumeData";
import {message} from "antd";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;
const ContentPositionStyled = styled.div`
`


export default () => {
    const query = useQuery();
    const bodyRef = useRef();
    const {id} = query;
    const resumeRef = useRef();
    const instance = useRef({templateId: undefined, name: undefined, resumeRef}).current;
    const [resumeLoading, setResumeLoading] = useState(true);
    const [resumeWidth, setResumeWidth] = useState(360)
    const resumeStore = useMemo(() => createStore(), []);
    const fetchData = async () => {
        const resumeData = await getResumeDataById(id).catch((err) => {
            message.error(err.msg)
        })
        if (!resumeData) return;
        dispatchResumeData(resumeStore.dispatch, resumeData);
        instance.templateId = resumeData.template.templateId;
        instance.name = resumeData.resume.name;
        setResumeLoading(false)
    };

    useEffect(() => {
        fetchData()
    }, []);

    // 宽度设置为页面宽度
    useLayoutEffect(() => {
        const width = document.body.clientWidth
        setResumeWidth(width)
    }, [])

    return <ResumeDetailInstanceContext.Provider value={instance}>
        <Provider context={ResumeReduxContext} store={resumeStore}>
            <GlobalStyle/>
            <ContentPositionStyled ref={bodyRef}>
                <div style={{pointerEvents: resumeLoading ? "none" : undefined}}>
                    {!resumeLoading && <Resume resumeRef={resumeRef}
                                               isDown={true}
                                               getModuleContentToolContainer={() => bodyRef.current}
                                               width={resumeWidth} store={resumeStore}/>}
                </div>
            </ContentPositionStyled>
        </Provider>
    </ResumeDetailInstanceContext.Provider>
}



