import { createSlice } from '@reduxjs/toolkit';
import initState from '../initState';
import { saveResumeData } from '../thunk/edit';

export default createSlice({
    name: 'edit',
    initialState: initState.edit,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveResumeData.pending, (state) => {
                state.status = 'saving';
            })
            .addCase(saveResumeData.fulfilled, (state) => {
                state.status = 'saved';
            })
            .addCase(saveResumeData.rejected, (state, action) => {
                state.status = 'error';
            });
    },
});
