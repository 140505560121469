import styled from 'styled-components';
import withComponent from '@/hoc/withComponent';

const DeductionStyled = styled.div`
  height: 38px;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

const TitleStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #353A40;
  line-height: 14px;
`;
const DesStyled = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9097A1;
  line-height: 12px;
`;

export default withComponent((props) => {
  const { upgradeSVipInfo } = props;
  return <DeductionStyled style={props.style}>
    <TitleStyled style={{ marginRight: 8 }}>已抵扣 <span style={{ color: '#EF333F' }}>{upgradeSVipInfo?.remainingSum}</span> 元</TitleStyled>
    <DesStyled>(已支付 {upgradeSVipInfo?.payMoney} 元 /{upgradeSVipInfo?.vipDays}天 * 未使用{upgradeSVipInfo?.remainingDays}天)</DesStyled>
  </DeductionStyled>;
});
