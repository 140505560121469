import styled from "styled-components";

export const icon3 = <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} dangerouslySetInnerHTML={{
  __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>切片</title>
  <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="未展开样式" transform="translate(-1138.000000, -15.000000)" fill="#11BBA6">
          <g id="编组-6备份-2" transform="translate(1128.000000, 10.000000)">
              <g id="加协作团队备份-11" transform="translate(10.000000, 5.000000)">
                  <g id="daoru-4" transform="translate(2.200000, 3.000000)">
                      <path d="M3.18494272,0 C2.83360611,0 2.55100927,0.282596836 2.55100927,0.633933442 L2.55100927,3.18494272 L3.81887616,3.18494272 L3.81887616,1.27550464 L13.3660666,1.27550464 L13.3660666,12.7321331 L3.81887616,12.7321331 L3.81887616,10.822695 L2.54337152,10.822695 L2.54337152,13.3660666 C2.54337152,13.7174032 2.82596836,14 3.17730496,14 L14,14 C14.3513366,14 14.6339334,13.7174032 14.6339334,13.3660666 L14.6339334,0.641571195 C14.6339334,0.290234588 14.3513366,0.00763775232 14,0.00763775232 C14,0 3.18494272,0 3.18494272,0 Z M6.04909984,4.7735952 C5.87343153,4.7735952 5.72831424,4.91871249 5.72831424,5.0943808 L5.72831424,6.36988543 L0.320785597,6.36988543 C0.145117294,6.36988543 0,6.51500273 0,6.69067103 L0,7.32460447 C0,7.50027278 0.145117294,7.64539007 0.320785597,7.64539007 L5.72831424,7.64539007 L5.72831424,8.91325696 L5.73595199,8.96672122 C5.74358974,9.03546099 5.78177851,9.08892526 5.82760502,9.13475177 C5.94980906,9.25695581 6.15602837,9.25695581 6.27823241,9.13475177 L8.18767049,7.22531369 L8.22585925,7.17948718 C8.30987452,7.04964539 8.29459902,6.88161484 8.18767049,6.77468631 L6.27823241,4.86524823 L6.24004364,4.82705947 C6.17894163,4.79650846 6.11020185,4.7735952 6.04909984,4.7735952 Z" id="形状"></path>
                  </g>
              </g>
          </g>
      </g>
  </g>
</svg>
`}} />

export const Button2Styled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 39px;
  border-radius: 8px;
  border: 1px solid #00BCA6;
  background-color: #ffffff;
  font-size: 14px;

  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #11BBA6;
  cursor: pointer;
  &:hover {
      opacity: 0.8;
  }
`
export const ContainerStyled = styled.div`
  background: #F7F7F7;
`
export const ContentContainer = styled.div`
  width: 1184px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 16px;
`
export const ListContentContainer = styled.div`
  min-height: 100vh;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  box-sizing: border-box;

  & > div:not(:nth-child(3n)) {
    margin-right: 24px;
  }
`
export const PreviewBoxContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 362px;
  margin-bottom: 20px;
  overflow: hidden;

  .resume-edit-button {
    display: none;
  }

  &:hover .resume-preview {
    &::before {
      position: absolute;
      content: "";
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
    }
  }

  &:hover {
    .resume-edit-button {
      display: flex;
    }
  }
`

export const PreviewContentContainer = styled.div`
  position: relative;
  height: 512px;
  border-radius: 8px;
  border: 1px solid #D4D4D4;
  overflow: hidden;
  iframe{
    outline: none;
    border: none;
  }
`
export const ContentHeaderStyled = styled.div`
  position: relative;
  height: 90px;
`
export const TitleTextStyled = styled.div`
  position: absolute;
  top: 29px;
  left: 30px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3C3C3C;
  line-height: 33px;
`
export const ButtonPositionStyled = styled.div`
  position: absolute;
  top: 28px;

`
export const EditButtonStyled = styled.div`
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 25px;
  display: flex;
  justify-content: center;
`
export const IconPointerStyled = styled.div`
  cursor: pointer;

  &:hover {
    path {
      fill: #11bba6;
      ${props => props.$delete && `fill: red;`}
    }
  }
`
export const PreviewFooterStyled = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
`
export const PreviewFooterNameStyled = styled.div`
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2C2C2C;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const PreviewFooterDateStyled = styled.div`
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin-top: 7px;
`
export const PreviewFooterTextBoxStyled = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  width: 0;
  overflow: hidden;
  padding-right: 20px;
`
export const PreviewFooterIconBoxStyled = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;

  & > :nth-child(1) {
    margin-right: 18px;
  }

  & > *{
    margin-right: 15px;
  }
`


export const ListNullWrapStyled = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 150px;
  img {
    width: 160px;
    height: 160px;
  }
  > p {
    margin-top: 8px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A0A7B1;
  }
`