import withComponent from "@/hoc/withComponent";
import useConfigRender from "@/components/resume/hooks/useConfigRender";
import {createElement, useCallback, useRef, useState} from "react";
import {Popover} from "antd";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import withField from "@/components/resume/hoc/withField";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import useModuleContentInstance from "@/components/resume/hooks/useModuleContentInstance";
import {EDUCATIONAL_BACKGROUND_SLOT_ENUM, JOB_INTEREST_SLOT_ENUM} from "@/common/slot/slot";
import SalaryController from "@/components/resume/components/SalaryController";
import {Module} from "@/common/module/module";
import useSelectedField from "@/components/resume/hooks/useSelectedField";


export default withComponent(withField((props) => {
    const {tagName, style, className, children = [],fieldRef} = props;
    const [visible, setVisible] = useState(false);
    const fieldInstance = useFieldInstance();
    const moduleContentInstance = useModuleContentInstance();
    const configRender = useConfigRender();
    const dispatch = useDispatch();
    useSelectedField({ visible, ref: fieldRef, type: 'select' })
    const salary = useSelector(state => state.resumeData[Module.JOB_INTEREST].data[JOB_INTEREST_SLOT_ENUM.SALARY]);
    const max_salary = useSelector(state => state.resumeData[Module.JOB_INTEREST].data[JOB_INTEREST_SLOT_ENUM.Max_SALARY]);
    const onChangeMin = useCallback((v) => {
        dispatch(resumeDataSlice.actions.changeValueByPath({
            path: `${[Module.JOB_INTEREST]}.data.${[JOB_INTEREST_SLOT_ENUM.SALARY]}`,
            value: v
        }))
    }, [])
    const onChangeMax = useCallback((v) => {
        dispatch(resumeDataSlice.actions.changeValueByPath({
            path: `${[Module.JOB_INTEREST]}.data.${[JOB_INTEREST_SLOT_ENUM.Max_SALARY]}`,
            value: v
        }))
    }, []);
    let text;
    if (salary === undefined) {
        text = "薪资面议"
    } else if (salary === 0) {
        text = "薪资面议"
    } else if (salary && max_salary > 0) {
        text = `${salary}k-${max_salary}k`
    } else {
        text = `${salary}k`
    }


    return <Popover
        content={<SalaryController defaultMinSalary={salary} defaultMaxSalary={max_salary} onChangeMin={onChangeMin}
                                   onChangeMax={onChangeMax}/>}
        destroyTooltipOnHide
        overlayClassName="ant-popover-custom"
        autoAdjustOverflow={false}
        trigger="click"
        transitionName=""
        onOpenChange={setVisible}
        getPopupContainer={null}
        placement="bottom"
        open={visible}>
        {
            createElement(tagName, {style,ref:fieldRef}, text)
        }
    </Popover>
}))
