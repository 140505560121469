import styled from "styled-components"
import Interview from "../ResumeDetail/component/Interview"
import withStore from "@/components/resume/hoc/withStore"

const InterviewReportStyled = styled.div`

`

const InterviewReport = () => {
    return <InterviewReportStyled>
        <Interview print={true} />
    </InterviewReportStyled>
}

export default withStore(InterviewReport)