import {useCallback, useMemo, useRef} from "react";
import withComponent from "@/hoc/withComponent";
import Input from "@/components/resume/Input"
import useForceUpdate from "@/hooks/useForceUpdate";


export default withComponent((props) => {
    const {onInput, value, className, borderStyle, color,onPre} = props;
    const instance = useRef({
        key: 0
    }).current;
    const [, update] = useForceUpdate();

    const updateTextKey = useCallback(() => {
        instance.key++
    }, [])
    const onFocus = useCallback(() => {
        instance.isFocus = true;
        update()
    }, []);
    const onBlur = useCallback(() => {
        instance.isFocus = false;
        update()
    }, []);
    if (!instance.isFocus) {
        updateTextKey()
    }

    return useMemo(() => <Input className={className}
                                onPre={onPre}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                value={value} borderStyle={borderStyle} color={color}
                                onInput={onInput}/>, [instance.key])
})
