import {combineReducers} from 'redux';
import {createReducer} from '@reduxjs/toolkit';
import initState from './initState';
import {STATE_RESET} from './actions';


import userSlice from './services/userSlice';
import editSlice from './services/editSlice';
import errorSlice from './services/errorSlice';
import mobileEditSlice from '@/pages/Mobile/pages/ResumeEdit/editSlice' 
import mobileIndexSlice from '@/pages/Mobile/pages/TemplateList/indexSlice' 
import mobileSlice from '@/pages/Mobile/mobileSlice';

const combineReducer = combineReducers({
    user: userSlice.reducer,
    edit: editSlice.reducer,
    error:errorSlice.reducer,
    mobileEdit: mobileEditSlice,
    mobileIndex: mobileIndexSlice,
    mobile: mobileSlice,
});

const reducer = createReducer(initState, (builder) => {
    builder
        .addCase(STATE_RESET, (state, action) => {
            return initState;
        })
        .addDefaultCase(combineReducer);
});

export default reducer;
