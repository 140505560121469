import styled from "styled-components";
import MobileLayout from "@/pages/Mobile/layout/index.js";
import { useEffect, useState } from "react";
import { InfiniteScroll } from "antd-mobile";
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js";
import { useNavigate, useLocation } from "react-router-dom";
import JzButton from "@/components/JzButton";

const MyStyled = styled.div`
  background-color: rgba(247, 248, 249, 1);
  /* min-height: 100vh; */
  box-sizing: border-box;
  .my-title {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-bottom: 15px;
  }
`;

const BoxStyled = styled.div`
  position: absolute;
  left: calc(50% - 132px);
  top: calc(50% - 67px);
  width: 265px;
  height: 134px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  & > div:nth-child(1) {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #353336;
    margin: 36px 0 0;
  }
  .t-btn {
    margin: 23px 0 0;
    width: 124px;
    height: 36px;
    background: #11bba6;
    border-radius: 9px;

    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
  }
`;

const List = () => {
  const [mutation, setMutation] = useState(0);
  const navigator = useNavigate();

  const {state} = useLocation()
  console.log(state);
  const { name, jobTitle, description, requirement } = state;
  const handleClick = ()=>{
    navigator('/mobile/interview',{
        state:{
            ...state
        }
    })
  }
  const onBack = ()=>{
    navigator('/mobile/interview/chooseJob',{
        state:{
            ...state
        }
    })
  }
  return (
    <>
      <MyStyled className="page-chooseJob">
        <NavigationHeader
          onBack = {onBack}
          title={jobTitle}
          style={{ width: "100vw" }}
        ></NavigationHeader>
        <div className="content" style={{background:'#fff',marginTop:'12px'}}>
          <div className="content-p" style={{padding:'12px 20px '}}><div className="subtitle">职位名称:  {jobTitle} </div> </div>
          <div className="content-p"><div className="subtitle"></div> <div>{description}</div></div>
          <div className="content-p"><div className="subtitle"></div> <div>{requirement} </div> </div>
          <div className="resume-wrap-footer flex">

          <JzButton
            onClick={handleClick}
            $type="primary"
            style={{width:'90%',marginTop:"20px",marginLeft:'5%'}}
          >
            使用目标岗位描述
          </JzButton>
        </div>
        </div>

        
      </MyStyled>
    </>
  );
};

export default List;
