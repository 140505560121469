import useSelector from "@/components/resume/redux/hooks/useSelector";
import { addJobDescription, getJobDescriptionsByTypeId, getJobTypesAll, updateJobDescription } from "@/http/api";
import { Cascader, ConfigProvider, Modal, Tag, message } from "antd"
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components"
import { v4 } from 'uuid'

import { Typography } from 'antd';

const { Paragraph, Text } = Typography;

const JdModalWrapStyled = styled.div`
    .divider {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #ECECEC;
    }
`

const JdTypeStyled = styled.div`
    padding-top: 26px;
    margin-bottom: 16px;
    span {
        display: inline-block;
        margin-right: 12px;
    }
`

const JdContainerStyled = styled.div`
    display: flex;
    .jd-list {
        width: 356px;
        margin-right: 16px;
        height: 464px;
        overflow-y: scroll;
    }
    .jd-list::-webkit-scrollbar {
        display: none;
    }
    .jd-detail {
        position: relative;
        flex: 1;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
        border-radius: 8px;
        padding: 20px;
        padding-bottom: 56px;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word; 
        strong {
            display: block;
        }
        .jz-detail-wrap {
            height: 370px;
            overflow-y: scroll;
        }
    }
    .jd-detail-btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 12px;
        width: 162px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #11BBA6;
        border-radius: 6px;
        color: #fff;
    }
    .jd-detail-btn:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`

const JdListItemStyled = styled.div`
    width: 100%;
    height: 116px;
    margin-bottom: 12px;
    padding: 16px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #ECECEC;
    ${props => props.$active && `border: 1px solid #11BBA6;box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.07);`}
    .jd-list-item-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .logo img {
            /* width: 24px; */
            border-radius: 4px;
            border: 1px solid #f8f8f8;
            height: 24px;
            margin-right: 6px;
        }
    }
    .jd-tag-list {
        height: 58px;
        overflow: hidden;
    }
    .jd-tag-item {
        font-size: 12px;
        height: 22px;
        line-height: 22px;
        display: inline-block;
        background: #F8F8F8;
        border-radius: 4px;
        padding: 0 8px;
        margin-right: 6px;
        margin-bottom: 6px;
    }
    &:hover {
        border: 1px solid #11BBA6;
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.07);
    }
`

const TagStyled = styled.div`
    ${props => props.$active && `background: #E7F8F6;color: #11BBA6;`}
`

const icon = <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 6 }} dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="选择职位描述" transform="translate(-797.000000, -630.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-3备份" transform="translate(541.000000, 205.000000)">
                <g id="编组-11" transform="translate(204.000000, 416.000000)">
                    <g id="新建一项备份-5" transform="translate(52.000000, 9.000000)">
                        <path d="M7.30208715,9.77742315 C7.51650859,9.9918446 7.52055428,10.3369768 7.31422421,10.5563307 L7.30208715,10.5688444 C7.0876657,10.7832658 6.74253352,10.7873115 6.52317959,10.5809814 L6.51066593,10.5688444 L3.4439087,7.50208715 C3.22948726,7.2876657 3.22544157,6.94253352 3.43177164,6.72317959 L3.4439087,6.71066593 L6.51066593,3.6439087 C6.72921086,3.42536377 7.08354221,3.42536377 7.30208715,3.6439087 C7.51650859,3.85833015 7.52055428,4.20346233 7.31422421,4.42281626 L7.30208715,4.43532992 L5.191,6.546 L12.7051306,6.54675723 C13.8143028,6.54675723 14.7155645,7.43692728 14.7334789,8.54183029 L14.7337506,12.4927124 C14.7337506,13.6018845 13.8435806,14.5031463 12.7386776,14.5210606 L11.6480347,14.5213324 C11.3389654,14.5213324 11.0884153,14.2707823 11.0884153,13.9617131 C11.0884153,13.6584754 11.3295999,13.4115693 11.630604,13.4023601 L12.7051306,13.4020938 C13.2001933,13.4020938 13.6028599,13.0064995 13.6142636,12.5141777 L13.614512,8.57537723 C13.614512,8.08031458 13.2189177,7.67764794 12.726596,7.66624421 L12.7051306,7.66599585 L5.19,7.665 L7.30208715,9.77742315 Z" id="形状结合" transform="translate(9.006875, 9.000666) scale(1, -1) translate(-9.006875, -9.000666) "></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

// type: select 选择, add 新增
const JdModal = ({ type = 'select', visible, onCancel, onChange }) => {
    const [selectId, setSelectId] = useState(1)
    // const [options, setOptions] = useState([])
    const options = useSelector(state => state.interview.jdList);
    const [path, setPath] = useState([])
    const [list, setList] = useState([])
    const detailRef = useRef(null)
    const selectObj = useMemo(() => {
        return list.find(item => item.id === selectId) || {}
    }, [list, selectId])

    useEffect(() => {
        if (options && options.length > 0) {
            const firstId = options[0].value
            const secondId = options[0].children[0].value
            const thirdId = options[0].children[0].children[0].value
            const p = [firstId, secondId, thirdId]
            setPath(p)
            getJdListById(thirdId)
        }
    }, [options])

    const getJdListById = async (id) => {
        const result = await getJobDescriptionsByTypeId({
            query: {
                jobTypeId: id
            }
        })
        if (result) {
            const newList=  result.map(item => ({
                ...item,
                tags: item.tags.filter(i => i.name).slice(0, 8),
                id: v4()
            }))
            setList(newList)
            setSelectId(newList[0].id)
        }
    }

    const handleChange = (data) => {
        getJdListById(data[2])
        setPath(data)
    }

    const handleClickJd = (id) => {
        setSelectId(id)
    }

    const handleSave = () => {
        if (type === 'select') {
            onChange(type, detailRef.current.innerText,selectId)
        } else if (type === 'add') {
            onChange(type, detailRef.current.innerText,selectId)
        }
        
        onCancel && onCancel()
    }

    return <Modal title="选择岗位" open={visible} onCancel={onCancel} width={990} footer={null}>
        <JdModalWrapStyled>
            <div className="divider" />
            <JdTypeStyled>
                <span>职位类型 </span>
                <ConfigProvider theme={{
                    components: {
                        Cascader: {
                            controlWidth: 290,
                            dropdownHeight: 300
                        }
                    }
                }}>
                    <Cascader value={path} options={options} onChange={handleChange} onClear={() => setPath([])} />
                </ConfigProvider>
            </JdTypeStyled>
            <JdContainerStyled>
                <div className="jd-list">
                    {list.map(item => {
                        return <JdListItemStyled $active={selectId === item.id} key={item.id} className="jd-list-item" onClick={() => handleClickJd(item.id)}>
                            <div className="jd-list-item-header">
                                <Paragraph ellipsis={true} style={{ marginBottom: 0 }}>
                                    {item.jobTitle}
                                </Paragraph>
                                <span className="logo">
                                    <img src={item.corporateIcon} />
                                    <Paragraph ellipsis={true} style={{ marginBottom: 0 }}>
                                        {item.corporateName}
                                    </Paragraph>
                                </span>
                            </div>
                            <div className="jd-tag-list">
                                {
                                    item.tags.map(tag => {
                                        return <TagStyled $active={
                                            ['year', 'degree', 'city'].includes(tag.type)
                                        } key={tag} className="jd-tag-item">{tag.name}</TagStyled>
                                    })
                                }
                            </div>
                        </JdListItemStyled>
                    })}
                </div>
                <div className="jd-detail">
                    <div ref={detailRef} className="jz-detail-wrap">
                        <strong style={{ marginBottom: 15 }}>{selectObj?.jobTitle}</strong>
                        <p>{selectObj?.formattedDescription}</p>
                    </div>

                    <div onClick={handleSave} className="jd-detail-btn">
                        {
                            type === 'add' ? '创建' : <>
                                {icon}
                                替换
                            </>
                        }
                    </div>
                </div>
            </JdContainerStyled>
        </JdModalWrapStyled>
    </Modal>
}

export default JdModal