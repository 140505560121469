import useQueryParam from "@/hooks/useQueryParam"
import { updateAnswerQuestion } from "@/http/api"
import { Spin, message } from "antd"
import { useState } from "react"
import styled from "styled-components"
import useSelector from "@/components/resume/redux/hooks/useSelector"
import useDispatch from "@/components/resume/redux/hooks/useDispatch"
import interviewSlice from "@/components/resume/redux/services/interviewSlice"


const KeyboardComponentStyled = styled.div`
    textarea {
        border-radius: 8px;
        padding: 14px;
        background-color: #fff;
        border: 1px solid #EAEAEB;
        outline: none;
        height: 282px;
        width: 323px;
        box-sizing: border-box;
        color: #333;
        resize: none;
    }

    textarea::placeholder {
        color: #D3D3D3;
    }
`

const SubmitButtonStyled = styled.div`
    width: 242px;
    height: 36px;
    border-radius: 6px;
    background: #DEDEDE;
    cursor: not-allowed;
    ${props => props.$active && `background: #11BBA6; cursor: pointer;`}
    color: #FFFFFF;
`

const KeyboardComponent = ({ onChange, answerId, questionId }) => {
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)
    const resumeId = useQueryParam('id')
    const isContentModified = useSelector(state => state.interview.isContentModified)
    const dispatch = useDispatch()

    const handleClick = (event) => {
        event.stopPropagation()
        if (loading) return 

        if (value.length > 0) {
            setLoading(true)
            const formData = new FormData()
            formData.append('type', 'text')
            formData.append('questionId', questionId)
            formData.append('resumeId', parseInt(resumeId))
            formData.append('answerId', answerId)
            formData.append('answer', value)
            // 提交一下数据
            updateAnswerQuestion({
                body: {
                    type: 'text',
                    questionId,
                    resumeId: parseInt(resumeId),
                    // 新逻辑不传
                    // answerId,
                    answer: value
                }
            }).then(res => {
                onChange && onChange(res)
            }).catch(err => {

            }).finally(() => {
                setLoading(false)
                dispatch(interviewSlice.actions.setIsContentModified(false))
            })
        }
    }

    const handleChange = (e) => {
        const value = e.target.value
        setValue(value)
        if (!isContentModified) {
            if (value) {
                dispatch(interviewSlice.actions.setIsContentModified(true))
            } else {
                dispatch(interviewSlice.actions.setIsContentModified(false))
            }
        }
    }

    return <KeyboardComponentStyled>
        <Spin spinning={loading} size="large">
            <textarea value={value} onChange={handleChange} placeholder="在此输入您的答案…" />
        </Spin>

        <div className="flex-center" style={{ marginTop: 16 }}>
            <SubmitButtonStyled onClick={handleClick} $active={value.length > 0} className="submit flex-center">提交</SubmitButtonStyled>
        </div>
    </KeyboardComponentStyled>
}

export default KeyboardComponent