import withComponent from '@/hoc/withComponent';
import styled from 'styled-components';

const ItemStyled = styled.div`
  height: 53px;
  text-align: right;
  width: 100%;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 53px;
`;

export default withComponent((props) => {
    const {text1,text2} = props;
    return <ItemStyled>
      {text1}&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#B5B5B5' }}>{text2}</span>
    </ItemStyled>;
  },
);
