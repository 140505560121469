
export const vipLastId = 26;
export const svipLastId = 28;
export const vip_m = 11;
export const vip_y = 12;
export const vip_life = 13;
export const vip_t_y = 26;
export const svip_m = 20;
export const svip_y = 21;
export const svip_life = 22;
export const svip_t_y = 28;

export const vipList = [vip_m, vip_y, vip_life, vip_t_y, vipLastId];
export const sVipList = [svip_m, svip_y, svip_life, svip_t_y, svipLastId];



export const upgradeSVipOrderConfig = [
  { id: svip_life, text: '升级终身SVIP' },
  { id: svip_y, text: '升级年SVIP' },
  { id: svip_m, text: '升级月SVIP' },
];
/*export const upgradeSVipOrderConfig = [
  { id: svip_t_y, text: '升级三年SVIP' },
  { id: svip_y, text: '升级年SVIP' },
  { id: svip_m, text: '升级月SVIP' },
];*/
export const singlePageOrderConfig = [
  { id: 1, text: '单页下载' },
];
export const vipTypeEnum = {
  svip: 'svip',
  vip: 'vip',
  upgradeSVip: 'upgradeSVip',
  singlePage: 'singlePage',
};

export const payLogoImage = `https://oss.jianzeppt.com/user/12426/05c15a87-1b4f-48fe-b47c-d382d85b1919.png?x-oss-process=image/quality,Q_50`;
export const weChatLogoImage = `https://oss.jianzeppt.com/user/12426/1c8fa6bd-6e2e-462a-9a19-c20b4e2b140d.png?x-oss-process=image/quality,Q_50`;
export const closeIcon = <div style={{ width: 16, height: 16 }} dangerouslySetInnerHTML={{
  __html: `<svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='position: absolute;top: 0;'>
    <g id='模板过滤/切换页面类型' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='单个付费-弹窗' transform='translate(-1172.000000, -380.000000)' fill='#2C2C2C'>
            <g id='编组' transform='translate(722.000000, 368.000000)'>
                <g id='字体下拉备份-6' transform='translate(450.000000, 12.000000)'>
                    <g id='编组-11' transform='translate(2.000000, 2.000000)'>
                        <polygon id='路径' points='0.0458891013 10.9363474 10.9110163 0 11.9541109 1.06365259 1.08810119 12'></polygon>
                        <polygon id='路径' points='10.9674952 11.9527067 0 1.12076145 1.03250478 0.0472932778 12 10.8783462'></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}} />;
export const close2Icon = <div style={{ width: 24, height: 24 }} dangerouslySetInnerHTML={{
  __html: `<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
  <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
    <g id='升级SVIP' transform='translate(-1052.000000, -66.000000)'>
      <g id='关闭' transform='translate(1052.000000, 66.000000)'>
        <circle id='椭圆形' fill='#5C5C5C' cx='12' cy='12' r='12'></circle>
        <g id='closel' transform='translate(7.000000, 7.000000)' fill='#FFFFFF' fill-rule='nonzero'>
          <path d='M9.7663551,8.65168539 L6.11645342,5.00039471 L9.76740755,1.34910402 C10.0768283,1.03965476 10.0778807,0.540746783 9.76846001,0.231297529 C9.46009173,-0.0770991764 8.95912483,-0.0770991764 8.651809,0.231297529 L4.99980241,3.88258822 L1.34779583,0.231297529 C1.04048,-0.0770991764 0.540565552,-0.0770991764 0.231144821,0.231297529 C-0.0761710068,0.539694235 -0.0761710068,1.04070731 0.233249724,1.35015657 L3.88315141,5.00039471 L0.232197273,8.65063284 C-0.0772234582,8.95902955 -0.0772234582,9.45899008 0.231144821,9.76843933 C0.385855187,9.92421651 0.590030771,10 0.78999655,10 C0.989962328,10 1.19413791,9.92421651 1.34779583,9.76949188 L4.99980241,6.11820119 L8.651809,9.76949188 C8.80651937,9.92316396 9.00859005,10 9.20960828,10 C9.41167896,10 9.61374964,9.92421651 9.76740755,9.76949188 C10.0757758,9.45899008 10.0768283,8.9600821 9.7663551,8.65168539 Z' id='路径'></path>
        </g>
      </g>
    </g>
  </g>
</svg>`,
}} />;
