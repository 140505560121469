import FlexStyled from "@/styled/FlexStyled";
import Pointer from "@/styled/Pointer";
import React, {useMemo} from "react";
import styled from "styled-components";

const closeIcon = <div style={{height: 16, width: 16, position: 'relative'}} dangerouslySetInnerHTML={{
    __html: `<svg width='100%' height='100%' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='position: absolute;top:0:left:0'>
    <g id='添加新页' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='添加新页--输入内容状态' transform='translate(-1870.000000, -98.000000)' fill='#999999' fill-rule='nonzero'>
            <g id='叉-关闭' transform='translate(1870.000000, 98.000000)'>
                <g id='编组-11' transform='translate(2.000000, 2.000000)'>
                    <polygon id='路径' points='0.0458891013 10.9363474 10.9110163 0 11.9541109 1.06365259 1.08810119 12'></polygon>
                    <polygon id='路径' points='10.9674952 11.9527067 0 1.12076145 1.03250478 0.0472932778 12 10.8783462'></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}}/>;
const HeaderContainerStyled = styled.div`
  flex-shrink: 0;
  height: 54px;
  padding: 0 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(224, 224, 224);
  position: relative;
`;
const TitlePositionStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 14px;
  font-weight: bold;
  color: #2C2C2C;
  padding: 0 20px;
  text-align: center;
`
const TitleTextStyled = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #2C2C2C;
  line-height: 54px;
`

export default (props) => {
    const {title, titleTextAlign = "left", onCancel, bottomBorderVisible = true} = props;


    return <HeaderContainerStyled style={{borderBottomColor: bottomBorderVisible ? undefined : "transparent"}}>
        <TitlePositionStyled style={{textAlign: titleTextAlign}}>
            <TitleTextStyled>
                {title}
            </TitleTextStyled>
        </TitlePositionStyled>
        <FlexStyled $alignItems='center' style={{position: 'absolute', top: 0, bottom: 0, right: 20}}>
            <Pointer onClick={onCancel}>{closeIcon}</Pointer>
        </FlexStyled>
    </HeaderContainerStyled>
}
