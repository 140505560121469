import withComponent from "@/hoc/withComponent";
import styled from "styled-components";
import Link from "@/components/resume/modules/portfolio/Link";
import {useSelector} from "@/components/resume/redux/hooks";
import getNestedProperty from "@/utils/getNestedProperty";
import {Module} from "@/common/module/module";
import Images from "@/components/resume/modules/portfolio/Images";
import useFieldVisible from "@/components/resume/hooks/useFieldVisible";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import {PORTFOLIO_FIELD_ENUM} from "@/common/field/field";

const ContainerStyled = styled.div`
`
export const CloseStyled = styled.div`
  width: 14px;
  height: 14px;
  background: #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`
export const closeIcon = <div dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="作品展示、自定义展示" transform="translate(-676.000000, -466.000000)" fill="#838383">
            <g id="编组-3" transform="translate(675.000000, 465.000000)">
                <g id="字体下拉备份-6" transform="translate(1.000000, 1.000000)">
                    <g id="编组-11" transform="translate(2.250000, 2.250000)">
                        <path d="M6.8193852,0 L7.47131931,0.664782867 L4.409,3.746 L7.5,6.79896639 L6.85468451,7.4704417 L3.753,4.407 L0.680063245,7.5 L0.0286806883,6.83521713 L3.091,3.753 L0,0.700475907 L0.645315488,0.0295582986 L3.747,3.092 L6.8193852,0 Z" id="形状结合"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>


export default withComponent((props) => {
    const {className} = props;
    const moduleInstance = useModuleInstance()

    const link_visible = useFieldVisible(moduleInstance?.data?.id, PORTFOLIO_FIELD_ENUM.LINK);
    const picture_visible = useFieldVisible(moduleInstance?.data?.id, PORTFOLIO_FIELD_ENUM.PICTURE);

    return <ContainerStyled className={className}>
        {
            picture_visible && <Images/>
        }
        {
            link_visible && <Link/>
        }
    </ContainerStyled>
})
