import {Module} from "@/common/module/module";
import {
    APPLICATION_MODULE_ENUM,
    CONTACT_INFORMATION_ENUM,
    CUSTOM_MODULE_FIELD_ENUM, INTEREST_FIELD_ENUM, SELF_EVALUATION_FIELD_ENUM,
} from "@/common/field/field";
import {
    LANGUAGE_PROFICIENCY_FIELD_ENUM,
    HONORS_AND_AWARDS_FIELD_ENUM,
    INTERNSHIP_EXPERIENCE_FIELD_ENUM,
    PROJECT_EXPERIENCE_FIELD_ENUM,
    CAMPUS_ACTIVITIES_FIELD_ENUM
} from "@/common/field/field";
import {
    EDUCATIONAL_BACKGROUND_FIELD_ENUM, PORTFOLIO_FIELD_ENUM, SKILLS_FIELD_ENUM, WORK_EXPERIENCE_FIELD_ENUM
} from "@/common/field/field";
import {
    JOB_INTEREST_FIELD_ENUM,
} from "@/common/field/field";

const TITLE_TEXT = "栏目标题"

export default (block_name, field_name) => {
    switch (field_name) {
        case JOB_INTEREST_FIELD_ENUM.JOB_TYPE: {
            return "求职岗位"
        }
        case JOB_INTEREST_FIELD_ENUM.ROLE: {
            return "意向岗位"
        }
        case JOB_INTEREST_FIELD_ENUM.CITY: {
            return "意向城市"
        }
        case JOB_INTEREST_FIELD_ENUM.SALARY: {
            return "薪资"
        }
        case JOB_INTEREST_FIELD_ENUM.JOB_STATUS: {
            return "求职状态"
        }
    }

    switch (block_name) {
        case Module.BASE: {
            return field_name
        }
        case Module.AVATAR: {
            return "头像"
        }
        case Module.CONTACT_INFORMATION: {
            switch (field_name) {
                case CONTACT_INFORMATION_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case CONTACT_INFORMATION_ENUM.PHONE_NUMBER: {
                    return "手机号"
                }
                case CONTACT_INFORMATION_ENUM.MAIL: {
                    return "邮箱"
                }

            }
            break
        }
        case Module.JOB_INTEREST: {
            switch (field_name) {
                case JOB_INTEREST_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
            }
            break
        }
        case Module.EDUCATIONAL_BACKGROUND: {
            switch (field_name) {
                case  EDUCATIONAL_BACKGROUND_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case  EDUCATIONAL_BACKGROUND_FIELD_ENUM.DATE: {
                    return "日期"
                }
                case  EDUCATIONAL_BACKGROUND_FIELD_ENUM.SCHOOL: {
                    return "学校名称"
                }
                case  EDUCATIONAL_BACKGROUND_FIELD_ENUM.MAJOR: {
                    return "专业"
                }
                case  EDUCATIONAL_BACKGROUND_FIELD_ENUM.EDUCATION: {
                    return "学历"
                }
                case  EDUCATIONAL_BACKGROUND_FIELD_ENUM.DETAILS: {
                    return "详情"
                }
            }
            break
        }
        case Module.CAMPUS_ACTIVITIES: {
            switch (field_name) {
                case  CAMPUS_ACTIVITIES_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case  CAMPUS_ACTIVITIES_FIELD_ENUM.DATE: {
                    return "日期"
                }
                case  CAMPUS_ACTIVITIES_FIELD_ENUM.ACTIVITY_NAME: {
                    return "活动名称"
                }
                case  CAMPUS_ACTIVITIES_FIELD_ENUM.ROLE: {
                    return "角色名称"
                }
                case  CAMPUS_ACTIVITIES_FIELD_ENUM.DETAILS: {
                    return "项目详情"
                }
            }
            break
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            switch (field_name) {
                case INTERNSHIP_EXPERIENCE_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case INTERNSHIP_EXPERIENCE_FIELD_ENUM.DATE: {
                    return "日期"
                }
                case INTERNSHIP_EXPERIENCE_FIELD_ENUM.ACTIVITY_NAME: {
                    return "活动名称"
                }
                case INTERNSHIP_EXPERIENCE_FIELD_ENUM.ROLE: {
                    return "角色名称"
                }
                case INTERNSHIP_EXPERIENCE_FIELD_ENUM.DETAILS: {
                    return "项目详情"
                }
            }
            break
        }
        case Module.WORK_EXPERIENCE: {
            switch (field_name) {
                case WORK_EXPERIENCE_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case WORK_EXPERIENCE_FIELD_ENUM.DATE: {
                    return "日期"
                }
                case WORK_EXPERIENCE_FIELD_ENUM.POSITION: {
                    return "岗位名称"
                }
                case WORK_EXPERIENCE_FIELD_ENUM.COMPANY: {
                    return "公司名称"
                }
                case WORK_EXPERIENCE_FIELD_ENUM.DETAILS: {
                    return "项目详情"
                }
            }
            break
        }
        case Module.PROJECT_EXPERIENCE: {
            switch (field_name) {
                case PROJECT_EXPERIENCE_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case PROJECT_EXPERIENCE_FIELD_ENUM.DATE: {
                    return "日期"
                }
                case PROJECT_EXPERIENCE_FIELD_ENUM.ROLE: {
                    return "角色名称"
                }
                case PROJECT_EXPERIENCE_FIELD_ENUM.NAME: {
                    return "项目名称"
                }
                case PROJECT_EXPERIENCE_FIELD_ENUM.DETAILS: {
                    return "项目详情"
                }
            }
            break
        }
        case Module.PORTFOLIO: {
            switch (field_name) {
                case PORTFOLIO_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case PORTFOLIO_FIELD_ENUM.LINK: {
                    return "链接"
                }
                case PORTFOLIO_FIELD_ENUM.PICTURE: {
                    return "图集"
                }
            }
            break
        }
        case Module.LANGUAGE_PROFICIENCY: {
            switch (field_name) {
                case LANGUAGE_PROFICIENCY_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case LANGUAGE_PROFICIENCY_FIELD_ENUM.ITEM: {
                    return ""
                }
                case LANGUAGE_PROFICIENCY_FIELD_ENUM.DETAILS: {
                    return "详情"
                }
            }
            break
        }
        case Module.INTEREST: {
            switch (field_name) {
                case INTEREST_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case INTEREST_FIELD_ENUM.DETAILS: {
                    return "详情"
                }
            }
            break
        }
        case Module.SKILLS: {
            switch (field_name) {
                case SKILLS_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case SKILLS_FIELD_ENUM.DETAILS: {
                    return "详情"
                }
            }
            break
        }
        case Module.HONORS_AND_AWARDS: {
            switch (field_name) {
                case HONORS_AND_AWARDS_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case HONORS_AND_AWARDS_FIELD_ENUM.DETAILS: {
                    return "详情"
                }
            }
            break
        }
        case Module.SELF_EVALUATION: {
            switch (field_name) {
                case SELF_EVALUATION_FIELD_ENUM.TITLE: {
                    return TITLE_TEXT
                }
                case SELF_EVALUATION_FIELD_ENUM.DETAILS: {
                    return "详情"
                }
            }
            break
        }
        case Module.CUSTOM_MODULE: {
            switch (field_name) {
                case CUSTOM_MODULE_FIELD_ENUM.TITLE: {
                    return '自定义标题'
                }
                case CUSTOM_MODULE_FIELD_ENUM.DETAILS: {
                    return "详情"
                }
            }
            break
        }
        case Module.APPLICATION_INFO: {
            switch (field_name) {
                case APPLICATION_MODULE_ENUM.TITLE: {
                    return '栏目标题'
                }
                case APPLICATION_MODULE_ENUM.SCHOOL: {
                    return '学校'
                }
                case APPLICATION_MODULE_ENUM.MAJOR: {
                    return "专业"
                }
                case APPLICATION_MODULE_ENUM.TABLE: {
                    return "表格"
                }
            }
            break
        }
    }
}
