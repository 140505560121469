import {produce} from 'immer';
import {Module} from "@/common/module/module";
import {
    CAMPUS_ACTIVITIES_SLOT_ENUM,
    CUSTOM_MODULE_SLOT_ENUM,
    EDUCATIONAL_BACKGROUND_SLOT_ENUM,
    INTEREST_SLOT_ENUM,
    INTERNSHIP_EXPERIENCE_SLOT_ENUM,
    JOB_INTEREST_SLOT_ENUM,
    LANGUAGE_PROFICIENCY_SLOT_ENUM,
    PORTFOLIO_SLOT_ENUM,
    PROJECT_EXPERIENCE_SLOT_ENUM,
    SELF_EVALUATION_SLOT_ENUM,
    SKILLS_SLOT_ENUM,
    WORK_EXPERIENCE_SLOT_ENUM
} from "@/common/slot/slot";

export default (data) => {
    return produce(data, (data) => {
        Object.keys(data).forEach((key) => {
            const item = data[key];
            if (key === Module.WORK_EXPERIENCE) {
                delete data[Module.WORK_EXPERIENCE].data[WORK_EXPERIENCE_SLOT_ENUM.TITLE_ICON]
            } else if (key === Module.JOB_INTEREST) {
                delete data[Module.JOB_INTEREST].data[JOB_INTEREST_SLOT_ENUM.TITLE_ICON]
                delete data[Module.JOB_INTEREST].data[JOB_INTEREST_SLOT_ENUM.JOB_TYPE_ICON]
                delete data[Module.JOB_INTEREST].data[JOB_INTEREST_SLOT_ENUM.ROLE_ICON]
                delete data[Module.JOB_INTEREST].data[JOB_INTEREST_SLOT_ENUM.CITY_ICON]
                delete data[Module.JOB_INTEREST].data[JOB_INTEREST_SLOT_ENUM.SALARY_ICON]
                delete data[Module.JOB_INTEREST].data[JOB_INTEREST_SLOT_ENUM.JOB_STATUS_ICON]
            } else if (key === Module.PROJECT_EXPERIENCE) {
                delete data[Module.PROJECT_EXPERIENCE].data[PROJECT_EXPERIENCE_SLOT_ENUM.TITLE_ICON]
            } else if (key === Module.INTERNSHIP_EXPERIENCE) {
                delete data[Module.INTERNSHIP_EXPERIENCE].data[INTERNSHIP_EXPERIENCE_SLOT_ENUM.TITLE_ICON]
            } else if (key === Module.CAMPUS_ACTIVITIES) {
                delete data[Module.CAMPUS_ACTIVITIES].data[CAMPUS_ACTIVITIES_SLOT_ENUM.TITLE_ICON]
            } else if (key === Module.SELF_EVALUATION) {
                delete data[Module.SELF_EVALUATION].data[SELF_EVALUATION_SLOT_ENUM.TITLE_ICON]
            } else if (key === Module.SKILLS) {
                delete data[Module.SKILLS].data[SKILLS_SLOT_ENUM.TITLE_ICON]
            } else if (key === Module.INTEREST) {
                delete data[Module.INTEREST].data[INTEREST_SLOT_ENUM.TITLE_ICON]
            } else if (key === Module.LANGUAGE_PROFICIENCY) {
                delete data[Module.LANGUAGE_PROFICIENCY].data[LANGUAGE_PROFICIENCY_SLOT_ENUM.TITLE_ICON]
            } else if (key === Module.CUSTOM_MODULE) {
                delete data[Module.CUSTOM_MODULE].data[CUSTOM_MODULE_SLOT_ENUM.TITLE_ICON]
            } else if (key === Module.PORTFOLIO) {
                delete data[Module.PORTFOLIO].data[PORTFOLIO_SLOT_ENUM.TITLE_ICON]
            } else if (key === Module.EDUCATIONAL_BACKGROUND) {
                delete data[Module.EDUCATIONAL_BACKGROUND].data[EDUCATIONAL_BACKGROUND_SLOT_ENUM.TITLE_ICON]
            } else {
                if (item.data.title_icon) {
                    delete item.data.title_icon
                }
            }
        })

    })
}
