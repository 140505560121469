import styled from 'styled-components';
import {useLocation} from "react-router-dom"
import {useSelector, useStore} from "react-redux"
import Sider from './component/Sider';
import FlexStyled from '@/styled/FlexStyled';
// import Remind from '@/pages/PersonalCenter/component/Remind';
import DownList from '@/pages/PersonalCenter/component/DownList';
import AIChatGPTList from '@/pages/PersonalCenter/component/AIChatGPTList';
// import RedeemCode from '@/pages/PersonalCenter/component/RedeemCode';
import {useEffect, useState} from 'react';
import Header from './component/Header';
import Telephone from '@/pages/PersonalCenter/component/Telephone';
import isLogin from '@/common/isLogin';
import {fetchUserThunk} from "@/components/resume/redux/thunk/user";
import qs from "qs";
import useQuery from "@/hooks/useQuery";
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js"

const BoxStyled = styled.div`
  flex-grow: 1;
  margin: auto;
  width: 1200px;
  background: #FFFFFF;
  box-shadow: 0px 2px 15px 0px rgba(197, 197, 197, 0.5);
  display: flex;
  flex-direction: column;
`;
const HeaderStyled = styled.div`
  height: 66px;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  display: flex;
  align-items: center;
  padding: 0 18px;
  flex-shrink: 0;
`;
const AStyled = styled.img`
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
`;
const UserNameStyled = styled.div`
  width: 49px;
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8694AB;
  line-height: 12px;
  white-space: nowrap;
`;
// const typeList = [{ id: 'down', text: '下载记录' }, { id: 'ai', text: 'AIChatGPT记录' }, { id: 'code', text: 'VIP兑换码' }];
// const typeList = [{ id: 'down', text: '下载记录' }, { id: 'ai', text: 'AI ChatGPT记录' }];


export default () => {
    const query = useQuery();
    const store = useStore();
    const {type: defaultType} = query;
    const user = useSelector((state) => state.user);
    const [type, setType] = useState(defaultType ? defaultType : 'down'); //ai down  code
    const isL = isLogin();
    const typeList = isLogin() ? [{id: 'down', text: '下载记录'}, {id: 'ai', text: 'AI ChatGPT记录'},
    // {id: 'ai', text: 'AI ChatGPT记录'}
    {
        id: 'telephone',
        text: '手机号绑定'
    },
  ] : [{id: 'down', text: '下载记录'}, 
  // {id: 'ai', text: 'AI ChatGPT记录'}
];

    useEffect(() => {
        const {dispatch} = store;
        dispatch(fetchUserThunk());
    }, []);

    return <FlexStyled $flexDirection='column' style={{height: '100vh', width: '100vw'}}>
        <Header/>
        <BoxStyled>
            <HeaderStyled>
                <AStyled src={user?.icon}/>
                <UserNameStyled style={{marginLeft: 11}}>{user?.userName}</UserNameStyled>
            </HeaderStyled>
            <FlexStyled $flexGrow={1}>
                <Sider type={type} list={typeList} setType={setType}/>
                <FlexStyled $flexGrow={1}>
                    {type === 'telephone' && isL && <Telephone/>}
                    {type === 'down' && <DownList />}
                    {type === 'ai' && <AIChatGPTList />}
                </FlexStyled>
            </FlexStyled>
        </BoxStyled>
    </FlexStyled>;
}
