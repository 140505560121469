const config = [
  {
    title: "颜色",
    children: ["全部","蓝色", "棕色","黑色"],
  },
  {
    title: "风格",
    children: ["全部", "时尚", "创意", "简洁", "经典"],
  },

  {
    title: "语言",
    children: ["全部", "中文", "英文"],
  },

  {
    title: "用途",
    children: [
      "全部",
      "求职简历",
      "实习简历",
      "校招简历",
      "内推简历",
      // '考研简历', '留学简历'
    ],
  },
  {
      title: "行业",
      children: [
          '全部',
          "互联网通信",
          "金融投资",
          "房地产建筑",
          "休闲服务",
          "教育培训",
          "广告传媒",
          "医疗制药",
          "消费运输",
          "制造能源",
          "职能支持",
      ]
  },
  {
    title: "职位",
    children: {
        全部:[],
      后端开发: [
        "数据库开发工程师",
        "应用开发工程师",
        "软件架构师",
        "Java后端工程师",
        "Python后端工程师",
      ],
      测试: ["功能测试工程师", "自动化测试工程师"],
      移动开发: ["Android开发工程师", "iOS开发工程师"],
      "运维/技术支持": ["系统运维工程师", "数据库管理员"],
      数据分析师: ["商业数据分析师", "用户行为分析师"],
      项目管理: ["项目经理", "IT项目经理", "建筑项目经理"],
      高端技术职位: ["市场总监", "销售副总裁"],
      硬件开发: ["嵌入式工程师", "FPGA开发工程师"],
      前端开发: ["React开发工程师", "Vue.js开发工程师"],
      通信: [
        "网络安全专家",
        "网络工程师",
        "网络管理员",
        "网络安全工程师",
        "通信工程师",
        "通信网络管理",
      ],
      人工智能: ["人工智能工程师", "机器学习工程师", "深度学习工程师"],
      "视觉/交互设计": ["UI设计师", "UX设计师"],
      用户研究: ["数据科学家", "用户研究员", "用户测试工程师"],
      "市场/营销": ["市场分析师", "品牌经理", "市场策划经理", "数字营销经理"],
      公关媒介: ["公关经理", "媒体关系专员"],
      销售: ["保险顾问", "企业销售代表", "零售销售顾问"],
      会展会务: ["会展策划经理", "会展执行专员"],
      广告: ["平面设计师", "广告策划经理", "广告设计师"],
      销售管理: [
        "外贸业务员",
        "采购经理",
        "网络营销专员",
        "销售主管",
        "区域销售经理",
      ],
      投融资: ["投资经理", "融资顾问"],
      银行: ["银行客户经理", "银行信贷员"],
      保险: ["保险经纪人", "保险顾问", "保险理赔专员"],
      "证券/基金/期货": ["证券分析师", "基金经理"],
      "建筑/规划设计": [
        "建筑师",
        "室内设计师",
        "土木工程师",
        "建筑师",
        "城市规划设计师",
      ],
      物业管理: ["物业经理"],
      工程管理: ["工程监理", "工程造价师"],
      翻译: ["英语翻译", "日语翻译"],
      旅游服务: ["旅行社经理", "旅游顾问", "导游", "旅行顾问"],
      "旅游产品开发/策划": ["旅游线路策划"],
      汽车制造: ["汽车设计师", "汽车测试工程师"],
      "能源/地质": [
        "航空工程师",
        "地质学家",
        "气象学家",
        "能源工程师",
        "地质勘探工程师",
      ],
      教务管理: ["教务主任"],
      地产项目管理: ["地产项目经理", "地产开发策划"],
      产品经理: ["互联网产品经理", "移动应用产品经理"],
      运营客服: ["客户关系经理", "客服专员", "客服主管"],
      人力资源: ["人力资源经理", "人力资源专员", "招聘专员"],
      行政: ["行政助理", "行政主管"],
      财务: [
        "金融分析师",
        "精算师",
        "会计师",
        "预算分析师",
        "审计师",
        "税务顾问",
        "风险管理师",
        "证券经纪人",
        "投资银行家",
        "财务分析师",
        "财务主管",
      ],
      高级管理职位: ["公司董事", "运营总监", "创意总监", "高级管理"],
      法务: ["法务专员", "律师", "法务专员", "企业法律顾问"],
      "采编/写作/出版": ["科技记者", "报道记者", "编辑"],

      影视媒体: [
        "舞台设计师",
        "影片剪辑师",
        "摄影师",
        "动画师",
        "广告设计师",
        "电影摄影师",
        "影视导演",
        "音效设计师",
        "音乐制作人",
        "自媒体",
      ],
      职业培训: ["职业培训师"],
      生物制药: ["药物研发工程师", "生物制药技术员"],

      "护士/护理": ["护士", "护士"],
      "医生/医技": ["药剂师", "医生", "临床医生", "医学检验师"],
      健康整形: ["整形医生", "美容顾问"],
      医疗器械: ["医疗器械销售", "医疗器械维修技师"],
      酒店经理: ["酒店经理", "酒店前台经理"],

      
  

    },
  },
];

export const getConfigNameByTypeAndIndex = (type = "风格", index = 0) => {
  if (type === '职位') {
    const c = config.find((item)=>item.title === '职位')
    const arr = Object.keys(c.children)
    return arr[index]  || "全部"
  } else {
    return config.find((item) => item.title === type)?.children[index] || "全部";
  }
};

export const getFilterConfigByType = (type = "风格") => {
  return config.find((item) => item.title === type).children || [];
};

export const configMap = {
  title: "职位",
  children: {
      全部:[],
    后端开发: [
      "数据库开发工程师",
      "应用开发工程师",
      "软件架构师",
      "Java后端工程师",
      "Python后端工程师",
    ],
    影视媒体: [
      "舞台设计师",
      "影片剪辑师",
      "摄影师",
      "动画师",
      "广告设计师",
      "电影摄影师",
      "影视导演",
      "音效设计师",
      "音乐制作人",
      "自媒体",
    ],
    广告: ["平面设计师", "广告策划经理", "广告设计师"],
    "采编/写作/出版": ["科技记者", "报道记者", "编辑"],
    通信: [
      "网络安全专家",
      "网络工程师",
      "网络管理员",
      "网络安全工程师",
      "通信工程师",
      "通信网络管理",
    ],
    "能源/地质": [
      "航空工程师",
      "地质学家",
      "气象学家",
      "能源工程师",
      "地质勘探工程师",
    ],
    旅游服务: ["旅行社经理", "旅游顾问", "导游", "旅行顾问"],
    保险: ["保险经纪人", "保险顾问", "保险理赔专员"],
    财务: [
      "金融分析师",
      "精算师",
      "会计师",
      "预算分析师",
      "审计师",
      "税务顾问",
      "风险管理师",
      "证券经纪人",
      "投资银行家",
      "财务分析师",
      "财务主管",
    ],
    酒店经理: ["酒店经理", "酒店前台经理"],
    "建筑/规划设计": [
      "建筑师",
      "室内设计师",
      "土木工程师",
      "建筑师",
      "城市规划设计师",
    ],
    "医生/医技": ["药剂师", "医生", "临床医生", "医学检验师"],
    "护士/护理": ["护士", "护士"],
    人工智能: ["人工智能工程师", "机器学习工程师", "深度学习工程师"],
    项目管理: ["项目经理", "IT项目经理", "建筑项目经理"],
    高级管理职位: ["公司董事", "运营总监", "创意总监", "高级管理"],
    "市场/营销": ["市场分析师", "品牌经理", "市场策划经理", "数字营销经理"],
    销售管理: [
      "外贸业务员",
      "采购经理",
      "网络营销专员",
      "销售主管",
      "区域销售经理",
    ],
    教师: [
      "研究员",
      "讲师",
      "教师",
      "音乐教师",
      "舞蹈教练",
      "体育教练",
      "小学教师",
      "高中教师",
    ],
    法务: ["法务专员", "律师", "法务专员", "企业法律顾问"],
    用户研究: ["数据科学家", "用户研究员", "用户测试工程师"],
    销售: ["保险顾问", "企业销售代表", "零售销售顾问"],
    运营客服: ["客户关系经理", "客服专员", "客服主管"],
    人力资源: ["人力资源经理", "人力资源专员", "招聘专员"],
    产品经理: ["互联网产品经理", "移动应用产品经理"],
    移动开发: ["Android开发工程师", "iOS开发工程师"],
    测试: ["功能测试工程师", "自动化测试工程师"],
    "证券/基金/期货": ["证券分析师", "基金经理"],
    教务管理: ["教务主任"],
    前端开发: ["React开发工程师", "Vue.js开发工程师"],
    "运维/技术支持": ["系统运维工程师", "数据库管理员"],
    数据分析师: ["商业数据分析师", "用户行为分析师"],
    硬件开发: ["嵌入式工程师", "FPGA开发工程师"],
    公关媒介: ["公关经理", "媒体关系专员"],
    高端技术职位: ["市场总监", "销售副总裁"],
    会展会务: ["会展策划经理", "会展执行专员"],
    物业管理: ["物业经理"],
    地产项目管理: ["地产项目经理", "地产开发策划"],
    工程管理: ["工程监理", "工程造价师"],
    翻译: ["英语翻译", "日语翻译"],
    生物制药: ["药物研发工程师", "生物制药技术员"],
    行政: ["行政助理", "行政主管"],
    健康整形: ["整形医生", "美容顾问"],
    医疗器械: ["医疗器械销售", "医疗器械维修技师"],
    投融资: ["投资经理", "融资顾问"],
    "旅游产品开发/策划": ["旅游线路策划"],
    "视觉/交互设计": ["UI设计师", "UX设计师"],
    银行: ["银行客户经理", "银行信贷员"],
    汽车制造: ["汽车设计师", "汽车测试工程师"],
    职业培训: ["职业培训师"],
  },
}

export default config;
