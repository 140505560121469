import { useReducer, useTransition } from 'react';

export default (transition = false) => {
  if (transition) {
    const [, update] = useReducer((s) => s + 1, 0);
    const [isPending, startTransition] = useTransition();
    return [
      isPending,
      () => {
        startTransition(update);
      },
    ];
  } else {
    return useReducer((s) => s + 1, 0);
  }
};
