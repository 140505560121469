import getNestedProperty from "@/utils/getNestedProperty";
import getModuleVisibleResumeDataPathByModule from "@/common/config/getModuleVisibleResumeDataPathByModule";
import { Module } from "../module/module";

export default (resumeData, id) => {
    if (id === Module.APPLICATION_INFO) {
        return getNestedProperty(resumeData, getModuleVisibleResumeDataPathByModule(id))
    }
    const value = getNestedProperty(resumeData, getModuleVisibleResumeDataPathByModule(id));
    return value === undefined ? true : value;
}

