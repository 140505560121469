import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'antd-mobile'
import './DropdownMenu.scss'
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setConfigParams } from '../indexSlice';
import { getFilterConfigByType } from '@/pages/Home/config';

/**
 *
 *
 * config {
 *   color: [],
 *   场景: [],
 *   风格: [],
 * }
 */
const config = {
    // '行业': getFilterConfigByType('行业'),
    '语言': getFilterConfigByType('语言'),
    '用途': getFilterConfigByType('用途'),
    '风格': getFilterConfigByType('风格')
}
const DropdownMenu = (props) => {
    const { onChange } = props
    const dispatch = useDispatch()
    const filter = useSelector(state => state?.mobileIndex?.filter)

    const handleChange = (type, value) => {
        dispatch(setConfigParams({type, value}))
    }

    return (
            <Dropdown>
                <Dropdown.Item key='abc' title={`风格：${filter.style}`}>
                    <div className='dropdown-tag-list'>
                        {config['风格'] && config['风格'].map(item => {
                            const cls = classNames({
                                'tag': true,
                                active: item === filter.style
                            })
                            return <div key={item} className={cls} onClick={() => handleChange('style', item)} >{item}</div>
                        })}
                    </div>
                </Dropdown.Item>
                <Dropdown.Item key='bizop' title={`用途：${filter.use}`}>
                    <div className='dropdown-tag-list'>
                        {config['用途'] && config['用途'].map(item => {
                            const cls = classNames({
                                'tag': true,
                                active: item === filter.use
                            })
                            return <div key={item} className={cls} onClick={() => handleChange('use', item)} >{item}</div>
                        })}
                    </div>
                </Dropdown.Item>
                {/* <Dropdown.Item key='more' title={`行业：${filter.position}`}>
                    <div className='dropdown-tag-list'>
                        {config['行业'] && config['行业'].map(item => {
                            const cls = classNames({
                                'tag': true,
                                active: item === filter.position
                            })
                            return <div key={item} className={cls} onClick={() => handleChange('position', item)} >{item}</div>
                        })}
                    </div>
                </Dropdown.Item> */}
            </Dropdown>
    );
};

export default DropdownMenu;
