import {Module} from "@/common/module/module";
import getTextByField from "@/common/field/getTextByField";

export default (block_name, slotName) => {
    if(slotName==="title_text"){
        return "标题"
    }
    switch (block_name) {
        case Module.BASE: {
            return getTextByField(block_name, slotName)
        }
        case Module.AVATAR: {
            return getTextByField(block_name, slotName);
        }
        case Module.JOB_INTEREST: {
            return getTextByField(block_name, slotName);
        }
        case Module.EDUCATIONAL_BACKGROUND: {
            return getTextByField(block_name, slotName);
        }
        case Module.CAMPUS_ACTIVITIES: {
            return getTextByField(block_name, slotName);
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            return getTextByField(block_name, slotName);

        }
        case Module.WORK_EXPERIENCE: {
            return getTextByField(block_name, slotName);

        }
        case Module.PROJECT_EXPERIENCE: {
            return getTextByField(block_name, slotName);
        }
        case Module.PORTFOLIO: {
            return getTextByField(block_name, slotName);
        }
        case Module.LANGUAGE_PROFICIENCY: {
            return getTextByField(block_name, slotName);
        }
        case Module.INTEREST: {
            return getTextByField(block_name, slotName);
        }
        case Module.SKILLS: {
            return getTextByField(block_name, slotName);
        }
        case Module.SELF_EVALUATION: {
            return getTextByField(block_name, slotName);
        }
        case Module.HONORS_AND_AWARDS: {
            return getTextByField(block_name, slotName);
        }
        case  Module.CUSTOM_MODULE: {
            return getTextByField(block_name, slotName);
        }
    }
}
