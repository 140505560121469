import withComponent from "@/hoc/withComponent"
import { useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { generateResume, uploadResumeByWord } from "@/http/mobileApi"
import { clearToast, showToast } from "@/pages/Mobile/utils/toast"
import { Picker, Toast } from "antd-mobile"
import data from "./data"
import TDropDown from "./TDropDown"

const TabItemOneStyled = styled.div`
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    .tab-import {
        height: 181px;
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 0 12px 0px rgba(0,0,0,0.05);
        border-radius: 8px;
    }
    .t-btn {
        width: 206px;
        height: 48px;
        background: #11BBA6;
        border-radius: 9px;

        font-size: 18px;
        color: #FFFFFF;
        cursor: pointer;
    }
    .t-label {
        font-size: 15px;
        color: #888888;
        margin-bottom: 8px;
    }
    input {
        outline: none;
        border: none;
        margin: 0;
        padding: 0;
        font-size: 18px;
    }
    input::placeholder {
        color: #DEDEDE;
    }
    .border-right {
        border-right: 1px solid #EFEFEF;
    }
`

const yearsOfWorkingConfig = [
    {
        name: '在校生',
        value: -1
    },
    {
        name: '应届生',
        value: 0
    },
    {
        name: '一年经验',
        value: 1
    },
    {
        name: '二年经验',
        value: 2
    },
    {
        name: '三年经验',
        value: 3
    },
    {
        name: '四年经验',
        value: 4
    },
    {
        name: '五年经验',
        value: 5
    },
    {
        name: '六年经验',
        value: 6
    },
    {
        name: '七年经验',
        value: 7
    },
    {
        name: '八年经验',
        value: 8
    },
    {
        name: '九年经验',
        value: 9
    },
    {
        name: '十年经验',
        value: 10
    },
    {
        name: '十年以上经验',
        value: 11
    },
]


const TabItemOne = ({ style }) => {
    const navigate = useNavigate()
    const [work, setWork] = useState('')
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [yearVisible, setYearVisible] = useState(false)
    const [job, setJob] = useState('')
    const filterOptions = useMemo(() => {
        return data.filter(item => item.name.includes(job))
    }, [data, job])

    const handleClickOne = () => {
        if (loading) return 
        if (!job) {
            Toast.show('请填写岗位名称')
            return 
        }
        if (!work) {
            Toast.show('请选择工作年限')
            return 
        }
        setLoading(true)
        showToast('生成中...')
        generateResume({
            positionTitle: job,
            yearsOfExperience: work
        }).then(res => {
            if (res) {
                navigate(`/resume?id=${res}`)
            }
        }).catch(err => {
            Toast.show(err.msg || err.message)
        }).finally(() => {
            setLoading(false)
            clearToast()
        })
    }

    const handleClickItem = (item) => {
        setJob(item.name)
        setVisible(false)
    }

    const handleClickYearItem = (item) => {
        setWork(item.value)
        setYearVisible(false)
    }

    const workLabel = yearsOfWorkingConfig.find(item => item.value === work)?.name || work

    const dropdownVisible = job && filterOptions.length > 0 && visible
    return <TabItemOneStyled style={style}>
        <div className="tab-import flex-center flex-col">
            <div className="flex-center px-14" style={{ width: '100%', boxSizing: 'border-box', marginBottom: 47 }}>
                <div className="border-right flex flex-col" style={{ flex: 7, position: 'relative'}}>
                    <div className="t-label mb-10">岗位名称</div>
                    <input 
                        value={job} 
                        onFocus={() => setVisible(true)}
                        onChange={e => setJob(e.target.value)} 
                        placeholder="请输入岗位名称" 
                    />

                    <TDropDown 
                        visible={dropdownVisible} 
                        options={filterOptions} 
                        onClick={handleClickItem} 
                        onClose={() => setVisible(false)}
                        highlight={job}
                    />
                </div>
                <div className="flex flex-col pl-12" style={{ flex: 5, position: 'relative'}}>
                    <div className="t-label mb-10">工作年限</div>
                    <input readOnly value={workLabel} onClick={() => {
                        setYearVisible(true)
                    }} placeholder="请输入岗位名称" />
                    <TDropDown 
                        visible={yearVisible} 
                        options={yearsOfWorkingConfig} 
                        onClick={handleClickYearItem} 
                        onClose={() => setYearVisible(false)}
                        highlight={job}
                    />
                </div>
            </div>

            <div className="flex-center">
                <div onClick={handleClickOne} className="flex-center t-btn">一键生成简历</div>
            </div>
        </div>
    </TabItemOneStyled>
}

export default withComponent(TabItemOne)