import styled from "styled-components"
import { ButtonStyled } from "."

const Button2Styled = styled.div`
    background: #F7F7F7;
    border-radius: 6px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6E727C;
    padding: 5px 10px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.$visible && `color: #11BBA6;background: rgba(17,187,166,0.08);box-shadow: none;`}

    &:hover {
        background: rgba(0,0,0,0.05);
        cursor: pointer;
    }
`

export const dropdownSvg = <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 4 }} dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="未展开样式" transform="translate(-508.000000, -118.000000)" fill="#D1D1D1" fill-rule="nonzero">
            <g id="编组-6" transform="translate(480.000000, 115.000000)">
                <g id="展开备份" transform="translate(34.000000, 9.000000) rotate(-360.000000) translate(-34.000000, -9.000000) translate(28.000000, 3.000000)">
                    <path d="M7.3755624,4.15770148 L3.92990694,7.43400981 C3.7350714,7.63879268 3.7350714,7.96146629 3.92568772,8.16168293 L7.37985721,11.4462351 C7.46955937,11.5408428 7.59611969,11.5947514 7.72781079,11.5947514 C7.85971663,11.5947514 7.98636003,11.540677 8.08009695,11.4413753 L8.12369634,11.3889258 C8.26902639,11.1883143 8.25447184,10.902137 8.07981727,10.7183657 L4.98271647,7.79975145 L8.07513809,4.88566608 C8.27036155,4.68051599 8.27036155,4.35784238 8.07981728,4.15770148 C7.88600084,3.9537681 7.56937883,3.9537681 7.3755624,4.15770148 Z" id="-" transform="translate(6.003000, 7.799751) scale(-1, 1) rotate(-90.000000) translate(-6.003000, -7.799751) "></path>
                    <path d="M7.3755624,0.557701478 L3.92990694,3.83400981 C3.7350714,4.03879268 3.7350714,4.36146629 3.92568772,4.56168293 L7.37985721,7.84623513 C7.46955937,7.94084278 7.59611969,7.99475145 7.72781079,7.99475145 C7.85971663,7.99475145 7.98636003,7.940677 8.08009695,7.84137532 L8.12369634,7.78892578 C8.26902639,7.58831426 8.25447184,7.30213698 8.07981727,7.11836568 L4.98271647,4.19975145 L8.07513809,1.28566608 C8.27036155,1.08051599 8.27036155,0.757842376 8.07981728,0.557701478 C7.88600084,0.353768102 7.56937883,0.353768102 7.3755624,0.557701478 Z" id="-备份" transform="translate(6.003000, 4.199751) scale(-1, 1) rotate(-90.000000) translate(-6.003000, -4.199751) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const dropdown2Svg = <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 4 }} dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="小助手" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="展开JD，点击优化简历出对话" transform="translate(-508.000000, -262.000000)" fill="#D1D1D1" fill-rule="nonzero">
            <g id="编组-3" transform="translate(48.000000, 66.000000)">
                <g id="编组-6" transform="translate(432.000000, 193.000000)">
                    <g id="展开备份" transform="translate(34.000000, 9.000000) rotate(-360.000000) translate(-34.000000, -9.000000) translate(28.000000, 3.000000)">
                        <path d="M7.3755624,0.557701478 L3.92990694,3.83400981 C3.7350714,4.03879268 3.7350714,4.36146629 3.92568772,4.56168293 L7.37985721,7.84623513 C7.46955937,7.94084278 7.59611969,7.99475145 7.72781079,7.99475145 C7.85971663,7.99475145 7.98636003,7.940677 8.08009695,7.84137532 L8.12369634,7.78892578 C8.26902639,7.58831426 8.25447184,7.30213698 8.07981727,7.11836568 L4.98271647,4.19975145 L8.07513809,1.28566608 C8.27036155,1.08051599 8.27036155,0.757842376 8.07981728,0.557701478 C7.88600084,0.353768102 7.56937883,0.353768102 7.3755624,0.557701478 Z" id="-" transform="translate(6.003000, 4.199751) scale(-1, -1) rotate(-90.000000) translate(-6.003000, -4.199751) "></path>
                        <path d="M7.3755624,4.15770148 L3.92990694,7.43400981 C3.7350714,7.63879268 3.7350714,7.96146629 3.92568772,8.16168293 L7.37985721,11.4462351 C7.46955937,11.5408428 7.59611969,11.5947514 7.72781079,11.5947514 C7.85971663,11.5947514 7.98636003,11.540677 8.08009695,11.4413753 L8.12369634,11.3889258 C8.26902639,11.1883143 8.25447184,10.902137 8.07981727,10.7183657 L4.98271647,7.79975145 L8.07513809,4.88566608 C8.27036155,4.68051599 8.27036155,4.35784238 8.07981728,4.15770148 C7.88600084,3.9537681 7.56937883,3.9537681 7.3755624,4.15770148 Z" id="-备份" transform="translate(6.003000, 7.799751) scale(-1, -1) rotate(-90.000000) translate(-6.003000, -7.799751) "></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const FooterStyled = styled.div`
    .expand {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D1D1D1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4px;
    }
    .expand:hover {
        cursor: pointer;
        color: #11BBA6;
        svg path{
            fill: #11BBA6;
        }
    }
`

const FooterWrapStyled = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
`


const TargetPositionFooter = ({ visible, onExpand, onClick, onSelect, tabKey, isSave }) => {

    return <FooterStyled>
        {
                <FooterWrapStyled>
                    <Button2Styled
                        onClick={onSelect}
                    >
                        选择岗位
                    </Button2Styled>
                    {/* <div onClick={onExpand} className="expand">
                        收起
                        {dropdown2Svg}
                    </div> */}
                    <ButtonStyled
                        isSave={isSave}
                        onClick={onClick}
                    >
                        {
                            tabKey === 'resume' ? "基于目标岗位帮我优化简历": "基于目标岗位生成面试问题"
                        }
                    </ButtonStyled>
                </FooterWrapStyled>
        }
    </FooterStyled>
}

export default TargetPositionFooter