import isVip from "@/common/isVip"
import { svipLastId, svip_life, vip_life } from "./config"
import { getUserRemainingDetail } from "@/http/api"
import Deduction from "@/pages/Mobile/components/Modal/PayModal/Deduction"
import { useEffect, useState } from "react"
import store from 'store'

const useUpgrade = ({ visible }) => {
    const [upgradeInfo, setUpgradeInfo] = useState(null)

    useEffect(() => {
        if (visible) {
            getUserRemainingDetail().then(res => {
                if (res) {
                    setUpgradeInfo(res)
                }
            })
        }
    }, [visible])

    const isUpgrade = () => {
        const user = store.get('user')
        const vip = isVip();
        
        return vip && user.vipType !== svipLastId && user.vipType !== svip_life;
    }

    const isVipLift = () => {
        const user = store.get('user')
        return user?.vipType && user.vipType === vip_life
    }

    const upgrade = isUpgrade()
    return {
        isUpgrade: upgrade ? 1 : 0,
        isVipLift: isVipLift(),
        upgradeInfo,
        Deduction: upgrade ? <Deduction upgradeSVipInfo={upgradeInfo} /> : <></>
    }
}

export default useUpgrade
