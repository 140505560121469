import styled from "styled-components";

const ContainerStyled = styled.div`
  position: absolute;
  top: 0;
  width: 820px;
  right: 12px;
  bottom: 0;
  display: flex;
  background: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
  z-index: 99;
  pointer-events: none;
`

export default () => {
    return <ContainerStyled>
        <img src="https://oss.jianzeppt.com/user/4556/dd606090-e1a4-4579-9034-1de2e9f80e3d.gif" width={130} height={44}/>
    </ContainerStyled>
}
