import useSelector from "@/components/resume/redux/hooks/useSelector"
import useQueryParam from "@/hooks/useQueryParam"
import { changeResumeTemplate, fetchResumeSearch } from "@/http/api"
import { getResumeTemplateById } from "@/http/mobileApi"
import RatioImg from "@/pages/Mobile/components/RatioImg"
import { showToast, clearToast } from "@/pages/Mobile/utils/toast"
import useResumeDetailInstance from "@/pages/ResumeDetail/hooks/useResumeDetailInstance"
import { compressAndResizeImage } from "@/utils/image"
import { Toast } from "antd-mobile"
import classNames from "classnames"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useImmerReducer } from "use-immer"

const limit = 20

const initialState = {
    dataList: [],
    filter: {
        use: '全部', // 用途
        searchWord: '', // 关键词
        position: '全部',
        profession: '全部',
        style: '全部',
    },
    hasMore: true, // 
    current: 1,
    loading: false,
}

const listReducer = (draft, action) => {
    switch (action.type) {
        case 'init':{
            draft.current = 1
            draft.dataList = []
            draft.hasMore = true
            break
        }
        case 'loading': {
            draft.loading = true
            break
        }
        case 'changed': {
            const selectedTemplateId = action.selectedTemplateId
            const data = action.data
            const selectedItem = data.find(item => item.id === selectedTemplateId)
            if (selectedItem) {
                draft.dataList = [selectedItem, ...data.filter(item => item.id !== selectedTemplateId)]
            } else {
                draft.dataList = data
            }
            break
        }
        case 'selected': {
            draft.dataList.unshift(action.data)
            break
        }
        default:
            break;
    }
}

const SwitchTemplateTabStyled = styled.div`
    .resume-list {
        display: grid;
        grid-template-columns: 34px 82px 82px 82px;
        grid-auto-flow: column; 
        overflow-x: scroll;
        column-gap: 8px;
        padding-top: 2px;
        .resume-item {
            position: relative;
            width: 82px;
            margin-bottom: 16px;
            overflow: auto;

            &-all {
                width: 34px;
                background: #F3F4F5;
                border-radius: 4px;
                writing-mode: vertical-rl;

                font-size: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #888888;

                letter-spacing: 4px;
            }

            &-img {
                border-radius: 4px;
                border: 1px solid #E2E2E2;
                box-sizing: border-box;
            }

            &-active {
                outline: 2px solid #11BBA6;
                border-radius: 4px;
            }

            &-tag {
                font-size: 11px;
                width: 70px;
                height: 20px;
                background: #11BBA6;
                border-radius: 4px;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                bottom: 6px;
                color: #ffffff;
            }
        }
    }
    .resume-use {
        overflow-x: scroll;
        margin-bottom: 12px;
    }
`

const SwitchTemplateTab = () => {
    const resumeId = useQueryParam('id')
    const navigate = useNavigate()
    const themeId = useQueryParam('themeId')
    const themeIndex = useQueryParam('themeIndex')
    const [state, dispatch] = useImmerReducer(listReducer, initialState)
    const resumeDetailInstance = useResumeDetailInstance()
    const template = useSelector(state => state.template)
    useEffect(() => {
        fetchList()
    }, [])

    // useEffect(() => {
    //     const templateId = template?.originId
    //     if (templateId) {
    //         fetchTemplateById(templateId)
    //     }
    // }, [template])

    useEffect(() => {
        const templateId = Number(template?.id)
        if (resumeId && themeId && templateId) {
            const importId = Number(themeId)
            if (templateId !== importId) {
                setTimeout(() => {
                    handleClickTemplate({ id: themeId, themeIndex })
                }, 0)
            }
        }
    }, [resumeId, themeId, template, themeIndex])

    // const fetchTemplateById = async (templateId) => {
    //     if (state.dataList.find(item => item.id === templateId)) return 

    //     const currentTemplate = await getResumeTemplateById({
    //         resumeId: templateId
    //     })
    //     dispatch({
    //         type: 'selected',
    //         data: currentTemplate
    //     })
    // }

    const fetchList = async () => {
        const res = await fetchResumeSearch({
            query: {
                page: state.current,
                limit,
                ...state.filter
            },
        })
        let { dataList } = res;
        dispatch({
            type: 'changed',
            data: dataList || [],
        })
    }

    const handleClick = (item) => {
        let info = {}
        try {
            info = JSON.parse(item.info)
        } catch (error) {
            
        }
        if (info.defaultThemeIndex) {
            resumeDetailInstance.resumeRef.current?.changeThemeWithSave?.(info.defaultThemeIndex)
        }
        showToast('加载中')
        setTimeout(() => {
            navigate(`/mobile/resume/preview?id=${resumeId}&themeId=${item.id}&themeIndex=${info.defaultThemeIndex}`, { replace: true })
        }, 200)
    }

    const handleClickTemplate = async (item) => {
        let removeThemeStyle = resumeDetailInstance.resumeRef.current.removeThemeStyle
        let removeTemplateStyle = resumeDetailInstance.resumeRef.current.removeTemplateStyle
        let versionLog = resumeDetailInstance.resumeRef.current.versionLog;
        let changeTheme = resumeDetailInstance.resumeRef.current.changeTheme
        showToast('加载中')

        try {
            await changeResumeTemplate({query: {resumeId, templateId: item.id}})
            removeThemeStyle()
            removeTemplateStyle()
            await window.fetchMobileData()
            changeTheme(item.themeIndex)
        } catch (e) {
            console.log(e)
        } finally {
            clearToast()
        }
    }

    const handleClickAll = () => {
        navigate(`/mobile/resume/switch_template?id=${resumeId}`)
    }

    return <SwitchTemplateTabStyled>
        <div className="resume-list">
            <div onClick={handleClickAll} className="resume-item resume-item-all flex-center">
                全部模板
            </div>
            {
                state.dataList.map(item => {
                    const isActive = template?.id === item?.id
                    const cls = classNames({
                        'resume-item': true,
                        'resume-item-active': isActive
                    })
                    return <div key={item.id} className={cls} onClick={() => handleClick(item)}>
                        <RatioImg className="resume-item-img" src={compressAndResizeImage(item.cover, { p: 20 })} ratio={[794, 1103]} />
                        {
                            isActive && <div className="resume-item-tag flex-center">当前模板</div>
                        }
                    </div>
                })
            }
        </div>
    </SwitchTemplateTabStyled>
}

export default SwitchTemplateTab