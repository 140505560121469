import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import getNestedProperty from "@/utils/getNestedProperty";
import {Module} from "@/common/module/module";
import styled from "styled-components";
import {useState} from "react";
import {Popover, Upload} from "antd";
import {uploadFile} from "@/http/api";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import {closeIcon, CloseStyled} from "@/components/resume/modules/portfolio/index";


const ImageBoxStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Image1BoxStyled = styled(ImageBoxStyled)`
`
const Image2BoxStyled = styled(ImageBoxStyled)`
  .portfolio-img:nth-of-type(2n+1) {
    margin-right: 4%;
  }
`
const Image3BoxStyled = styled(ImageBoxStyled)`
  .portfolio-img:nth-of-type(3n) {
    margin-right: 0;
  }
`
const ImageStyled = styled.div`
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  margin-bottom: 10px;
`
const Image1Styled = styled(ImageStyled)`
  width: 100%;
  flex-shrink: 0;
`
const Image2Styled = styled(ImageStyled)`
  width: 48%;
  flex-shrink: 0;
`
const Image3Styled = styled(ImageStyled)`
  width: 32%;
  margin-right: 2%;
  flex-shrink: 0;
`
const EditStyled = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: #11BBA6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const editIcon = <div dangerouslySetInnerHTML={{
    __html: `<svg width="40px" height="40px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="作品展示、自定义展示" transform="translate(-654.000000, -574.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-3备份-37" transform="translate(650.000000, 570.000000)">
                <g id="设置备份" transform="translate(4.000000, 4.000000)">
                    <g id="bianji-15" transform="translate(2.285714, 2.331429)">
                        <path d="M9.74274767,3.68676206 C9.839706,3.78372038 9.99765423,3.78372038 10.0961764,3.68676206 L11.2518571,2.5310814 C11.3488154,2.43412307 11.3488154,2.27617484 11.2518571,2.17765267 L9.14692314,0.0727187427 C9.04996481,-0.0242395809 8.89201658,-0.0242395809 8.79349441,0.0727187427 L7.63781375,1.22839941 C7.54085542,1.32535773 7.54085542,1.48330597 7.63781375,1.58182813 L9.74274767,3.68676206 Z M8.92954883,4.48745015 L6.83712565,2.39502698 C6.7354758,2.29337712 6.57283603,2.29337712 6.47118618,2.39502698 L0.0844475721,8.78176558 C0.0359684104,8.83024474 0.00938306357,8.89592619 0.00938306357,8.96317148 L0,11.0634139 C0,11.2072875 0.115724451,11.3245758 0.259598092,11.3230274 L2.35984049,11.3136289 C2.42864962,11.3136289 2.49276722,11.2854797 2.54124638,11.2385644 L8.92798499,4.85182579 C9.03119869,4.75173978 9.03119869,4.58753616 8.92954883,4.48745015 Z M11.1689733,10.1673313 L5.190398,10.1673313 C5.12002502,10.1673313 5.05277973,10.1954805 5.00430057,10.2455235 L4.39752913,10.8694972 C4.23801705,11.0337008 4.3537415,11.3089374 4.58362655,11.3089374 L11.1689733,11.3089374 C11.312847,11.3089374 11.4285714,11.1932129 11.4285714,11.0493393 L11.4285714,10.4253655 C11.4285714,10.2830558 11.312847,10.1673313 11.1689733,10.1673313 Z" id="形状"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>

const ImageComponent = (props) => {
    const {imgLayoutType, src, index} = props;
    const [hover, setHover] = useState(false);
    const dispatch = useDispatch();
    let ImageComponent;
    if (imgLayoutType === 1) {
        ImageComponent = Image1Styled;
    } else if (imgLayoutType === 2) {
        ImageComponent = Image2Styled;
    } else if (imgLayoutType === 3) {
        ImageComponent = Image3Styled;
    }
    const onMouseEnter = () => {
        setHover(true)
    }
    const onMouseLeave = () => {
        setHover(false)
    }
    const onDelete = () => {
        dispatch(resumeDataSlice.actions.deletePortfolioItem({
            type: 'image',
            index
        }))
    }
    const onClickEdit = async (res) => {
        const file = res.file;
        const fileURL = await uploadFile({body: {file}});
        dispatch(resumeDataSlice.actions.changeValueByPath({
            path: `[${Module.PORTFOLIO}].data.images[${index}.src]`,
            value: fileURL
        }))

    }
    return <Popover content={<CloseStyled onClick={onDelete}>{closeIcon}</CloseStyled>}
                    overlayClassName="ant-popover-custom"
                    autoAdjustOverflow={false}
                    trigger="hover"
                    transitionName=""
                    getPopupContainer={null}
                    placement="rightTop"
                    overlayInnerStyle={{right: 19, top: -6, position: "relative"}}
    >
        <ImageComponent className="portfolio-img" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <img src={src}/>
            {
                hover && <Upload showUploadList={false} customRequest={onClickEdit}>
                    <EditStyled>
                        {editIcon}
                    </EditStyled>
                </Upload>
            }
        </ImageComponent>
    </Popover>
}

export default () => {
    const imgLayoutType = useSelector(state => getNestedProperty(state.resumeData, `[${Module.PORTFOLIO}].data.imgLayoutType`));
    const images = useSelector(state => getNestedProperty(state.resumeData, `[${Module.PORTFOLIO}].data.images`));

    let ImageBoxComponent;
    if (imgLayoutType === 1) {
        ImageBoxComponent = Image1BoxStyled;
    } else if (imgLayoutType === 2) {
        ImageBoxComponent = Image2BoxStyled;
    } else if (imgLayoutType === 3) {
        ImageBoxComponent = Image3BoxStyled;
    }
    return images && images.length > 0 && <ImageBoxComponent>
        {
            images.map((item, index) => <ImageComponent className="portfolio-img"
                                                        key={index}
                                                        index={index}
                                                        imgLayoutType={imgLayoutType}
                                                        src={item.src}/>)
        }
    </ImageBoxComponent>
}

