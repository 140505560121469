import styled from "styled-components";

export const MenuBoxStyled = styled.div`
  .ant-popover-inner {
    padding: 0 !important;
  }

  .ant-popover-arrow {
    visibility: hidden !important;
  }
`
export const ContainerStyled = styled.div`
  width: 180px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  pointer-events: auto;
  padding-bottom: 20px;
  user-select: none;
`
export const TitleStyled = styled.div`
  height: 48px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #2C2C2C;
  line-height: 48px;
  padding: 0 20px;
`
export const MenuItemStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  width: 160px;
  height: 40px;
  cursor: pointer;
  
  .ant-switch-checked {
    background: #019885 !important;
  }

  &:hover {
    background: #F4F4F4;
  }
`
export const MenuItemTitleStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2C2C2C;
  line-height: 14px;
`
export const RequiredStyled = styled.div`
  width: 32px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #11BBA6;
  line-height: 18px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #11BBA6;
  text-align: center;
  margin-right: 13px;
`
export const NoCheckedStyled = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 4px;
  border: 1px solid #DDDDDD;
  margin-right: 20px;
`
export const CheckedStyled = styled.div`
  width: 14px;
  height: 14px;
  background: #11BBA6;
  border-radius: 4px;
  color: white;
  line-height: 14px;
  text-align: center;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  //&:before {
  //  content: "√";
  //}
`
