import {useCallback, useMemo, useRef} from "react";
import withComponent from "@/hoc/withComponent";
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import Input from "@/components/resume/Input"
import getTextByValue from "@/common/getTextByValue";
import getNestedProperty from "@/utils/getNestedProperty";
import useForceUpdate from "@/hooks/useForceUpdate";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import debounce from "@/utils/debounce";
import getPlaceholderBySlot from "@/common/config/getPlaceholder";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import useModuleContentInstance from "@/components/resume/hooks/useModuleContentInstance";
import getResumePath from "@/common/resume/getResumePath";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import hoverSlice from "@/components/resume/redux/services/hoverSlice";
import focusSlice from "@/components/resume/redux/services/focusSlice";
import {message} from "antd";
import getBlockNameBySlot from "@/common/slot/getBlockNameBySlot";
import getFieldNameBySlot from "@/common/getFieldNameBySlot";

export default withComponent((props) => {
    const {tagName, style, className, slot_value} = props;
    const moduleContentInstance = useModuleContentInstance();
    const fieldInstance = useFieldInstance();
    const instance = useRef({
        key: 0
    }).current;
    const [, update] = useForceUpdate();
    const dispatch = useDispatch();
    const moduleInstance = useModuleInstance();
    let for_block_name = getBlockNameBySlot(slot_value) || fieldInstance?.for_block_name;
    let for_field_name = fieldInstance?.for_field_name;

    const path = getResumePath(for_block_name, for_field_name, slot_value, moduleContentInstance?.index, moduleInstance?.data?.id);

    const placeholder = getPlaceholderBySlot(for_block_name, for_field_name, slot_value);
    const value = useSelector((state) => getNestedProperty(state.resumeData, path));
    const text = getTextByValue(value);
    const updateTextKey = useCallback(() => {
        instance.key++
    }, [])
    const onInput = useCallback((e) => {
        debounceChange(e.currentTarget.innerText)
    }, []);
    const onKeyDown = useCallback((e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            message.info("此处不可以使用回车键")
        }
    }, []);

    const debounceChange = useCallback(debounce((value) => {
        dispatch(resumeDataSlice.actions.changeValueByPath({path: path, value: getTextByValue(value)}));
    }, 500), [path])
    const onFocus = useCallback(() => {
        dispatch(focusSlice.actions.setModuleId(moduleInstance?.data?.id));
        instance.isFocus = true;
        update()
    }, []);
    const onBlur = useCallback(() => {
        dispatch(focusSlice.actions.setModuleId(null));
        instance.isFocus = false;
        update()
    }, []);
    const onPre = useCallback(() => {
        debounceChange.flush();
    }, []);
    if (!instance.isFocus) {
        updateTextKey()
    }
    return useMemo(() => <Input style={{...style, wordBreak: "break-all"}}
                                className={className}
                                onKeyDown={onKeyDown}
                                tagName={tagName}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onPre={onPre}
                                type={slot_value}
                                data-type={slot_value}
                                slot_value={slot_value}
                                value={text}
                                onInput={onInput}
                                placeholder={placeholder}/>, [instance.key])
})

