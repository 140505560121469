import FlexStyled from '@/styled/FlexStyled';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import withComponent from '@/hoc/withComponent';
import useForceUpdate from '@/hooks/useForceUpdate';
import styled from 'styled-components';
import {useDispatch, useSelector} from "@/components/resume/redux/hooks";
import {Modal, Tooltip} from "antd";
import debounce from "@/utils/debounce";
import resumeSlice from "@/components/resume/redux/services/resumeSlice";
import ResumeName from "@/pages/ResumeDetail/ResumeName";


const TitleContainerStyled = styled(FlexStyled)`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #2C2C2C;
  pointer-events: auto;
`;
const TextContainerStyled = styled.div`
  max-width: 170px;
  height: 30px;
  cursor: text;
  pointer-events: auto;

`
const TextStyled = styled.div`
  height: 100%;
  line-height: 30px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  pointer-events: auto;

  &:hover {
    /* color: white; */
    border-bottom: 1px solid #666666;
  }
`
const InputContainerStyled = styled.div`
  height: 30px;
  width: 170px;
  pointer-events: auto;
`
const InputStyled = styled.input`
  height: 100%;
  width: 100%;
  font-family: PingFangSC-Medium, PingFang SC;
  outline: none !important;
  border: none;
  padding: 0;
  background: rgb(57, 57, 57);
  border-bottom: 1px solid transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: white;
  pointer-events: auto;



`

export default withComponent((props) => {
    const {canEdit} = props;
    const dispatch = useDispatch();
    const inputRef = useRef();
    const [visible, setVisible] = useState(false);
    const [nameModalOpen, setNameModalOpen] = useState(false);
    const that = useRef({isFocus: false, showInput: false, value: undefined, onChangeHandler: null}).current;
    const resumeName$1 = useSelector((state) => state.resume?.name);
    const [, update] = useForceUpdate();
    const onFocus = () => {
        that.isFocus = true;
        update();
    };
    const onBlur = () => {
        that.isFocus = false;
        that.showInput = false;
        if (that.onChangeTimer) {
            that.onChangeHandler();
            that.onChangeTimer = null;
            that.onChangeHandler = null;
        }
        update();
    };
    const onClick = () => {
        setNameModalOpen(true)
    };
    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            onBlur();
        }
    };
    const onMouseLeave = () => {
        setVisible(false)
    }
    const onChange = (e) => {
        const value = e.target.value;
        clearTimeout(that.onChangeTimer);
        that.onChangeHandler = () => {
            // onChange2(value);
            dispatch(resumeSlice.actions.changeName({value}));
            that.onChangeTimer = 0;
            that.onChangeHandler = null;
        };
        that.onChangeTimer = setTimeout(that.onChangeHandler, 1000);
    };
    useEffect(() => {
        if (that.showInput) {
            inputRef.current?.focus();
        }
    }, [that.showInput]);
    const resumeName = resumeName$1 || '我的简历'


    return (<TitleContainerStyled onMouseLeave={onMouseLeave}
                                  style={{marginLeft: 16, pointerEvents: !canEdit ? 'none' : undefined}}>
        <Tooltip placement='bottom'
                 visible={visible && !that.showInput}
                 onVisibleChange={setVisible}
                 transitionName=""
                 title={'修改简历名称'}
                 mouseEnterDelay={0.5}
                 mouseLeaveDelay={0}
                 arrowPointAtCenter>
            {that.showInput ? <InputContainerStyled>
                <InputStyled ref={inputRef} maxLength={20} style={{width: '100%'}}
                             onKeyDown={onKeyDown} onChange={onChange} autoFocus onFocus={onFocus}
                             onBlur={onBlur}
                             defaultValue={resumeName}/>
            </InputContainerStyled> : <TextContainerStyled>
                <TextStyled onClick={onClick}>{resumeName}</TextStyled>
            </TextContainerStyled>}
        </Tooltip>
        <Modal open={nameModalOpen}
               width={446}
               modalRender={() => <ResumeName text={resumeName} onCancel={() => setNameModalOpen(false)}/>}

        />
    </TitleContainerStyled>);
});

