import React, { useState } from 'react';
import FlexStyled from '@/styled/FlexStyled';

export default (props) => {

  return <FlexStyled $flexDirection='column' $alignItems='flex-start' $justifyContent='flex-start' style={{padding: '20px', width: '100%'}}>
    <div style={{ fontSize: 18, fontWeight: 'bold' }}>页数超出限制</div>
    <FlexStyled $justifyContent='center' style={{ marginTop: 15, fontSize: 14, color: '#999999' }}>单个简历页数达到使用限制</FlexStyled>
    <div style={{color: '#11BBA6', textAlign: 'center', width: '100%', marginTop: 20}}>联系客服：19537581425</div>
  </FlexStyled>;
};
