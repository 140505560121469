import React, {createElement, createRef, useEffect, useRef} from "react";
import Version from "@/common/resume/version";
import {autoOnePageLevels} from "@/const/config";
import paginationSlice from "@/components/resume/redux/services/paginationSlice";
import {Module} from "@/common/module/module";
import config from "@/const";
import useForceUpdate from "@/hooks/useForceUpdate";
import {useSelector, useStore} from "@/components/resume/redux/hooks";
import useDispatch from "@/components/resume/redux/hooks/useDispatch";
import {ResumeInstanceContext} from "@/components/resume/context";
import ReactDOM from "react-dom/client";
import {createStore} from "@/components/resume/redux/store";
import Resume from '@/components/resume/index'
import initState from "@/components/resume/redux/initState";
import resumeSlice from "@/components/resume/redux/services/resumeSlice";
import axios from "axios";

const themeStyle = document.createElement('style');
document.head.appendChild(themeStyle);


export default (Component) => {
    return (props) => {
        const {
            baseWidth = config.resume.width, baseHeight = config.resume.height, width, openEdit,
        } = props;
        const {getModuleContentToolContainer} = props;
        const offsets = useSelector((state) => state.pagination.offsets);

        const store = useStore();
        const dispatch = useDispatch();
        const [, update] = useForceUpdate();
        const instance = useRef({
            isInit: false,
            dataMaster: undefined,//向外部传递数据改变信息
            paginationCoverHeight: 32,
            width,
            rootContainer: undefined,
            zoomContainer: undefined,
            contentContainer: undefined,
            mergeOnePageContainer: undefined,
            linkContainers: [],
            contentHeight: undefined,   //简历内容真实高度
            pageHeight: baseHeight,
            pageWidth: baseWidth,
            pageCount: undefined,   //当前页数
            paginationPadding: 30,
            version: props.version ? props.version : new Version(),
            paginationOffsetsCallbackQueue: undefined,
            openEdit,
            get currentPaginationPadding() {
                return instance.paginationPadding / instance.scale
            },
            get scale() {
                // return  1
                return (instance.width || 0) / baseWidth
            },
            get currentPaginationCoverHeight() {
                return instance.paginationCoverHeight / instance.scale
            },
            get currentPageHeight() {
                return instance.scale * baseHeight
            }, //一页高度 缩放后
            get currenContentHeight() {
                const v = instance.contentHeight * instance.scale;
                return Number.isNaN(v) ? 0 : v
            },
            drake: undefined,   //拖拽实例
            getContentHeight() {
                const moduleList = [...instance.contentContainer.querySelectorAll(".resume-module")];
                const rectList = moduleList.map((module) => {
                    return module.getBoundingClientRect()
                });
                const targetBottom = Math.max(...rectList.map(item => item.bottom));
                const contentRect = instance.contentContainer.getBoundingClientRect();
                const offsetTop = contentRect.top;
                const contentHeight = targetBottom - offsetTop;
                const height = contentHeight;
                let count = Math.ceil(height / (instance.currentPageHeight));
                if (Number.isNaN(count)) {
                    count = 1;
                }
                return {
                    contentHeight: count * instance.currentPageHeight / instance.scale, pageCount: count
                }
            },
            updateContentHeight() {
                const {contentHeight, pageCount} = instance.getContentHeight()
                let prePageCount = instance.pageCount;
                instance.contentHeight = contentHeight;
                instance.pageCount = pageCount;
                if (instance.pageCount !== prePageCount) {
                    dispatch(paginationSlice.actions.setPageCount(pageCount))
                    update()
                }
            },
            async mergeOnePage() {
                const computedOnePageLevel = async () => {
                    const resumeRoot = instance.rootContainer;
                    const moduleList = [...resumeRoot.querySelectorAll(".resume-module")];
                    const detailList = [...resumeRoot.querySelectorAll(".detail-input")];
                    const elementList = [...moduleList, ...detailList];
                    const onePageLevelList = Object.values(autoOnePageLevels);
                    const elementStyleMap = new Map();
                    let resLevel;
                    const onePageLevelReverseList = [...onePageLevelList].reverse();
                    elementList.forEach((element) => {
                        elementStyleMap.set(element, new Map());
                    })
                    for (let i = 0; i < onePageLevelReverseList.length; i++) {
                        const level = onePageLevelReverseList[i];
                        try {
                            const pageCount = await new Promise((resolve, reject) => {
                                let fragment = document.createElement("div");
                                instance.mergeOnePageContainer.append(fragment);
                                const rootContainer = document.createElement("div");
                                rootContainer.setAttribute("id", "root")
                                fragment.append(rootContainer);
                                const root = ReactDOM.createRoot(rootContainer);
                                const ref = createRef();
                                const over = () => {
                                    root.unmount();
                                    instance.mergeOnePageContainer.innerHTML = ""
                                }

                                const onInit = () => {
                                    resolve(ref.current?.pageCount);
                                    over();
                                }
                                const onError = () => {
                                    console.log("onError");
                                    over()
                                    reject()
                                }
                                const resumeStore = createStore({
                                    initState: {
                                        resume: store.getState().resume,
                                        resumeData: store.getState().resumeData,
                                        template: store.getState().template,
                                        theme: store.getState().theme,
                                        pagination: {
                                            ...initState.pagination, openAutoPagination: true, onePageLevel: level
                                        }
                                    }
                                })
                                root.render(<Resume resumeRef={ref}
                                                    isTest={true}
                                                    store={resumeStore} onInit={onInit} onError={onError}
                                                    width={instance.width}/>);

                            });
                            if (pageCount === 1) {
                                resLevel = level;
                                break
                            }
                        } catch (e) {

                        }
                    }
                    return resLevel;
                }
                const {pagination} = store.getState();
                const {onePageLevel} = pagination;
                if (onePageLevel === null) {
                    const onePageLevel = await computedOnePageLevel();
                    if (onePageLevel) {
                        instance.setPaginationOnePageLevel(onePageLevel)
                        dispatch(paginationSlice.actions.setOffsets(null));
                    } else {
                        throw new Error("too long")
                    }
                } else {
                    instance.setPaginationOnePageLevel(null)
                }
            },
            async pagination() {
                if (instance.paginationOffsetsCallback) {
                    return
                }
                const excludeModule = [Module.BASE, Module.AVATAR];
                const {pagination} = store.getState()
                const preOffset = pagination.offsets;
                const resumeRoot = instance.rootContainer;
                const rootRect = resumeRoot.getBoundingClientRect();
                const leftContainer = resumeRoot.querySelector('[data-layout-type="left"]');
                const mainContainer = resumeRoot.querySelector('[data-layout-type="main"]');


                const handler = (moduleList) => {
                    const moduleMap = new Map();
                    const getCurrentPageCountByTop = (top) => {
                        return Math.ceil(top / instance.pageHeight)
                    }
                    const isOverflowPage = (count, height, top) => {
                        const maxBottom = count * instance.pageHeight;
                        const bottom = height + top;
                        return bottom > (maxBottom - instance.currentPaginationPadding);
                    }
                    const getOverflowOffset = (count, height, top) => {
                        const maxBottom = count * instance.pageHeight;
                        const bottom = height + top;
                        return height - (bottom - maxBottom) + instance.currentPaginationCoverHeight / 2 + instance.currentPaginationPadding;
                    }
                    const getRelativeTopByRect = (rect) => {
                        return (rect.top - rootRect.top) / instance.scale;
                    }

                    let off1 = 0;
                    moduleList.forEach((element) => {
                        const id = element.dataset.id;
                        const obj = {top: 0};
                        const rect = element.getBoundingClientRect();
                        let offset = Number(preOffset?.[id]);
                        if (Number.isNaN(offset)) {
                            offset = 0;
                        }
                        off1 = off1 + offset;
                        obj.top = getRelativeTopByRect(rect);
                        obj.top = obj.top - off1;
                        obj.height = rect.height / instance.scale;
                        moduleMap.set(element, obj);
                    });
                    let off = 0;
                    moduleList.forEach((element) => {
                        const info = moduleMap.get(element);
                        const pageCount = getCurrentPageCountByTop(info.top + off);
                        const isOver = isOverflowPage(pageCount, info.height, info.top + off);
                        if (isOver) {
                            const offset = getOverflowOffset(pageCount, info.height, info.top + off, element);
                            info.offset = offset;
                            off = off + offset;
                        }
                    });
                    const offsets = {};
                    [...moduleMap.keys()].forEach((key) => {
                        const id = key.dataset.id;
                        const value = moduleMap.get(key);
                        offsets[id] = value.offset;
                    });
                    return offsets
                }


                const offsets = {}
                if (leftContainer) {
                    const moduleList = [...leftContainer.querySelectorAll(".resume-module")].filter((element) => !excludeModule.includes(element.dataset.blockName));
                    Object.assign(offsets, handler(moduleList));
                }
                if (mainContainer) {
                    const moduleList = [...mainContainer.querySelectorAll(".resume-module")].filter((element) => !excludeModule.includes(element.dataset.blockName));
                    Object.assign(offsets, handler(moduleList));
                }
                dispatch(paginationSlice.actions.setOffsets(offsets));
                await new Promise((resolve) => {
                    instance.paginationOffsetsCallback = resolve
                })
            },
            setPaginationOnePageLevel(level) {
                if (!level) {
                    instance.contentContainer.removeAttribute("data-page-level");
                } else {
                    instance.contentContainer.setAttribute("data-page-level", level);
                }
                const {pagination} = store.getState();
                const {onePageLevel} = pagination;
                if (onePageLevel !== level) {
                    dispatch(paginationSlice.actions.setOnePageLevel(level));
                }

            },
            changeTheme(index) {
                const {theme, resume} = store.getState();
                const {colorList, classList} = theme || {};
                let targetIndex = index;
                if (index === undefined) {
                    if (resume?.defaultThemeIndex !== undefined) {
                        targetIndex = resume?.defaultThemeIndex;
                    }
                }
                if(instance.currentThemeIndex===targetIndex){
                    return
                }
                if (colorList?.[Number(targetIndex)]) {
                    instance.currentThemeIndex = targetIndex;
                    instance.removeThemeStyle()
                    const classItem = classList[Number(targetIndex)];
                    if (classItem) {
                        const sheet = themeStyle.sheet;
                        classItem.forEach((rule, index) => {
                            sheet.insertRule(rule, index);
                        })
                    }
                    const target = instance.rootContainer.querySelector('.wbdCv-baseStyle')
                    if (target) {
                        target.setAttribute("data-theme-index", targetIndex + 1)
                    }
                }
            },
            changeThemeWithSave(index) {
                instance.changeTheme(index)
                dispatch(resumeSlice.actions.setThemeIndex(index))
            },
            removeThemeStyle() {
                const sheet = themeStyle.sheet;
                while (sheet.cssRules.length > 0) {
                    sheet.deleteRule(0);
                }
            },
            async insetTemplateStyle() {
                instance.removeTemplateStyle()
                const {resume, resumeData, template, pagination} = store.getState();
                const {linkConfig} = template || {};
                instance.changeTheme(resume.themeIndex)
                if (linkConfig) {
                    const linkPromiseList = linkConfig.map((item) => {
                        const link = document.createElement("link");
                        const src = "https://web.static.jianzeppt.com/resume-css/" + item.href;
                        if (item.href.indexOf("c1e4d770.css") === -1 && item.href.indexOf("2ace02b3.css") === -1) {
                            link.setAttribute("href", src);
                            link.setAttribute("rel", item.rel);
                            document.head.append(link);
                        }
                        instance.linkContainers.push(link);
                        return axios.get(src)
                    });
                    await Promise.all(linkPromiseList).catch(() => void 0)
                }
            },
            removeTemplateStyle() {
                if (instance.linkContainers.length > 0) {
                    instance.linkContainers.forEach((link) => {
                        try {
                            document.head.removeChild(link);
                        } catch (e) {
                        }
                    })
                    instance.linkContainers = []
                }
            },
            get getModuleContentToolContainer() {
                return getModuleContentToolContainer
            },

        }).current;


        useEffect(() => {
            if (instance.paginationOffsetsCallback) {
                instance.paginationOffsetsCallback();
                instance.paginationOffsetsCallback = undefined
            }
        }, [offsets]);
        window.instance = instance;

        return <ResumeInstanceContext.Provider value={instance}>
            {createElement(Component, props)}
        </ResumeInstanceContext.Provider>
    }
}
