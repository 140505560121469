import styled from "styled-components"
import { ReactComponent as DetailIcon } from '@/pages/Mobile/svg/detail.svg'
import { useState } from "react"
import { ActionSheet, Dialog, Toast } from "antd-mobile"
import { copyResumeOfUser, deleteResumeOfUser, editResumeName } from "@/http/api"
import { useNavigate } from "react-router-dom"

const UserResumeItemStyled = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 8px -2px rgba(227,227,227,1);
    border-radius: 12px;
    padding: 7px;
    height: 120px;
    box-sizing: border-box;
    /* justify-content: space-between; */
    img {
        border-radius: 8px;
        border: 1px solid #E2E2E2;
        margin-right: 18px;
        /* width: 75px; */
        height: 106px;
    }
    iframe{
        margin-right: 18px;
        outline: none;
        border: 1px solid #E2E2E2;
        border-radius: 8px;
    }
    .info {
        flex: 1;
        height: 100%;
        h2 {
            padding-right: 15px;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #343542;
        }
    }
    .bottom {

        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        svg {
            width: 20px;
            height: 20px;
        }
    }
`



const UserResumeItem = (props) => {
    const { title = '张三-区域销售经理3-5年求职简历', time = '2023-10-27 11:08', id, style, onMutation } = props
    const [actionSheetVisible, setActionSheetVisible] = useState(false)
    const [dialogVisible, setDialogVisible] = useState(false)
    const [showDialogType, setShowDialogType] = useState('')
    const [resumeTitle, setResumeTitle] = useState(title)
    const navigate = useNavigate()
    const handleClick = (e) => {
        e.stopPropagation();
        setActionSheetVisible(true)
    }

    const getActionEdit = () => {
        return {
            title: '请输入简历名称',
            content: <div style={{ padding: '0 20px', marginTop: 10 }}>
                <input 
                    value={resumeTitle} 
                    onChange={(e) => setResumeTitle(e.target.value)} 
                    className="flex-center" 
                    style={{
                        background: '#F7F8F9',
                        outline: 'none',
                        border: 'none',
                        width: '100%',
                        height: 36
                    }} 
                />
            </div>,
            actions: [
                [
                    {
                        key: 'cancel',
                        text: '取消',
                        style: { color: '#000' },
                    },
                    {
                        key: 'confirm',
                        text: '确认名称',
                        style: { color: '#11BBA6' },
                        onClick: handleEditName
                    },
                ]
            ]
        }
    }

    const getActionDelete = () => {
        return {
            title: '温馨提示',
            content: <div style={{ textAlign: 'center', color: '#888888', marginTop: 10 }}>确认要删除简历吗</div>,
            actions: [
                [
                    {
                        key: 'cancel',
                        text: '取消',
                        style: { color: '#000' },
                    },
                    {
                        key: 'confirm',
                        text: '确认删除',
                        style: { color: '#FA5151' },
                        onClick: handleDelete
                    },
                ]
            ]
        }
    }

    const handleAction = (type) => {
        if (type === 'edit') {
            navigate(`/mobile/resume?id=${id}`)
        } else {
            setActionSheetVisible(false)
            setDialogVisible(true)
    
            setShowDialogType(type)
        }
    }

    const handleDelete = () => {
        deleteResumeOfUser({
            resumePageId: id
        }).then(res => {
            Toast.show({
                icon: 'success',
                content: '删除成功',
            })
            onMutation && onMutation()
        }).catch(err => {
            Toast.show({
                icon: 'fail',
                content: '删除失败',
            })
        })
    }

    const handleEditName = () => {
        editResumeName({
            resumePageId: id,
            name: resumeTitle
        }).then(res => {
            Toast.show({
                icon: 'success',
                content: '修改成功',
            })
            onMutation && onMutation()
        }).catch(err => {
            Toast.show({
                icon: 'fail',
                content: '修改失败',
            })
        })
    }


    const actions = [
        {
            text: '编辑简历',
            key: 'edit',
            onClick: () => handleAction('edit'),
        },
        {
            text: '重命名',
            key: 'rename',
            onClick: () => handleAction('rename'),
            dialog: getActionEdit()
        },
        // {
        //     text: '分享',
        //     key: 'share',
        //     onClick: () => { },
        // },
        {
            text: '删除',
            key: 'delete',
            danger: true,
            onClick: () => handleAction('delete'),
            dialog: getActionDelete()
        },
    ]

    const dialogConfig = actions.find(item => item.key === showDialogType)?.dialog || {}
    return <UserResumeItemStyled className="flex-center" style={style}>
        <div onClick={() => handleAction('edit')} className="flex-center" style={{ height: '100%', width: '100%' }}>
            <iframe
                width={75}
                height={106}
                id={id}
                src={`/resume/container?id=${id}`}
                style={{ pointerEvents: 'none'}}
            />
            <div className="info flex flex-col justify-between">
                <h2>{title}</h2>
                <div onClick={handleClick} className="bottom flex items-center justify-between">
                    <span>{time}</span>
                    <DetailIcon />
                </div>
            </div>
        </div>
        <ActionSheet
            cancelText='取消'
            actions={actions}
            visible={actionSheetVisible}
            onClose={() => setActionSheetVisible(false)}
        />

        <Dialog
            visible={dialogVisible}
            closeOnAction={true}
            onClose={() => setDialogVisible(false)}
            {
                ...dialogConfig
            }
        />
    </UserResumeItemStyled>
}

export default UserResumeItem