export default (fileUrl, onPercent) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', fileUrl, true);
    xhr.responseType = 'blob';
    xhr.addEventListener('progress', (evt) => {
      if (evt.lengthComputable) {
        const percent = _.toInteger((100 * evt.loaded) / evt.total);
        onPercent?.(percent);
      }
    }, false);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(xhr.response);
      } else if (xhr.readyState === 4) {
        reject();
      }
    };
    xhr.send();
  });
}
