import withComponent from "@/hoc/withComponent";
import styled from "styled-components";
import paginationSlice from "@/components/resume/redux/services/paginationSlice";
import {useStore, useSelector, useDispatch} from "@/components/resume/redux/hooks/index";
import JobStatusController from "@/components/resume/components/JobStatusController";
import {message, Popover, Switch} from "antd";
import {useState} from "react";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";


const ButtonStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FF514E;
  color: #FFFFFF;
  width: 90px;
  z-index: 3;
  height: 24px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 17px;
  transform: translateY(-100%);
  pointer-events: auto;
  cursor: pointer;
`
const LineStyled = styled.div`
  position: absolute;
  z-index: 3;
  color: red;
  border-top: 1px dashed #FF514E;
  top: 0;
  right: 0;
  left: 0;
  height: 1px;
  pointer-events: auto;
  cursor: pointer;
  transition: right 0.5s;
  //transform: ;
`
const PaginationStyled = styled.div`
  background: #ECEFF1;
  left: 0;
  right: 0;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  pointer-events: none;
  z-index: 3;
  transform: translateY(-50%);
`
const PaginationTitleStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  display: flex;
  align-items: center;
  pointer-events: auto;
`
const PaginationTitleIconStyled = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 8px;
`
const PaginationPageTextStyled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right:14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
`
const PaginationPageHintStyled = styled.div`
  position: relative;
  left: -16px;
  width: 158px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.18);
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 13px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;

`
const PaginationPageHintTriangleStyled = styled.div`
  position: absolute;
  bottom: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid white;
`

const PaginationPageSwitchStyled = styled.div`
  pointer-events: auto;
  display: flex;
  align-items: center;

  .ant-switch-checked {
    background: #66D3C5;
  }

`
const PaginationPageSwitchTextStyled = styled.div`
  margin-left: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #A2A2A2;
  line-height: 20px;
`


const closeIcon = <div style={{width: 12, height: 12}} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="作品展示、自定义展示" transform="translate(-676.000000, -466.000000)" fill="#FFFFFF">
            <g id="编组-3" transform="translate(675.000000, 465.000000)">
                <g id="字体下拉备份-6" transform="translate(1.000000, 1.000000)">
                    <g id="编组-11" transform="translate(2.250000, 2.250000)">
                        <path d="M6.8193852,0 L7.47131931,0.664782867 L4.409,3.746 L7.5,6.79896639 L6.85468451,7.4704417 L3.753,4.407 L0.680063245,7.5 L0.0286806883,6.83521713 L3.091,3.753 L0,0.700475907 L0.645315488,0.0295582986 L3.747,3.092 L6.8193852,0 Z" id="形状结合"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const icon1 = <div style={{width: 12, height: 12, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0">
    <g id="文档智能打分✅✅✅" stroke="none" stroke-width="1" fill="#000000" fill-rule="evenodd">
        <g id="修改建议和分数球交互" transform="translate(-976.000000, -133.000000)" fill="#000000" fill-rule="nonzero">
            <g id="秒出助手备份-2" transform="translate(976.000000, 133.000000)">
                <path d="M6,1.5 C8.48528137,1.5 10.5,3.51471863 10.5,6 C10.5,8.48528137 8.48528137,10.5 6,10.5 C3.51471863,10.5 1.5,8.48528137 1.5,6 C1.5,3.51471863 3.51471863,1.5 6,1.5 Z M6,2.166 C3.88254027,2.166 2.166,3.88254027 2.166,6 C2.166,8.11745973 3.88254027,9.834 6,9.834 C8.11745973,9.834 9.834,8.11745973 9.834,6 C9.834,4.98315998 9.43006187,4.00796708 8.7110474,3.2889526 C7.99203292,2.56993813 7.01684002,2.166 6,2.166 Z M6.417,4.938 L6.417,8.781 L5.667,8.781 L5.667,4.938 L6.417,4.938 Z M6.417,3.219 L6.417,4.047 L5.667,4.047 L5.667,3.219 L6.417,3.219 Z" id="形状结合"></path>
            </g>
        </g>
    </g>
</svg>`
}}/>


export default withComponent((props) => {
    const {pageCount, currentPageHeight, paginationCoverHeight,openEdit,} = props;
    const openAutoPagination = useSelector((state) => state.pagination.openAutoPagination);
    const openHintPagination = useSelector((state) => state.pagination.openHintPagination);
    const hidePageIndex = useSelector((state) => state.pagination.hidePageIndex);
    const checked = useSelector((state) => state.pagination.onePageLevel);

    const hintPaginationLineVisible = useSelector((state) => state.pagination.hintPaginationLineVisible)
    const dispatch = useDispatch();
    const resumeInstance = useResumeInstance();
    // const [checked, setChecked] = useState(false);
    const onClick = () => {
        dispatch(paginationSlice.actions.switchHintPaginationLineVisible());
    }
    const onChange = () => {
        const hide = message.loading("", 0)
        resumeInstance.mergeOnePage().catch(() => {
            message.info("内容过长,无法合并一页")
        }).finally(() => {
            hide()
        })
    }
    

    if (openAutoPagination) {
        return <>
            <div style={{position: "absolute", top: 0, right: 0, left: 0, pointerEvents: "none"}}>
                {Array.from({length: pageCount}).map((item, index) => {
                    const isLast = pageCount - 1 === index
                    return !isLast && <PaginationStyled key={index + 1}
                                                        style={{
                                                            top: (index + 1) * currentPageHeight,
                                                            height: paginationCoverHeight,
                                                            visibility: hidePageIndex === index ? 'hidden' : undefined,
                                                        }}>
                        <PaginationTitleStyled style={{visibility:openEdit?undefined:"hidden"}}>
                            <Popover
                                content={<PaginationPageHintStyled>
                                    如果超出安全边距，会为您 <span style={{color: "#11BBA6"}}>自动布局</span> 哦~
                                    <PaginationPageHintTriangleStyled/>
                                </PaginationPageHintStyled>}
                                destroyTooltipOnHide
                                overlayClassName="ant-popover-custom"
                                autoAdjustOverflow={false}
                                trigger="hover"
                                transitionName=""
                                getPopupContainer={null}
                                placement="topLeft"
                            >
                                <PaginationTitleIconStyled>{icon1}</PaginationTitleIconStyled>
                            </Popover>
                            分页处文字如被遮挡，请使用回车键避开哦～</PaginationTitleStyled>
                        <PaginationPageTextStyled >第 {index + 1} 页</PaginationPageTextStyled>
                       
                    </PaginationStyled>
                })}
            </div>
        </>
    }
    if (openHintPagination) {
        return <div style={{position: "absolute", top: 0, right: 0, left: 0, pointerEvents: "none"}}>
            {Array.from({length: pageCount}).map((item, index) => {
                if (index === 0) {
                    return
                }
                return <div key={index} style={{
                    position: "absolute",
                    top: index * currentPageHeight,
                    display: index === 0 ? 'none' : undefined,
                    right: 0,
                    left: 0,
                    border: "1px solid red"
                    // width: state ? 40 : undefined,
                }}>
                    <LineStyled onClick={onClick} style={{right: hintPaginationLineVisible ? "94%" : 0}}/>
                    {!hintPaginationLineVisible && <ButtonStyled onClick={onClick}>
                        <div style={{marginRight: 4}}>跨页分界线</div>
                        {closeIcon}
                    </ButtonStyled>}
                </div>
            })}
        </div>
    }
})
