import withComponent from "@/hoc/withComponent";
import {
    CheckedStyled,
    ContainerStyled,
    MenuItemStyled,
    MenuItemTitleStyled,
    NoCheckedStyled,
    RequiredStyled,
    TitleStyled
} from "@/components/resume/components/menuStyled";
import {useCallback, useMemo} from "react";
import {useDispatch, useSelector, useStore} from "@/components/resume/redux/hooks";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import {
    APPLICATION_MODULE_ENUM,
    BASE_FIELD_ENUM,
    CAMPUS_ACTIVITIES_FIELD_ENUM, CONTACT_INFORMATION_ENUM,
    EDUCATIONAL_BACKGROUND_FIELD_ENUM,
    INTEREST_FIELD_ENUM,
    INTERNSHIP_EXPERIENCE_FIELD_ENUM,
    JOB_INTEREST_FIELD_ENUM,
    LANGUAGE_PROFICIENCY_FIELD_ENUM,
    PORTFOLIO_FIELD_ENUM,
    PROJECT_EXPERIENCE_FIELD_ENUM, SELF_EVALUATION_FIELD_ENUM, SKILLS_FIELD_ENUM,
    WORK_EXPERIENCE_FIELD_ENUM
} from "@/common/field/field";
import getTextByField from "@/common/field/getTextByField";

import {Module} from "@/common/module/module";
import getTextByModule from "@/common/module/getTextByModule";

import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import getFieldVisiblePath from "@/common/config/getFieldVisiblePath";
import getFieldVisible from "@/common/config/getFieldVisible";
import {Switch} from "antd";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";


const successIcon = <div style={{height: 12, width: 12, position: "relative"}} dangerouslySetInnerHTML={{
    __html: `<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;top: 0;left: 0">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="基本信息状态" transform="translate(-679.000000, -332.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-16" transform="translate(532.000000, 110.000000)">
                <g id="编组-9备份" transform="translate(146.000000, 221.000000)">
                    <g id="字体下拉备份-11" transform="translate(1.000000, 1.000000)">
                        <g id="duihao-10" transform="translate(1.500000, 2.872500)">
                            <path d="M3.46165985,6.25669199 C3.28421975,6.25669199 3.10748101,6.1886616 2.97212157,6.05330216 L0.20251315,3.27247224 C-0.0675043834,3.00105202 -0.0675043834,2.56060783 0.20251315,2.28918761 C0.473232028,2.01776739 0.91157218,2.01776739 1.18158971,2.28918761 L3.46165985,4.57837522 L7.81841029,0.204091175 C8.08842782,-0.0680303916 8.52676797,-0.0680303916 8.79748685,0.204091175 C9.06750438,0.475511397 9.06750438,0.915254237 8.79748685,1.18667446 L3.95119813,6.05330216 C3.81583869,6.1886616 3.6383986,6.25669199 3.46165985,6.25669199 Z" id="路径"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>


const getFieldConfig = (module, template) => {
    switch (module) {
        case Module.BASE: {
            if (template.moduleIncludeField[Module.BASE]) {
                const arr1 = [...template.moduleIncludeField[Module.BASE]];
                const arr2 = getRequiredFieldConfig(module);

                return arr2.filter(x => arr1.includes(x)).concat(arr1.filter(x => !arr2.includes(x)));
            } else {
                return [
                    BASE_FIELD_ENUM.NAME,
                    BASE_FIELD_ENUM.PHONE_NUMBER,
                    BASE_FIELD_ENUM.MAIL,
                    BASE_FIELD_ENUM.MARITAL_STATUS,
                    BASE_FIELD_ENUM.YEARS_OF_WORKING,
                    BASE_FIELD_ENUM.HEIGHT_AND_WEIGHT,
                    BASE_FIELD_ENUM.PRESENT_ADDRESS,
                    BASE_FIELD_ENUM.GENDER,
                    BASE_FIELD_ENUM.WECHAT_ID,
                    BASE_FIELD_ENUM.NATION,
                    BASE_FIELD_ENUM.ADDRESS_AND_ORIGIN,
                    BASE_FIELD_ENUM.POLITICS_STATUS,
                    BASE_FIELD_ENUM.BIRTHDAY
                ]
            }
        }
        case Module.JOB_INTEREST: {
            return [
                JOB_INTEREST_FIELD_ENUM.TITLE,
                JOB_INTEREST_FIELD_ENUM.JOB_TYPE,
                JOB_INTEREST_FIELD_ENUM.ROLE,
                JOB_INTEREST_FIELD_ENUM.CITY,
                JOB_INTEREST_FIELD_ENUM.SALARY,
                JOB_INTEREST_FIELD_ENUM.JOB_STATUS,
            ]
        }
        case Module.EDUCATIONAL_BACKGROUND: {
            return [
                EDUCATIONAL_BACKGROUND_FIELD_ENUM.TITLE,
                EDUCATIONAL_BACKGROUND_FIELD_ENUM.DATE,
                EDUCATIONAL_BACKGROUND_FIELD_ENUM.SCHOOL,
                EDUCATIONAL_BACKGROUND_FIELD_ENUM.MAJOR,
                EDUCATIONAL_BACKGROUND_FIELD_ENUM.EDUCATION,
                EDUCATIONAL_BACKGROUND_FIELD_ENUM.DETAILS,
            ]
        }
        case Module.CAMPUS_ACTIVITIES: {
            return [
                CAMPUS_ACTIVITIES_FIELD_ENUM.TITLE,
                CAMPUS_ACTIVITIES_FIELD_ENUM.DATE,
                CAMPUS_ACTIVITIES_FIELD_ENUM.ACTIVITY_NAME,
                CAMPUS_ACTIVITIES_FIELD_ENUM.ROLE,
                CAMPUS_ACTIVITIES_FIELD_ENUM.DETAILS,
            ]
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            return [
                INTERNSHIP_EXPERIENCE_FIELD_ENUM.TITLE,
                INTERNSHIP_EXPERIENCE_FIELD_ENUM.DATE,
                INTERNSHIP_EXPERIENCE_FIELD_ENUM.ACTIVITY_NAME,
                INTERNSHIP_EXPERIENCE_FIELD_ENUM.ROLE,
                INTERNSHIP_EXPERIENCE_FIELD_ENUM.DETAILS,
            ]
        }
        case Module.WORK_EXPERIENCE: {
            return [
                WORK_EXPERIENCE_FIELD_ENUM.TITLE,
                WORK_EXPERIENCE_FIELD_ENUM.DATE,
                WORK_EXPERIENCE_FIELD_ENUM.POSITION,
                WORK_EXPERIENCE_FIELD_ENUM.COMPANY,
                WORK_EXPERIENCE_FIELD_ENUM.DETAILS,
            ]
        }
        case Module.PROJECT_EXPERIENCE: {
            return [
                PROJECT_EXPERIENCE_FIELD_ENUM.TITLE,
                PROJECT_EXPERIENCE_FIELD_ENUM.DATE,
                PROJECT_EXPERIENCE_FIELD_ENUM.ROLE,
                PROJECT_EXPERIENCE_FIELD_ENUM.NAME,
                PROJECT_EXPERIENCE_FIELD_ENUM.DETAILS,
            ]
        }
        case Module.PORTFOLIO: {
            return [
                PORTFOLIO_FIELD_ENUM.LINK,
                PORTFOLIO_FIELD_ENUM.PICTURE,
            ]
        }
        case Module.LANGUAGE_PROFICIENCY: {
            return [
                LANGUAGE_PROFICIENCY_FIELD_ENUM.TITLE,
                LANGUAGE_PROFICIENCY_FIELD_ENUM.DETAILS,
            ]
        }
        case Module.INTEREST: {
            return [
                INTEREST_FIELD_ENUM.TITLE,
                INTEREST_FIELD_ENUM.DETAILS,
            ]
        }
        case Module.SKILLS: {
            return [
                SKILLS_FIELD_ENUM.TITLE,
                SKILLS_FIELD_ENUM.DETAILS,
            ]
        }
        case Module.SELF_EVALUATION: {
            return [
                SELF_EVALUATION_FIELD_ENUM.TITLE,
                SELF_EVALUATION_FIELD_ENUM.DETAILS,
            ]
        }
        case Module.CUSTOM_MODULE: {
            return [
                SELF_EVALUATION_FIELD_ENUM.TITLE,
                SELF_EVALUATION_FIELD_ENUM.DETAILS,
            ]
        }
        case Module.CONTACT_INFORMATION: {
            return [
                CONTACT_INFORMATION_ENUM.TITLE,
                CONTACT_INFORMATION_ENUM.PHONE_NUMBER,
                CONTACT_INFORMATION_ENUM.MAIL,
            ]
        }
        case Module.APPLICATION_INFO: {
            return [
                APPLICATION_MODULE_ENUM.TITLE,
                APPLICATION_MODULE_ENUM.SCHOOL,
                APPLICATION_MODULE_ENUM.MAJOR,
                APPLICATION_MODULE_ENUM.TABLE,
            ]
        }
    }
    return []
}
const getRequiredFieldConfig = (module) => {
    switch (module) {
        case Module.BASE: {
            return [
                BASE_FIELD_ENUM.NAME,
                BASE_FIELD_ENUM.PHONE_NUMBER,
                BASE_FIELD_ENUM.MAIL,
                BASE_FIELD_ENUM.EDUCATION_BACKGROUND
            ]
        }
    }
    return []
}

export default withComponent(() => {
    const store = useStore();
    const dispatch = useDispatch();
    const resumeData = useSelector((state) => state.resumeData);
    const template = useSelector((state) => state.template);
    const moduleInstance = useModuleInstance();
    // 213
    const blockName = moduleInstance.data.id === Module.APPLICATION_INFO ? moduleInstance.data.id : moduleInstance.for_block_name
    const fieldConfig = useMemo(() => getFieldConfig(blockName, template), []);
    const requiredFieldConfig = useMemo(() => getRequiredFieldConfig(moduleInstance.for_block_name), [])
    const config = useMemo(() => fieldConfig.map((field) => ({
        id: field,
        title: getTextByField(blockName, field),
        value: field,
        isRequired: requiredFieldConfig.includes(field),
        isChecked: getFieldVisible(resumeData, moduleInstance.data.id, field)
    })), [resumeData]);
    const checkedHandler = useCallback((e) => {
        const {id: field} = e.currentTarget.dataset;
        const {resumeData} = store.getState();
        const path = getFieldVisiblePath(moduleInstance.data.id, field);
        if (path) {
            const visible = getFieldVisible(resumeData, moduleInstance.data.id, field);
            dispatch(resumeDataSlice.actions.changeValueByPath({path, value: !visible}))
        }
        // setOpen(false)
    }, []);
    const onChange = useCallback(() => {
        store.dispatch(resumeDataSlice.actions.switchModuleVisible({
            id: moduleInstance.data.id
        }));
    }, [])
    const title = getTextByModule(blockName);
    return <ContainerStyled>
        <TitleStyled>{title}管理</TitleStyled>
        {
            config.map((item, key) => {
                const onClick = item.isRequired ? undefined : (item.isChecked ? checkedHandler : checkedHandler);
                return <MenuItemStyled key={item.id} data-id={item.id} data-value={item.isChecked} onClick={onClick}>
                    <MenuItemTitleStyled>{item.title}</MenuItemTitleStyled>
                    {item.isRequired ? <RequiredStyled>必填</RequiredStyled> : <>
                        {
                            item.isChecked ?
                                <CheckedStyled>
                                    {successIcon}
                                </CheckedStyled> :
                                <NoCheckedStyled/>
                        }
                    </>}
                </MenuItemStyled>
            })
        }
        {
            moduleInstance.for_block_name !== Module.BASE && <MenuItemStyled style={{cursor: "auto"}}>
                <MenuItemTitleStyled>隐藏当前模块</MenuItemTitleStyled>
                <Switch checked={false} onChange={onChange} size="small" style={{marginRight: 16}}/>
            </MenuItemStyled>
        }

    </ContainerStyled>
})

