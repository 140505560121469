import {useSelector} from "react-redux";
import NotLogin from "./NotLogin";
import User from "./User";

export default () => {
    const user = useSelector(state => state.user);
    if (user && user.isWXLogin) {
        return <User/>
    } else {
        return <NotLogin type='black'/>
    }
}
