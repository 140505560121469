import {BASE_FIELD_ENUM, JOB_INTEREST_FIELD_ENUM} from "@/common/field/field";
import { cityConfig, jobInterestRoleConfig } from "@/components/resume/const/getMenuConfigByField";
const marriageConfig = ['未婚', '已婚', '保密']
const yearsOfWorkingConfig = ['在校生', '应届生', '1年经验', '2年经验', '3年经验', '4年经验', '5年经验', '6年经验', '7年经验', '8年经验', '9年经验', '10年经验', '10年以上经验']
const politicsConfig = ['中共党员', '中共预备党员', '共青团员', '民主党派人士', '无党派民主人士', '普通公民'];
const educationConfig = ['初中及以下', '高中', '中技', '中专', '大专', '本科', '硕士', '博士'];
const jobInterestJobTypeConfig = ['全职', '兼职', '实习'];
const genderConfig = ["男","女"]


const configToPickerConfig = (arr) => {
    return arr.map(item => {
        return {
            label: item,
            value: item,
        }
    })
}

// name, label, value

const optionToPickerOption = (option) => {
    return option?.map(item => {
        if (item.children) {
            return {
                ...item,
                label: item.name,
                value: item.name,
                children: optionToPickerOption(item.children)
            }
        }
        return {
            ...item,
            label: item.name,
            value: item.name
        }
    })
}

const list = [
    {
        value: "DIMISSION_SEARCH_JOB",
        label: "离职找工作",
    },
    {
        value: "ON_JOb_SEARCH_JOB",
        label: "在职找工作",
    },
    {
        value: "ON_JOB_LOOK_FOR_CHANCE",
        label: "在职看机会",
    },
    {
        value: "NO_SEARCH_JOB",
        label: "暂时不找工作",
    },
]

const list2 = [
    {
        value: "ONE_WEEK_TO_WORK",
        label: "一周内到岗",
    },
    {
        value: "ONE_MONTH_TO_WORK",
        label: "一个月内到岗",
    },
    {
        value: "THREE_MONTH_TO_WORK",
        label: "三个月内到岗",
    },
    {
        value: "LATER",
        label: "到岗时间另议",
    },
]

// JOB_INTEREST_JOB_STATUS jobArrivalTime

export const pickerModuleList = [
    {
        key: JOB_INTEREST_FIELD_ENUM.JOB_TYPE,
        columns: [configToPickerConfig(jobInterestJobTypeConfig)],
    },
    {
        key: JOB_INTEREST_FIELD_ENUM.ROLE,
        cascader: true,
        options: optionToPickerOption(jobInterestRoleConfig),
    },
    {
        key: JOB_INTEREST_FIELD_ENUM.CITY,
        cascader: true,
        picker: true,
        options: optionToPickerOption(cityConfig),
    },
    {
        key: JOB_INTEREST_FIELD_ENUM.SALARY,
        columns: [
            [
                {label: '薪资面议', value: 0},
                ...new Array(100).fill(0).map((item,index) => ({
                    label: `${index+1}k`,
                    value: index+1
                })),
            ],
            [
                ...new Array(100).fill(0).map((item,index) => ({
                    label: `${index+1}k`,
                    value: index+1
                })),
            ],
        ]
    },
    {
        key: JOB_INTEREST_FIELD_ENUM.JOB_STATUS,
        join: true,
        columns: [list,list2]
    },
]