import { useNavigate } from "react-router-dom"
import styled from "styled-components"

const PcTipStyled = styled.div`
    background: #FFFFFF;
    border-radius: 8px;
    font-size: 13px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    margin-bottom: 8px;
    padding: 13px;
    color: #494949;
    justify-content: space-between;
    .link {
        color: #11BBA6;
    }
`


const closeSvg = <div className="flex-center" dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="提示去PC端编辑" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="提示“去PC端制作”简历" transform="translate(-18.000000, -103.000000)" fill="#999999" fill-rule="nonzero">
            <g id="返回" transform="translate(18.000000, 103.000000)">
                <path d="M11.1603265,3.33333333 C11.2967594,3.33530908 11.4265012,3.39267547 11.5196983,3.49223514 C11.7194559,3.70465856 11.7148737,4.03678993 11.5095435,4.24341084 L8.23894602,7.49996954 L11.5100534,10.7559322 C11.7148737,10.9620428 11.7194559,11.2941742 11.5199045,11.5063778 C11.4265052,11.6063915 11.2968405,11.6638943 11.1604481,11.6659798 C11.0240555,11.6680654 10.8926921,11.6145536 10.7966009,11.5177672 L7.5,8.23520325 L4.20393545,11.51723 C4.10731983,11.614766 3.97600942,11.6684481 3.83957645,11.6666201 C3.70324063,11.6645982 3.57349876,11.6072318 3.48030172,11.5076721 C3.28054406,11.2952487 3.28512625,10.9631173 3.49045647,10.7564964 L6.76105398,7.49993769 L3.48994662,4.24397506 C3.28512625,4.03786442 3.28054406,3.70573305 3.48009547,3.49352946 C3.57349484,3.39351568 3.70315952,3.33601291 3.83955186,3.3339274 C3.97594446,3.33184188 4.10730793,3.38535361 4.20339909,3.48214005 L7.5,6.76470398 L10.7960646,3.48267719 C10.873357,3.40464846 10.9728542,3.35468613 11.0794151,3.33878265 L11.1603265,3.33333333 Z" id="路径"></path>
            </g>
        </g>
    </g>
</svg>
`}} />

const PcTip = ({visible, onClose}) => {
    const navigate = useNavigate()

    const handlePc = () => {
        navigate("/mobile/pc_tip")
    }

    return visible && <PcTipStyled className="flex-center">
        <div className="flex-center">
            <div onClick={onClose}>{closeSvg}</div>
            <div className="ml-12">PC端编辑简历效率更高哦～</div>
        </div>

        <div onClick={handlePc} className="link">去PC制作</div>
    </PcTipStyled>
}

export default PcTip