import {createAsyncThunk} from "@reduxjs/toolkit";
import {getUserInfo, login_anonymous} from "@/http/api";
import setUserByLocal from "@/common/user/setUserByLocal"

export const fetchUserThunk = createAsyncThunk(
    'user/fetchUserThunk',
    async (id, thunkAPI) => {
        const res = await getUserInfo();
        setUserByLocal(res)
        return res
    },
);
export const fetchAnonymousUserThunk = createAsyncThunk(
    'user/fetchAnonymousUserThunk',
    async (id, thunkAPI) => {
        const res = await login_anonymous();
        setUserByLocal(res)
        return res;
    },
);
export const logoutUserThunk = createAsyncThunk(
    'user/logoutUserThunk',
    async (arg, thunkAPI) => {
        setUserByLocal(undefined);
        return null
    },
);
