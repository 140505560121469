import { Modal } from 'antd';
import FlexStyled from '@/styled/FlexStyled';

import Pointer from '@/styled/Pointer';
import {BtnStyleEl, BtnStyleEl2} from "@/components/ErrorModal";
import JzImage from '@/components/JzImage';

const closeIcon = <div dangerouslySetInnerHTML={{
    __html: `<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='输入主题，流程弹窗◉◉' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='弹窗' transform='translate(-1124.000000, -381.000000)' fill='#999999' fill-rule='nonzero'>
            <g id='搜索' transform='translate(1124.000000, 381.000000)'>
                <g id='编组-11' transform='translate(4.500000, 4.500000)'>
                    <polygon id='路径' points='0.0573613767 13.6704343 13.6387704 0 14.9426386 1.32956573 1.36012649 15'></polygon>
                    <polygon id='路径' points='13.709369 14.9408834 0 1.40095181 1.29063098 0.0591165973 15 13.5979328'></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}}/>;

const SaveModal = ({ visible, onCancel, onConfirm }) => {


    return <Modal width={'auto'}
        visible={visible}
        footer={false}
        mask={true}
        destroyOnClose
        zIndex={999999}
        getContainer={null}
        maskClosable
        centered
        onCancel={onCancel}
        maskTransitionName=''
        modalRender={() => <FlexStyled flexDirection='column' alignItems='flex-start' justifyContent='flex-start'
            style={{
                width: 400,
                minHeight: 160,
                background: '#FFFFFF',
                borderRadius: 10,
                pointerEvents: 'auto',
            }}>
            <FlexStyled style={{ position: 'absolute', top: 12, right: 12 }} justifyContent='flex-end'>
                <Pointer onClick={onCancel}>{closeIcon}</Pointer>
            </FlexStyled>
            <FlexStyled $flexDirection='column' $alignItems='flex-start' $justifyContent='flex-start' style={{ padding: '20px', width: '100%' }}>
                <FlexStyled $alignItems='center' style={{ fontSize: 18, fontWeight: 'bold', lineHeight: 1 }}>
                    <JzImage src='delete-icon.png' style={{ width: 20, height: 20, marginRight: 6 }} />
                    确定退出吗？
                </FlexStyled>
                <FlexStyled $justifyContent='center' style={{ marginTop: 15, fontSize: 14, color: '#999999' }}>
                    退出后作答内容将不会被保留
                </FlexStyled>
                <FlexStyled $justifyContent='flex-end' style={{ marginTop: 42, fontSize: 16, color: '#007AFF', width: '100%' }}>
                    <BtnStyleEl2 onClick={onCancel}>取消</BtnStyleEl2>
                    <BtnStyleEl style={{ marginLeft: 10 }}  onClick={onConfirm}>确定</BtnStyleEl>
                </FlexStyled>
            </FlexStyled>
        </FlexStyled>}
    />
}

export default SaveModal