import Menu from "@/components/resume/moduleManage/Menu";
import ToolPopover from "@/components/resume/components/ToolPopover";
import {useState} from "react";

export default (Component) => {
      return (props) => {
            const [open, setOpen] = useState(false);
            return <ToolPopover content={<Menu setOpen={setOpen}/>} open={open} onOpenChange={setOpen}>
                  <Component {...props}/>
            </ToolPopover>
      }
}
