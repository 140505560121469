import a from "./useSelector"
import b from "./useDispatch"
import c from "./useStore"
// export useSelector from  './useSelector'
// export useDispatch from  './useDispatch'
// export useStore from  './useStore'


export const useSelector = a
export const useDispatch = b
export const useStore = c
export const useResumeSelector = a;
export const useResumeDispatch = b;
export const useResumeStore = c;
