import withComponent from "@/hoc/withComponent"
import styled from "styled-components"
import { 
    feat1Svg,
    feat2Svg,
    feat3Svg,
    feat4Svg,
    feat5Svg,
    feat6Svg,
} from "../svg"

const FeatureListStyled = styled.div`
    font-family: PingFangSC, PingFang SC;
    padding-top: 30px;
    background: #FFFFFF;
    padding-bottom: 54px;
    .feature-h1 {
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 14px;
        text-align: center;
    }

    .feature-p {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin-bottom: 40px;
        text-align: center;
    }

    .feature-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0 27px;
        row-gap: 40px;
    }
    .feature-item-svg {
        width: 44px;
        height: 44px;
        background: rgba(17,187,166,0.1);
        border-radius: 50%;
    }
`

const list = [
    {
        svg: feat1Svg,
        title: '自动排版',
        p: '只需填写内容，程序会自动排版出精美的简历',
    },
    {
        svg: feat2Svg,
        title: '模块定制',
        p: '所有简历模块皆可自由调整、隐藏或显示',
    },
    // {
    //     svg: feat3Svg,
    //     title: '皮肤色彩定制',
    //     p: '千万种皮肤随心定制，做出独一无二的简历',
    // },
    // {
    //     svg: feat4Svg,
    //     title: '支持中英文',
    //     p: '中英文一键切换，字体可修改',
    // },
    {
        svg: feat5Svg,
        title: '一键换模板',
        p: '填写一份简历，一键切换海量模板',
    },
    {
        svg: feat6Svg,
        title: '一键下载',
        p: '一键下载简历，轻松打印投递',
    },
]

const FeatureList = () => {




    return <FeatureListStyled>
        <div className="feature-h1">简历功能介绍</div>
        <div className="feature-p">多种实用功能，助您高效制作简历</div>
        <div className="feature-list">
            {list.map((item, index) => {
                return <div className="flex-center justify-start flex-col" key={index}>
                    <div className="feature-item-svg flex-center mb-24" >
                        {item.svg}
                    </div>
                    <div className="mb-8" style={{ fontSize: 15, color: '#333'}}>{item.title}</div>
                    <div style={{ color: '#999', fontSize: 12, textAlign: 'center', maxWidth: 140 }}>
                        {item.p}
                    </div>
                </div>
            })}
        </div>
    </FeatureListStyled>
}

export default withComponent(FeatureList)