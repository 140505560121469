enum BASE {
    NAME = "姓名",
    GENDER = "性别",
    AGE = "年龄",
    EDUCATION_BACKGROUND = "学历",
    PHONE_NUMBER = "手机号",
    MAIL = "邮箱",
    MARITAL_STATUS = "婚姻状况",
    YEARS_OF_WORKING = "工作年限",
    HEIGHT_AND_WEIGHT = "身高体重",
    HEIGHT = "身高",
    WEIGHT = "体重",
    PRESENT_ADDRESS = "现居地",
    ADDRESS_AND_ORIGIN = "籍贯",
    AVATAR = "头像",
    WECHAT_ID = "微信号",
    NATION = "民族",
    POLITICS_STATUS = "政治面貌",
    JOB_INTENTION = "求职意向",
    BIRTHDAY = "生日",
    OTHER = "其他",
}

enum CONTACT_INFORMATION {
    TITLE = 'CONTACT_INFORMATION_TITLE',
    PHONE_NUMBER = "CONTACT_INFORMATION_PHONE_NUMBER",
    MAIL = "CONTACT_INFORMATION_MAIL",
}

enum AVATAR {
    AVATAR = 'avatar',
}

enum JOB_INTEREST {
    TITLE = 'JOB_INTEREST_TITLE', // 岗位
    JOB_TYPE = "JOB_INTEREST_JOB_TYPE",//求职类型
    ROLE = 'JOB_INTEREST_ROLE', // 岗位
    CITY = 'JOB_INTEREST_CITY', // 城市
    SALARY = 'JOB_INTEREST_SALARY', // 薪资
    JOB_STATUS = 'JOB_INTEREST_JOB_STATUS', // 求职状态
}

enum WORK_EXPERIENCE {
    TITLE = 'title',
    DATE = 'date', // 申请时间
    POSITION = 'position', // 岗位名称
    COMPANY = 'company', // 公司名称
    DETAILS = 'details', // 申请详情
}

enum PROJECT_EXPERIENCE {
    TITLE = 'title',
    DATE = 'date',
    ROLE = 'role', // 角色名称
    NAME = 'name', // 项目名称
    DETAILS = 'details', // 项目详情
}

enum INTERNSHIP_EXPERIENCE {
    TITLE = 'title',
    DATE = 'date',
    ACTIVITY_NAME = 'activity_name', // 活动名称
    ROLE = 'role', // 角色名称
    DETAILS = 'details', // 项目详情
}

enum MY_STRENGTHS {
    DETAILS = 'details', // 详情
}

enum SKILLS {
    TITLE = 'title',
    DETAILS = 'details',
}

enum INTEREST {
    TITLE = 'title',
    DETAILS = 'details',
}

enum PORTFOLIO {
    TITLE = 'title',
    LINK = 'link',
    PICTURE = 'PICTURE'
}

enum EDUCATIONAL_BACKGROUND {
    TITLE = "title",  //标题文字
    DATE = "date",  //日期
    SCHOOL = 'school', // 学校名称
    MAJOR = 'major', // 专业
    EDUCATION = "education",//学历
    LOGO = "logo",//logo
    DETAILS = "details",//logo
}

enum CAMPUS_ACTIVITIES {
    TITLE = 'title',
    DATE = 'date',
    ACTIVITY_NAME = 'activity_name', // 活动名称
    ROLE = 'role', // 角色名称
    DETAILS = 'details', // 项目详情
}

enum HONORS_AND_AWARDS {
    TITLE = 'title',
    DETAILS = 'details',
}

enum LANGUAGE_PROFICIENCY {
    TITLE = 'title',
    ITEM = 'item',
    DETAILS = "details",
}

enum SOCIAL_MEDIA {
    LINK = 'link',
}

enum QR_CODE {
    QR_CODE = 'qr_code',
}

enum SELF_EVALUATION {
    TITLE = 'title',
    DETAILS = 'details',
}

enum CUSTOM_MODULE {
    TITLE = "title",
    DETAILS = "details",
}

enum APPLICATION_MODULE {
    TITLE = "title",
    SCHOOL = 'school',
    MAJOR = 'major',
    TABLE = 'table'
}


export type FiledType =
    BASE
    | AVATAR
    | JOB_INTEREST
    | WORK_EXPERIENCE
    | MY_STRENGTHS
    | SKILLS
    | PORTFOLIO
    | EDUCATIONAL_BACKGROUND
    | INTERNSHIP_EXPERIENCE
    | CAMPUS_ACTIVITIES
    | PROJECT_EXPERIENCE
    | HONORS_AND_AWARDS
    | LANGUAGE_PROFICIENCY
    | SOCIAL_MEDIA
    | QR_CODE
    | SELF_EVALUATION
    | INTEREST
    | CUSTOM_MODULE


export {
    BASE as BASE_FIELD_ENUM,
    AVATAR as AVATAR_FIELD_ENUM,
    JOB_INTEREST as JOB_INTEREST_FIELD_ENUM,
    WORK_EXPERIENCE as WORK_EXPERIENCE_FIELD_ENUM,
    MY_STRENGTHS as MY_STRENGTHS_FIELD_ENUM,
    SKILLS as SKILLS_FIELD_ENUM,
    PORTFOLIO as PORTFOLIO_FIELD_ENUM,
    EDUCATIONAL_BACKGROUND as EDUCATIONAL_BACKGROUND_FIELD_ENUM,
    CAMPUS_ACTIVITIES as CAMPUS_ACTIVITIES_FIELD_ENUM,
    PROJECT_EXPERIENCE as PROJECT_EXPERIENCE_FIELD_ENUM,
    INTERNSHIP_EXPERIENCE as INTERNSHIP_EXPERIENCE_FIELD_ENUM,
    HONORS_AND_AWARDS as HONORS_AND_AWARDS_FIELD_ENUM,
    LANGUAGE_PROFICIENCY as LANGUAGE_PROFICIENCY_FIELD_ENUM,
    SOCIAL_MEDIA as SOCIAL_MEDIA_FIELD_ENUM,
    QR_CODE as QR_CODE_FIELD_ENUM,
    SELF_EVALUATION as SELF_EVALUATION_FIELD_ENUM,
    INTEREST as INTEREST_FIELD_ENUM,
    CUSTOM_MODULE as CUSTOM_MODULE_FIELD_ENUM,
    CONTACT_INFORMATION as CONTACT_INFORMATION_ENUM,
    APPLICATION_MODULE as APPLICATION_MODULE_ENUM
};

