import withComponent from "@/hoc/withComponent"
import styled from "styled-components"
import { headerFeat1Svg, headerFeat2Svg, headerFeat3Svg, headerFeat4Svg, headerInput1Svg, headerInput2Svg } from "../svg"
import JzImage from "@/components/JzImage"
import User from '@/components/user/index';
import Tabs from "./Tabs"
import { LogoComponent } from "@/const/logo";
import { useNavigate } from 'react-router-dom';

const HeaderStyled = styled.div`
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    padding-top:22px;
    overFlow
    position: relative;
    .logo-img {
        position: absolute;
        width: 141px;
        height: 36px;
        top: 16px;
        left: 36px;
    }
    .header-h1 {
        font-size: 48px;
        color: #2C2C2C;
        margin-bottom: 19px;
        text-align: center;
        margin-top:34px;
    }
    .header-p {
        font-size: 18px;
        color: #2C2C2C;
        margin-bottom: 50px;
        text-align: center;
        font-weight:
    }
    .header-tabs {
        width: 766px;
        margin: 0 auto;
    }
    .header-feat {
        font-size: 13px;
        color: #2C2C2C;
        background: #009B89;
        > div {
            padding: 10px 8px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }
        svg {
            width: 24px;
            height: 24px;
        }
    }
    .header-feat-p {
        color: rgba(255,255,255,0.8); 
        max-width: 118px;
    }
`
const TabsSection = styled.div`
    display: flex;
    align-items: center;
    column-gap: 50px;
    margin-right: 50px;
    color: #2C2C2C;
    font-size: 14px;
    .tab-1,
    .tab-2{
        cursor: pointer;
    }
`

const featList = [
    {
        svg: headerFeat1Svg,
        title: '自动排版',
        p: '输入内容、自动排版生成简历',
    },
    {
        svg: headerFeat2Svg,
        title: '专业规范',
        p: '知名HR推荐的简历',
    },
    {
        svg: headerFeat3Svg,
        title: '模板切换',
        p: '海量模板任意切换',
    },
    {
        svg: headerFeat4Svg,
        title: '云端存储',
        p: 'PC、小程序等随时下载',
    },
]

const Header = () => {
    const navigate = useNavigate()
    const navToPage = (type) => {
        navigate(`/resume?tabKey=${type}`)
    }

    return <HeaderStyled>
        <div style={{
            display:'flex',
            justifyContent:'space-between',
            paddingLeft:50
        }}> 

               <LogoComponent isDark={false}/>
     
                <div className="flex-center" style={{ marginRight: 50}}>
                    {/* <div style={{ fontSize: 14, color: '#2C2C2C', cursor: 'pointer', marginRight: 50}} onClick={() => window.open('/')}>简历模板</div> */}
                     <TabsSection>
                     <div onClick={() => navToPage('resume')} className='tab-1'>改简历</div>
                     <div onClick={() => navToPage('question')} className='tab-2'>练面试</div>
                     </TabsSection>
   
                    <User />
                </div>
        </div>
     
        
        <div className="header-h1">改简历，练面试，接Offer</div>
        <div className="header-p">HR推荐的专业AI简历面试工具</div>

        <div className="header-tabs">
            <Tabs />
        </div>

        {/* <div className="header-feat">
            <div style={{ maxWidth: 1030, margin: '0 auto' }}>
                {featList.map((item, index) => {
                    return <div className="flex-center justify-start flex-col" key={index}>
                        <div className="flex-center mb-6" >
                            {item.svg}
                            <div className="pl-4">{item.title}</div>
                        </div>
                        <div className="header-feat-p" style={{ color: 'rgba(255,255,255,0.8)', fontSize: 12, textAlign: 'center' }}>
                            {item.p}
                        </div>
                    </div>
                })}
            </div>
        </div> */}
    </HeaderStyled>
}

export default withComponent(Header)