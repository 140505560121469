import styled from "styled-components"
import MobileLayout from "../../layout"
import { getMobileImg, isMiniProgram } from "../../utils"
import MyResume from "./components/MyResume"
import ResumeList from "./components/ResumeList"
import SearchInput from "./components/SearchInput"
import { useNavigate } from "react-router-dom"
import useQueryParam from "@/hooks/useQueryParam"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { clearFilter } from "../TemplateList/indexSlice"
import JzImage from "@/components/JzImage"
import Tabs from "./components/Tabs"
import useWxAppTip from "./hooks/useWxAppTip"
import NavWxButton from "@/components/NavWxButton"
import { isB } from "@/common/AOrB"

const HomeStyled = styled.div`
    background: #11BBA6 ;
`

const HeaderStyled = styled.div`
    /* background: #70D3C7 linear-gradient(135deg, rgba(129,221,210,0.5) 0%, rgba(64,206,189,0.5) 100%); */
    background: linear-gradient(180deg, #27DFD2 0%, #11BBA6 100%);
    /* overflow: hidden; */
    position: relative;
    padding-bottom: 20px;
    .logo-img {
        /* left: 12px;
        top: 18px; */
        width: 80px;
        height: 21px;
        /* position: absolute; */
    }
    .header-h1 {
        font-size: 23px;
        color: #FFFFFF;
        margin-bottom: 22px;
        text-align: center;
    }
    .header-p {
        font-size: 12px;
        color: #FFFFFF;
        margin-bottom: 12px;
        text-align: center;
    }
`

const Home = () => {
    const filter = useSelector(state => state.mobileIndex.filter)
    const [myResumeVisible, setMyResumeVisible] = useState(false)
    const { el } = useWxAppTip()
    const [visible, setVisible] = useState(false)

    const user = useSelector(state => state.user)
    
    useEffect(() => {
        if (user) {
            setVisible(isB() && !isMiniProgram())
        }
    }, [user])

    return <MobileLayout>
        <HomeStyled>
            <HeaderStyled>
                <div className="flex-center justify-between" style={{ padding: 12, paddingBottom: 24 }}>
                    <JzImage className="logo-img" src="sldknfijxcbnvjhbu.png" />

                </div>
                <div className="header-h1">高效智能的在线简历编辑神器</div>
                <div className="header-p">一键应用专业模版，轻松斩获高薪offer</div>
                <Tabs />
            </HeaderStyled>
            {/* <MyResume onChange={v => setMyResumeVisible(v)} /> */}
            <ResumeList 
                home 
                filter={{
                    position: filter.position
                }} 
            />
        </HomeStyled>
        {el}
        {visible ? <NavWxButton /> : <></> }
    </MobileLayout>
}

export default Home