import styled from "styled-components";
import {Switch} from "antd"
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import {Module} from "@/common/module/module";
import {useSelector, useStore} from "@/components/resume/redux/hooks";
import {useCallback} from "react";
import getResumePath from "@/common/resume/getResumePath";

const ContainerStyled = styled.div`
  width: 170px;
  height: 116px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 0.5);
  border-radius: 6px;
  border: 1px solid #E0E0E0;
  padding-top: 12px;
`
const ItemBoxContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
`
const ItemContainerStyled = styled.div`
  padding-top: 7px;
  width: 46px;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  cursor: pointer;

  &:hover {
    background: #11BBA6;
    color: #FFFFFF;

    path {
      fill: #FFFFFF;
    }
  }

  ${props => props.$isActive ? 'background: #11BBA6;color: #FFFFFF;path {fill: #FFFFFF;}' : ''}
`
const BottomContainerStyled = styled.div`
  padding-top: 16px;

  .ant-switch-checked {
    background: #11BBA6 !important;
  }
`
const BottomContainerSpanStyled = styled.span`
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #9FA7A6;
  margin-left: 8px;
  margin-right: 12px;
`
const icon1 = <div style={{width: 30, height: 30}} dangerouslySetInnerHTML={{
    __html: `<svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="基本信息状态" transform="translate(-191.000000, -714.000000)" fill="#E6E9EB">
            <g id="编组-5" transform="translate(175.000000, 695.000000)">
                <g id="圆形" transform="translate(16.000000, 19.000000)">
                    <path d="M15,0 C23.2842712,0 30,6.71572875 30,15 C30,19.9286171 27.6229699,24.302049 23.9527922,27.0364134 L23.9766779,26.7847201 C23.9853785,26.6825057 23.9931525,26.5782715 24,26.4720173 C24,23.0104789 22.3384615,19.8258635 19.7076923,18.164325 C18.3230769,19.2720173 16.8,19.964325 15,19.964325 C13.2,19.964325 11.5384615,19.2720173 10.2923077,18.164325 C7.66153846,19.8258635 6,23.0104789 6,26.4720173 C6,26.6561709 6.00295466,26.8347822 6.00886399,27.007851 C2.36056211,24.2716907 0,19.9115148 0,15 C0,6.71572875 6.71572875,0 15,0 Z M12.2307692,7.77970962 C10.5692308,8.74894039 9.46153846,10.6874019 9.46153846,12.6258635 C9.46153846,15.6720173 11.9538462,18.164325 15,18.164325 C18.0461538,18.164325 20.5384615,15.6720173 20.5384615,12.6258635 C20.5384615,10.6874019 19.4307692,8.88740193 17.7692308,7.77970962 C16.1076923,6.67201732 13.8923077,6.81047886 12.2307692,7.77970962 Z" id="形状结合"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const icon2 = <div style={{width: 30, height: 30}} dangerouslySetInnerHTML={{
    __html: `<svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="基本信息状态" transform="translate(-245.000000, -712.000000)" fill="#E6E9EB">
            <g id="编组-5" transform="translate(175.000000, 695.000000)">
                <g id="1:1" transform="translate(70.000000, 17.000000)">
                    <path d="M30,0 L30,30.0013065 L25.9594041,30.0017716 C25.9749688,29.9366831 25.9885008,29.8695693 26,29.80043 C26,25.5694765 23.9692308,21.6769993 20.7538462,19.6461417 C19.0615385,21.0000468 17.2,21.8462375 15,21.8462375 C12.8,21.8462375 10.7692308,21.0000468 9.24615385,19.6461417 C6.03076923,21.6769993 4,25.5694765 4,29.80043 C4,29.8698671 4.0023671,29.9372613 4.0071013,30.0026127 L0,30.0013065 L0,0 L30,0 Z M11.6153846,6.95328123 C9.58461538,8.1379482 8.23076923,10.5072821 8.23076923,12.8766161 C8.23076923,16.5998552 11.2769231,19.6461417 15,19.6461417 C18.7230769,19.6461417 21.7692308,16.5998552 21.7692308,12.8766161 C21.7692308,10.5072821 20.4153846,8.30718634 18.3846154,6.95328123 C16.3538462,5.59937611 13.6461538,5.76861425 11.6153846,6.95328123 Z" id="形状结合"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
const icon3 = <div style={{width: 30, height: 37}} dangerouslySetInnerHTML={{
    __html: `<svg width="30px" height="37px" viewBox="0 0 30 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="基本信息状态" transform="translate(-299.000000, -712.000000)" fill="#E6E9EB">
            <g id="编组-5" transform="translate(175.000000, 695.000000)">
                <g id="3:4" transform="translate(124.000000, 17.000000)">
                    <path d="M30,0 L30,37 L25.6651179,37.0009889 C25.8883726,36.6909373 26,36.2903184 26,35.7991323 C26,31.568363 23.9692308,27.6760554 20.7538462,25.6452861 C19.0615385,26.9991323 17.2,27.8452861 15,27.8452861 C12.8,27.8452861 10.7692308,26.9991323 9.24615385,25.6452861 C6.03076923,27.6760554 4,31.568363 4,35.7991323 C4,36.2903184 4.11311269,36.6909373 4.33933808,37.0009889 L0,37 L0,0 L30,0 Z M11.6153846,12.9529784 C9.58461538,14.1375938 8.23076923,16.5068246 8.23076923,18.8760554 C8.23076923,22.5991323 11.2769231,25.6452861 15,25.6452861 C18.7230769,25.6452861 21.7692308,22.5991323 21.7692308,18.8760554 C21.7692308,16.5068246 20.4153846,14.3068246 18.3846154,12.9529784 C16.3538462,11.5991323 13.6461538,11.768363 11.6153846,12.9529784 Z" id="形状结合"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
export default () => {
    const store = useStore();
    const styleType = useSelector((state) => state.resumeData[Module.AVATAR]?.data?.styleType || 'circle');
    const onChange = (e) => {
        store.dispatch(resumeDataSlice.actions.switchModuleVisible({
            id: Module.AVATAR
        }));
    }
    const onChangeStyleType = useCallback((e) => {
        const {type} = e.currentTarget.dataset;
        store.dispatch(resumeDataSlice.actions.changeValueByPath({
            path: `${Module.AVATAR}.data.styleType`,
            value: type
        }));
    }, [])
    return <ContainerStyled>
        <ItemBoxContainerStyled>
            <ItemContainerStyled $isActive={styleType === "circle"} data-type="circle" onClick={onChangeStyleType}>
                <div>{icon1}</div>
                <div>圆形</div>
            </ItemContainerStyled>
            <ItemContainerStyled $isActive={styleType === "1/1"} data-type="1/1" onClick={onChangeStyleType}>
                <div>{icon2}</div>
                <div>1:1</div>
            </ItemContainerStyled>
            <ItemContainerStyled $isActive={styleType === "3/4"} data-type="3/4" onClick={onChangeStyleType}>
                <div>{icon3}</div>
                <div>3:4</div>
            </ItemContainerStyled>
        </ItemBoxContainerStyled>
        <BottomContainerStyled>
            <BottomContainerSpanStyled>隐藏</BottomContainerSpanStyled>
            <Switch onChange={onChange}/>
        </BottomContainerStyled>
    </ContainerStyled>
}
