import {useSelector} from "@/components/resume/redux/hooks";
import getNestedProperty from "@/utils/getNestedProperty";
import getResumePath from "@/common/resume/getResumePath";
import useFieldInstance from "@/components/resume/hooks/useFieldInstance";
import useModuleContentInstance from "@/components/resume/hooks/useModuleContentInstance";
import {Module} from "@/common/module/module";
import {EDUCATIONAL_BACKGROUND_SLOT_ENUM, WORK_EXPERIENCE_SLOT_ENUM} from "@/common/slot/slot";
import moment from "moment/moment";
import { convertEngDate } from "@/utils/dateToeng";

export default () => {
    const fieldInstance = useFieldInstance();
    const moduleContentInstance = useModuleContentInstance();
    let start_slot_value;
    let end_slot_value;
    switch (fieldInstance.for_block_name) {
        case Module.EDUCATIONAL_BACKGROUND: {
            start_slot_value = EDUCATIONAL_BACKGROUND_SLOT_ENUM.START_DATE
            end_slot_value = EDUCATIONAL_BACKGROUND_SLOT_ENUM.END_DATE
            break
        }
        case Module.WORK_EXPERIENCE: {
            start_slot_value = WORK_EXPERIENCE_SLOT_ENUM.START_DATE
            end_slot_value = WORK_EXPERIENCE_SLOT_ENUM.END_DATE
            break
        }
        case Module.PROJECT_EXPERIENCE: {
            start_slot_value = WORK_EXPERIENCE_SLOT_ENUM.START_DATE
            end_slot_value = WORK_EXPERIENCE_SLOT_ENUM.END_DATE
            break
        }
        case Module.INTERNSHIP_EXPERIENCE: {
            start_slot_value = WORK_EXPERIENCE_SLOT_ENUM.START_DATE
            end_slot_value = WORK_EXPERIENCE_SLOT_ENUM.END_DATE
            break
        }
        case Module.CAMPUS_ACTIVITIES: {
            start_slot_value = WORK_EXPERIENCE_SLOT_ENUM.START_DATE
            end_slot_value = WORK_EXPERIENCE_SLOT_ENUM.END_DATE
            break
        }
    }

    const startPath = getResumePath(fieldInstance.for_block_name, fieldInstance.for_field_name, start_slot_value, moduleContentInstance?.index);
    const endPath = getResumePath(fieldInstance.for_block_name, fieldInstance.for_field_name, end_slot_value, moduleContentInstance?.index);

    const startDate = useSelector((state) => getNestedProperty(state.resumeData, startPath));
    const endDate = useSelector((state) => getNestedProperty(state.resumeData, endPath));

    const { language } = useSelector((state) => state.resume)

    const newStartDate = language === 'en_US' ? convertEngDate(moment(startDate).format("YYYY.MM")) : moment(startDate).format("YYYY.MM")
    const newEndDate = language === 'en_US' ? convertEngDate(moment(endDate).format("YYYY.MM")) : moment(endDate).format("YYYY.MM")

    return `${newStartDate} - ${endDate ? newEndDate : moment(endDate).format("YYYY.MM")}`
}
