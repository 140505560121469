import {createElement, useRef} from "react";
import {ModuleContext} from "@/components/resume/context";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";
import useStore from "@/components/resume/redux/hooks/useStore";
import {Module} from "@/common/module/module";

export default (Component) => {
    return (props) => {
        const {data} = props;
        const resumeInstance = useResumeInstance();
        const moduleContainerRef = useRef();
        const store = useStore();
        const instance = useRef({
            get data() {
                const {resumeData} = store.getState();
                if (props.block_name === Module.CUSTOM_MODULE) {
                    return resumeData[data.id]
                }
                return resumeData[props.block_name]
            },
            get container() {
                return moduleContainerRef.current
            },
            for_block_name: props.block_name,
            for_field_name: undefined,
        }).current;
        return <ModuleContext.Provider value={instance}>
            {createElement(Component, Object.assign({},props,{moduleContainerRef}))}
        </ModuleContext.Provider>
    }
}
