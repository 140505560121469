import styled from "styled-components"
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";
import {createPortal} from "react-dom";
import {useCallback, useEffect, useMemo, useRef} from "react";
import withComponent from "@/hoc/withComponent";
import classNames from "classnames";
import useForceUpdate from "@/hooks/useForceUpdate";
import config from "@/const";
import {MenuBoxStyled} from "@/components/resume/components/menuStyled";
import useConfigRender from "@/components/resume/hooks/useConfigRender";
import withModule from "@/components/resume/hoc/withModule";
import {useDispatch, useResumeStore, useSelector} from "@/components/resume/redux/hooks";
import getModuleVisible from "@/common/config/getModuleVisible";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import useModuleInstance from "@/components/resume/hooks/useModuleInstance";
import AddButton from "@/components/resume/moduleManage/button/AddButton";
import {Module} from "@/common/module/module";
import LayoutButton from "@/components/resume/moduleManage/button/LayoutButton";
import BaseMenuButton from "@/components/resume/moduleManage/button/BaseMenuButton";
import DragButton from "@/components/resume/moduleManage/button/DragButton";
import getModuleDataListBySort from "@/common/resume/getModuleDataListBySort";
import {Tooltip} from "antd";
import hoverSlice from "@/components/resume/redux/services/hoverSlice";
import paginationSlice from "../redux/services/paginationSlice";

const BorderStyled = styled.div`
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  border: ${props => props.$borderWidth}px dashed #11BBA6 !important;
  pointer-events: none !important;
  width: auto !important;
  z-index: 99;

`
const ContainerStyled = styled(MenuBoxStyled)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  display: flex;
  //align-items: center;
`
export const IconContainerStyled = styled.div`
  position: relative;
  width: 28px;
  height: 28px;
  background: #11BBA6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;

  &:hover::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    opacity: 0.2;
  }
`


const icon1 = <div style={{
    height: 16, width: 16, position: "relative"
}} dangerouslySetInnerHTML={{
    __html: `<svg width='16px' height='16px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'
     xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='页面-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='Arrow' transform='translate(-192.000000, 0.000000)' fill-rule='nonzero'>
            <g id='arrow_up_line' transform='translate(192.000000, 0.000000)'>
                <path d='M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z'
                      id='MingCute' fill-rule='nonzero'></path>
                <path d='M12.7071,3.63616 C12.3166,3.24563 11.6834,3.24563 11.2929,3.63615 L5.63603,9.29301 C5.24551,9.68353 5.24551,10.3167 5.63603,10.7072 C6.02656,11.0977 6.65972,11.0977 7.05025,10.7072 L11,6.75747 L11,20.0001 C11,20.5524 11.4477,21.0001 12,21.0001 C12.5523,21.0001 13,20.5524 13,20.0001 L13,6.75748 L16.9497,10.7072 C17.3403,11.0977 17.9734,11.0977 18.364,10.7072 C18.7545,10.3167 18.7545,9.68353 18.364,9.29301 L12.7071,3.63616 Z'
                      id='路径' fill='#FFFFFFFF'></path>
            </g>
        </g>
    </g>
</svg>
`
}}/>
const icon2 = <div style={{
    height: 16, width: 16, position: "relative"
}}
                   dangerouslySetInnerHTML={{
                       __html: `<svg width='16px' height='16px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'
     xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='页面-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='Arrow' fill-rule='nonzero'>
            <g id='arrow_down_line'>
                <path d='M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z'
                      id='MingCute' fill-rule='nonzero'></path>
                <path d='M11,17.2426 L7.05025,13.2929 C6.65972,12.9023 6.02656,12.9023 5.63603,13.2929 C5.24551,13.6834 5.24551,14.3166 5.63603,14.7071 L11.2929,20.3639 C11.6834,20.7545 12.3166,20.7545 12.7071,20.3639 L18.364,14.7071 C18.7545,14.3166 18.7545,13.6834 18.364,13.2929 C17.9734,12.9023 17.3403,12.9023 16.9497,13.2929 L13,17.2426 L13,4 C13,3.44772 12.5523,3 12,3 C11.4477,3 11,3.44772 11,4 L11,17.2426 Z'
                      id='路径' fill='#FFFFFFFF'></path>
            </g>
        </g>
    </g>
</svg>
`
                   }}/>


const addButtonVisibleConfig = [
    Module.EDUCATIONAL_BACKGROUND,
    Module.WORK_EXPERIENCE,
    Module.PORTFOLIO,
    Module.INTERNSHIP_EXPERIENCE,
    Module.CAMPUS_ACTIVITIES,
    Module.PROJECT_EXPERIENCE
];
const layoutButtonVisibleConfig = [Module.PORTFOLIO];
const dragConfig = [
    Module.JOB_INTEREST,
    Module.EDUCATIONAL_BACKGROUND,
    Module.CAMPUS_ACTIVITIES,
    Module.INTERNSHIP_EXPERIENCE,
    Module.WORK_EXPERIENCE,
    Module.PROJECT_EXPERIENCE,
    Module.PORTFOLIO,
    Module.LANGUAGE_PROFICIENCY,
    Module.INTEREST,
    Module.SKILLS,
    Module.SELF_EVALUATION,
    Module.HONORS_AND_AWARDS,
    Module.CUSTOM_MODULE,
    Module.CONTACT_INFORMATION,
];

// TODO：模块管理
export default withComponent(withModule((props) => {
    const {tagName, style, className, children = [], block_name, moduleContainerRef} = props;
    const that = useRef({
        position: undefined, mouseTimer: undefined
    }).current;
    const configRender = useConfigRender();
    const moduleInstance = useModuleInstance();
    const store = useResumeStore();
    const dispatch = useDispatch();
    const resumeData = useSelector((state) => state.resumeData);
    const template = useSelector((state) => state.template);
    const moduleManageVisible = useSelector((state) => state.hover?.moduleId === moduleInstance.data?.id || state.focus?.moduleId === moduleInstance.data?.id);
    const memoChildren = useMemo(() => children.map((config) => configRender(config)), [children]);
    const addButtonVisible = addButtonVisibleConfig.includes(block_name);
    const layoutButtonVisible = layoutButtonVisibleConfig.includes(block_name);
    const dragButtonVisible = dragConfig.includes(block_name);
    const pluginRef = useRef();
    const [, update] = useForceUpdate();
    const getContainer = useCallback(() => {
        return moduleContainerRef.current
    }, []);
    const updatePluginPosition = useCallback(() => {
        const target = getContainer();
        if (target) {
            const rootContainerRect = instance.rootContainer.getBoundingClientRect();
            const rect = target.getBoundingClientRect();
            const top = rect.top - rootContainerRect.top
            that.position = {
                top,
                bottom: top + rect.height,
                right: rootContainerRect.right - rect.right
            };
            update();
        }
    }, []);
    const onMouseEnter = useCallback(() => {
        dispatch(hoverSlice.actions.setModuleId(moduleInstance.data.id));
        updatePluginPosition();
    }, []);
    const onMouseLeave = useCallback(() => {
        dispatch(hoverSlice.actions.setModuleId(null))
        // that.position = null;
        update();
    }, []);
    const movePre = useCallback(() => {
        const {template} = store.getState()
        dispatch(resumeDataSlice.actions.preMoveModule({id: moduleInstance.data.id, template}));
    }, []);
    const moveNext = useCallback(() => {
        const {template} = store.getState()
        dispatch(resumeDataSlice.actions.nextMoveModule({id: moduleInstance.data.id, template}))
    }, []);
    const instance = useResumeInstance();
    const onOpenChange = () => {
        console.log("onOpenChange");
    };
    const visible = useMemo(() => {
        if (moduleInstance.for_block_name === Module.CUSTOM_MODULE) {
            return getModuleVisible(resumeData, moduleInstance.data.id)
        }
        return getModuleVisible(resumeData, block_name)
    }, [resumeData]);
    const offset = useSelector((state) => state.pagination.offsets?.[moduleInstance?.data?.id]);
    const computedStyle = {position: "relative", ...style};
    let isMain = true;
    const {leftModuleConfig, mainModuleConfig} = template;
    if (leftModuleConfig.includes(block_name)) {
        isMain = false;
    }
    const visibleModuleDataList = useMemo(() => {
        return getModuleDataListBySort(resumeData).filter((item) => item.visible).filter((item) => {
            return isMain ? mainModuleConfig.includes(item.type) : leftModuleConfig.includes(item.type);
        })
    }, [resumeData]);
    const canPreMove = useMemo(() => {
        return visibleModuleDataList[0]?.id !== moduleInstance?.data?.id && dragButtonVisible
    }, [visibleModuleDataList, dragButtonVisible, moduleInstance?.data?.id]);
    const canNextMove = useMemo(() => {
        return visibleModuleDataList[visibleModuleDataList.length - 1]?.id !== moduleInstance?.data?.id && dragButtonVisible
    }, [visibleModuleDataList, dragButtonVisible]);
    const pluginRender = useCallback(() => {
        if (instance.rootContainer && moduleManageVisible && that.position) {
            return createPortal(<ContainerStyled ref={pluginRef} style={{
                position: "absolute", top: that.position?.top, right: that.position?.right, zIndex: 9
            }}>
                {dragButtonVisible && canPreMove &&
                    <Tooltip title={"上移"} placement={"bottom"} overlayClassName="tooltipPointerEventsNone">
                        <DragButton onClick={movePre} text={icon1}/>
                    </Tooltip>}
                {dragButtonVisible && canNextMove &&
                    <Tooltip title={"下移"} placement={"bottom"} overlayClassName="tooltipPointerEventsNone">
                        <DragButton onClick={moveNext} text={icon2}/>
                    </Tooltip>}
                {addButtonVisible && <AddButton/>}
                {layoutButtonVisible && <LayoutButton/>}
                <BaseMenuButton onOpenChange={onOpenChange}/>
            </ContainerStyled>, instance.rootContainer)
        }
    }, [moduleManageVisible, addButtonVisible, that.position, canPreMove, canNextMove]);

    const hidePagingPrompt = () => {
        Array.from({ length: instance.pageCount }).forEach((value, index) => {
            const height = instance.currentPageHeight * (index + 1)
            if (that.position?.top < height && that.position?.bottom > height) {
                dispatch(paginationSlice.actions.setHidePageIndex(index))
            }
        })
    }
    const showPagingPrompt = () => {
        dispatch(paginationSlice.actions.setHidePageIndex(-1))
    }

    useEffect(() => {
        updatePluginPosition()
    }, [resumeData, visible]);

    useEffect(() => {
        if (moduleManageVisible) {
            hidePagingPrompt()
        } else {
            showPagingPrompt()
        }
    }, [moduleManageVisible])
    const C = tagName;
    
    if (block_name === "基本信息") {
        return visible && <C ref={moduleContainerRef} style={computedStyle}
                             data-type={block_name}
                             data-id={moduleInstance?.data?.id}
                             data-block-name={block_name}
                             className={classNames(className, "resume-module")}
                             onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {memoChildren}
            {block_name !== Module.AVATAR && pluginRender()}
            {moduleManageVisible && <BorderStyled $borderWidth={config.resume.width / 726}/>}
            {/* 暂时隐藏掉头像模版的功能 */}
        </C>
    } else {
        return visible && <div style={{position: "relative"}}>
            {/* <div style={{height: offset}}/> */}
            <C ref={moduleContainerRef} style={computedStyle}
               data-type={block_name}
               data-id={moduleInstance?.data?.id}
               data-block-name={block_name}
               className={classNames(className, "resume-module")}
               onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {memoChildren}
                {block_name !== Module.AVATAR && pluginRender()}
                {/* 暂时隐藏掉头像模版的功能 */}
            </C>
            {moduleManageVisible && <BorderStyled $borderWidth={config.resume.width / 726}/>}
        </div>
    }
}))

