import styled from "styled-components"
import useResumeData from "@/pages/Mobile/hooks/useResumeData"
import { CascadePicker, Cascader, DatePicker, Form, Input, Picker } from 'antd-mobile'
import { BASE_FIELD_ENUM } from "@/common/field/field";
import { useEffect, useState } from "react";
import moment from "moment";
import { processBasicInfo, processBasicInfoExport } from "./utils";
import { getFieldConfigByModuleName, mapModuleNameToFieldList } from "./config";
import { every, get, isEmpty } from 'lodash'
import JzButton from "@/components/JzButton";
import FiledManagePopup from "../components/FiledManagePopup";
import useResumeFieldVisible from "@/pages/Mobile/hooks/useResumeFieldVisible";
import { Module } from "@/common/module/module";
import useQueryParam from "@/hooks/useQueryParam";
import RangeDatePicker from "./RangeDatePicker";
import useUpdateResumeData from "@/pages/Mobile/hooks/useUpdateResumeData";
import QueryString from "qs";
import { useNavigate } from "react-router-dom";
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js"


const ModuleEditStyled = styled.div`
    padding-right: 20px;
    padding-left: 4px;
    margin-bottom: 52px;

    .adm-form-item-label {
        font-size: 12px !important;
    }
    .adm-input-element, .adm-form-item-child-inner {
        font-size: 15px !important;
    }
    .adm-list-default .adm-list-body {
        border: none;
    }
    .resume-wrap-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 15px;
        padding-bottom: 24px;
        background-color: #FFFFFF;
    }
`


// const moduleName = Module.JOB_INTEREST



const GeneralModuleEdit = () => {
    const resumeId = useQueryParam('id')
    const moduleName = useQueryParam('name')
    const contentIndex = useQueryParam('contentIndex')
    const navigate = useNavigate()
    const fieldConfig = getFieldConfigByModuleName(moduleName)
    const { data: resumeData } = useResumeData()
    const { onChangeByPath, onDeleteByPath } = useUpdateResumeData()
    const { fieldList, onChangeFieldList } = useResumeFieldVisible({ moduleName, fieldConfig })
    const [form] = Form.useForm()
    const basicInfoData = resumeData?.[moduleName]?.data?.content?.[contentIndex] || {}
    const [fieldManagePopupVisible, setFieldManagePopupVisible] = useState(false)

    const onFinish = () => {
        const values = form.getFieldsValue()
        const newValues = processBasicInfoExport(values)

        const path = `${moduleName}.data.content[${contentIndex}]`
        const allValuesAreEmpty = every(newValues, isEmpty)
        if (allValuesAreEmpty) {
            navigate(-1)
        } else {
            onChangeByPath(path, newValues)
        }
    }

    const handleFieldChange = (fl) => {
        onChangeFieldList(fl)
        setFieldManagePopupVisible(false)
    }
// 'https://resume.10sppt.com/mobile/resume/preview?id=10668'
    const handleNavigateLongText = (key) => {
        // 保存一手数据
        const values = form.getFieldsValue()
        const newValues = processBasicInfoExport(values)
        const path = `${moduleName}.data.content[${contentIndex}]`
        // 这里保存禁止再回退
        onChangeByPath(path, newValues, true, { isToast: false, disableNavigate: true })

        // 再跳转
        const params = QueryString.stringify({
            name: moduleName,
            fieldName: key,
            id: resumeId,
            contentIndex
        })
        navigate(`/mobile/edit/long_text?${params}`)
    }

    const handleDelete = () => {
        const path = `${moduleName}.data.content`
        const content = get(resumeData, path)
        console.log('content', content)
        if (content && content.length > 0) {
            const newContent = [...content].filter((item, index) => index !== parseInt(contentIndex))
            onDeleteByPath(path, newContent)
        } else {
            navigate(-1)
        }
    }


    useEffect(() => {
        if (!isEmpty(basicInfoData)) {
            const newData = processBasicInfo(basicInfoData, moduleName)
            form.setFieldsValue(newData)
        }

    }, [basicInfoData])

    return <ModuleEditStyled>
        <NavigationHeader style={{ width: "100vw", left: "-4px"}} title={document.title}></NavigationHeader>
        <Form
            form={form}
            onFinish={onFinish}
        >
            {
                fieldList.filter(field => field.required || field.checked).map((field, index) => {
                    const info = field.value
                    const label = field.label
                    const type = field.type
                    const config = field.config || []
                    if (type === 'picker') {
                        return <Form.Item
                            key={index}
                            label={label}
                            name={info}
                            trigger='onConfirm'
                            onClick={(e, pickerRef) => {
                                pickerRef.current?.open() // ⬅️
                            }}>
                            <Picker popupClassName="edit-custom-picker" title={label} columns={config}>
                                {(items, { open }) => {
                                    return (
                                        <div>
                                            {items.every(item => item === null)
                                                ? '请选择'
                                                : items.filter(item => item).map(item => item?.label ?? '请选择').join(' - ')}
                                        </div>
                                    )
                                }}
                            </Picker>
                        </Form.Item>
                    } else if (type === 'rangePicker') {
                        return <Form.Item
                            key={index}
                            label={label}
                            name={info}
                        >
                            <RangeDatePicker />
                        </Form.Item>
                    } else if (type === 'longText') {
                        return <Form.Item  key={index} label={label} name={info} arrow>
                                <Input 
                                onClick={() => handleNavigateLongText(info)}
                                style={{
                                    // '--color': '#DEDEDE',
                                }} placeholder='请输入' />
                        </Form.Item>
                    }


                    return <Form.Item key={index} label={label} name={info}>
                        <Input style={{
                            // '--color': '#DEDEDE',
                        }} placeholder='请输入' />
                    </Form.Item>
                })
            }
        </Form>

        
        <div className="resume-wrap-footer flex">
            <JzButton onClick={() => handleDelete()} className="flex-1" style={{ marginRight: 8 }} >删除</JzButton>
            <JzButton onClick={() => onFinish()} className="flex-2" $type="primary">保存</JzButton>
        </div>
        <FiledManagePopup
            visible={fieldManagePopupVisible}
            fieldList={fieldList}
            onChange={handleFieldChange}
            onCancel={() => setFieldManagePopupVisible(false)}
        />
    </ModuleEditStyled>
}

export default GeneralModuleEdit