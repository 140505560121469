const engMap = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
}
/**
 * 
 *  传dateString: 2020-02
 *  return February 2020
 */
export const convertEngDate = (dateString) =>  {
    let [year, month] = dateString.split('.');
    return `${engMap[month]} ${year}`;
}