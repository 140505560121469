import { useEffect } from "react";


const useClickOutside = ({ whiteList, callback, enable = true }) => {
    useEffect(() => {
        if (enable) {
            // 组件挂载时添加监听器
            document.addEventListener('mousedown', handleClickOutside);
        }

        // 组件卸载时移除监听器
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [whiteList, callback, enable])

    function handleClickOutside(event) {
        // Check if the clicked element is not in the whiteList
        const isOutside = whiteList.every(element => {
            // If element is a string, we assume it's a selector and get the actual element
            const domElements = typeof element === 'string' ? document.querySelectorAll(element) : [element];
            // Check if any of the domElements contains the clicked target
            return Array.from(domElements).every(domElement => {
                // Check if the domElement is valid and if it does not contain the clicked target
                return domElement && !domElement.contains(event.target);
            });
        });

        if (isOutside) {
            callback();
        }
    }
}

export default useClickOutside