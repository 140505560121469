import {createSlice} from '@reduxjs/toolkit';
import initState from '../initState';


export default createSlice({
    name: 'hover',
    initialState: initState.hover,
    reducers: {
        set(state, {payload}) {
            return payload
        },
        setModuleId(state, {payload}) {
            state.moduleId = payload
        }
    }
});
