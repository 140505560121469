import styled from "styled-components";
import {Button, message, Space} from "antd";
import {useDispatch, useSelector, useStore} from "@/components/resume/redux/hooks";
import config, {autoOnePageLevels} from "@/const/config";
import withComponent from "@/hoc/withComponent";
import paginationSlice from "@/components/resume/redux/services/paginationSlice";
import useResumeDetailInstance from "@/pages/ResumeDetail/hooks/useResumeDetailInstance";
import JzThemeButton from "@/components/JzThemeButton";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";

const ContainerStyled = styled.div`
    position: relative;
    /* height: 48px; */
    background: #FFFFFF;
    /* border: 1px solid #ECECEC; */
    display: flex;
    align-items: center;
    /* padding: 0 16px; */
    /* width: 794px; */
    justify-content: space-between;
    z-index: 99;
`
const Container2Styled = styled.div`
  flex-shrink: 0;

`
const CellStyled = styled.div`
  display: flex;
  align-items: center;
`

const ModuleItemStyled = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #DBDBDC;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 13px;
    > div {
        display: flex;
        align-items: center;
        margin-right: 4px;
    }
    &:hover {
        cursor: pointer;
        color: #11BBA6;
        border-color: #11BBA6;
        svg {
            circle {
                fill: #11BBA6;
            }
            path {
                fill: ${props => props.unActivePath ? '' : '#11BBA6'};
            }
        }
    }
`

const icon1 = <div dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="图标选中样式" transform="translate(-51.000000, -68.000000)" fill="#6E727C" fill-rule="nonzero">
            <g id="模块管理" transform="translate(51.000000, 68.000000)">
                <path d="M9.35574809,10.6422291 L3.77280473,10.6432294 C3.11672137,10.6432294 2.57657261,11.1396481 2.5074687,11.7773656 L2.5,11.9160526 L2.5,16.227186 C2.5,16.8832733 2.99640623,17.4234187 3.63411863,17.4925221 L3.77280473,17.4999907 L8.08393814,17.4999907 C8.42151543,17.4999907 8.74526192,17.3658954 8.9839597,17.1272007 C9.18856033,16.9226027 9.31631212,16.6555207 9.34863711,16.3708054 L9.35676136,16.227186 L9.35574809,10.6422291 Z M16.227186,10.6432294 L10.6422444,10.6422291 L10.6432479,16.227186 C10.6432479,16.8832733 11.1396541,17.4234187 11.7773665,17.4925221 L11.9160526,17.4999907 L16.227186,17.4999907 C16.8832733,17.4999907 17.4234187,17.0035845 17.4925221,16.3658721 L17.4999907,16.227186 L17.4999907,11.9160526 C17.4999907,11.5784753 17.3658954,11.2547288 17.1272007,11.0160311 C16.9226027,10.8114304 16.6555207,10.6836786 16.3708054,10.6513536 L16.227186,10.6432294 Z M8.08393814,2.5 L3.77280473,2.5 C3.11671746,2.5 2.57657209,2.99640623 2.50746864,3.63411863 L2.5,3.77280473 L2.5,8.08393814 C2.5,8.42151543 2.63409538,8.74526192 2.87279008,8.9839597 C3.07738807,9.18856033 3.34447008,9.31631212 3.62918532,9.34863711 L3.77280473,9.35676136 L9.35574809,9.35573275 L9.35674286,3.77280473 C9.35674286,3.16358084 8.92871913,2.65432725 8.35698463,2.52935694 L8.22262423,2.50746864 L8.08393814,2.5 Z M14.0716286,2.5 C12.1781913,2.5 10.6432571,4.03493417 10.6432571,5.92837143 C10.6432571,7.82180869 12.1781913,9.35674286 14.0716286,9.35674286 C15.9650658,9.35674286 17.5,7.82180869 17.5,5.92837143 C17.5,4.03493417 15.9650658,2.5 14.0716286,2.5 Z" id="形状"></path>
            </g>
        </g>
    </g>
</svg>
`}} />
const icon2 = <div dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="图标选中样式" transform="translate(-161.000000, -68.000000)" fill="#6E727C" fill-rule="nonzero">
            <g id="编组-7备份" transform="translate(51.000000, 68.000000)">
                <g id="模块管理备份" transform="translate(110.000000, 0.000000)">
                    <path d="M11.7316487,3.75 L11.68134,4.3254059 C11.6090213,5.15549966 10.8984107,5.80322432 10.0651726,5.80322434 C9.21935742,5.80322434 8.52446832,5.16807684 8.44900525,4.32226162 L8.39869655,3.75 L2.5,3.75 L2.5,9.75874685 L4.68842899,9.75874685 L4.68842899,15.6134233 C4.68842899,16.6541848 5.53424422,17.5 6.57500571,17.5 L13.5584839,17.5 C14.5992454,17.5 15.4450606,16.6541848 15.4450606,15.6134233 L15.4450606,9.75874685 L17.6334896,9.75874685 L17.6334896,3.75 L11.7316487,3.75 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />
const icon3 = <div dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="简历详情编辑页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="图标选中样式" transform="translate(-271.000000, -68.000000)">
            <g id="编组-7备份" transform="translate(51.000000, 68.000000)">
                <g id="模块管理备份-2" transform="translate(220.000000, 0.000000)">
                    <circle id="椭圆形" fill="#6E727C" cx="10" cy="10" r="8.75"></circle>
                    <path d="M7.06818182,10.1625 L7.0675,12.54875 L12.13625,12.54875 L12.1363636,11.0855256 L14.7751953,13.1965909 L12.1363636,15.3076562 L12.13625,13.84375 L6.42045454,13.8443182 C6.14222018,13.8443182 5.90495981,13.6688877 5.81325072,13.4226039 L5.78316302,13.3130207 L5.77272727,13.1965909 L5.77272727,10.1625 L7.06818182,10.1625 Z M7.86363636,4.6875 L7.8625,6.15015621 L13.5795455,6.15083803 C13.8577798,6.15083803 14.0950402,6.32626854 14.1867493,6.57255227 L14.216837,6.6821355 L14.2272727,6.7985653 L14.2272727,9.83265621 L12.9318182,9.83265621 L12.93125,7.44515621 L7.8625,7.44515621 L7.86363636,8.90963061 L5.22480474,6.7985653 L7.86363636,4.6875 Z" id="形状" fill="#FFFFFF" fill-rule="nonzero"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />
const icon4 = <div dangerouslySetInnerHTML={{ __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="智能一页交互调整-📑📑" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="默认进行分页展示" transform="translate(-334.000000, -79.000000)" fill="#6E727C" fill-rule="nonzero">
            <g id="模块管理备份-3" transform="translate(334.000000, 79.000000)">
                <g id="编组" transform="translate(2.222222, 0.902222)">
                    <path d="M2.13346256,8.41038568 C2.13346256,8.64767039 2.32874997,8.84295781 2.56603469,8.84295781 L9.03571789,8.84295781 C9.2730026,8.84295781 9.46829002,8.64767039 9.46829002,8.41038568 C9.46829002,8.17310096 9.2730026,7.97781354 9.03571789,7.97781354 L2.56603469,7.97781354 C2.32665011,7.97781354 2.13346256,8.17310096 2.13346256,8.41038568 Z M2.13346256,5.9357176 C2.13346256,6.17300232 2.32874997,6.36828974 2.56603469,6.36828974 L4.90873376,6.36828974 C5.14601848,6.36828974 5.3413059,6.17300232 5.3413059,5.9357176 C5.3413059,5.69843289 5.14601848,5.50314547 4.90873376,5.50314547 L2.56603469,5.50314547 C2.32665011,5.50314547 2.13346256,5.69843289 2.13346256,5.9357176 Z M9.03571789,10.4524816 L2.56603469,10.4524816 C2.32874997,10.4524816 2.13346256,10.647769 2.13346256,10.8850538 C2.13346256,11.1223385 2.32874997,11.3176259 2.56603469,11.3176259 L9.03571789,11.3176259 C9.2730026,11.3176259 9.46829002,11.1223385 9.46829002,10.8850538 C9.46829002,10.647769 9.2730026,10.4524816 9.03571789,10.4524816 Z" id="形状"></path>
                    <path d="M7.31382882,0 L0.825396825,0 C0.369542746,0 0,0.369542746 0,0.825396825 L0,13.3696887 C0,13.8255428 0.369542746,14.1950855 0.825396825,14.1950855 L10.7301587,14.1950855 C11.1860128,14.1950855 11.5555556,13.8255428 11.5555556,13.3696887 L11.5555556,4.24172673 L7.31382882,0 Z M7.87659257,1.78698488 L9.86936419,3.7797565 L8.02568296,3.7797565 C7.9458881,3.7797565 7.87659257,3.71046096 7.87659257,3.6306661 L7.87659257,1.78698488 Z M0.865144265,13.3299412 L0.8630444,13.3278414 L0.8630444,0.867244129 L0.865144265,0.865144265 L6.9757506,0.865144265 L7.00934844,0.8987421 L7.00934844,3.62856624 C7.00934844,4.18713025 7.46711895,4.6428009 8.0235831,4.6428009 L10.6883114,4.6428009 L10.6883114,13.3278414 L0.865144265,13.3299412 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`}} />

const icon5 = <div dangerouslySetInnerHTML={{ __html: `
    <?xml version="1.0" encoding="UTF-8"?>
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>切片</title>
        <g id="智能一页交互调整" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="hover显示“智能一页”" transform="translate(-1150.000000, -1115.000000)" fill="#6E727C">
                <g id="编组-5备份-10" transform="translate(1111.000000, 1107.000000)">
                    <g id="模块管理备份-3" transform="translate(39.000000, 8.000000)">
                        <path d="M10,2 L14,2 L14,4 L11,4 L11,12 L14,12 L14,14 L10,14 L10,2 Z M5,12 L5,4 L2,4 L2,2 L6,2 L6,14 L2,14 L2,12 L5,12 Z" id="形状备份" fill-rule="nonzero" transform="translate(8.000000, 8.000000) rotate(-90.000000) translate(-8.000000, -8.000000) "></path>
                        <path d="M1.3925,6.53458953 L3.20825,7.79508953 C3.27555456,7.84180606 3.31569787,7.91853567 3.31569787,8.00046453 C3.31569787,8.08239338 3.27555456,8.159123 3.20825,8.20583953 L1.3925,9.46633953 C1.31608674,9.51934977 1.21656358,9.52556225 1.13415113,9.48246632 C1.05173868,9.43937038 1.00005994,9.35408989 1,9.26108953 L1,6.73983953 C1.00005994,6.64683916 1.05173868,6.56155868 1.13415113,6.51846274 C1.21656358,6.4753668 1.31608674,6.48157929 1.3925,6.53458953 Z M14.8813489,6.51846274 C14.9637613,6.56155868 15.0154401,6.64683916 15.0155,6.73983953 L15.0155,9.26108953 C15.0154401,9.35408989 14.9637613,9.43937038 14.8813489,9.48246632 C14.7989364,9.52556225 14.6994133,9.51934977 14.623,9.46633953 L12.80725,8.20583953 C12.7399454,8.159123 12.6998021,8.08239338 12.6998021,8.00046453 C12.6998021,7.91853567 12.7399454,7.84180606 12.80725,7.79508953 L14.623,6.53458953 C14.6994133,6.48157929 14.7989364,6.4753668 14.8813489,6.51846274 Z" id="形状结合"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    `}} />
export default ((props) => {
    const {onClickModule, setThemeVisible, setChangeTemplateVisible, setImportModalVisible} = props;
    const dispatch = useDispatch();
    const openAutoPagination = useSelector((state) => state.pagination.openAutoPagination);
    const openHintPagination = useSelector((state) => state.pagination.openHintPagination);
    const openAutoOnePage = useSelector((state) => state.pagination.openAutoOnePage);
    const onePageLevel = useSelector((state) => state.pagination.onePageLevel);

    const instance = useResumeInstance();
    const onClickHintPagination = () => {
        dispatch(paginationSlice.actions.switchHintPagination())

    }
    const onClickAutoPagination = () => {
        dispatch(paginationSlice.actions.switchAutoPagination())
    }
    const onClickAutoOnePage = () => {
        dispatch(paginationSlice.actions.switchAutoOnePage())
    }
    const clickPageButtonQuery = () => {
        const hide = message.loading("", 0)
        console.log(instance)
        instance.mergeOnePage().then((res)=>{
            console.log(res)
        }).catch(() => {
            message.info("内容过长,无法合并一页")
        }).finally(() => {
            hide()
        })
    }

   

    return <Container2Styled>
        <ContainerStyled>
            <CellStyled>
                <Space>
                    <ModuleItemStyled onClick={() => setThemeVisible(true)}>{icon2}主题设置</ModuleItemStyled>
                    <ModuleItemStyled unActivePath={true} onClick={() => setChangeTemplateVisible(true)}>{icon3}更换模板</ModuleItemStyled>
                    <ModuleItemStyled onClick={onClickModule}>{icon1}模块管理</ModuleItemStyled>
                </Space>
            </CellStyled>
            
        </ContainerStyled>
    </Container2Styled>
})
