import thunk from 'redux-thunk';
import reducers from './reducers';
import initState from './initState';
import {configureStore} from '@reduxjs/toolkit';


export const createStore = (props = {}) => {
    const {devTools = false} = props;
    return configureStore({
        reducer: reducers,
        devTools,
        preloadedState: props.initState||initState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    });
}


