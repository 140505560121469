// 提示用户收藏到小程序

import JzImage from "@/components/JzImage"
import { isMiniProgram } from "@/pages/Mobile/utils"
import { useLayoutEffect, useState } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

const MaskStyled = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
`

const WxTipStyled = styled.div`
    position: fixed;
    z-index: 101;
    right: 9px;
    top: 10px;
    width: 246px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    padding: 14px;
    border-radius: 10px;

    &::after {
        content: "";
        position: absolute;
        right: 40px;
        top: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        z-index: -1;
        /* filter: drop-shadow(1px 3px 1px rgba(0, 0, 0, .2)); */
    }

    .tip-btn {
        position: absolute;
        bottom: -50px;
        width: 104px;
        height: 38px;
        border-radius: 19px;
        border: 1px solid rgba(255,255,255,0.49);
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
    }
`

const useWxAppTip = () => {
    const [visible, setVisible] = useState(false)

    useLayoutEffect(() => {
        const wx_app_tip = window.localStorage.getItem('wx_app_tip')
        const isShow = isMiniProgram() && !wx_app_tip
        if (isShow) {
            window.localStorage.setItem('wx_app_tip', true)
            setVisible(true)
        }
    }, [])

    return {
        el: visible && createPortal(<div>
            <WxTipStyled>
                <div className="mb-10">1、点击右上角</div>
                <div className="mb-9">2、选择添加到“我的小程序”</div>
                <JzImage className="mb-16" style={{ width: '100%'}} src="nvjkcxiuhsfiusfh1111.png" />
                <div className="mb-10">3、微信首页下边，进入了了简历小程序</div>
                <JzImage style={{ width: '100%'}} src="jkdnfjksdfkjs2222.png" />
                <div onClick={() => setVisible(false)} className="flex-center tip-btn">我知道了</div>
            </WxTipStyled>
            <MaskStyled />
        </div>, document.body)
    }
}


export default useWxAppTip