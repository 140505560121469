import React from 'react';
import FlexStyled from '@/styled/FlexStyled';
import Pointer from '@/styled/Pointer';
import {BtnStyleEl} from "@/components/ErrorModal";


export default (props) => {
  const { onCancel } = props;
  return <FlexStyled $flexDirection='column' $alignItems='flex-start' $justifyContent='flex-start' style={{
    width: 400,
    // minHeight: 160,
    background: '#FFFFFF',
    borderRadius: 10,
    pointerEvents: 'auto',
    padding: '20px'
  }}>
    <div style={{ fontSize: 18, fontWeight: 'bold' }}>生成超出限制</div>
    <FlexStyled $justifyContent='center' style={{ marginTop: 15, fontSize: 14, color: '#999999' }}>AI ChatGPT生成达到使用限制，请升级</FlexStyled>
    <FlexStyled $justifyContent='flex-end' style={{ width: '100%', marginTop: 20, fontSize: 16, color: '#007AFF' }}>
      {/*<Pointer onClick={onCancel}>升级会员</Pointer>*/}
      <BtnStyleEl onClick={onCancel}>升级</BtnStyleEl>

    </FlexStyled>
  </FlexStyled>;
};
