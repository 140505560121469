// import Header from '@/components/TemplateStation/Header';
import React from "react";
import {
  ArticleContainerStyled,
  ArticleTitleStyled,
  ArticleParagraphStyled,
  ArticleTitle2Styled,
} from "@/pages/Agreement/styled";
// import FlexStyled from '@/styled/FlexStyled';
import config from "@/const";
import Footer from "@/components/Footer";
import styled from "styled-components";
import Header from "@/components/Header";

const FlexStyled = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  align-self: ${(props) => props.alignSelf};
  flex: ${(props) => props.flex};
  flex-wrap: ${(props) => props.flexWrap};
  flex-wrap: ${(props) => props.flexWrap};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
`;

export default () => {
  return (
    <>
      <FlexStyled
        flexDirection="column"
        style={{ minHeight: "100vh", background: "#eceff1" }}
      >
        <Header />
        <FlexStyled
          flexGrow={1}
          justifyContent="center"
          style={{ paddingTop: 40, paddingBottom: 40 }}
        >
          <ArticleContainerStyled>
            <ArticleTitleStyled>了了简历法律声明</ArticleTitleStyled>
            <ArticleTitle2Styled>法律声明</ArticleTitle2Styled>
            <ArticleParagraphStyled>
              版权声明网站相关作品是由了了简历签约设计师设计上传，了了简历拥有版权;未经了了简历书面授权，请勿作他用。人物肖像，字体及音频如需商用需第三方额外授权
            </ArticleParagraphStyled>
            <ArticleTitle2Styled>知识产权保护</ArticleTitle2Styled>
            <ArticleParagraphStyled>
              了了简历尊重知识产权，如知识产权权利人认为平台内容涉嫌侵权，可通过邮箱：ai.support@jzmbti.com提出书面通知，我们将及时处理。网站提供的党政主题相关内容(国旗、国徽、党微...)，目的在于配合国家政策宣传，仅限个人学习分享使用，禁止用于任何广告和商用目的
            </ArticleParagraphStyled>
          </ArticleContainerStyled>
        </FlexStyled>
        <Footer />
      </FlexStyled>
    </>
  );
};
