import styled from "styled-components"
import MobileLayout from "../../layout"
import { listResumeUser } from "@/http/api"
import moment from "moment"
import { useEffect, useState } from "react"
import UserResumeItem from "../Home/components/UserResumeItem"
import { InfiniteScroll } from "antd-mobile"
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js"
import {mobileSlice,fetchResumeUserList} from '@/pages/Mobile/mobileSlice.js'
import store from "@/redux/store"
import { useDispatch, useSelector } from 'react-redux'
import FlexStyled from "@/styled/FlexStyled"
import { useNavigate } from "react-router-dom"
const MyStyled = styled.div`
    background-color: rgba(247, 248, 249, 1);
    padding: 15px;
    min-height: 100vh;
    box-sizing: border-box;
    .my-title {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-bottom: 15px;
    }
    .img{
        width: 160px;
        height: 160px;
        margin-top:150px;
    }
    .word{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #A0A7B1;
        margin:12px 0;
    }
    .btn{
            margin-top: 13px;
            width: 124px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            background: #11BBA6;
            border-radius: 6px;
            color: #ffffff;
            font-weight: 500;
            font-size: 14px;
        }
`


const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {hasMore ? (
          <>
            {/* <span>加载中...</span> */}
          </>
        ) : (
          <span></span>
        )}
      </>
    )
  }
const List = () => {
    const [mutation, setMutation] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const [dataList, setDataList] = useState([])
    const [current, setCurrent] = useState(1)
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    useEffect(() => {
        if (mutation > 0) {
            setCurrent(1) 
            setDataList([])
            setHasMore(true)
        }
    }, [mutation])

    const fetchList = async () => {
        if (loading) return 
        setLoading(true)
        const res = await listResumeUser({
            query: {
                page: current,
                pageSize: 6
            }
        });
        const dataList = res?.dataList?.map((item) => {
            let data = JSON.parse(item.pagesData?.[0]?.userData);;
            return {
                data,
                resume: {
                    id: item.id, resumeId: item.id,
                },
                resumeData: data,
                name: item.name,
                updateTime: moment(item.updateTime).format("YYYY-MM-DD HH:mm"),
                id: item.id
            }
        });
        if (dataList) {
            setCurrent(current => current + 1)
            setDataList((list) => [...list, ...dataList])
            setHasMore(dataList.length > 0)
        } else {
            setHasMore(false)
        }
        setTotal(res?.count || 0)
        dispatch(fetchResumeUserList())

        // dispatch(mobileSlice.actions.set({resumeUserCount:res.count,resumeUserList:res.dataList,}))
        setLoading(false)
    }

    const handleMutation = () => {
        setMutation(m => m + 1)
    }

    return  <MyStyled>
        <NavigationHeader title={document.title+'('+total+')'} style={{left:'-15px',top:'-15px',width:'100vw'}}></NavigationHeader>
        {total > 0 && <div className="my-wrap">
            {dataList.map(item => {
                return <UserResumeItem 
                    key={item.id} 
                    id={item.id} 
                    title={item.name} 
                    
                    time={item.updateTime}
                    style={{ marginBottom: 15 }}
                    onMutation={handleMutation}
                />
            })}
        </div>}
        {total === 0 &&
        <FlexStyled $flexDirection="column" $alignItems="center" $justifyContent="center" $alignContent="center" >
            <img className="img" src='https://pub.jianzeppt.cn/image/%E6%9A%82%E6%97%A0%E7%AE%80%E5%8E%86%402x.png'>
            </img>
            <div className="word">暂无简历，请先</div>
            <div onClick={() => navigate(`/mobile/index`)} className="btn">新建简历</div>

            </FlexStyled> }
        <InfiniteScroll loadMore={fetchList} hasMore={hasMore} >
            <InfiniteScrollContent hasMore={hasMore} />
        </InfiniteScroll>
    </MyStyled>
}

export default List