import React from 'react';
import ErrorBoundary from '../components/ErrorBoundary';
import {createElement} from "react";

export default (WrappedComponent) => {
    return React.forwardRef((props, ref) => {
        return (
            <ErrorBoundary onError={props.onError}>
                {createElement(WrappedComponent, {...props, ref})}
            </ErrorBoundary>
        );
    });
};
