import styled from "styled-components"

export const LoadingStyled = styled.div`
    position: relative;
    ${props => props.$loading && `opacity: 0.65`};
    &::before {
        content: "";
        width: 20px;
        height: 20px;
        border: 2px solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: ${props => props.$loading ? `inline-block` : 'none'};
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        margin-right: 10px;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 
`