import {createElement, useCallback, useEffect, useMemo, useRef} from "react";
import withComponent from "@/hoc/withComponent";
import styled from "styled-components";
import useResumeInstance from "@/components/resume/hooks/useResumeInstance";
import useInputValidate from "./hooks/useInputValidate";


const style = (props) => `

  outline: none;
  &:empty:before {
   content: attr(data-placeholder);
    color: gray;
     cursor: text;
     }
`


export const PInputStyled = styled.p`
  ${props => style(props)}
  &:focus {
    /* outline: ${props => props.$borderWidth}px ${props => props.$borderStyle} #11BBA6; */
    /* background-color: rgba(188,188,188,0.3); */
  }

  ${props => props.$color ? `color:${props.$color}` : ""}
`
export const SpanInputStyled = styled.span`

  ${props => style(props)}
  &:focus {
    /* outline: ${props => props.$borderWidth}px ${props => props.$borderStyle} #11BBA6; */
    /* background-color: rgba(188,188,188,0.3); */
  }

  ${props => props.$color ? `color:${props.$color}` : ""}
`
export const DivInputStyled = styled.div`
  ${props => style(props)}
  &:focus {
    /* outline: ${props => props.$borderWidth}px ${props => props.$borderStyle} #11BBA6; */
    /* background-color: rgba(188,188,188,0.3); */
  }

  ${props => props.$color ? `color:${props.$color}` : ""}
`
export const valueIsEmpty = (value) => {
    const dom = new DOMParser().parseFromString(value, 'text/html');
    if (dom.body.childNodes.length === 0) {
        return true;
    }
    // @ts-ignore
    return (dom.body.childNodes.length === 1 && (dom.body.childNodes[0].innerHTML === '&nbsp;' || dom.body.childNodes[0].innerHTML === '' || dom.body.childNodes[0].innerText === ''));
};
export default withComponent((props) => {
    const {
        tagName = "div",
        style,
        className,
        value,
        data,
        color,
        borderStyle = "dashed",
        placeholder,
        onKeyDown: onKeyDown$1,
        slot_value,
    } = props;
    const {onInput: onInput$1, onFocus, onBlur,onPre} = props;
    const ref = useRef()
    useInputValidate({ ref, slot_value })

    const onPaste = (event) => {
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text/plain');
        const plainText = pastedData.replace(/<[^>]+>/g, '');
        document.execCommand('insertText', false, plainText);
    }
    const onKeyDown = useCallback((e) => {
        if (e.ctrlKey && e.keyCode === 90) {
            e.preventDefault();
            onPre?.()
            ref.current.blur();
            return
        }
        onKeyDown$1?.(e)
    }, [])
    const onInput = useCallback((e) => {
        onInput$1(e)
    }, [])
    const instance = useResumeInstance();

    let tagName1;
    if (tagName === "span") {
        tagName1 = SpanInputStyled
    } else if (tagName === "p") {
        tagName1 = PInputStyled
    } else {
        tagName1 = DivInputStyled
    }

    return createElement(tagName1, {
        onInput,
        style,
        onFocus,
        onBlur,
        onKeyDown,
        onPaste,
        $color: color,
        $borderStyle: borderStyle, // $isEmpty: isEmpty,
        "$borderWidth": 1 / instance.scale,
        className,
        "data-placeholder": placeholder, ...data,
        contentEditable: "true",
        ref,
        dangerouslySetInnerHTML: {__html: value}
    });
})
