import styled from "styled-components";
import useResumeData from "@/pages/Mobile/hooks/useResumeData";
import { TextArea } from "antd-mobile";
import { useEffect, useMemo, useState } from "react";
import { getFieldConfigByModuleName } from "./config";
import { isEmpty } from "lodash";
import JzButton from "@/components/JzButton";
import useResumeFieldVisible from "@/pages/Mobile/hooks/useResumeFieldVisible";
import { Module } from "@/common/module/module";
import useUpdateResumeData from "@/pages/Mobile/hooks/useUpdateResumeData";
import useQuery from "@/hooks/useQuery";
import getPlaceholder from "@/common/config/getPlaceholder";
import { useNavigate } from "react-router-dom";
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js";

const ModuleEditStyled = styled.div`
  padding: 20px;
  margin-bottom: 52px;

  .adm-form-item-label {
    font-size: 12px !important;
  }
  .adm-input-element,
  .adm-form-item-child-inner {
    font-size: 15px !important;
  }
  .adm-list-default .adm-list-body {
    border: none;
  }
  .resume-wrap-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 15px;
    padding-bottom: 24px;
    background-color: #ffffff;
  }
  .long-title {
    font-size: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #161619;
    margin-bottom: 24px;
  }

  textarea {
    height: calc(100vh - 206px);
  }
  .adm-text-area-count {
    font-size: 13px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #b3b3b3;
  }
`;

// const moduleName = Module.JOB_INTEREST

const LongTextEdit = () => {
  let { name: moduleName, contentIndex, fieldName, customId } = useQuery();
  if (customId) {
    moduleName = customId;
  }
  const navigate = useNavigate();
  const fieldConfig = getFieldConfigByModuleName(moduleName);
  const { data: resumeData } = useResumeData();
  const { onChangeByPath } = useUpdateResumeData();
  const { fieldList } = useResumeFieldVisible({ moduleName, fieldConfig });
  const basicInfoData = useMemo(() => {
    if (contentIndex !== undefined) {
      return resumeData?.[moduleName]?.data?.content?.[contentIndex] || {};
    } else {
      return resumeData?.[moduleName]?.data || {};
    }
  }, [resumeData, moduleName, contentIndex]);
  const [inputValue, setInputValue] = useState(basicInfoData[fieldName]);
  const field = fieldList.find((item) => item.value === fieldName);

  const onFinish = () => {
    if (!fieldName) return;

    let path = "";
    if (contentIndex !== undefined) {
      path = `${moduleName}.data.content[${contentIndex}].${fieldName}`;
    } else {
      path = `${moduleName}.data.${fieldName}`;
    }
    if (basicInfoData[fieldName] === inputValue) {
      navigate(-1);
    } else {
      onChangeByPath(path, inputValue);
    }
  };

  useEffect(() => {
    if (!isEmpty(basicInfoData)) {
      setInputValue(basicInfoData[fieldName]);
    }
  }, [basicInfoData, fieldName]);

  let placeholder =
    getPlaceholder(moduleName, fieldName) ||
    "在这里添加自定义描述（5000字以内）";
  if (moduleName === Module.EDUCATIONAL_BACKGROUND) {
    placeholder = getPlaceholder(moduleName, undefined, fieldName);
  }

  return (
    <ModuleEditStyled>
      <NavigationHeader
        style={{ width: "100vw", left: "-20px", top: "-20px" }}
        title={document.title}
      ></NavigationHeader>

      <div>
        <div className="long-title">
          {field.label || basicInfoData["title_text"]}
        </div>
        <div>
          <TextArea
            style={{
              "--font-size": 15,
            }}
            onChange={(v) => setInputValue(v)}
            value={inputValue}
            placeholder={placeholder}
            maxLength={5000}
            showCount
          />
        </div>
      </div>
      <div className="resume-wrap-footer flex">
        <JzButton onClick={() => onFinish()} className="flex-1" $type="primary">
          保存
        </JzButton>
      </div>
    </ModuleEditStyled>
  );
};

export default LongTextEdit;
