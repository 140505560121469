import {message, Modal, Spin} from 'antd';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';
import Search from '@/components/IconModal/Search';
import FlexStyled from '@/styled/FlexStyled';

import axios from 'axios';
import useForceUpdate from '@/hooks/useForceUpdate';
import {Scrollbars} from 'react-custom-scrollbars';
import Pointer from "@/styled/Pointer";
import {getSearchIcon} from "@/http/api";

const closeIcon = <div dangerouslySetInnerHTML={{
    __html: `<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='新建文档流程～～' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='大图预览弹窗' transform='translate(-1428.000000, -284.000000)'>
            <g id='字体下拉备份-7' transform='translate(1428.000000, 284.000000)'>
                <circle id='椭圆形' fill='#5C5C5C' cx='12' cy='12' r='12'></circle>
                <g id='closel' transform='translate(7.000000, 7.000000)' fill='#FFFFFF' fill-rule='nonzero'>
                    <path d='M9.7663551,8.65168539 L6.11645342,5.00039471 L9.76740755,1.34910402 C10.0768283,1.03965476 10.0778807,0.540746783 9.76846001,0.231297529 C9.46009173,-0.0770991764 8.95912483,-0.0770991764 8.651809,0.231297529 L4.99980241,3.88258822 L1.34779583,0.231297529 C1.04048,-0.0770991764 0.540565552,-0.0770991764 0.231144821,0.231297529 C-0.0761710068,0.539694235 -0.0761710068,1.04070731 0.233249724,1.35015657 L3.88315141,5.00039471 L0.232197273,8.65063284 C-0.0772234582,8.95902955 -0.0772234582,9.45899008 0.231144821,9.76843933 C0.385855187,9.92421651 0.590030771,10 0.78999655,10 C0.989962328,10 1.19413791,9.92421651 1.34779583,9.76949188 L4.99980241,6.11820119 L8.651809,9.76949188 C8.80651937,9.92316396 9.00859005,10 9.20960828,10 C9.41167896,10 9.61374964,9.92421651 9.76740755,9.76949188 C10.0757758,9.45899008 10.0768283,8.9600821 9.7663551,8.65168539 Z' id='路径'></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}}/>;
export const ContainerStyled = styled.div`
  pointer-events: auto;
  width: 990px;
  height: 684px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  overflow: hidden;
`;
const LeftContainerStyled = styled.div`
  width: 174px;
  height: 100%;
  background: #2C2C2C;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
`;
const RightContainerStyled = styled.div`
  width: 816px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const TagItemStyled = styled.div`
  width: 158px;
  height: 38px;
  border-radius: 6px;
  margin-bottom: 4px;
  overflow: hidden;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 38px;
  padding-left: 16px;
  cursor: pointer;

  ${props => props.active ? 'color: #11BBA6;' : 'color: #AFAFAF;'}
  ${props => props.active ? 'background: rgba(17, 187, 166, 0.14);' : ''}
  &:hover {
    color: #11BBA6;
    background: rgba(17, 187, 166, 0.14);
  }
`;
const IconItemStyled = styled.div`
  width: 138px;
  height: 138px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #EEEEEE;
  margin-right: 15px;
  overflow: hidden;
  margin-bottom: 15px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;
const IconContainerStyled = styled.div`
  padding-left: 23px;
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
`;

const configs = [{id: '0', text: '所有图标'}];
const iconList = [
    "&#xe64b;",
    "&#xe64c;",
    "&#xe64d;",
    "&#xe64e;",
    "&#xe64f;",
    "&#xe650;",
    "&#xe651;",
    "&#xe652;",
    "&#xe653;",
    "&#xe654;",
    "&#xe655;",
    "&#xe656;",
    "&#xe657;",
    "&#xe658;",
    "&#xe659;",
    "&#xe65a;",
    "&#xe65b;",
    "&#xe65c;",
    "&#xe65d;",
    "&#xe65e;",
    "&#xe65f;",
    "&#xe660;",
    "&#xe661;",
    "&#xe662;",
    "&#xe663;",
    "&#xe664;",
    "&#xe665;",
    "&#xe666;",
    "&#xe667;",
    "&#xe668;",
    "&#xe669;",
    "&#xe66a;",
    "&#xe66b;",
    "&#xe66c;",
    "&#xe66d;",
    "&#xe66e;",
    "&#xe66f;",
    "&#xe670;",
    "&#xe671;",
    "&#xe672;",
    "&#xe673;",
    "&#xe674;",
    "&#xe675;",
    "&#xe676;",
    "&#xe677;",
    "&#xe678;",
    "&#xe679;",
    "&#xe67a;",
    "&#xe67b;",
    "&#xe67c;",
    "&#xe67d;",
    "&#xe69b;",
    "&#xe69c;",
    "&#xe69d;",
    "&#xe69e;",
    "&#xe69f;",
    "&#xe6a0;",
    "&#xe6a1;",
    "&#xe6a2;",
    "&#xe6a3;",
    "&#xe6a4;",
    "&#xe6a5;",
    "&#xe6a6;",
    "&#xe6a7;",
    "&#xe6a8;",
    "&#xe6a9;",
    "&#xe6aa;",
    "&#xe6ab;",
    "&#xe6ac;",
    "&#xe6ad;",
    "&#xe6ae;",
    "&#xe6af;",
    "&#xe6b0;",
    "&#xe6b1;",
    "&#xe6b2;",
    "&#xe6b3;",
    "&#xe6b4;",
    "&#xe6b5;",
    "&#xe6b6;",
    "&#xe67e;",
    "&#xe67f;",
    "&#xe680;",
    "&#xe681;",
    "&#xe682;",
    "&#xe683;",
    "&#xe684;",
    "&#xe685;",
    "&#xe686;",
    "&#xe687;",
    "&#xe688;",
    "&#xe689;",
    "&#xe68a;",
    "&#xe68b;",
    "&#xe68c;",
    "&#xe68d;",
    "&#xe68e;",
    "&#xe68f;",
    "&#xe690;",
    "&#xe691;",
    "&#xe692;",
    "&#xe693;",
    "&#xe694;",
    "&#xe695;",
    "&#xe696;",
    "&#xe697;",
    "&#xe698;",
    "&#xe699;",
    "&#xe69a;",
    "&#xe6b7;",
    "&#xe6b8;",
    "&#xe6b9;",
    "&#xe6ba;",
    "&#xe6bb;",
    "&#xe6bc;",
    "&#xe6bd;",
    "&#xe6be;",
    "&#xe6bf;",
    "&#xe6c0;",
    "&#xe6c1;",
    "&#xe62e;",
    "&#xe62f;",
    "&#xe630;",
    "&#xe631;",
    "&#xe632;",
    "&#xe633;",
    "&#xe634;",
    "&#xe635;",
    "&#xe636;",
    "&#xe637;",
    "&#xe638;",
    "&#xe639;",
    "&#xe63a;",
    "&#xe63b;",
    "&#xe63c;",
    "&#xe63d;",
    "&#xe63e;",
    "&#xe63f;",
    "&#xe640;",
    "&#xe641;",
    "&#xe642;",
    "&#xe643;",
    "&#xe644;",
    "&#xe645;",
    "&#xe646;",
    "&#xe647;",
    "&#xe648;",
    "&#xe649;",
    "&#xe64a;",
    "&#xe601;",
    "&#xe602;",
    "&#xe603;",
    "&#xe604;",
    "&#xe605;",
    "&#xe606;",
    "&#xe607;",
    "&#xe608;",
    "&#xe609;",
    "&#xe60a;",
    "&#xe60b;",
    "&#xe60c;",
    "&#xe60d;",
    "&#xe60e;",
    "&#xe60f;",
    "&#xe610;",
    "&#xe611;",
    "&#xe612;",
    "&#xe613;",
    "&#xe626;",
    "&#xe627;",
    "&#xe628;",
    "&#xe629;",
    "&#xe62a;",
    "&#xe62b;",
    "&#xe62c;",
    "&#xe62d;",
    "&#xe614;",
    "&#xe615;",
    "&#xe616;",
    "&#xe617;",
    "&#xe618;",
    "&#xe619;",
    "&#xe61a;",
    "&#xe61b;",
    "&#xe61c;",
    "&#xe61d;",
    "&#xe61e;",
    "&#xe61f;",
    "&#xe620;",
    "&#xe621;",
    "&#xe622;",
    "&#xe623;",
    "&#xe624;",
    "&#xe625;"
]
const Container1 = (props) => {
    const {value, item, onClick} = props;
    return <IconItemStyled onClick={() => onClick?.(item)}>
        <div className={`icon iconfont`} dangerouslySetInnerHTML={{__html: item}}
             style={{width: 66, height: 66, fontSize: 60, lineHeight: "60px", textAlign: "center"}}>
        </div>
    </IconItemStyled>
}

const Container = (props) => {
    const {onClickIcon, onCancel} = props;
    const that = useRef({
        dataSource: undefined,
        list: [],
        loading: false,
        current: 1,
        count: undefined,
        pageSize: 60,
        searchValue: '',
        typeIndex: 0
    }).current;
    const [, update] = useForceUpdate(false);
    const inputRef = useRef();
    const cancelFetchData = useCallback(() => {
        if (that.dataSource) {
            that.dataSource.cancel();
            that.dataSource = null;
        }
    }, []);
    const onChange = (e) => {
        that.searchValue = e.target.value;
    };
    const onChangeTypeIndex = async (index) => {
        that.typeIndex = index;
        that.list = [];
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        update();
        try {
            await fetchData(1, that.pageSize, '', configs[index]?.id);
        } finally {
        }
    };
    const fetchData = async (current, pageSize, searchText, searchType) => {
        try {
            cancelFetchData();
            let source = axios.CancelToken.source();
            that.dataSource = source;
            that.loading = true;
            const tags = searchText ? [searchText] : [];
            const data = await getSearchIcon({
                body: {
                    pageNum: current, pageSize: pageSize, tags, searchType,
                }
            });
            that.list = [...that.list, ...data.dataList];
            that.current = data.currPage;
            that.pageCount = data.pageCount;
            that.count = data.count;
        } finally {
            that.loading = false;
            update();
        }
    };
    const onScroll = (e) => {
        return
        const canFetch = (e.target.scrollHeight - 200 < e.target.scrollTop + e.target.offsetHeight) && !that.loading && (that.current <= that.pageCount);
        if (canFetch) {
            fetchData(that.current + 1, that.pageSize, that.searchValue, configs[that.typeIndex]?.id);
        }
    };
    const onEnter = () => {
        that.list = [];
        update();
        fetchData(1, that.pageSize, that.searchValue, configs[that.typeIndex]?.id);
    };

    return <ContainerStyled>
        <Pointer onClick={onCancel} style={{position: "absolute", top: 0, right: -30}}>{closeIcon}</Pointer>
        <LeftContainerStyled>
            {configs.map((config, index) => {
                return <TagItemStyled key={config.id} active={index === that.typeIndex}
                                      onClick={() => onChangeTypeIndex(index)}>{config.text}</TagItemStyled>;
            })}
        </LeftContainerStyled>
        <RightContainerStyled>
            <FlexStyled $flexShrink={0} $justifyContent='center' style={{paddingTop: 15}}>
            </FlexStyled>
            <FlexStyled $flexGrow={1} style={{paddingTop: 15}}>
                <Scrollbars>
                    <IconContainerStyled>
                        {iconList.map((item) => {
                            return <Container1 key={item} item={item} onClick={onClickIcon}
                                               value={item}/>
                        })}
                        <FlexStyled $justifyContent='center'
                                    style={{
                                        paddingTop: 16, width: '100%', visibility: that.loading ? undefined : 'hidden'
                                    }}>
                            <Spin/>
                        </FlexStyled>
                    </IconContainerStyled>
                </Scrollbars>
            </FlexStyled>
        </RightContainerStyled>
    </ContainerStyled>;
};

export default (props) => {
    const {
        onCancel,
        visible,
        getContainer,
        zIndex,
        centered = true,
        onKeyDown,
        destroyOnClose = true,
        mask = true,
        showFooter = true,
        maskClosable = true
    } = props;
    return <Modal width={'auto'}
                  visible={visible}
                  footer={false}
                  transitionName=''
                  getContainer={getContainer}
                  destroyOnClose={destroyOnClose}
                  mask={mask}
                  zIndex={zIndex}
                  maskClosable={maskClosable}
                  centered={centered}
                  onCancel={onCancel}
                  onKeyDown={onKeyDown}
                  maskTransitionName=''
                  onWheel={(e) => e.stopPropagation()}
                  modalRender={() => <Container {...props} showFooter={showFooter}/>}
    />;
}
