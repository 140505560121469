import React from 'react';
import FlexStyled from '../styled/FlexStyled';
import {Spin} from "antd";

export default (props) => {
  const { style = {}, children, loading, className } = props;

  return <div className={className} style={{ position: loading ? 'relative' : undefined, visibility: loading ? 'hidden' : undefined, pointerEvents: loading ? 'none' : undefined, ...style }}>
    {
      loading && <FlexStyled $alignItems='center' $justifyContent='center' style={{ position: 'absolute', width: '100%', height: '100%', visibility: 'visible' }}>
        <Spin />
      </FlexStyled>
    }
    {children}
  </div>;
};
