// 购买结果页
import { useEffect, useState } from 'react'
// import { mobileWechatQr } from '@/service/mobile'
import { ReactComponent as WeSvg } from '@/pages/Mobile/svg/wx.svg'
import CenterModal from '../CenterModal'
import store from 'store'
import './index.scss'
import { gzhQRCode } from '@/http/api'

const PaySuccessModal = ({ isBind, visible, onClose }) => {
    const [ticket, setTicket] = useState('')
    useEffect(() => {
        if (!visible) return () => { }
        const { id, mobile } = store.get('user')
        const data = {
            state: `phone-${mobile}-user-id-${id}=`
        }
        gzhQRCode(data).then(res => {
            setTicket(res.ticket)
        })
    }, [visible])

    const handleReload = () => {
        localStorage.removeItem('outTradeNo')
        window.location.reload()
    }

    // 改成弹窗
    return <CenterModal visible={visible} onClose={onClose}>
        {
            !isBind ? <div className="pay-success-result">
                <div className='icon'>
                    <img src='https://oss.jianzeppt.com/user/573972/d2cbd5da-caa5-4528-a02f-06cf40ce77c7.png' alt='success' />
                </div>
                <h3>支付成功</h3>
                <p>关注公众号，PC端不走失</p>
                <div className="card">
                    <div className='list'>
                        <div className="item">【1】将本页截屏</div>
                        <div className="item">【2】打开<span className='wx-icon'><WeSvg></WeSvg></span>「扫一扫」，选择「相册」，选择截屏照片</div>
                        <div className="item">【3】关注公众号，即可绑定会员</div>
                    </div>
                    <div className="qr-code">
                        <img src={`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${ticket}`} alt="qr-code"></img>
                    </div>
                    <div onClick={onClose} className="jz-resume-btn">我已截屏并关注</div>
                </div>
            </div> :
                <div className="pay-success-result is-bind">
                    <h3 className='bind-wx'>绑定微信</h3>
                    <p className='bind-wx-p'>关注公众号，与PC端账户绑定：</p>
                    <div className="card">
                        <div className='list'>
                            <div className="item">【1】将本页截屏</div>
                            <div className="item">【2】打开<span className='wx-icon'><WeSvg></WeSvg></span>「扫一扫」，选择「相册」，选择截屏照片</div>
                            <div className="item">【3】关注公众号，即可相互绑定</div>
                        </div>
                        <div className="qr-code">
                            <img src={`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${ticket}`} alt="qr-code"></img>
                        </div>
                        <div onClick={handleReload} className="jz-resume-btn">我已截屏并关注，刷新页面</div>
                    </div>
                </div>
        }

    </CenterModal>
}

export default PaySuccessModal
