import styled from 'styled-components';

export const CellStyled = styled.div`
  height: 40px;
  border-bottom: 1px solid #E3E3E3;
  line-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Cell1Styled = styled(CellStyled)`
  width: 386px;
  padding-left: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const Cell2Styled = styled(CellStyled)`
  width: 472px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const Cell3Styled = styled(CellStyled)`
  width: 124px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const Cell4Styled = styled(CellStyled)`
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const Header2Styled = styled.div`
  height: 40px;
  background: #F6F6F6;
  display: flex;
  align-items: center;
`;
