export const fontFamilyList = [{
    id: 'Aileron-Regular',
    name: 'Aileron-Regular',
    fontFamily: 'Aileron-Regular',
    url: `https://pub.jianzeppt.cn/fonts/Aileron-Regular.ttf`,
}, {
    id: 'Times New Roman',
    name: 'Times New Roman',
    fontFamily: 'Times New Roman',
    url: `https://pub.jianzeppt.cn/fonts/Times%20New%20Roman.ttf`,
}, {
    id: '阿里汉仪智能黑体',
    name: '阿里汉仪智能黑体',
    fontFamily: '阿里汉仪智能黑体',
    url: `https://pub.jianzeppt.cn/fonts/阿里汉仪智能黑体.ttf`,
}, {
    id: '方正楷体简体',
    name: '方正楷体简体',
    fontFamily: '方正楷体简体',
    url: `https://pub.jianzeppt.cn/fonts/方正楷体简体.ttf`,
}, {
    id: '方正书宋简体',
    name: '方正书宋简体',
    fontFamily: '方正书宋简体',
    url: `https://pub.jianzeppt.cn/fonts/方正书宋简体.ttf`,
}, {
    id: '仿宋_GB2312',
    name: '仿宋_GB2312',
    fontFamily: '仿宋_GB2312',
    url: `https://pub.jianzeppt.cn/fonts/仿宋_GB2312.ttf`,
}, {
    id: '黑体', name: '黑体', fontFamily: '黑体', url: `https://pub.jianzeppt.cn/fonts/黑体.ttf`,
}, {
    id: '楷体', name: '楷体', fontFamily: '楷体', url: `https://pub.jianzeppt.cn/fonts/楷体.ttf`,
}, {
    id: '隶书', name: '隶书', fontFamily: '隶书', url: `https://pub.jianzeppt.cn/fonts/隶书.ttf`,
}, {
    id: '苹方黑体-简体',
    name: '苹方黑体-简体',
    fontFamily: '苹方黑体-简体',
    url: `https://pub.jianzeppt.cn/fonts/苹方黑体-简体.ttf`,
}, {
    id: '思源黑体CN',
    name: '思源黑体CN',
    fontFamily: '思源黑体CN',
    url: `https://pub.jianzeppt.cn/fonts/思源黑体CN.otf`,
}, {
    id: '思源宋体CN',
    name: '思源宋体CN',
    fontFamily: '思源宋体CN',
    url: `https://pub.jianzeppt.cn/fonts/思源宋体CN.otf`,
},];

const loadFont = (font) => {

    return new Promise((resolve, reject) => {
        const { fontFamily, url } = font;
        const newFontFace = new FontFace(fontFamily, `url(${url})`);
        newFontFace.load().then((loadedFace) => {
          document.fonts.add(loadedFace);
          resolve()
        }).catch((error) => {
          console.error(`Failed to load font: ${fontFamily}`, error);
          reject(error)
        });
    })
}

export default loadFont