import styled from "styled-components"
import { BASE_FIELD_ENUM } from "@/common/field/field";
import { useNavigate } from "react-router-dom";
import useQueryParam from "@/hooks/useQueryParam";
import { uploadFile } from "@/http/api";
import useQueryField from "@/pages/Mobile/hooks/useQueryField";
import { Module } from "@/common/module/module";
import useUpdateResumeData from "@/pages/Mobile/hooks/useUpdateResumeData";
import { BASE_Slot } from "@/common/slot/slot";

const BasicInfoStyled = styled.div`
    padding: 20px;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;

    .resume-name {
        font-size: 24px;
        display: flex;
        margin-bottom: 8px;
        padding-left:110px;
        transform: translate(0, 26px);
    }
    .resume-basic {
        font-size: 12px;
        color: #888888;
        margin-bottom: 16px;
        padding-left:110px;
        transform: translate(0, 30px);
        display:flex;
        justify-content: space-between;
        &-avatar {
            position: absolute;
            top: 16px;
            left: 20px;
            width: 100px;
            height: 100px;
            img {
                height: 100%;
            }
            input {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
    }
    .resume-info-list{
        padding-top:40px;
    }

    .resume-info-item {
        font-size: 12px;
        color: #555555;
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`

export const editSvg = <div className="flex-center" dangerouslySetInnerHTML={{
    __html: `
    <?xml version="1.0" encoding="UTF-8"?>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>切片</title>
        <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="编辑模板" transform="translate(-112.000000, -117.000000)" fill="#333333" fill-rule="nonzero">
                <g id="编辑" transform="translate(112.000000, 117.000000)">
                    <path d="M11.2178053,5 C11.6470088,5 11.994912,5.34802225 11.994912,5.77722567 C11.994912,6.20642909 11.6470088,6.55438562 11.2178053,6.55445133 L6.55445133,6.55445133 L6.55445133,17.4356107 L17.4356107,17.4356107 L17.4356107,12.7722567 L17.4410513,12.6813213 C17.4896208,12.2729744 17.8478674,11.9731293 18.2583858,11.997229 C18.6689042,12.0213287 18.9896059,12.3610317 18.990062,12.7722567 L18.990062,17.4356107 C18.990062,17.8478768 18.82629,18.2432576 18.5347738,18.5347738 C18.2432576,18.82629 17.8478768,18.990062 17.4356107,18.990062 L6.55445133,18.990062 C5.69595157,18.990062 5,18.2941105 5,17.4356107 L5,6.55445133 C5,5.69595157 5.69595157,5 6.55445133,5 L11.2178053,5 Z M18.7724389,5.22772712 C19.0758537,5.53123363 19.0758537,6.02321771 18.7724389,6.32672422 L11.6281805,13.4717598 C11.3232135,13.7663064 10.8384535,13.7620938 10.5386515,13.4622917 C10.2388494,13.1624897 10.2346368,12.6777297 10.5291834,12.3727627 L17.6734418,5.22850435 C17.9769483,4.92508949 18.4689323,4.92508949 18.7724389,5.22850435 L18.7724389,5.22772712 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </svg>
`}}>

</div>

const BasicInfo = (props) => {
    const { style, data = {} } = props
    const navigate = useNavigate()
    const resumeId = useQueryParam('id')
    const avatar = useQueryField({ moduleName: Module.AVATAR })
    const { onChangeByPath } = useUpdateResumeData({ disableNavigate: true })

    const infoList = [
        BASE_FIELD_ENUM.PHONE_NUMBER,
        BASE_FIELD_ENUM.MAIL,
        BASE_FIELD_ENUM.WECHAT_ID,
        BASE_FIELD_ENUM.NATION,
        BASE_FIELD_ENUM.ADDRESS_AND_ORIGIN,
        BASE_FIELD_ENUM.HEIGHT,
        BASE_FIELD_ENUM.WEIGHT,
        BASE_FIELD_ENUM.MARITAL_STATUS,
        BASE_FIELD_ENUM.POLITICS_STATUS,
    ]

    const info2List = [
        BASE_FIELD_ENUM.YEARS_OF_WORKING,
        BASE_FIELD_ENUM.GENDER,
        BASE_Slot.BIRTHDAY_YEAR,
        BASE_FIELD_ENUM.EDUCATION_BACKGROUND,
        BASE_FIELD_ENUM.PRESENT_ADDRESS,
    ].filter(item => {
        return data[item]
    })
    const newInfo2List = info2List.reduce((acc,val,i)=>{
        if (i !== info2List.length - 1) {
            return [...acc, val, '|'];
        } else {
            return [...acc, val];
        }
    },[])
    console.log(newInfo2List)
    // console.log('info2List', info2List, data)
    const name = data[BASE_FIELD_ENUM.NAME]

    // BASE_FIELD_ENUM

    const handleClickEdit = () => {
        navigate(`/mobile/edit/basic?id=${resumeId}`)
    }

    const handleChange = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file);

        const fileURL = await uploadFile({ body: formData });
        onChangeByPath(`${Module.AVATAR}.data.src`, fileURL)
    }

    return <BasicInfoStyled style={style}>
        <div onClick={handleClickEdit}>
            <div className="flex-center justify-start resume-name">
                {name}
                <div style={{ marginLeft: 10 }}>
                    {editSvg}
                </div>
            </div>
            <div className="resume-basic">
                {
                    newInfo2List.map((item, index) => {
                        if(item==='|'){
                            return <span key={index}> {item} </span>
                        }
                        let text = data[item]
                        if (item === BASE_FIELD_ENUM.YEARS_OF_WORKING) {
                            if (!isNaN(Number(data[item]))) {
                                text = data[item] + '年经验'
                            }
                        } else if (item === BASE_Slot.BIRTHDAY_YEAR) {
                            const year = new Date().getFullYear();
                            const birthdayYear = data['生日年份']
                            const age = Number.isNaN(year - Number(birthdayYear)) ? 0 : year - Number(birthdayYear)
                            text = age + '岁'
                        }
                        return <span key={index}> {text} </span>
                    })
                }
            </div>
            <div className="resume-info-list">
                {
                    infoList.map((infoItem, index) => {
                        return data[infoItem] && <div className="resume-info-item">{infoItem}：{data[infoItem]}</div>
                    })
                }
            </div>
        </div>
        {
            avatar?.visible !== false && <div className="flex-center resume-basic-avatar">
                <img src={avatar?.data?.src} />

                <input accept="image/*" type="file" onChange={handleChange} />
            </div>
        }
    </BasicInfoStyled>
}

export default BasicInfo