import withComponent from "@/hoc/withComponent"
import styled from "styled-components"
import { headerFeat1Svg, headerFeat2Svg, headerFeat3Svg, headerFeat4Svg, headerInput1Svg, headerInput2Svg } from "../svg"
import JzImage from "@/components/JzImage"
import User from '@/components/user/index';
import Tabs from "./Tabs"

const HeaderStyled = styled.div`
    background: linear-gradient(180deg, #27DFD2 0%, #11BBA6 100%);
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    padding-top: 100px;
    position: relative;
    .logo-img {
        position: absolute;
        width: 141px;
        height: 36px;
        top: 16px;
        left: 36px;
    }
    .header-h1 {
        font-size: 48px;
        color: #FFFFFF;
        margin-bottom: 19px;
        text-align: center;
    }
    .header-p {
        font-size: 18px;
        color: #FFFFFF;
        margin-bottom: 36px;
        text-align: center;
    }
    .header-tabs {
        width: 528px;
        margin: 0 auto;
    }
    .header-feat {
        font-size: 13px;
        color: #FFFFFF;
        background: #009B89;
        > div {
            padding: 10px 8px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }
        svg {
            width: 24px;
            height: 24px;
        }
    }
    .header-feat-p {
        color: rgba(255,255,255,0.8); 
        max-width: 118px;
    }
`

const featList = [
    {
        svg: headerFeat1Svg,
        title: '自动排版',
        p: '输入内容、自动排版生成简历',
    },
    {
        svg: headerFeat2Svg,
        title: '专业规范',
        p: '知名HR推荐的简历',
    },
    {
        svg: headerFeat3Svg,
        title: '模板切换',
        p: '海量模板任意切换',
    },
    {
        svg: headerFeat4Svg,
        title: '云端存储',
        p: 'PC、小程序等随时下载',
    },
]

const Header = () => {

    return <HeaderStyled>
        <JzImage className="logo-img" src="sldknfijxcbnvjhbu.png" />
        <div className="flex-center" style={{ position: 'absolute', right: 36, top: 16}}>
            <div style={{ fontSize: 14, color: '#FFF', cursor: 'pointer', marginRight: 50}} onClick={() => window.open('/')}>简历模板</div>
            <User />
        </div>
        
        <div className="header-h1">高效智能的在线简历编辑神器</div>
        <div className="header-p">一键应用专业模版，轻松斩获高薪offer</div>

        <div className="header-tabs">
            <Tabs />
        </div>

        <div className="header-feat">
            <div style={{ maxWidth: 1030, margin: '0 auto' }}>
                {featList.map((item, index) => {
                    return <div className="flex-center justify-start flex-col" key={index}>
                        <div className="flex-center mb-6" >
                            {item.svg}
                            <div className="pl-4">{item.title}</div>
                        </div>
                        <div className="header-feat-p" style={{ color: 'rgba(255,255,255,0.8)', fontSize: 12, textAlign: 'center' }}>
                            {item.p}
                        </div>
                    </div>
                })}
            </div>
        </div>
    </HeaderStyled>
}

export default withComponent(Header)