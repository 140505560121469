import withComponent from '@/hoc/withComponent';
import React, { useEffect, useRef, useState } from 'react';
import FlexStyled from '@/styled/FlexStyled';
import config from '@/const/config';

import LogoComponent from "@/components/Logo"

import styled from 'styled-components';
import ThemeButton from '@/styled/ThemeButton';
import User from "@/components/user";

const { themeColor } = config

const BoxStyled = styled.div`
  background: linear-gradient(180deg, #C3E7F6, rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(10px);
  height: 60px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
`;
const InputContainerPositionStyled = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const InputContainerStyled = styled.div`
  pointer-events: auto;
  position: relative;
  width: 580px;
  height: 44px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 28px;
  padding-right: 5px;
  display: flex;
  align-items: center;

  ::placeholder {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #888888;
  }
`;
const InputStyled = styled.input`
  flex-grow: 1;
  outline: none;
  border: none;
  height: 100%;
  margin-right: 28px;

  ::placeholder {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #888888;
  }
`;
const ButtonStyled = styled(ThemeButton)`
  width: 56px;
  height: 38px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchButtonStyled = styled(ThemeButton)`
  flex-shrink: 0;
  width: 110px;
  height: 34px;
  background: #00BCA6;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 14px;
  letter-spacing: 1px;

`;

const SearchButtonStyled2 = styled.div`
  flex-shrink: 0;
  width: 110px;
  height: 34px;
  background: #FFFFFF;
  border-radius: 6px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${themeColor.color};
  border: 1px solid ${themeColor.color};

  :hover {
    color: ${themeColor.hoverColor};
    border: 1px solid ${themeColor.hoverColor};
  }

  :active {
    :hover {
      color: ${themeColor.activeColor};
      border: 1px solid ${themeColor.activeColor};
    }
  }
`;

export default withComponent((props) => {
    const {
        value,
        onChange,
        onSearch,
        onKeyDown
    } = props;

    return <>
        <BoxStyled>
            <LogoComponent isDark={false} />
            <InputContainerPositionStyled>
                <InputContainerStyled>
                    <InputStyled onKeyDown={onKeyDown} value={value} onChange={onChange} placeholder="在1000+专业简历中搜索" />
                    <SearchButtonStyled onClick={onSearch}>
                        搜索模板
                    </SearchButtonStyled>
                </InputContainerStyled>
            </InputContainerPositionStyled>
            <FlexStyled>
                <User />
            </FlexStyled>
        </BoxStyled>
    </>;
});
